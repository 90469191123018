import { gql } from 'graphql-request';
import { utility } from '@leaf/components';

export const getLocationsGql = ({ query, shipperId }) => {
  const whereGQL = gql`
    where: {
      _and: [
        { shipper_id: { _eq: "${shipperId}" } }
        ${
          query !== undefined && query !== ''
            ? gql`{
            _or: [
              ${utility.numbers.isNumber(query) ? gql`{ id: { _eq: "${query}" } }` : ''}
              ${utility.numbers.isNumber(query) ? gql`{ zip: { _ilike: "%${query}%" } }` : ''}
              {city: { _ilike: "%${query}%" } }
              {state: { _ilike: "%${query}%" } }
              {street: { _ilike: "%${query}%" } }
            ]}`
            : ''
        }
      ]
    }
  `;

  return gql`
    query getLocations($limit: Int, $orderBy: [execution_location_order_by], $offset: Int) {
      executionLocation: execution_location(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        businessName: business_name
        zip
        state
        city
        id
        street
      }
      executionLocationAggregate: execution_location_aggregate {
        aggregate {
          count
        }
      }
    }
  `;
};
