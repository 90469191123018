import React from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { v4 } from 'uuid';
import { DoubleValuesProgressBar, utility, PhasePanel, CompanyAvatar } from '..';
import {
  Container,
  RowWrapper,
  RowItem,
  ProgressWrapper,
  TypographyScoped,
  ExpandButtonWrapper,
  IconButtonTopWrapper,
  IconButtonWrapper,
  ExpandMoreIconWrapper,
  CircularProgressContainer,
} from './BoardInfoPanel.styled';

const BoardInfoPanel = ({
  data = [],
  selectedTotalValue,
  getPhaseData,
  handleSelectedShipper,
  selectedShipper,
  isDataLoading,
}) => {
  const [phasePanelData, setPhasePanelData] = React.useState([]);

  const getTotalValue = item => {
    const calculatedVakyes = {
      annualSpend: utility.format.money(item.annualSpend),
      numberOfLanes: utility.numbers.numberWithCommas(item.numberOfLanes),
      annualLoads: utility.numbers.numberWithCommas(item.annualLoads),
      annualizedCostSavings: utility.format.money(item.annualSpend),
      networkMoves: utility.format.money(item.annualSpend),
    };

    return calculatedVakyes[selectedTotalValue];
  };

  const hadleContrainerClicked = shipperName => {
    handleSelectedShipper(shipperName);
  };

  React.useEffect(() => {
    if (selectedShipper && data.length > 0) {
      const shipper = data.find(item => item.shipperName === selectedShipper);
      getPhaseData(shipper.shipperGuid).then(response => {
        setPhasePanelData(response);
      });
    }
  }, [selectedShipper, data]);

  const calculateAnnualSpendPercentage = (annualSpend, annualSpendTotal) => {
    if (annualSpend && annualSpendTotal) return (annualSpend / annualSpendTotal) * 100;

    return 0;
  };

  const formatNumber = value => {
    if (selectedTotalValue === 'numberOfLanes' || selectedTotalValue === 'annualLoads') {
      return utility.numbers.formatNumberAsView(value, 0, false);
    }

    return utility.format.money(value);
  };

  if (isDataLoading)
    return (
      <CircularProgressContainer>
        <CircularProgress />
      </CircularProgressContainer>
    );

  return (
    <>
      {data.length > 0 &&
        data?.map(item => (
          <React.Fragment key={v4()}>
            <Container
              onClick={() => hadleContrainerClicked(item.shipperName)}
              $selectedShipper={selectedShipper === item.shipperName}
            >
              <RowWrapper mb={2}>
                <RowItem>
                  <CompanyAvatar src={item.shipperId} width={24} height={24} />
                  <Typography variant="h6" noWrap>
                    {item.shipperName}
                  </Typography>
                </RowItem>
                <Typography variant="h6" noWrap>
                  Total {getTotalValue(item)}
                </Typography>
              </RowWrapper>
              <ProgressWrapper>
                <DoubleValuesProgressBar
                  primaryValue={calculateAnnualSpendPercentage(
                    item[`${selectedTotalValue}Scoped`],
                    item[selectedTotalValue],
                  )}
                  secondaryValue={calculateAnnualSpendPercentage(
                    item[`${selectedTotalValue}Unassigned`],
                    item[selectedTotalValue],
                  )}
                />
              </ProgressWrapper>
              <RowWrapper>
                <TypographyScoped variant="body1" $opacity="0.84" $bgColor="#0064B0" noWrap>
                  Scoped {formatNumber(item[`${selectedTotalValue}Scoped`]) ?? '-'}
                </TypographyScoped>
                <TypographyScoped variant="body1" $opacity="0.54" noWrap>
                  Unassigned {formatNumber(item[`${selectedTotalValue}Unassigned`]) ?? '-'}
                </TypographyScoped>
                <TypographyScoped variant="body1" $opacity="0.34" noWrap>
                  Out of scope {formatNumber(item[`${selectedTotalValue}OutOfScope`]) ?? '-'}
                </TypographyScoped>
              </RowWrapper>
              <ExpandButtonWrapper>
                <IconButtonTopWrapper disableRipple className="hover-state selected-state">
                  <ExpandMoreIconWrapper />
                </IconButtonTopWrapper>
                <IconButtonWrapper disableRipple className="selected-state">
                  <ExpandMoreIconWrapper />
                </IconButtonWrapper>
              </ExpandButtonWrapper>
            </Container>
            {selectedShipper === item.shipperName && <PhasePanel data={phasePanelData.data} />}
          </React.Fragment>
        ))}
    </>
  );
};

export default BoardInfoPanel;
