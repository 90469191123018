import React from 'react'
import { ContractType, CONTRACT_TYPE, Form } from '@leaf/components'
import styled from '@emotion/styled'
import { FormControl, FormControlLabel, FormGroup } from '@mui/material'
import { handleConfirmSpot } from '@/shipments/domain/Shipment'
import { useSnackbar } from 'notistack'

const { Switch } = Form.Generic

const ContentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default ({ id, type }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isChecked, setIsChecked] = React.useState(type === CONTRACT_TYPE.SPOT)

  const handleChangeStatus = (event) => {
    event.stopPropagation()
    setIsChecked(!isChecked)
    handleConfirmSpot(id, !isChecked)
      .then(() => {
        enqueueSnackbar(`Contract type successfully updated`, { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to update contract type! ${error}`)
        setIsChecked(isChecked)
      })
  }

  return (
    <ContentTypeContainer
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <ContractType type={isChecked ? CONTRACT_TYPE.SPOT : CONTRACT_TYPE.OTHER} />
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isChecked} size='small' onChange={handleChangeStatus} />}
            label='Confirm as Spot'
          />
        </FormGroup>
      </FormControl>
    </ContentTypeContainer>
  )
}
