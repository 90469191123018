import messages from '@/error/messages'

export default (error) => {
  const id = error?.response?.data?.id
  const key = error?.response?.data?.key
  const message =
    error?.response?.data?.message ||
    error?.response?.data?.messages?.join('\n') ||
    error.errorMessage ||
    error?.response?.data?.detail ||
    typeof error?.response?.data?.error !== 'object'
      ? error?.response?.data?.error
      : error?.response?.data?.error.ErrString

  const text = message || messages[key] || error?.message || messages.UNKNOWN

  return id ? `${text} ${id}` : text
}

export const tryCatch = (fn) => {
  try {
    return fn()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return Promise.reject(messages.INTERNAL)
  }
}
