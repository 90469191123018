import { utility } from '@leaf/components';

export default class CompanySimple {
  constructor({ created, ...response }) {
    Object.assign(this, {
      ...response,
      createdDate: utility.date.backendStringToNormalizedUtcDate(created),
    });

    this.view = {
      id: this.guid,
      createdDate: utility.date.normalizedUtcDateToViewString(this.createdDate),
    };
  }
}
