/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Stack,
  Button,
  FormControl,
  Box,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Form } from '@leaf/components'
import _, { debounce } from 'lodash'
import useGQL, { useGQLClientWithAuth } from '@/hooks/useGQL'
import { OverlayLoader } from '@/contracts/shared/OverlayLoader'
import CircularProgress from '@mui/material/CircularProgress'
import { isLikeUUID } from '@/utility/uuid'
import { useSnackbar } from 'notistack'
import styled from '@emotion/styled'
import { searchLane, searchLaneV2, getLaneById, getShippers } from '../domain/contractModel'
import { LaneOption } from './LaneOption'

const Container = styled(Box)`
  .MuiFormGroup-root {
    flex-direction: row;
  }
  .MuiAutocomplete-inputRoot {
    .Mui-disabled {
      padding: inherit;
    }
  }
`

const LEG_FORM_TYPE = {
  FROM_SCRATCH: 'FROM_SCRATCH',
  CREATE_FROM_ADAPT_LANE: 'CREATE_FROM_ADAPT_LANE',
}

const LEG_FORM_TYPE_DISPLAY_NAMES = {
  [LEG_FORM_TYPE.FROM_SCRATCH]: 'Create from Scratch',
  [LEG_FORM_TYPE.CREATE_FROM_ADAPT_LANE]: 'Create based on existing lane',
}

export const LegFields = ({
  index,
  defaultLaneOptions = [],
  deliveryPickupOptions = [],
  control,
  field,
  errors,
  clearErrors,
  remove,
  watch,
  setMapData,
  mapData,
  setValue,
  newLanes,
  liveLanes,
  mapRouteLegs,
  buyerId,
  showAreaPicker,
  editMode,
  isStandaloneLaneForm,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [variables, setVariables] = useState(null)
  const [laneOptions, setLaneOptions] = useState(defaultLaneOptions)
  const [selectedLane, setSelectedLane] = useState(null)
  const [legToRemove, setLegToRemove] = useState(-1)
  const fetchRequired = useRef(false)
  const firstRender = useRef(true)
  const [loadingLanes, setLoadingLanes] = useState(false)
  const [shippers, setShippers] = useState([])

  const watchLegOriginChange = watch(`legs.${index}.originName`)
  const watchLegDestinationChange = watch(`legs.${index}.destinationName`)
  const formType = watch(`legs.${index}.formType`)

  const [secureClient, loading] = useGQLClientWithAuth()
  const getGQLClient = useGQL()

  const queryBUYERID = buyerId ? [{ shipper: { id: { _eq: buyerId } } }] : []

  const constructSearchVariable = debounce((value) => {
    const operatorOR = []
    const operatorAND = []
    const searchTermValues = value.split(' ')

    if (searchTermValues.length === 1 && isLikeUUID(searchTermValues[0])) {
      operatorOR.push({ id: { _eq: `${searchTermValues[0]}` } })
    } else {
      searchTermValues.forEach((term) => {
        const originName = { origin_name: { _ilike: `%${term}%` } }
        const destinationName = { destination_name: { _ilike: `%${term}%` } }
        const shipperName = { shipper: { name: { _ilike: `%${term}%` } } }
        operatorOR.push(originName, destinationName, shipperName)
        operatorAND.push(originName, destinationName, shipperName)
      })
    }

    if (operatorAND.length >= 1) {
      setVariables({
        where: { _or: operatorOR, _and: [{ _or: operatorAND }, { _and: queryBUYERID }] },
      })
    } else {
      setVariables({ where: { _or: operatorOR, _and: queryBUYERID } })
    }
  }, 250)

  const removeByTeaHexFormat = (h3Cells) => {
    const BYTEA_HEX_FORMAT = '\\x0'
    return h3Cells?.map((cell) => cell.replace(BYTEA_HEX_FORMAT, ''))
  }

  const addLegToMap = React.useCallback(
    (lane) => {
      setMapData([...mapData, lane])
    },
    [mapData, setMapData]
  )

  const setDataFields = useCallback(
    (lane) => {
      const laneDetail = { ...lane }
      laneDetail.originH3Cells = removeByTeaHexFormat(lane.originH3Cells)
      laneDetail.destinationH3Cells = removeByTeaHexFormat(lane.destinationH3Cells)
      liveLanes.current[index] = lane
      newLanes.current[index] = laneDetail
      setValue(`legs.${index}.laneSelector`, lane)
      setValue(`legs.${index}.pickupType`, laneDetail.pickupType)
      setValue(`legs.${index}.pickupStops`, laneDetail.pickupStops)

      setValue(`legs.${index}.originName`, laneDetail.originName)
      setValue(`legs.${index}.destinationName`, laneDetail.destinationName)
      setValue(`legs.${index}.shipper`, laneDetail.shipper)

      setValue(`legs.${index}.deliveryType`, laneDetail.deliveryType)
      setValue(`legs.${index}.deliveryStops`, laneDetail.deliveryStops)
    },
    [index, liveLanes, newLanes, setValue]
  )

  const updateLaneDetails = React.useCallback(
    (lane) => {
      setDataFields(lane)
      clearErrors([
        `legs.${index}.pickupType`,
        `legs.${index}.deliveryType`,
        `legs.${index}.originName`,
        `legs.${index}.destinationName`,
        `legs.${index}.shipper`,
      ])
      fetchRequired.current = false
    },
    [index, setDataFields, clearErrors]
  )

  const removeLeg = () => {
    liveLanes.current.splice(index, 1)
    newLanes.current.splice(index, 1)
    if (liveLanes.current.length === 0) {
      liveLanes.current = [{}]
    }
    if (newLanes.current.length === 0) {
      newLanes.current = [{}]
    }
    setMapData([])
    setLegToRemove(index)
  }

  const handleLaneChange = (data) => {
    if (index === 0) {
      setMapData([])
    }
    if (data === null) {
      setValue(`legs.${index}.pickupType`, null)
      setValue(`legs.${index}.pickupStops`, '')
      setValue(`legs.${index}.deliveryType`, null)
      setValue(`legs.${index}.deliveryStops`, '')
      setValue(`legs.${index}.originName`, '')
      setValue(`legs.${index}.destinationName`, '')
      setValue(`legs.${index}.shipper`, null)
      liveLanes.current.splice(index, 1)
      newLanes.current.splice(index, 1)
    } else {
      fetchRequired.current = true
      setSelectedLane({ index, data })
    }
  }

  useEffect(() => {
    if (editMode && firstRender.current) {
      setDataFields(field)
      setMapData(mapRouteLegs)
      firstRender.current = false
    }
  }, [setDataFields, field, setMapData, mapRouteLegs, editMode])

  useEffect(() => {
    if (secureClient) {
      if (shippers?.length === 0) {
        getShippers(() => Promise.resolve(secureClient))
          .then((response) => {
            setShippers(response ?? [])
          })
          .catch(enqueueSnackbar)
      }
      if (variables !== null) {
        // Search lanes as the user types
        searchLane(secureClient, variables).then((res) => {
          setLaneOptions(res.lane)
          setLoadingLanes(false)
        })
      }
    }
  }, [secureClient, variables, enqueueSnackbar])

  useEffect(() => {
    if (isStandaloneLaneForm && !editMode) {
      setLoadingLanes(true)
      searchLaneV2(getGQLClient, variables)
        .then((res) => {
          setLaneOptions(res.lane)
          setLoadingLanes(false)
        })
        .catch(enqueueSnackbar)
    }
  }, [])

  useEffect(() => {
    if (formType && !isStandaloneLaneForm) {
      setValue(`legs.${index}.laneSelector`, null)
      setValue(`legs.${index}.pickupType`, null)
      setValue(`legs.${index}.pickupStops`, '')
      setValue(`legs.${index}.deliveryType`, null)
      setValue(`legs.${index}.deliveryStops`, '')
      setValue(`legs.${index}.originName`, '')
      setValue(`legs.${index}.destinationName`, '')
      setValue(`legs.${index}.shipper`, null)
      if (newLanes.current[index]) {
        newLanes.current[index].originH3Cells = []
        newLanes.current[index].destinationH3Cells = []
      }
    }
  }, [formType])

  useEffect(() => {
    if (!firstRender.current && newLanes.current[index]) {
      newLanes.current[index].originName = watchLegOriginChange
    }
  }, [watchLegOriginChange])

  useEffect(() => {
    if (!firstRender.current && newLanes.current[index]) {
      newLanes.current[index].destinationName = watchLegDestinationChange
    }
  }, [watchLegDestinationChange])

  useEffect(() => {
    // Fetch lane details when user select a lane
    if (selectedLane && selectedLane.data && secureClient && fetchRequired.current) {
      getLaneById(secureClient, { where: { id: { _eq: selectedLane.data.id } } }).then((res) => {
        updateLaneDetails(res[0])
        addLegToMap(res[0])
      })
    }
  }, [selectedLane, secureClient, updateLaneDetails, addLegToMap])

  useEffect(() => {
    if (legToRemove !== -1) {
      remove(legToRemove)
      if (liveLanes.current?.length === 1) {
        const lane = liveLanes.current[0]
        setMapData(_.isEmpty(lane) ? [] : [lane])
      } else {
        setMapData(liveLanes.current)
      }
    }
  }, [legToRemove, remove, liveLanes, setMapData])

  if (loading) {
    return null
  }

  const isLaneRequired = !(
    newLanes.current[index]?.originH3Cells?.length &&
    newLanes.current[index]?.destinationH3Cells?.length
  )

  const shouldShowFormTypes = !editMode || (editMode && !field.laneId && !isStandaloneLaneForm)
  const shouldShowLaneSelector =
    shouldShowFormTypes && formType !== undefined && formType !== LEG_FORM_TYPE.FROM_SCRATCH

  return (
    <Container borderRadius={1} bgcolor='white' boxShadow={2} padding={2} marginBottom={4}>
      <OverlayLoader open={fetchRequired.current} />
      {!isStandaloneLaneForm && (
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={1}
          sx={{ mb: 1 }}
        >
          <Typography variant='h5'>Leg {index + 1}</Typography>
          <IconButton aria-label='delete' size='small' onClick={removeLeg}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      )}
      {shouldShowFormTypes && (
        <Box marginBottom={1}>
          <Controller
            name={`legs.${index}.formType`}
            control={control}
            defaultValue={LEG_FORM_TYPE.FROM_SCRATCH}
            render={(props) => (
              <Form.Generic.Radio
                {...props}
                options={Object.keys(LEG_FORM_TYPE_DISPLAY_NAMES).map((key) => ({
                  value: key,
                  label: LEG_FORM_TYPE_DISPLAY_NAMES[key],
                }))}
              />
            )}
          />
        </Box>
      )}
      {shouldShowLaneSelector && (
        <Controller
          name={`legs.${index}.laneSelector`}
          defaultValue={field.laneSelector}
          control={control}
          rules={isLaneRequired ? { required: true } : {}}
          loading={loadingLanes}
          render={({ value, onChange }) => (
            <Form.Generic.Autocomplete
              value={value}
              options={laneOptions}
              isOptionEqualToValue={(option, selected) => option.originName === selected.originName}
              getOptionLabel={(option) =>
                `${option.originName} - ${option.destinationName} ID: ${option.laneId}`
              }
              filterOptions={(x) => x}
              onChange={(__, data) => {
                // handle lane selection
                onChange(data)
                handleLaneChange(data)
              }}
              onInputChange={(event, data) => {
                // handles the variables for the search
                if (event && event.type === 'change') {
                  setLoadingLanes(true)
                  constructSearchVariable(data)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Select Leg*'
                  variant='outlined'
                  error={errors.legs ? errors.legs[index]?.laneSelector : false}
                  helperText={
                    errors.legs && errors.legs[index]?.laneSelector ? 'Required field' : null
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingLanes ? <CircularProgress sx={{ mr: 4 }} size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => <LaneOption props={props} option={option} />}
            />
          )}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant='h5'>Origin</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Form.Library.Input
              control={control}
              label='Origin Name*'
              InputLabelProps={{ shrink: true }}
              controllerProps={{
                rules: { required: true },
              }}
              name={`legs.${index}.originName`}
              error={errors.legs ? errors.legs[index]?.originName : false}
              helperText={errors.legs && errors.legs[index]?.originName ? 'Required field' : null}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' onClick={() => showAreaPicker(index, 'origin')}>
            {`${
              newLanes.current[index]?.originH3Cells?.length > 0 ? 'Adjust' : 'Define'
            } origin area`}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`legs.${index}.pickupType`}
            defaultValue={field.pickupType}
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={deliveryPickupOptions}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={(option) => option}
                onChange={(__, data) => {
                  if (newLanes.current[index]) {
                    newLanes.current[index].pickupType = data
                  }
                  onChange(data)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Pickup Type*'
                    error={errors.legs ? errors.legs[index]?.pickupType : false}
                    helperText={
                      errors.legs && errors.legs[index]?.pickupType ? 'Required field' : null
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`legs.${index}.pickupStops`}
            defaultValue={field.pickupStops}
            control={control}
            render={({ onChange, ...rest }) => (
              <TextField
                type='number'
                label='Pickup Stops'
                min='1'
                step='1'
                onChange={(event) => {
                  if (newLanes.current[index]) {
                    newLanes.current[index].pickupStops = event.target.value
                  }
                  onChange(event)
                }}
                {...rest}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant='h5'>Destination</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Form.Library.Input
              control={control}
              label='Destination Name*'
              InputLabelProps={{ shrink: true }}
              controllerProps={{
                rules: { required: true },
              }}
              error={errors.legs ? errors.legs[index]?.destinationName : false}
              helperText={
                errors.legs && errors.legs[index]?.destinationName ? 'Required field' : null
              }
              name={`legs.${index}.destinationName`}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' onClick={() => showAreaPicker(index, 'destination')}>
            {`${
              newLanes.current[index]?.destinationH3Cells?.length > 0 ? 'Adjust' : 'Define'
            } destination area`}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`legs.${index}.deliveryType`}
            defaultValue={field.deliveryType}
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={deliveryPickupOptions}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={(option) => option}
                onChange={(__, data) => {
                  if (newLanes.current[index]) {
                    newLanes.current[index].deliveryType = data
                  }
                  onChange(data)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Delivery Type*'
                    error={errors.legs ? errors.legs[index]?.deliveryType : false}
                    helperText={
                      errors.legs && errors.legs[index]?.deliveryType ? 'Required field' : null
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`legs.${index}.deliveryStops`}
            defaultValue={field.deliveryStops}
            control={control}
            render={({ onChange, ...rest }) => (
              <TextField
                type='number'
                label='Delivery Stops'
                min='1'
                step='1'
                onChange={(event) => {
                  if (newLanes.current[index]) {
                    newLanes.current[index].deliveryStops = event.target.value
                  }
                  onChange(event)
                }}
                {...rest}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={`legs.${index}.shipper`}
            defaultValue={field.shipper}
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={shippers}
                isOptionEqualToValue={(option, selected) => option.value === selected?.value}
                getOptionLabel={(option) => option.label}
                onChange={(__, data) => {
                  onChange(data)
                  if (newLanes.current[index]) {
                    newLanes.current[index].shipper = data
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Shipper*'
                    variant='outlined'
                    error={errors.legs ? errors.legs[index]?.shipper : false}
                    helperText={
                      errors.legs && errors.legs[index]?.shipper ? 'Required field' : null
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography variant='h6' sx={{ my: 2 }}>
        Loaded Miles:{' '}
        {newLanes.current[index] !== undefined ? newLanes.current[index].leafMiles : 0}
      </Typography>
    </Container>
  )
}
