export const toDecimalPercentage = percentage => percentage / 100;

export const toPercentage = decimal => decimal * 100;
export const toFixedNumber = (value, fractionDigits) => parseFloat(value.toFixed(fractionDigits));

export const formatNumberAsView = (number, digit = 0, showCurrency = true) => {
  if (typeof number === 'number') {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
      ...(showCurrency && {
        style: 'currency',
        currency: 'USD',
      }),
    }).format(number);
  }

  return '-';
};

const hasDecimals = number => number % 1 !== 0;

export const UNIT = {
  PERCENT: 'PERCENT',
  CURRENCY: 'USD',
};

const internationalNumberFormat = new Intl.NumberFormat('en-US');

export const numberWithCommas = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const calculateValue = (valueToFormat, unitSign = '', round, areOnlyThousandsRounded) => {
  let value = valueToFormat;
  let minus = '';
  if (value < 0) {
    minus = '-';
    value = Math.abs(valueToFormat);
  }

  if (value < 1e3) {
    if (round && areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(value)}`;
    return `${minus}${unitSign}${value}`;
  }

  if (value < 1e6) {
    const number = value / 1e3;
    if (round && areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}k`;
    return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}k`;
  }

  if (value < 1e9) {
    const number = value / 1e6;
    if (round && !areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}M`;
    return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}M`;
  }

  if (value < 1e12) {
    const number = value / 1e9;
    if (round && !areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}B`;
    return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}B`;
  }

  const number = value / 1e12;
  if (round && !areOnlyThousandsRounded) return `${minus}${unitSign}${Math.round(number)}T`;
  return `${minus}${unitSign}${number.toFixed(hasDecimals(number) ? 1 : 0)}T`;
};

export const formatNumber = (value, unit = UNIT.CURRENCY, round = false, areOnlyThousandsRounded = false) => {
  // TODO: Move these undefined checks in domain models when introduced
  if (value === undefined) {
    return '-';
  }

  if (unit === UNIT.PERCENT) {
    return `${(value * 100).toFixed()}%`;
  }
  if (unit === UNIT.CURRENCY) {
    return calculateValue(value, '$', round, areOnlyThousandsRounded);
  }

  if (!unit) {
    return calculateValue(value);
  }

  return internationalNumberFormat.format(value);
};
