import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, ListItem, FormControl, Typography, Box, Stack, Button } from '@mui/material'
import {
  SpacedContainer,
  PageContainer,
  PageContentLayout,
  Overview,
  Form,
  ContractType,
  CONTRACT_TYPE,
  utility,
} from '@leaf/components'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import useGQL from '@/hooks/useGQL'

import { GQL_EQUIPMENT_TYPES } from '@/graphql/filter-queries/GQL_EQUIPMENT_TYPES'
import { GQL_SHIPPERS } from '@/graphql/filter-queries/GQL_COMPANIES'
import Ops from '@/domain/planning/Ops'
import { getOpsPlanId } from './domain/GQL_OPS_PLANS_DETAILS'

const { Input, Datepicker, Select, ErrorMessage } = Form.Library

const validationSchema = yup.object({
  shipperId: yup.string().required(),
  routeId: yup.string().required(),
  equipmentTypeId: yup.string().required(),
  plannedWeeklyVolume: yup.string().required(),
  plannedAllocationPct: yup.string().required(),
  plannedShipperRpm: yup.string().required(),
  plannedStartDate: yup.string().required(),
  plannedEndDate: yup.string().required(),
  plannedLeafTenderAcceptance: yup.string().required(),
})

const SubtitleWrapper = styled.div`
  padding-top: ${({ theme }) => `${theme.spacing(1)}`};
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}`};
`

const BoxWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  position: fixed;
  bottom: 0;
  left: 60px;
  width: calc(100% - 60px);
  border-top: 1px solid #ccc;
  z-index: 1;
`

export default ({ opsPlan, setEditOpsPlan }) => {
  const getGQLClient = useGQL()
  const history = useHistory()
  const [shippers, setShippers] = useState()
  const [equipmentTypes, setEquipmentTypes] = useState()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    getGQLClient()
      .then((client) => client.request(GQL_SHIPPERS))
      .then((res) => setShippers(res.company))
    getGQLClient()
      .then((client) => client.request(GQL_EQUIPMENT_TYPES))
      .then((res) => setEquipmentTypes(res.equipment_type))
  }, [])

  const getImplementationPlanWeekly = (maxCapacityPctFlexWeekly, plannedWeeklyVolume) => {
    if (!maxCapacityPctFlexWeekly) return '-'
    if (plannedWeeklyVolume >= 25) return 2
    if (plannedWeeklyVolume >= 10) return 4
    if (plannedWeeklyVolume >= 4) return 6

    return '-'
  }

  const getImplementationPlanNetworkMove = (maxCapacityPctNetworkMove, plannedWeeklyVolume) => {
    if (!maxCapacityPctNetworkMove) return '-'
    if (plannedWeeklyVolume >= 25) return 4
    if (plannedWeeklyVolume >= 10) return 6
    if (plannedWeeklyVolume >= 4) return 8

    return '-'
  }

  const getImplementationPlanDayOf = (maxCapacityPctFlexDayOf, plannedWeeklyVolume) => {
    if (!maxCapacityPctFlexDayOf) return '-'
    if (plannedWeeklyVolume >= 25) return 6
    if (plannedWeeklyVolume >= 10) return 8
    if (plannedWeeklyVolume >= 4) return 10

    return '-'
  }

  const getInitialFormData = () => ({
    shipperId: opsPlan?.shipper.id,
    routeId: opsPlan?.route.id,
    equipmentTypeId: opsPlan?.equipmentType.id,
    adaptLaneId: opsPlan?.adaptLaneId,
    adaptRunDate: opsPlan?.adaptRunDate,
    adaptWeeklyVolume: opsPlan?.adaptWeeklyVolume,
    adaptTargetShipperRpm: opsPlan?.adaptTargetShipperRpm,
    plannedWeeklyVolume: opsPlan?.plannedWeeklyVolume,
    plannedAllocationPct: opsPlan?.plannedAllocationPct * 100,
    plannedShipperRpm: opsPlan?.plannedShipperRpm,
    plannedStartDate: opsPlan?.plannedStartDate,
    plannedEndDate: opsPlan?.plannedEndDate,
    plannedLeafTenderAcceptance: opsPlan?.plannedLeafTenderAcceptance * 100,
    maxCapacityPctSpot: opsPlan?.maxCapacityPctSpot * 100,
    maxCapacityPctRg: opsPlan?.maxCapacityPctRg * 100,
    maxCapacityPctFleet: opsPlan?.maxCapacityPctFleet * 100,
    maxCapacityPctFlexWeekly: opsPlan?.maxCapacityPctFlexWeekly * 100,
    maxCapacityPctNetworkMove: opsPlan?.maxCapacityPctNetworkMove * 100,
    maxCapacityPctFlexDayOf: opsPlan?.maxCapacityPctFlexDayOf * 100,
    lspTargetLinehaulRpmSpot: opsPlan?.lspTargetLinehaulRpmSpot,
    lspTargetLinehaulRpmRg: opsPlan?.lspTargetLinehaulRpmRg,
    lspTargetLinehaulRpmFleet: opsPlan?.lspTargetLinehaulRpmFleet,
    lspTargetLinehaulRpmFlexWeekly: opsPlan?.lspTargetLinehaulRpmFlexWeekly,
    lspTargetLinehaulRpmNetworkMove: opsPlan?.lspTargetLinehaulRpmNetworkMove,
    lspTargetLinehaulRpmFlexDayOf: opsPlan?.lspTargetLinehaulRpmFlexDayOf,
    implementationPlanSpot: opsPlan?.plannedWeeklyVolume && opsPlan?.maxCapacityPctSpot ? 0 : '-',
    implementationPlanRg: opsPlan?.plannedWeeklyVolume && opsPlan?.maxCapacityPctRg ? 0 : '-',
    implementationPlanFleet: opsPlan?.plannedWeeklyVolume && opsPlan?.maxCapacityPctFleet ? 4 : '-',
    implementationPlanWeekly: getImplementationPlanWeekly(
      opsPlan?.maxCapacityPctFlexWeekly,
      opsPlan?.plannedWeeklyVolume
    ),
    implementationPlanNetworkMove: getImplementationPlanNetworkMove(
      opsPlan?.maxCapacityPctNetworkMove,
      opsPlan?.plannedWeeklyVolume
    ),
    implementationPlanDayOf: getImplementationPlanDayOf(
      opsPlan?.maxCapacityPctNetworkMove,
      opsPlan?.plannedWeeklyVolume
    ),
  })

  const { handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getInitialFormData(),
  })

  const saveOpsPlan = (data) =>
    Ops.updateShipperOpsPlan({
      shipper_id: Number(data?.shipperId),
      route_id: data?.routeId,
      equipment_type_id: Number(data?.equipmentTypeId),
      adapt_lane_ref: data?.adaptLaneId,
      adapt_run_date: data?.adaptRunDate,
      adapt_weekly_volume: data?.adaptWeeklyVolume,
      adapt_target_shipper_rpm: data?.adaptTargetShipperRpm,
      planned_weekly_volume: Number(data?.plannedWeeklyVolume),
      planned_allocation_pct: utility.numbers.toDecimalPercentage(
        Number(data?.plannedAllocationPct)
      ),
      planned_shipper_rpm: Number(data?.plannedShipperRpm),
      planned_start_date: utility.date.toBackendFormat(data?.plannedStartDate),
      planned_end_date: utility.date.toBackendFormat(data?.plannedEndDate),
      planned_leaf_tender_acceptance_pct: utility.numbers.toDecimalPercentage(
        Number(data?.plannedLeafTenderAcceptance)
      ),
      max_capacity_pct_spot: data?.maxCapacityPctSpot,
      max_capacity_pct_rg: data?.maxCapacityPctRg,
      max_capacity_pct_fleet: data?.maxCapacityPctFleet,
      max_capacity_pct_flex_weekly: data?.maxCapacityPctFlexWeekly,
      max_capacity_pct_network_move: data?.maxCapacityPctNetworkMove,
      max_capacity_pct_flex_day_of: data?.maxCapacityPctFlexDayOf,
      lsp_target_linehaul_rpm_spot: data?.lspTargetLinehaulRpmSpot,
      lsp_target_linehaul_rpm_rg: data?.lspTargetLinehaulRpmRg,
      lsp_target_linehaul_rpm_fleet: data?.lspTargetLinehaulRpmFleet,
      lsp_target_linehaul_rpm_flex_weekly: data?.lspTargetLinehaulRpmFlexWeekly,
      lsp_target_linehaul_rpm_network_move: data?.lspTargetLinehaulRpmNetworkMove,
      lsp_target_linehaul_rpm_flex_day_of: data?.lspTargetLinehaulRpmFlexDayOf,
    }).then(() => enqueueSnackbar('Ops plan saved successfully', { variant: 'success' }))

  const handleSaveEditedOpsPlan = async (data) => {
    const client = await getGQLClient()

    const newIdRequired =
      Number(data?.shipperId) !== opsPlan?.shipper?.id ||
      data?.routeId !== opsPlan?.route?.id ||
      Number(data?.equipmentTypeId) !== opsPlan?.equipmentType?.id
    if (newIdRequired) {
      // If there are changes to shipper, route, or equipment type, it can potentially overwrite another existing plan
      // Check to make sure there are no other plans that have the same shipper, route, and equipement type
      const res = await client.request(
        getOpsPlanId(data?.shipperId, data?.routeId, data?.equipmentTypeId)
      )
      if (res.shipper_route_plan.length > 0) {
        enqueueSnackbar(
          'Unable to update ops plan: There is nother plan that already exists with the same shipper, route, and equipment type',
          { variant: 'error' }
        )
        return
      }
    }

    try {
      await saveOpsPlan(data)
      if (!newIdRequired) {
        // Simple update - Refresh the page
        history.go(0)
        return
      }
    } catch (error) {
      enqueueSnackbar(error)
      return
    }

    // Delete the old ops plan
    await Ops.deleteShipperOpsPlan(opsPlan.id).catch(enqueueSnackbar)

    // Get the new plan's ID and go to the details view for the new ops plan
    const res = await client.request(
      getOpsPlanId(data?.shipperId, data?.routeId, data?.equipmentTypeId)
    )
    setEditOpsPlan(false)
    history.push(`/planning/ops-plans/${res.shipper_route_plan[0]?.id}`)
  }

  React.useEffect(() => {
    if (opsPlan) {
      reset(getInitialFormData())
    }
  }, [opsPlan])

  const getListItem = (children) => (
    <ListItem disableGutters>
      <FormControl fullWidth>{children}</FormControl>
    </ListItem>
  )

  return (
    <PageContainer>
      <form
        onSubmit={handleSubmit((data) => {
          handleSaveEditedOpsPlan(data)
        })}
      >
        <SpacedContainer container spacing={2} autoWidth>
          {opsPlan && (
            <>
              <Grid item xs={4}>
                <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
                  <Overview title='Lane Overview' titleBgColor='blue'>
                    {getListItem(
                      <>
                        <Select
                          name='shipperId'
                          label='Shipper*'
                          options={shippers?.map((shipper) => ({
                            label: shipper.name,
                            id: shipper.id,
                          }))}
                          control={control}
                          error={errors.shipperId}
                        />
                        <ErrorMessage errors={errors} name='shipperId' />
                      </>
                    )}
                    {getListItem(
                      <>
                        <Input
                          name='routeId'
                          label='Route ID*'
                          control={control}
                          error={errors.routeId}
                        />
                        <ErrorMessage errors={errors} name='routeId' />
                      </>
                    )}
                    {getListItem(
                      <>
                        <Select
                          name='equipmentTypeId'
                          label='Equipment Type*'
                          options={equipmentTypes?.map((equipmentType) => ({
                            label: equipmentType.name,
                            id: equipmentType.id,
                          }))}
                          control={control}
                          error={errors.equipmentTypeId}
                        />
                        <ErrorMessage errors={errors} name='equipmentTypeId' />
                      </>
                    )}
                    {getListItem(
                      <Input name='adaptLaneId' label='Adapt Lane ID' control={control} />
                    )}
                    {getListItem(
                      <Datepicker
                        name='adaptRunDate'
                        label='Adapt Run Date'
                        useFieldNameHandler={false}
                        control={control}
                      />
                    )}
                    {getListItem(
                      <Input
                        name='adaptWeeklyVolume'
                        label='Adapt Weekly Volume'
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='adaptTargetShipperRpm'
                        label='Adapt Target Shipper RPM (USD)'
                        control={control}
                        type='number'
                      />
                    )}
                  </Overview>
                </PageContentLayout>
              </Grid>
              <Grid item xs={4}>
                <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
                  <Overview title='Plan Details' titleBgColor='green'>
                    {getListItem(
                      <>
                        <Input
                          name='plannedWeeklyVolume'
                          label='Planned Weekly Volume*'
                          control={control}
                          error={errors.plannedWeeklyVolume}
                        />
                        <ErrorMessage errors={errors} name='plannedWeeklyVolume' />
                      </>
                    )}
                    {getListItem(
                      <Input
                        name='plannedAllocationPct'
                        label='Planned Allocation* (%)'
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <>
                        <Input
                          name='plannedShipperRpm'
                          label='Planned Shipper RPM* (USD)'
                          control={control}
                          error={errors.plannedShipperRpm}
                        />
                        <ErrorMessage errors={errors} name='plannedShipperRpm' />
                      </>
                    )}
                    {getListItem(
                      <>
                        <Datepicker
                          name='plannedStartDate'
                          label='Planned Start Date*'
                          control={control}
                          error={errors.plannedStartDate}
                          useFieldNameHandler={false}
                        />
                        <ErrorMessage errors={errors} name='plannedStartDate' />
                      </>
                    )}
                    {getListItem(
                      <>
                        <Datepicker
                          name='plannedEndDate'
                          label='Planned End Date*'
                          control={control}
                          error={errors.plannedEndDate}
                          useFieldNameHandler={false}
                        />
                        <ErrorMessage errors={errors} name='plannedEndDate' />
                      </>
                    )}
                    {getListItem(
                      <>
                        <Input
                          name='plannedLeafTenderAcceptance'
                          label='Planned Leaf Tender Acceptance* (%)'
                          control={control}
                          error={errors.plannedLeafTenderAcceptance}
                          type='number'
                        />
                        <ErrorMessage errors={errors} name='plannedLeafTenderAcceptance' />
                      </>
                    )}
                  </Overview>
                </PageContentLayout>
              </Grid>
              <Grid item xs={4}>
                <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
                  <Overview title='Contract Types' titleBgColor='yellow'>
                    <SubtitleWrapper>
                      <Typography variant='h6'>Maximum Capacity by Contract Type (%)</Typography>
                    </SubtitleWrapper>
                    {getListItem(
                      <Input
                        name='maxCapacityPctSpot'
                        label={<ContractType type={CONTRACT_TYPE.SPOT} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='maxCapacityPctRg'
                        label={<ContractType type={CONTRACT_TYPE.UNCOMMITTED} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='maxCapacityPctFleet'
                        label={<ContractType type={CONTRACT_TYPE.FLEET} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='maxCapacityPctFlexWeekly'
                        label={<ContractType type={CONTRACT_TYPE.WEEK_OF} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='maxCapacityPctNetworkMove'
                        label={<ContractType type={CONTRACT_TYPE.NETWORK} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='maxCapacityPctFlexDayOf'
                        label={<ContractType type={CONTRACT_TYPE.DAY_OF} />}
                        control={control}
                        type='number'
                      />
                    )}
                    <SubtitleWrapper>
                      <Typography variant='h6'>LSP Target Linehaul RPM (USD) </Typography>
                    </SubtitleWrapper>
                    {getListItem(
                      <Input
                        name='lspTargetLinehaulRpmSpot'
                        label={<ContractType type={CONTRACT_TYPE.SPOT} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='lspTargetLinehaulRpmRg'
                        label={<ContractType type={CONTRACT_TYPE.UNCOMMITTED} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='lspTargetLinehaulRpmFleet'
                        label={<ContractType type={CONTRACT_TYPE.FLEET} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='lspTargetLinehaulRpmFlexWeekly'
                        label={<ContractType type={CONTRACT_TYPE.WEEK_OF} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='lspTargetLinehaulRpmNetworkMove'
                        label={<ContractType type={CONTRACT_TYPE.NETWORK} />}
                        control={control}
                        type='number'
                      />
                    )}
                    {getListItem(
                      <Input
                        name='lspTargetLinehaulRpmFlexDayOf'
                        label={<ContractType type={CONTRACT_TYPE.DAY_OF} />}
                        control={control}
                        type='number'
                      />
                    )}
                    <SubtitleWrapper>
                      <Typography variant='h6'>Implementation Week (Auto-Generated)</Typography>
                    </SubtitleWrapper>
                    {getListItem(
                      <Input
                        name='implementationPlanSpot'
                        label={<ContractType type={CONTRACT_TYPE.SPOT} />}
                        control={control}
                        disabled
                      />
                    )}
                    {getListItem(
                      <Input
                        name='implementationPlanRg'
                        label={<ContractType type={CONTRACT_TYPE.UNCOMMITTED} />}
                        control={control}
                        disabled
                      />
                    )}
                    {getListItem(
                      <Input
                        name='implementationPlanFleet'
                        label={<ContractType type={CONTRACT_TYPE.FLEET} />}
                        control={control}
                        disabled
                      />
                    )}
                    {getListItem(
                      <Input
                        name='implementationPlanWeekly'
                        label={<ContractType type={CONTRACT_TYPE.WEEK_OF} />}
                        control={control}
                        disabled
                      />
                    )}
                    {getListItem(
                      <Input
                        name='implementationPlanNetworkMove'
                        label={<ContractType type={CONTRACT_TYPE.NETWORK} />}
                        control={control}
                        disabled
                      />
                    )}
                    {getListItem(
                      <Input
                        name='implementationPlanDayOf'
                        label={<ContractType type={CONTRACT_TYPE.DAY_OF} />}
                        control={control}
                        disabled
                      />
                    )}
                  </Overview>
                </PageContentLayout>
              </Grid>
              <BoxWrapper>
                <p>* Fields are required</p>
                <Stack
                  spacing={2}
                  direction='row'
                  sx={{ justifyContent: 'flex-end', margin: '4px' }}
                >
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => setEditOpsPlan(false)}
                  >
                    Cancel
                  </Button>
                  <Button type='submit' variant='contained' color='primary'>
                    Save
                  </Button>
                </Stack>
              </BoxWrapper>
            </>
          )}
        </SpacedContainer>
      </form>
    </PageContainer>
  )
}
