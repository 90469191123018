import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import { setupAxios } from '@/setup/axios'
import sentry from '@/setup/sentry'
import axios from 'axios'

import App from '@/App'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUIX_PRO_LICENSE_KEY || '')

sentry()

const AppWithToken = () => {
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (getAccessTokenSilently) {
      setupAxios(getAccessTokenSilently)
    }
  }, [getAccessTokenSilently])

  return <App />
}

// TODO: Change this to a real type
const onRedirectCallback = async (appState?: any, user?: any) => {
  await axios.post(`/users/${user?.sub.replace('auth0|leaf|', '')}/login`)

  window.history.replaceState(appState, document.title, `${window.location.origin}/continue`)
}

ReactDOM.render(
  <Auth0Provider
    domain={import.meta.env.VITE_AUTH0_DOMAIN || ''}
    clientId={import.meta.env.VITE_AUTH0_CLIENT_ID || ''}
    redirectUri={`${window.location.origin}/continue`}
    onRedirectCallback={onRedirectCallback}
    audience={import.meta.env.VITE_AUTH0_AUDIENCE}
  >
    <AppWithToken />
  </Auth0Provider>,
  document.getElementById('root')
)
