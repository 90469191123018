/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Grid, Box, Typography } from '@mui/material';
import { CompanyAvatar, USER_TYPE } from '@leaf/components';

const CustomRenderOption = styled(Box)`
  border-bottom: 1px solid #ccc;
`;

const MoreInfo = styled(Typography)`
  margin: 4px 0;
`;

export const LaneOption = ({ props, option }) => {
  const avatarName = option.shipper !== null ? option.shipper.name : 'Shipper';
  const avatarId = option.shipper && option.shipper.id;
  return (
    <CustomRenderOption {...props}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={3}>
          <CompanyAvatar name={avatarName} id={avatarId} type={USER_TYPE.SHIPPER} isChip />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">{option.originName}</Typography>
        </Grid>

        <Grid item xs={1}>
          <ArrowRightAltIcon />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">{option.destinationName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <MoreInfo>
            ID - {option.id}
            <br /> Created at -{new Date(option.createdAt).toLocaleDateString('en-US')}
          </MoreInfo>
        </Grid>
      </Grid>
    </CustomRenderOption>
  );
};
