import React, { useState } from 'react'
import axios from 'axios'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  DialogActions,
  Autocomplete,
  CircularProgress,
  Box,
  Alert,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import styled from '@emotion/styled'

import { useLoading } from '@leaf/components'

import { ROLES } from '@/constants/roles'
import { cloneDeep } from 'lodash'

const CustomDialogOptions = styled(DialogActions)`
  justify-content: ${({ edit }) => (edit ? 'space-between' : 'flex-end')};
`

const isValid = (user, isAdmin) => {
  const v = user.first_name && user.last_name && user.email && user.role
  if (isAdmin) {
    return v
  }
  return v && user.company_id && user.default_app
}

const defaultUser = {
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  company_id: null,
  default_app: null,
  phone_number: '',
}

export default ({ title, onClose, open, companies, selectedUser }) => {
  const [user, setUser] = React.useState(selectedUser || defaultUser)
  const [inviteLink, setInviteLink] = useState(null)

  const { enqueueSnackbar } = useSnackbar()

  const isAdmin = user.role === 'LE_ADMIN'
  const canSubmit = isValid(user, isAdmin)

  const handleClose = () => {
    onClose()
    setUser(defaultUser)
    setInviteLink(null)
  }

  const onSuccess = (message) => (response) => {
    if (response?.url) {
      setInviteLink(response?.url)
    }
    enqueueSnackbar(message, { variant: 'success' })
  }
  const onFailure = (message) => () => {
    enqueueSnackbar(message, { variant: 'failure' })
  }

  const buildMessage = (messageType) => [
    `User successfully ${messageType}. Please refresh the page.`,
    `User could not be ${messageType}.`,
  ]

  const [doRequest, isLoading] = useLoading(async () => {
    const payload = cloneDeep(user)

    if (isAdmin) {
      delete payload.company_id
      delete payload.default_app
    }
    delete payload.company

    const messageType = selectedUser ? 'updated' : 'created'
    const [successMessage, failureMessage] = buildMessage(messageType)
    return selectedUser
      ? axios
          .put(`users/${selectedUser.id}`, payload)
          .then(onSuccess(successMessage))
          .catch((err) => console.log(err) || onFailure(err))
      : axios
          .post('users', payload)
          .then(onSuccess(successMessage))
          .catch(onFailure(failureMessage))
  })

  const onDelete = () => {
    const [successMessage, failureMessage] = buildMessage('deactivated')
    return axios
      .patch(`users/${selectedUser.id}/status`)
      .then(onSuccess(successMessage))
      .catch(onFailure(failureMessage))
  }

  const onInvite = () =>
    axios
      .put(`users/${selectedUser.id}/invite`)
      .then(({ url }) => {
        setInviteLink(url)
        enqueueSnackbar('Email successfully sent.', { variant: 'success' })
      })
      .catch(onFailure('Email could not be sent.'))

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleChangeCompanyId = (_, company) =>
    setUser({
      ...user,
      company,
      company_id: company?.value,
    })

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id='first_name'
                name='first_name'
                label='First name'
                value={user.first_name}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id='last_name'
                name='last_name'
                label='Last name'
                value={user.last_name}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id='email'
                name='email'
                label='Email address'
                value={user.email}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id='phone_number'
                name='phone_number'
                label='Phone'
                value={user.phone_number}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>

              <Select
                id='role'
                name='role'
                label='Role'
                onChange={handleChange}
                value={user.role}
                required
              >
                {ROLES.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Default App</InputLabel>

              <Select
                name='default_app'
                label='Default App'
                onChange={handleChange}
                value={user.default_app}
                required={!isAdmin}
                disabled={isAdmin}
              >
                <MenuItem key='CLIENT' value='CLIENT'>
                  CLIENT
                </MenuItem>
                <MenuItem key='TENDER' value='TENDER'>
                  TENDER
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              name='company'
              options={companies}
              value={user.company}
              getOptionLabel={(company) => company.label}
              onChange={handleChangeCompanyId}
              renderInput={(params) => <TextField {...params} label='Company' />}
              disabled={isAdmin}
            />
          </Grid>

          {inviteLink && (
            <Grid item sx={12}>
              <Alert variant='filled' severity='info'>
                <Typography variant='body1'>
                  Only in case the customer cannot receive any emails, you may use following link.
                  Send it or use it to generate credentials: {inviteLink}
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <CustomDialogOptions edit={!!selectedUser}>
        {selectedUser && (
          <Box>
            <Button
              sx={{ marginRight: '8px' }}
              color='error'
              variant='contained'
              onClick={onDelete}
            >
              {selectedUser.active ? 'Deactivate' : 'Activate'}
            </Button>

            <Button color='warning' variant='contained' onClick={onInvite}>
              Invite
            </Button>
          </Box>
        )}

        <Box>
          <Button
            sx={{ marginRight: '8px' }}
            color='secondary'
            variant='contained'
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            color='primary'
            variant='contained'
            onClick={doRequest}
            disabled={!canSubmit || isLoading}
            autoFocus
          >
            {isLoading ? <CircularProgress size={18} /> : 'Save'}
          </Button>
        </Box>
      </CustomDialogOptions>
    </Dialog>
  )
}
