import { gql } from 'graphql-request';

export const GQL_FUEL_PRICE_INDEX = gql`
  query getFuelPriceIndex {
    fuelPriceIndex: fuel_price_index {
      id
      name
    }
  }
`;
