import React from 'react';
import {
  SpacedContainer,
  PageContainer,
  PageContentLayout,
  DetailsOverview,
  utility,
  CompanyAvatar,
  USER_TYPE,
  ContractType,
  CONTRACT_TYPE,
} from '@leaf/components';
import { Grid } from '@mui/material';

const getOpsPlanLaneOverview = data => [
  {
    type: 'row',
    data: {
      label: 'Shipper',
      value: <CompanyAvatar name={data.shipper.name} id={data.shipper.id} type={USER_TYPE.SHIPPER} />,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Route ID',
      value: data.routeId,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Equipment Type',
      value: data.equipmentType.name,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Adapt Lane ID',
      value: data.adaptLaneId,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Adapt Run Date',
      value: utility.date.backendStringToViewString(data.adaptRunDate) ?? '-',
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Adapt Weekly Volume',
      value: data.adaptWeeklyVolume,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Adapt Target Shipper RPM',
      value: utility.numbers.formatNumberAsView(data.adaptTargetShipperRpm, 2),
    },
  },
  {
    type: 'divider',
  },
];

const getOpsPlanPlanDetails = data => [
  {
    type: 'row',
    data: {
      label: 'Planned Weekly Volume',
      value: data.plannedWeeklyVolume,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Planned Allocation',
      value: utility.numbers.toPercent(data.plannedAllocationPct * 100),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Planned Shipper RPM',
      value: utility.numbers.formatNumberAsView(data.plannedShipperRpm, 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Planned Start Date',
      value: utility.date.backendStringToViewString(data.plannedStartDate) ?? '-',
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Planned End Date',
      value: utility.date.backendStringToViewString(data.plannedEndDate) ?? '-',
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Planned Leaf Tender Acceptance',
      value: utility.numbers.toPercent(data.plannedLeafTenderAcceptance * 100),
    },
  },
  {
    type: 'divider',
  },
];

const getOpsPlanContractTypes = data => [
  {
    type: 'subtitle',
    data: {
      label: 'Maximum Capacity by Contract Type',
    },
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.SPOT} />,
      value: utility.numbers.toPercent(data.maxCapacityPctSpot * 100),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.UNCOMMITTED} />,
      value: utility.numbers.toPercent(data.maxCapacityPctRg * 100),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.FLEET} />,
      value: utility.numbers.toPercent(data.maxCapacityPctFleet * 100),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.WEEK_OF} />,
      value: utility.numbers.toPercent(data.maxCapacityPctFlexWeekly * 100),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.NETWORK} />,
      value: utility.numbers.toPercent(data.maxCapacityPctNetworkMove * 100),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.DAY_OF} />,
      value: utility.numbers.toPercent(data.maxCapacityPctFlexDayOf * 100),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'subtitle',
    data: {
      label: 'LSP Target Linehaul RPM',
    },
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.SPOT} />,
      value: utility.numbers.formatNumberAsView(data.lspTargetLinehaulRpmSpot, 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.UNCOMMITTED} />,
      value: utility.numbers.formatNumberAsView(data.lspTargetLinehaulRpmRg, 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.FLEET} />,
      value: utility.numbers.formatNumberAsView(data.lspTargetLinehaulRpmFleet, 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.WEEK_OF} />,
      value: utility.numbers.formatNumberAsView(data.lspTargetLinehaulRpmFlexWeekly, 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.NETWORK} />,
      value: utility.numbers.formatNumberAsView(data.lspTargetLinehaulRpmNetworkMove, 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.DAY_OF} />,
      value: utility.numbers.formatNumberAsView(data.lspTargetLinehaulRpmFlexDayOf, 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'subtitle',
    data: {
      label: 'Implementation Plan (Week Number)',
    },
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.SPOT} />,
      value: data.implementationPlanWeekSpot,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.UNCOMMITTED} />,
      value: data.implementationPlanWeekRg,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.FLEET} />,
      value: data.implementationPlanWeekFleet,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.WEEK_OF} />,
      value: data.implementationPlanWeekFlexWeekly,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.NETWORK} />,
      value: data.implementationPlanWeekNetworkMove,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: <ContractType type={CONTRACT_TYPE.DAY_OF} />,
      value: data.implementationPlanWeekFlexDayOf,
    },
  },
  {
    type: 'divider',
  },
];

const OpsPlansDetailsGeneral = ({ opsPlan }) => (
  <PageContainer>
    <SpacedContainer container spacing={2} autoWidth>
      {opsPlan && (
        <>
          <Grid item xs={4}>
            <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
              <DetailsOverview title="Lane Overview" data={getOpsPlanLaneOverview(opsPlan)} />
            </PageContentLayout>
          </Grid>
          <Grid item xs={4}>
            <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
              <DetailsOverview title="Plan Details" data={getOpsPlanPlanDetails(opsPlan)} />
            </PageContentLayout>
          </Grid>
          <Grid item xs={4}>
            <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
              <DetailsOverview title="Contract Types" data={getOpsPlanContractTypes(opsPlan)} />
            </PageContentLayout>
          </Grid>
        </>
      )}
    </SpacedContainer>
  </PageContainer>
);

export default OpsPlansDetailsGeneral;
