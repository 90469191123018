import React from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { Dialog, Typography, IconButton, Button, Box } from '@mui/material';
import { Close, Publish, Clear, CloudDownload } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { UploadButton, LazySpinner, utility } from '@leaf/components';

import {
  DialogTitleContainer,
  DialogContentContainer,
  DialogContentPanel,
  UploadButtonContainer,
  FileUploadedContainer,
  IconWrapper,
  DialogContentTitle,
  DownloadTemplateContainer,
  DialogActionsContainer,
  IconButtonDownload,
} from './ImportLanesDialog.styled';
import { importLanes } from '../domain/laneModel';

interface ImportLanesDialogProps {
  open: boolean;
  handleCloseDialog: () => void;
  title: string;
}

export default ({ open, handleCloseDialog, title }: ImportLanesDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedFile, setUploadedFile] = React.useState<File | null>();
  const [isDataLoading, setIsDataLoading] = React.useState(false);

  const handleDownload = () => {
    return axios
      .get(`routing/lane_import_template.xlsx`, {
        responseType: 'blob',
      })
      .then(response => {
        utility.table.triggerBrowserDownload(response, 'lane_import_template', 'xlsx');
      });
  };

  const handleUploadFile = (file: File | null) => {
    setUploadedFile(file);
  };

  const handleCloseImportDialog = () => {
    setUploadedFile(null);
    handleCloseDialog();
  };

  const handleValidateUploadedFile = async () => {
    if (uploadedFile) {
      try {
        setIsDataLoading(true);
        const data = await importLanes(uploadedFile);
        utility.table.triggerBrowserDownload(data, `lane_import_${format(new Date(), 'yyyy_MM_dd-HH_mm_ss')}`, 'xlsx');
        handleCloseImportDialog();
        enqueueSnackbar('Lanes uploaded, view Excel for details.', { variant: 'info' });
      } catch (error: any) {
        enqueueSnackbar(error);
        setUploadedFile(null);
      } finally {
        setIsDataLoading(false);
        setUploadedFile(null);
      }
    }
  };

  const renderUploadSuccessfully = () => (
    <FileUploadedContainer>
      <IconWrapper />
      <Typography variant="h6">{uploadedFile?.name ? uploadedFile?.name : 'undefined'}</Typography>
      <Typography>{uploadedFile?.size ? `${(uploadedFile.size / 1024 / 1024).toFixed(2)} MB` : 'undefined'}</Typography>
      <IconButton onClick={() => handleUploadFile(null)} size="large">
        <Clear color="disabled" />
      </IconButton>
    </FileUploadedContainer>
  );

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleCloseImportDialog} open={open}>
      <DialogTitleContainer>
        <Typography variant="body2">{title}</Typography>

        <IconButton onClick={handleCloseImportDialog}>
          <Close />
        </IconButton>
      </DialogTitleContainer>

      <DialogContentContainer dividers>
        {isDataLoading ? (
          <LazySpinner height="220px" />
        ) : (
          <Box height={220} width="100%">
            <DialogContentPanel>
              <DialogContentTitle>
                <Typography variant="body2">Import EXCEL file</Typography>

                <DownloadTemplateContainer onClick={handleDownload}>
                  <IconButtonDownload>
                    <CloudDownload />
                  </IconButtonDownload>

                  <Typography variant="body2">Template</Typography>
                </DownloadTemplateContainer>
              </DialogContentTitle>
              <Typography variant="body1">Upload Guide</Typography>
              <Typography>Maximum Rows Permitted: 500</Typography>
            </DialogContentPanel>
            <UploadButtonContainer>
              {uploadedFile ? (
                renderUploadSuccessfully()
              ) : (
                <UploadButton
                  variant="contained"
                  color="primary"
                  icon={<Publish />}
                  label="CHOOSE A FILE"
                  handleUpload={handleUploadFile}
                  fileTypes={['.xlsx']}
                />
              )}
            </UploadButtonContainer>
          </Box>
        )}
      </DialogContentContainer>
      <DialogActionsContainer>
        <Button variant="outlined" onClick={handleCloseImportDialog}>
          CANCEL
        </Button>
        <Button variant="contained" color="primary" onClick={handleValidateUploadedFile} disabled={!uploadedFile}>
          IMPORT
        </Button>
      </DialogActionsContainer>
    </Dialog>
  );
};
