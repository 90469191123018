/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';
import { Grid, Box, Stack, Typography, Divider } from '@mui/material';

export const Dot = styled(Box)`
  display: flex;
  background-color: #ff9800;
  border-radius: 8px;
  height: 4px;
  width: 4px;
  margin-top: 7px;
`;
export const SectionHeader = styled(Grid)`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  display: flex;
  padding: ${({ theme }) => `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 16px;
`;

export const FormContent = ({ children }) => (
  <Box sx={{ 'pt': 3, 'display': 'flex', 'gap': 3, '& > :not(style)': { width: '50%' } }}>{children}</Box>
);

export const FormItem = ({ children }) => <Box sx={{ '& > :not(style)': { mb: 3 } }}>{children}</Box>;

export const FormFooter = ({ requiredText, children }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
    {requiredText && <p>{requiredText}</p>}
    {children}
  </Box>
);

export const FormActions = ({ children, sx, ...rest }) => (
  <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end', ...sx }} {...rest}>
    {children}
  </Stack>
);

export const MileSummary = ({ totalMiles = 0, loadedMiles = 0, deadHeadMiles = 0 }) => (
  <FlexBox sx={{ py: 2 }}>
    <Divider sx={{ flex: 1, marginBottom: '7px;' }} />
    <Typography>Total Miles: {totalMiles}</Typography>
    <Dot />
    <Typography>Loaded Miles: {loadedMiles}</Typography>
    <Dot />
    <Typography>Deadhead Miles: {deadHeadMiles}</Typography>
    <Divider sx={{ flex: 1, marginBottom: '7px;' }} />
  </FlexBox>
);
