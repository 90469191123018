import { GraphQLClient } from 'graphql-request';
import { getContractVolumesGQL } from './GQL_CONTRACT_VOLUMES';

export interface ContractVolume {
  type?: string;
  weekStartDate: string;
  maxVolume: number | undefined;
  allocatedVolume: number | undefined;
  maxUnallocatedVolume: number | undefined;
  [key: string]: string | number | undefined;
}

const VOLUME_TYPES = ['Contract Volume', 'Matched Volume', 'Available Volume'];
const COLUMN_FIELD_NAMES = ['maxVolume', 'allocatedVolume', 'maxUnallocatedVolume'];

export const getContractVolumes = (
  getGQLClient: () => Promise<GraphQLClient>,
  id: string,
): Promise<ContractVolume[]> => {
  const GQL = getContractVolumesGQL(id);
  return getGQLClient().then(client => {
    if (!client) {
      return Promise.resolve([]);
    }
    return client.request(GQL).then((data: { contractVolumesWeekly: ContractVolume[] }) => {
      return data.contractVolumesWeekly;
    });
  });
};

export const volumeMapper = (volumes: ContractVolume[]) => {
  if (volumes.length === 0) {
    return [];
  }
  const result = [];
  for (let i = 0; i < 3; i += 1) {
    let mappedVolume: ContractVolume = {
      weekStartDate: '',
      maxVolume: undefined,
      allocatedVolume: undefined,
      maxUnallocatedVolume: undefined,
    };
    const fieldName = COLUMN_FIELD_NAMES[i];
    volumes.forEach((volume, index) => {
      mappedVolume = { ...mappedVolume, ...volume };
      mappedVolume.type = VOLUME_TYPES[i];
      mappedVolume[`week-${index}`] = volume[fieldName as keyof ContractVolume];
    });
    result[i] = mappedVolume;
  }
  return result;
};
