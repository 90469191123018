import { gql } from 'graphql-request';

export const GQL_LANE_BY_ID = gql`
  # variable {"where": {"id": {"_eq": "01848027-c1fe-6c8d-be1c-a69e4108a80c"}}}
  query getLaneDetails($where: lane_bool_exp = { id: {} }) {
    lane(where: $where) {
      laneId: id
      originName: origin_name
      destinationName: destination_name
      deliveryType: delivery_type
      pickupType: pickup_type
      destinationGeo: destination_geo
      originGeo: origin_geo
      pathGeo: path
      leafMiles: leaf_miles
      destinationH3Cells: destination_h3
      destinationPoint: destination_point
      originH3Cells: origin_h3
      originPoint: origin_point
      pickupStops: origin_stops
      deliveryStops: destination_stops
      shipper {
        id
        name
      }
    }
  }
`;
