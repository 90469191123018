import { gql } from 'graphql-request';

export const GQL_ACCOUNT_TEAM_MEMBERS = gql`
  query getAccountTeamMembers {
    account_team_member {
      user {
        id
        firstName: first_name
        lastName: last_name
      }
    }
  }
`;

export const getAccountTeamMembersByCompanyGQL = companyId => gql`
  query getAccountTeamMember {
    accountTeamMember: account_team_member(where: { company_id: { _eq: "${companyId}" } }) {
      user {
        id
        firstName: first_name
        lastName: last_name
      }
    }
  }
`;
