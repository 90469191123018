import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Grid,
  DialogActions,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import styled from '@emotion/styled'
import { useLoading, Form } from '@leaf/components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { emptyToNull } from '@/utility/types/string'
import { updateExecutionLane } from './domain/executionLanesModel'

const CustomDialogOptions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
`

const { Input, ErrorMessage } = Form.Library

const validationSchema = yup.object().shape({
  shipperDistance: yup.number().transform(emptyToNull).nullable(),
  co2ReductionPerShipment: yup.number().transform(emptyToNull).nullable(),
  referenceRpm: yup.number().transform(emptyToNull).nullable(),
})

export default ({ open, onClose, selectedLane }) => {
  const { enqueueSnackbar } = useSnackbar()

  // eslint-disable-next-line consistent-return
  const getInitialFormData = () => {
    if (selectedLane)
      return {
        shipperDistance: selectedLane.shipper_distance,
        co2ReductionPerShipment: selectedLane.co2_reduction_per_shipment,
        referenceRpm: selectedLane.reference_rpm,
      }
  }

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getInitialFormData(),
  })

  const [doRequest, isLoading] = useLoading(async (data) =>
    updateExecutionLane(selectedLane.id, data)
      .then(() => {
        onClose(true)
        enqueueSnackbar('Lane successfully updated. Please refresh the page.', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Lane could not be updated.', { variant: 'failure' })
      })
  )

  return (
    <Dialog onClose={() => onClose(false)} open={open} fullWidth>
      <DialogTitle>Edit Lane</DialogTitle>

      <form
        noValidate
        onSubmit={handleSubmit((data) => {
          doRequest(data)
        })}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input
                  name='shipperDistance'
                  label='Shipper Miles'
                  type='number'
                  control={control}
                  error={errors.shipperDistance}
                />
                <ErrorMessage errors={errors} name='shipperDistance' />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input
                  name='co2ReductionPerShipment'
                  label='CO2 Reduction (tons per shipment)'
                  type='number'
                  control={control}
                  error={errors.co2ReductionPerShipment}
                />
                <ErrorMessage errors={errors} name='co2ReductionPerShipment' />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input
                  name='referenceRpm'
                  label='Reference RPM'
                  type='number'
                  control={control}
                  error={errors.referenceRpm}
                />
                <ErrorMessage errors={errors} name='referenceRpm' />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <CustomDialogOptions>
          <Button onClick={() => onClose(false)} color='secondary' variant='contained'>
            Cancel
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={isLoading}>
            Save
          </Button>
        </CustomDialogOptions>
      </form>
    </Dialog>
  )
}
