import Button from '@mui/material/Button';
import React from 'react';
import styled from '@emotion/styled';

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: end;
`;

export default props => {
  const { submitButton = 'Add new', cancelButton = 'Cancel', onCancelClick, onConfirmClick = undefined } = props;

  return (
    <ButtonsContainer>
      <Button variant="contained" color="secondary" onClick={onCancelClick}>
        {cancelButton}
      </Button>
      <Button type="submit" variant="contained" color="primary" onClick={onConfirmClick}>
        {submitButton}
      </Button>
    </ButtonsContainer>
  );
};
