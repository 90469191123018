import { gql } from 'graphql-request'
import { isLikeUUID } from '@/utility/uuid'

export const GQL_DOMICILES = gql`
  query getDomiciles {
    fleet_run_definition(distinct_on: domicile) {
      domicile
    }
  }
`

const WHERE = {
  DOMICILE_VAR: gql`$domicile: [String!]`,
  DOMICILE_CLAUSE: gql`domicile: { _in: $domicile }`,
}

const whereClause = ({ where, search }) => {
  let whereGQL = gql``
  const whereVarsKeys = Object.keys(where).filter((k) => Array.isArray(where[k]) && where[k].length)

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: {
          ${whereVarsKeys.map((key) =>
            where[key]
              ? gql`
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                `
              : ''
          )}

          ${
            search !== undefined && search !== ''
              ? gql`
            _or: [
              ${isLikeUUID(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
              { domicile: { _ilike: "%${search}%" } }
            ]
            `
              : ''
          }
        }
      }
    `
  }
  return whereGQL
}

const whereVar = ({ where }) => gql`
  ${where.domicile ? WHERE.DOMICILE_VAR : ``}
`

export const getFleetDiscoveryGQL = (variables) => {
  const whereGQL = whereClause(variables)
  return gql`
    query getFleetDiscovery(
      $orderBy: [fleet_run_definition_order_by]
      $limit: Int
      $offset: Int
      ${whereVar(variables)}
    ) {
      fleet_run_definition(
        order_by: $orderBy
        limit: $limit
        offset: $offset
        ${whereGQL}
      ) {
        id
        run_ts
        domicile
        run_summary {
          distinct_lanes
          distinct_shippers
          total_permutations
          total_margin
          total_revenue
          trucks_used
          fleet_weekly_loads
          lane_percent_usage_min
          max_dests
          max_miles
          max_origins
          max_shippers
          truck_cost
          truck_limit
        }
        lane_parameters_aggregate {
          aggregate {
            count
          }
        }
      }
      fleet_run_definition_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `
}
