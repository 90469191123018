/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';

export default React.memo(({ id, label, children, required, ...inputProps }) => (
  <FormControl id={id} required={required} fullWidth variant="outlined">
    <InputLabel>{label}</InputLabel>
    <Select label={label} {...inputProps}>
      {children}
    </Select>
  </FormControl>
));
