import { utility } from '@leaf/components';

const { date } = utility;

export default class ShipmentSimple {
  constructor({ pickupAppointment, ...response }) {
    Object.assign(this, {
      pickupAppointment: date.normalizedUtcDateToViewString(
        date.backendStringToNormalizedUtcDate(pickupAppointment?.split('T')[0]),
      ),
      ...response,
    });

    this.view = {
      guid: this.id,
      shipperName: this.shipperName ?? '-',
      carrierName: this.carrierName ?? '-',
      shipperShipmentId: this.shipperShipmentId ?? '-',
      transportationMode: this.transportationMode ?? '-',
    };
  }

  formatAddress = address => `${address.city} ${address.state} ${address.zipCode}`;
}
