import axios from 'axios';

import { utility, toCustomFilterOptions } from '@leaf/components';

const getTotal = (v, fn) => {
  if (v === undefined || v === null) {
    return '-';
  }
  return fn(v);
};

export default class AdaptLane {
  constructor(response) {
    Object.assign(this, {
      ...response,
      batchDate: utility.date.backendStringToViewString(response.batchDate, ''),
      batchDateLatest: utility.date.backendStringToViewString(response.batchDateLatest, ''),
    });

    this.view = {
      parentId: response.parentId || '-',
      leafMiles: utility.format.miles(response.leafMiles),
      companyMiles: utility.format.miles(response.companyMiles),
      leafTransitTime: utility.format.hours(response.leafTransitTime),
      equipmentClasses: response.equipmentClasses ? response.equipmentClasses.join(', ') : '-',
      totalShipments: getTotal(response.totalShipments, v => v.toLocaleString()),
      totalSpend: getTotal(response.totalSpend, utility.format.money),
      period: this.earliest && this.latest ? `${this.earliest} to ${this.latest}` : '-',
      lanes: [
        {
          origin: response.origin ?? '',
          destination: response.destination ?? '',
          laneType: response.laneType,
          shipper: {
            guid: response.shipperGuid ?? '',
            id: response.shipperId ?? '',
            name: response.shipperName ?? '',
          },
        },
      ],
      shipperName: response.shipperName,
    };
  }

  static getAll = async params =>
    axios.get(`view/adapt/lanes?${utility.pagination.toServerSideParams(params)}`).then(response => ({
      ...response,
      data: response.data.map(adaptLane => new AdaptLane(adaptLane)),
    }));

  static getShippers = async () =>
    axios.get(`view/adapt/lanes/shippers`).then(
      response =>
        response.shippers?.map(({ name, id }) => ({
          label: name,
          value: id,
        })) ?? [],
    );

  static getEnum = name => async () =>
    axios.get(`view/adapt/lanes/filters?name=${name}`).then(response => toCustomFilterOptions(response.values));

  static getLocation = async searchTerm =>
    axios.get(`geo/addresses/search?query=${searchTerm}&includeOnly=City,State`).then(response => response);

  static download = async params =>
    axios
      .get(`view/adapt/lanes/download?${utility.pagination.toServerSideParams(params)}`)
      .then(res => utility.table.triggerBrowserDownload(res, 'adaptLanesTableDownload'));
}
