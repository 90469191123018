import styled from '@emotion/styled';
import { IconButton, Typography } from '@mui/material';

export const Body = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ff9800;
`;

export const Description = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;

  color: rgba(0, 0, 0, 0.54);
`;

export const IconContainer = styled.div`
  display: flex;

  svg:first-child {
    margin-right: 4px;
  }
`;

export const RouteCardItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const LaneWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 155px;
    white-space: nowrap;
  }
`;

export const IconWrapper = styled.div`
  padding: 0 16px;
  svg {
    vertical-align: middle;
  }
`;

export const CopyButton = styled(IconButton)`
  padding: 0px;
`;
