export default class Address {
  constructor(response) {
    Object.assign(this, response);

    this.view = {
      street: this.street || '-',
      city: this.city || '-',
      country: this.country || '-',
      zipCode: this.zipCode || '-',
      state: this.state || '-',
    };
  }

  static mapLocation = location => {
    if (location.name) {
      return location.name;
    }
    return Address.mapAddress(location.city, location.state, location.zipCode);
  };

  static mapAddress = (city, state, zipCode) => {
    if (city) {
      if (state && !zipCode) {
        return `${city}, ${state}`;
      }
      if (zipCode && !state) {
        return `${city}, ${zipCode}`;
      }
      return `${city}, ${state} ${zipCode}`;
    }
    if (state && zipCode) {
      return `${state} ${zipCode}`;
    }
    if (state) {
      return state;
    }
    if (zipCode) {
      return zipCode;
    }
    return '-';
  };

  static mapAddressLong = (street, city, state, zipCode) => {
    if (street) {
      return `${street}, ${this.mapAddress(city, state, zipCode)}`;
    }
    return this.mapAddress(city, state, zipCode);
  };

  mapAddress = () => Address.mapAddress(this.city, this.state, this.zipCode);

  mapAddressLong = () => {
    if (this.street) {
      return `${this.street}, ${this.mapAddress()}`;
    }
    return this.mapAddress();
  };
}
