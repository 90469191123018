import React from 'react'
import { Box } from '@mui/material'
import { PageContent } from '@leaf/components'
import FocusContract from './FocusContract'
import VolumeAvailability from './VolumeAvailability'
import ContractMatchesOverview from './ContractMatchesOverview'

export default ({ contractId, isBuyerContract }) => (
  <Box display='flex' flexDirection='column' height='100%'>
    <PageContent withMargin>
      <FocusContract contractId={contractId} />
    </PageContent>
    <PageContent withMargin>
      <VolumeAvailability contractId={contractId} />
    </PageContent>
    <PageContent withMargin>
      <ContractMatchesOverview contractId={contractId} isBuyerContract={isBuyerContract} />
    </PageContent>
  </Box>
)
