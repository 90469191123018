import React from 'react';
import { v4 } from 'uuid';
import { TRIP_TYPE, TripType, Lane, utility, LANE_TYPE } from '@leaf/components';

import styled from '@emotion/styled';

const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`;

const MOVE_TYPES_MAPPED = {
  'TRIANGULAR CIRCUIT': TRIP_TYPE.CIRCUIT,
  'ROUND TRIP': TRIP_TYPE.ROUND_TRIP,
  'CM': TRIP_TYPE.CONTINUOUS_MOVE,
  'SHORTY': TRIP_TYPE.SHORTY,
};

const TableCellWrapper = styled.div`
  text-align: right;
`;

export const getColumns = (tableState, filterOptions) => [
  {
    label: 'Action type',
    name: 'moveType',
    options: {
      ...filterOptions.moveType,
      customBodyRender: type => {
        const tripType = MOVE_TYPES_MAPPED[type];
        return tripType ? <TripType type={tripType} /> : type;
      },
    },
  },
  {
    label: 'ID',
    name: 'networkMoveId',
    options: {
      filter: false,
    },
  },
  {
    label: 'Shipper',
    name: 'shipperName',
    options: {
      ...filterOptions.shipperName,
    },
  },
  {
    label: 'Lanes',
    name: 'view.lanes',
    field: 'l0Name',
    options: {
      filter: false,
      customBodyRender: lanes =>
        lanes
          ?.filter(item => item?.lane)
          .map(({ lane, shipper }) => (
            <LaneWrapper key={v4()} isSingleRow={lanes.length === 1}>
              <Lane
                displayAsGrid
                origin={{ primaryContent: [lane?.split('-')[0] ?? '-'] }}
                destination={{ primaryContent: [lane?.split('-')[1] ?? '-'] }}
                shipper={shipper}
                type={LANE_TYPE.POWER}
              />
            </LaneWrapper>
          )),
    },
  },
  {
    label: 'Notes',
    name: 'notes',
    options: { filter: false },
  },
  {
    label: 'Annual loads',
    name: 'annualLoads',
    options: {
      filter: false,
    },
  },
  {
    label: 'Equipment',
    name: 'equipmentClass',
    options: {
      ...filterOptions.equipmentClass,
    },
  },
  {
    label: 'Loads impacted',
    name: 'view.loadsImpacted',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Cost savings per Trip',
    name: 'margin',
    field: 'margin',
    options: {
      filter: false,
      customBodyRender: margin => <TableCellWrapper>{utility.table.signed(margin)}</TableCellWrapper>,
    },
  },
  {
    label: 'Total miles per trip',
    name: 'totalMiles',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Possible annual trips',
    name: 'minTrips',
    options: { filter: false, ...utility.table.rightAlignColumnProps() },
  },
  {
    label: 'Cost reduction',
    name: 'view.costReduction',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'CO2 reduction',
    name: 'view.CO2Reduction',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Network type',
    name: 'networkType',
    options: {
      ...filterOptions.networkType,
    },
  },
  {
    label: 'Phase',
    name: 'phase',
    options: {
      filter: false,
    },
  },
  {
    label: 'Start date',
    name: 'startDate',
    options: {
      filter: false,
    },
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      filter: false,
    },
  },
];
