import React from 'react'
import styled from '@emotion/styled'
import { utility, Form, ContractType, NewTabLink } from '@leaf/components'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { Edit, InfoOutlined } from '@mui/icons-material'

const Date = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacing(1)}`};
`

const InfoIcon = styled(InfoOutlined)`
  color: ${({ theme }) => `${theme.palette.primary.main}`};
  &&& {
    width: 24px;
    height: 24px;
  }
`

const ImpliedDateTooltip = () => (
  <Tooltip title="Implied based on the overlap between the buy and sell contract date ranges.">
    <InfoIcon fontSize="small" className="info-icon" />
  </Tooltip>
)

const { ActionButtons } = utility.table

export const getOptions = () => ({
  search: false,
  download: false,
  filter: false,
  viewColumns: false,
  ...utility.table.getRowHoverActionProps('contract-match'),
})

export const getColumns = (handleEnableMatchContract, setContractIdsToEdit) => [
  {
    name: 'ids',
    label: 'IDs',
    options: {
      display: 'excluded',
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Enabled',
    name: 'toggleEnabled',
    options: {
      sort: false,
      customBodyRender: ({ isEnabled, ids }) => (
        <Form.Generic.Switch
          onChange={(e) => {
            handleEnableMatchContract({ isEnabled: e.target.checked, ...ids })
          }}
          defaultChecked={isEnabled}
          disabled={isEnabled}
        />
      ),
    },
  },
  {
    name: 'contractType',
    label: 'Contract Type',
    options: {
      sort: false,
      customBodyRender: (type) => <ContractType type={type} />,
    },
  },
  {
    name: 'accountName',
    label: 'Account Name',
    options: { sort: false },
  },
  {
    name: 'origin',
    label: 'Origin Area',
    options: { sort: false },
  },
  {
    name: 'destination',
    label: 'Destination Area',
    options: { sort: false },
  },
  {
    name: 'startDate',
    label: 'Start Date',
    options: {
      sort: false,
      customBodyRender: ({ value, isImplied }) => (
        <Date>
          <Typography variant="body1">{value}</Typography>
          {isImplied && <ImpliedDateTooltip />}
        </Date>
      ),
    },
  },
  {
    name: 'endDate',
    label: 'End Date',
    options: {
      sort: false,
      customBodyRender: ({ value, isImplied }) => (
        <Date>
          <Typography variant="body1">{value}</Typography>
          {isImplied && <ImpliedDateTooltip />}
        </Date>
      ),
    },
  },
  {
    name: 'remainingWeeks',
    label: 'Remaining weeks',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    name: 'assignedWeeklyVolume',
    label: 'Assigned Weekly Volume',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'shipperMaxUnallocatedVolumeUntilEnd',
    label: 'Remaining Available Shipper Volume',
    options: {
      sort: false,

      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    name: 'weeklyShipperMaxUnallocatedVolumeUntilEnd',
    label: 'Remaining Available Weekly Shipper Volume',
    options: {
      sort: false,

      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    name: 'carrierMaxUnallocatedVolumeUntilEnd',
    label: 'Remaining Available LSP Volume',
    options: {
      sort: false,

      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    name: 'weeklyCarrierMaxUnallocatedVolumeUntilEnd',
    label: 'Remaining Available Weekly LSP Volume',
    options: {
      sort: false,

      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    name: 'allocatedVolume',
    label: 'Match Total Volume',
    options: {
      sort: false,

      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    name: 'weeklyAllocatedVolume',
    label: 'Match Weekly Volume',
    options: {
      sort: false,

      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    name: 'areaOverlap',
    label: 'Area Overlap',
    options: { sort: false },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: (actionsData) => {
        const { ids } = actionsData
        return (
          <ActionButtons
            id={`Actions-contract-match-${Object.values(ids).join(',')}`}
          >
            <NewTabLink url={`/contracts/${ids.sellContractId}`} />
            <Tooltip title="Edit Matched Contract">
              <IconButton onClick={() => setContractIdsToEdit(ids)}>
                <Edit />
              </IconButton>
            </Tooltip>
          </ActionButtons>
        )
      },
    },
  },
]
