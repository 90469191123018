import { getCurrentUserMetadata } from '@/authentication'

// TODO: LEAF-989 LEAF-4469
export const isUserAllowedToAccessAdaptBoard = (email) => {
  if (import.meta.env.DEV) {
    return true
  }
  if (!email) {
    return false
  }
  return Boolean(
    [
      'ross.otto@leaflogistics.com',
      'stefan.friederichs@leaflogistics.com',
      'andrea.pope@leaflogistics.com',
      'andreas.kull@leaflogistics.com',
      'josh.lewis@leaflogistics.com',
      'amanda.roe@leaflogistics.com',
      'josh.schmoldt@leaflogistics.com',
      'tony.xu@leaflogistics.com',
    ].find((item) => item === email)
  )
}

// TODO: LEAF-989 LEAF-4469
export const isUserAllowedToEditDashboard = (token) => {
  if (import.meta.env.DEV) {
    return true
  }
  if (!token) {
    return false
  }
  const { uid } = getCurrentUserMetadata(token)
  return [1, 7762, 1000, 53314, 51414, 54960, 49683, 100264, 52328, 4102].includes(Number(uid))
}
