import { utility } from '@leaf/components'
import { format } from 'date-fns'

export function updateContract(state, payload) {
  return {
    ...state,
    newContract: {
      ...state.newContract,
      ...payload,
    },
  }
}

export function setDefaultLaneOptions(state, payload) {
  return {
    ...state,
    LANE_OPTIONS: payload,
  }
}

export function updateRouteCount(state, payload) {
  return {
    ...state,
    ROUTE_COUNT: payload,
  }
}

export function trackNewEditRoutes(state, payload) {
  return {
    ...state,
    newEditRoutes: state.newEditRoutes.concat(payload),
  }
}

export function untrackNewEditRoutes(state, payload) {
  return {
    ...state,
    newEditRoutes: state.newEditRoutes.filter((route) => route !== payload),
  }
}

export function trackDeleteRoutes(state, payload) {
  return {
    ...state,
    deleteRoutes: state.deleteRoutes.concat(payload),
  }
}

export function untrackDeleteRoutes(state, payload) {
  return {
    ...state,
    deleteRoutes: state.deleteRoutes.filter((route) => route !== payload),
  }
}

export function setContractToDirty(state, payload) {
  return {
    ...state,
    isContractDirty: payload,
  }
}

export function setGeneralStepValid(state, payload) {
  return {
    ...state,
    isGeneralStepValid: payload,
  }
}

export function setTimeframeVolumeStepValid(state, payload) {
  return {
    ...state,
    isTimeframeVolumeStepValid: payload,
  }
}

export function setPricingStepValid(state, payload) {
  return {
    ...state,
    isPricingStepValid: payload,
  }
}

export function setRoutesToDirty(state, payload) {
  return {
    ...state,
    isRoutesDirty: payload,
  }
}
/**
 *
 * @param {Date} date -- Javascript Date Object
 * @returns {String} "MM/dd/yyyy"
 */
const dateToStringViewFormat = (date) => {
  if (date && Number(date.getDate())) {
    return format(date, utility.date.VIEW_FORMAT)
  }
  return null
}

const theDate = new Date()
const todayDate = dateToStringViewFormat(theDate)
const futureDate = dateToStringViewFormat(
  new Date(theDate.setDate(theDate.getDate() + 30))
)

export const defaultValues = {
  id: null,

  // Step 1
  buyerId: null,
  sellerId: null,
  contractPartyFlag: 'SHIPPER', // or CARRIER
  contractParty: '', // NOTE: list contract Party -- DO NOT POST
  contractStatus: '',
  shipperRoutePlanId: '',
  equipmentTypeId: '',
  accessorialIds: [],
  notes: '',
  isFleet: false,
  isBackupAward: false,

  // Step2: Timeframe and Volume
  startDate: todayDate,
  endDate: futureDate,
  volumeType: '',
  volumeFrequency: '',
  minVolume: '',
  maxVolume: '',
  includeWeekends: false,
  volumePattern: '', // TBD  also verify with Ravi  this field "dayOfFlexVolume"

  // Step 3: Pricing
  pricingMechanism: '',
  carrierUpcharge: '',
  fixedFeePerShipment: '',
  linehaulRpm: '',
  minimumLinehaulCharge: '',
  minTenderLeadTimeHrs: '',
  pricingIndex: '',
  pricingIndexPremium: '',
  pricingIndexPremiumOverrideSun: '',
  fscId: '',
  assetPricing: '', // This is Rate Per asset?
  assetPricingFrequency: '', // DAILY | WEEKLY
  minChargeableMiles: '',
  minChargeableMilesFrequency: '', // WEEKLY | DAILY

  // Tracking days -- these values are only needed for the UI
  MONDAY: '',
  TUESDAY: '',
  WEDNESDAY: '',
  THURSDAY: '',
  FRIDAY: '',
  SATURDAY: '',
  SUNDAY: '',
}

export const legInitialValues = [
  {
    laneId: '',
    laneSelector: null, // This is use to select the lane -- omit when doing POST
    leafMiles: 0, // Omit when posting
    originName: '', // strings
    originH3Cells: [], // array of strings
    originPoint: {
      longitude: '', // float
      latitude: '', // float
    },
    pickupType: '', // string
    pickupStops: '', // number

    destinationName: '', // string
    destinationH3Cells: [], // array of strings
    destinationPoint: {
      longitude: '', // float
      latitude: '', // float
    },
    deliveryType: '', // string
    deliveryStops: '', // number

    shipper: null,
  },
]
