import { gql } from 'graphql-request';

/**
 * Variables:
 * {"where": {"id": {"_eq": "2c023479-8ae1-11ed-b40e-f90f1f517bc9"}}}
 */

export const GQL_ROUTES_BY_ID = gql`
  query getRoutesById($where: route_bool_exp = { id: {} }) {
    route(where: $where) {
      id
      legs {
        lane {
          laneId: id
          originName: origin_name
          destinationName: destination_name
          deliveryType: delivery_type
          pickupType: pickup_type
          destinationGeo: destination_geo
          originGeo: origin_geo
          pathGeo: path
          leafMiles: leaf_miles
          destinationH3Cells: destination_h3
          destinationPoint: destination_point
          originH3Cells: origin_h3
          originPoint: origin_point
          pickupStops: origin_stops
          deliveryStops: destination_stops
          shipper {
            name
            id
          }
        }
        route {
          tripType: trip_type
        }
      }
    }
  }
`;
