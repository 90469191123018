import { gql } from 'graphql-request';

export const getContractVolumesGQL = contractId => gql`
  query getContractVolumes {
    contractVolumesWeekly: contract_volumes_weekly(where: { id: { _eq: "${contractId}" } }) {
      maxUnallocatedVolume: max_unallocated_volume
      maxVolume: max_volume
      allocatedVolume: allocated_volume
      weekStartDate: week_start_date
    }
  }
`;
