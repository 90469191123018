import React from 'react';
import styled from '@emotion/styled';

import { CircularProgress, Grid } from '@mui/material';

const Loading = styled(Grid, {
  shouldForwardProp: prop => prop !== '$offset',
})`
  height: ${({ $offset, height }) => (!height ? `calc(100vh - ${$offset})` : height)};
  align-content: center;
  justify-content: center;
`;

const LazySpinner = ({ height, offset = '0px' }) => (
  <Loading container $offset={offset} height={height}>
    <CircularProgress />
  </Loading>
);

export default LazySpinner;
