import React from 'react';
import { TextField } from '@mui/material';
import { TRIP_TYPE, TripType, NewTabLink, utility, Lane } from '@leaf/components';
import { v4 } from 'uuid';
import styled from '@emotion/styled';

const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`;

const TableCellWrapper = styled.div`
  text-align: right;
`;

const MOVE_TYPES_MAPPED = {
  'TRIANGULAR CIRCUIT': TRIP_TYPE.CIRCUIT,
  'ROUND TRIP': TRIP_TYPE.ROUND_TRIP,
  'CM': TRIP_TYPE.CONTINUOUS_MOVE,
  'SHORTY': TRIP_TYPE.SHORTY,
};

export const getColumns = (tableState, filterOptions, shipperIds) => [
  {
    label: 'Network',
    name: 'networkType',
    options: { ...filterOptions.networkType },
  },
  {
    label: 'Type',
    name: 'moveType',
    options: {
      ...filterOptions.moveType,
      customBodyRender: type => {
        const tripType = MOVE_TYPES_MAPPED[type];
        return tripType ? <TripType type={tripType} /> : type;
      },
    },
  },
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
    },
  },
  {
    label: 'Lanes',
    name: 'view.lanes',
    field: 'l0Name',
    options: {
      filter: false,
      customBodyRender: lanes =>
        lanes
          ?.filter(item => item?.lane)
          .map(({ lane, shipper }) => (
            <LaneWrapper key={v4()} isSingleRow={lanes.length === 1}>
              <Lane
                displayAsGrid
                origin={{ primaryContent: [lane?.split('-')[0] ?? '-'] }}
                destination={{ primaryContent: [lane?.split('-')[1] ?? '-'] }}
                shipper={shipper}
              />
            </LaneWrapper>
          )),
    },
  },
  {
    label: '1st Lane Miles',
    name: 'l0Miles',
    options: { filter: false },
  },
  {
    label: '1st Lane Annual Loads',
    name: 'l0AnnualLoads',
    options: { filter: false },
  },
  {
    label: '1st Lane Weekly Base',
    name: 'l0WeeklyBase',
    options: { filter: false },
  },
  {
    label: '1st Lane mean rpm',
    name: 'l0MeanRpm',
    options: { filter: false },
  },
  {
    label: 'Lane Outlier rpm',
    name: 'l0OutlierRpm',
    field: 'outlier_rpm',
    options: {
      ...filterOptions.outlierRPM,
      customBodyRender: isOutlier => (isOutlier ? 'Yes' : 'No'),
    },
  },
  {
    label: '1st Lane Low',
    name: 'l0Low',
    options: {
      filter: false,
      customBodyRender: isLow => (isLow ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Low legs',
    name: 'view.lowLegsCount',
    field: 'low_leg',
    options: {
      ...filterOptions.lowLeg,
    },
  },
  {
    label: 'Low volume legs',
    name: 'lowLegs',
    options: {
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Deadhead 1st Lane - 2nd Lane',
    name: 'deadheadL0L1',
    options: { filter: false },
  },

  {
    label: '2nd Lane Miles',
    name: 'l1Miles',
    options: { filter: false },
  },
  {
    label: '2nd Lane Annual Loads',
    name: 'l1AnnualLoads',
    options: { filter: false },
  },
  {
    label: '2nd Lane Weekly Base',
    name: 'l1WeeklyBase',
    options: { filter: false },
  },
  {
    label: '2nd Lane mean rpm',
    name: 'l1MeanRpm',
    options: { filter: false },
  },
  {
    label: '2nd Lane Outlier rpm',
    name: 'l1OutlierRpm',
    options: {
      filter: false,
      customBodyRender: isOutlier => (isOutlier ? 'Yes' : 'No'),
    },
  },
  {
    label: '2nd Lane Low',
    name: 'l1Low',
    options: {
      filter: false,
      customBodyRender: isLow => (isLow ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Deadhead 2nd Lane - 3rd Lane',
    name: 'deadheadL1L2',
    options: { filter: false },
  },

  {
    label: '3rd Lane Miles',
    name: 'l2Miles',
    options: { filter: false },
  },
  {
    label: '3rd Lane Annual Loads',
    name: 'l2AnnualLoads',
    options: { filter: false },
  },
  {
    label: '3rd Lane Weekly Base',
    name: 'l2WeeklyBase',
    options: { filter: false },
  },
  {
    label: '3rd Lane mean rpm',
    name: 'l2MeanRpm',
    options: { filter: false },
  },
  {
    label: '3rd Lane Outlier rpm',
    name: 'l2OutlierRpm',
    options: {
      filter: false,
      customBodyRender: isOutlier => (isOutlier ? 'Yes' : 'No'),
    },
  },
  {
    label: '3rd Lane Low',
    name: 'l2Low',
    options: {
      filter: false,
      customBodyRender: isLow => (isLow ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Deadhead 3rd Lane - 4th Lane',
    name: 'deadheadFinal',
    options: { filter: false },
  },
  {
    label: 'Equipment Type',
    name: 'equipmentClass',
    options: { ...filterOptions.equipmentClass },
  },
  {
    label: 'Annualized Cost Savings',
    name: 'benefit',
    field: 'benefit',
    options: {
      filter: false,
      customBodyRender: benefit => <TableCellWrapper>{utility.table.signed(benefit)}</TableCellWrapper>,
    },
  },
  {
    label: 'Annualized Cost Savings',
    name: 'view.benefit',
    field: 'benefit_from',
    options: {
      ...filterOptions.benefit,
      customFilterListOptions: {
        render: v => `Annualized Cost Savings from ${utility.numbers.formatNumber(v)}`,
      },
    },
  },
  {
    label: 'Start date',
    name: 'startDate',
    options: { filter: false },
  },
  {
    label: 'Running date',
    name: 'runningDate',
    options: { filter: false },
  },
  {
    label: 'Days',
    name: 'days',
    options: { filter: false },
  },
  {
    label: 'Supply Target Day Rate',
    name: 'supplyTargetDayRateLsp',
    options: { filter: false },
  },
  {
    label: 'Supply Target Linehaul',
    name: 'startsupplyTargetLinehaulLsp',
    options: { filter: false },
  },
  {
    label: 'Leaf Daily Linehaul',
    name: 'leafDailyLinehaulLsp',
    options: { filter: false },
  },
  {
    label: 'Shipper Trigger Linehaul',
    name: 'shipperTriggerLinehaulLsp',
    options: { filter: false },
  },
  {
    label: 'Cost savings per Trip',
    name: 'margin',
    field: 'margin',
    options: {
      filter: false,
      customBodyRender: margin => <TableCellWrapper>{utility.table.signed(margin)}</TableCellWrapper>,
    },
  },
  {
    label: 'Total miles per trip',
    name: 'view.totalMiles',
    field: 'totalMiles',
    options: {
      ...filterOptions.totalMiles,
      filterType: 'custom',
      filterList: tableState.filters.totalMiles,
      customFilterListOptions: {
        render: v => {
          if (v.length === 1) {
            return `Total miles from ${utility.format.miles(v[0])}`;
          }
          if (v.length === 2) {
            return `Total miles ${utility.format.miles(v[0])} - ${utility.format.miles(v[1])}`;
          }

          return '';
        },
      },
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Possible annual trips',
    name: 'view.minTrips',
    field: 'minTrips',
    options: { filter: false, ...utility.table.rightAlignColumnProps() },
  },
  {
    label: 'Feasibility',
    name: 'feasibility',
    options: { filter: false },
  },
  {
    label: 'Notes',
    name: 'notes',
    options: { filter: false },
  },
  {
    label: 'Is Low',
    name: 'isLow',
    options: {
      filter: false,
      customBodyRender: isLow => (isLow ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Balance Type',
    name: 'balanceType',
    options: { ...filterOptions.balanceType },
  },
  {
    label: 'Complexity',
    name: 'complexity',
    options: { filter: false, ...utility.table.rightAlignColumnProps() },
  },
  {
    label: 'Status',
    name: 'status',
  },
  {
    name: 'minComplexity',
    options: {
      sort: false,
      display: 'excluded',
      filterType: 'custom',
      filterList: tableState.filters.minComplexity,
      customFilterListOptions: {
        render: values => `Complexity >= ${values[0]}`,
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => {
          const currentValue = filterList[index];
          const handleChange = event => {
            onChange([event.target.value], index, column);
          };
          return (
            <TextField
              label="Minimum complexity"
              type="number"
              onChange={handleChange}
              value={currentValue}
              fullWidth
            />
          );
        },
      },
    },
  },
  {
    name: 'maxComplexity',
    options: {
      sort: false,
      display: 'excluded',
      filterType: 'custom',
      filterList: tableState.filters.maxComplexity,
      customFilterListOptions: {
        render: values => `Complexity <= ${values[0]}`,
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => {
          const currentValue = filterList[index];
          const handleChange = event => {
            onChange([event.target.value], index, column);
          };
          return (
            <TextField
              label="Maximum complexity"
              type="number"
              onChange={handleChange}
              value={currentValue}
              fullWidth
            />
          );
        },
      },
    },
  },
  {
    label: 'Move owner',
    name: 'networkMoveOwnerName',
    field: 'network_owner',
    options: {
      display: 'excluded',
      ...filterOptions.networkOwner,
      customFilterListOptions: {
        render: values => `Move owner: ${values.map(value => shipperIds[value])}`,
      },
    },
  },
  {
    label: 'Lane owner',
    name: 'owner',
    field: 'lane_owner',
    options: {
      display: 'excluded',
      ...filterOptions.owner,
      customFilterListOptions: {
        render: values => `Lane owner: ${values.map(value => shipperIds[value])}`,
      },
    },
  },
  {
    label: 'Excluding lane owner',
    name: 'view.ownerExcluding',
    field: 'owner_excluding',
    options: {
      display: 'excluded',
      ...filterOptions.ownerExcluding,
      customFilterListOptions: {
        render: values => `Excluding lane owner: ${values.map(value => shipperIds[value])}`,
      },
    },
  },
  {
    label: 'Actions',
    name: 'view.id',
    options: {
      filter: false,
      sort: false,
      display: true,
      customBodyRender: id => <NewTabLink url={`/adapt/network-moves/${id}`} />,
    },
  },
  {
    label: 'Lane',
    name: 'view.l0Name',
    field: 'lane',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
];
