import { USER_TYPE } from '@leaf/components'
import { GQL_EQUIPMENT_TYPES } from '@/graphql/filter-queries/GQL_EQUIPMENT_TYPES'
import { getOpsGQL, GQL_COMPANIES } from './GQL_OPS_PLANS'

const mapOps = (shipperOps) =>
  shipperOps.map((op) => ({
    ...op,
    company: {
      id: op.shipper.id,
      name: op.shipper.name,
      type: USER_TYPE.SHIPPER,
    },
    actionsData: {
      id: op.id,
    },
  }))

const getOrderBy = (sort) => {
  if (!sort) {
    // By default, sort by shipper name ascending and planned weekly volume descending
    return [{ shipper: { name: 'asc_nulls_last' } }, { planned_weekly_volume: 'desc_nulls_last' }]
  }
  // Sort on nested fields
  if (sort.name === 'company') {
    return [{ shipper: { name: sort.order } }]
  }
  if (sort.name === 'equipmentType') {
    return [{ equipment_type: { name: sort.order } }]
  }
  // Sort on basic fields
  return [{ [sort.field ?? sort.name]: sort.order }]
}

export const getOps = (getGQLClient, tableState, forDownload = false) => {
  const variables = {
    sort: tableState.sort,
    search: tableState.search,
    ...(!forDownload && {
      limit: tableState.rowsPerPage,
      offset: tableState.page * tableState.rowsPerPage,
    }),
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      company_id: tableState.filters.company ? tableState.filters.company : undefined,
      equipment_type_id: tableState.filters.equipmentType
        ? tableState.filters.equipmentType
        : undefined,
    },
  }
  const { where, search, sort, ...rest } = variables

  return getGQLClient().then((client) => {
    const GQL = getOpsGQL({
      where,
      search,
      ...rest,
    })
    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then((res) => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.stats.aggregate.count,
      data: mapOps(res.shipperOps),
    }))
  })
}

export const getCompanies = (getGQLClient) =>
  getGQLClient()
    .then((client) => client.request(GQL_COMPANIES))
    .then((res) =>
      res.shipper_route_plan.map((e) => ({ label: e.shipper.name, value: e.shipper.id }))
    )

export const getEquipmentTypes = (getGQLClient) =>
  getGQLClient()
    .then((client) => client.request(GQL_EQUIPMENT_TYPES))
    .then((res) => res.equipment_type.map((e) => ({ label: e.name, value: e.id })))
