import { miles, money } from '../../utility/format';
import { EQUIPMENT_CLASSES } from './EquipmentClass';

export default class Summary {
  constructor(data) {
    this.equipmentClass = data.equipment_class.toLowerCase();

    Object.assign(this, {
      ...data,
    });

    this.general = {
      miles: miles(data.miles),
      shipments: data.shipments.toLocaleString(),
      spend: money(data.spend),
      savings: money(data.savings),
      observation: data?.observations?.split(', ') || [],
      actions: data?.actions?.split(', ') || [],
      equipmentClass: EQUIPMENT_CLASSES[this.equipmentClass],
    };

    this.geo = {
      origin: data.origin,
      destination: data.destination,
      path: data.path,
    };
  }

  static get = async (client, companyId, laneId) =>
    client.get(`/analytics/explorer/summaries/companies/${companyId}/lanes/${laneId}`).then(summaries =>
      summaries.reduce(
        (acc, summary) => {
          const s = new Summary(summary);
          acc[s.equipmentClass] = s;
          acc.equipmentClasses.push(s.equipmentClass);
          return acc;
        },
        { equipmentClasses: [] },
      ),
    );
}
