import { gql } from 'graphql-request';

export const getOpsPlanDetailsGQL = id =>
  gql`
		query getOpsPlanDetails {
			shipperOps: shipper_route_plan(where: { id: { _eq: "${id}" } }) {
				id
				shipper {
					id
					name
				}
				route {
					id
					tripType: trip_type
					avgTotalMiles: avg_total_miles
					avgDeadheadMiles: avg_deadhead_miles
					legs(order_by: {sequence: asc}) {
						deadheadMiles: deadhead_miles
						lane {
							destinationName: destination_name
							originName: origin_name
							destinationGeo: destination_geo
							originGeo: origin_geo
							path
							pickupType: pickup_type
							deliveryType: delivery_type
							leafMiles: leaf_miles
							id
							destinationH3: destination_h3
							originH3: origin_h3
						}
					}
				}
				equipmentType: equipment_type {
					id
					name
				}
				adaptLaneId: adapt_lane_ref
				adaptRunDate: adapt_run_date
				adaptWeeklyVolume: adapt_weekly_volume
				adaptTargetShipperRpm: adapt_target_shipper_rpm
				plannedWeeklyVolume: planned_weekly_volume
				plannedAllocationPct: planned_allocation_pct
				plannedShipperRpm: planned_shipper_rpm
				plannedStartDate: planned_start_date
				plannedEndDate: planned_end_date
				plannedLeafTenderAcceptance: planned_leaf_tender_acceptance_pct
				maxCapacityPctSpot: max_capacity_pct_spot
				maxCapacityPctRg: max_capacity_pct_rg
				maxCapacityPctFleet: max_capacity_pct_fleet
				maxCapacityPctFlexWeekly: max_capacity_pct_flex_weekly
				maxCapacityPctNetworkMove: max_capacity_pct_network_move
				maxCapacityPctFlexDayOf: max_capacity_pct_flex_day_of
				lspTargetLinehaulRpmSpot: lsp_target_linehaul_rpm_spot
				lspTargetLinehaulRpmRg: lsp_target_linehaul_rpm_rg
				lspTargetLinehaulRpmFleet: lsp_target_linehaul_rpm_fleet
				lspTargetLinehaulRpmFlexWeekly: lsp_target_linehaul_rpm_flex_weekly
				lspTargetLinehaulRpmNetworkMove: lsp_target_linehaul_rpm_network_move
				lspTargetLinehaulRpmFlexDayOf: lsp_target_linehaul_rpm_flex_day_of
				implementationPlanWeekSpot: implementation_plan_week_spot
				implementationPlanWeekRg: implementation_plan_week_rg
				implementationPlanWeekFleet: implementation_plan_week_fleet
				implementationPlanWeekFlexWeekly: implementation_plan_week_flex_weekly
				implementationPlanWeekNetworkMove: implementation_plan_week_network_move
				implementationPlanWeekFlexDayOf: implementation_plan_week_flex_day_of
			}
		}
	`;

export const getOpsPlanContractsGQL = id =>
  gql`
		query getOpsPlanContracts(
			$limit: Int
			$offset: Int
		) {
			contract(
				limit: $limit
				offset: $offset,
				where: {shipper_route_plans: {shipper_route_plan_id: {_eq: "${id}"}}}
			) {
				id
				contractStatus: contract_status
				contractType: contract_type
				isFleet: is_fleet
        startDate: start_date
        endDate: end_date
        linehaulRpm: linehaul_rpm
        maxVolume: max_volume
				buyer {
					name
				}
				seller {
					name
				}
				contractRoutes: contract_routes(where: {is_primary: {_eq: true}}) {
					route {
						legs(order_by: {sequence: asc}) {
							lane {
								destinationName: destination_name
								originName: origin_name
							}
						}
					}
				}
			}
			contract_aggregate(where: {shipper_route_plans: {shipper_route_plan_id: {_eq: "${id}"}}}) {
				aggregate {
					count
				}
			}
		}
	`;

export const getOpsPlanId = (shipperId, routeId, equipmentTypeId) =>
  gql`
		query getOpsPlanId {
			shipper_route_plan(where: {shipper_id: {_eq: ${shipperId}}, route_id: {_eq: "${routeId}"}, equipment_type_id: {_eq: ${equipmentTypeId}}}) {
				id
			}
		}
  `;
