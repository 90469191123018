import React from 'react'
import { List, ListItemIcon } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  CollapsWrapper,
  NestedNavigationItemParent,
  NestedNavigationItemWrapper,
} from './NestedNavigation.styled'
import { NavigationItemText } from './NavigationItem'

export default ({
  parent,
  childs,
  link,
  location,
  isNavbarOpen,
  isSidebarExpanded,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleOpenMenuItem = () => {
    setIsOpen(!isOpen)
  }

  const handleOpenCollapsedItem = () => {
    setIsOpen(true)
  }

  React.useEffect(() => {
    setIsOpen(isNavbarOpen)
  }, [isNavbarOpen])

  React.useEffect(() => {
    setIsOpen(() => childs?.find((item) => item.to === location?.pathname))
  }, [])

  const renderArrowIcons = () => {
    if (isSidebarExpanded) {
      return isOpen ? <ArrowDropUp /> : <ArrowDropDown />
    }

    return null
  }

  return (
    <List>
      <NestedNavigationItemParent
        onClick={handleOpenMenuItem}
        button
        active={childs?.find((item) => location?.pathname?.startsWith(item.to))}
        $expanded={isSidebarExpanded}
        className={isSidebarExpanded ? '' : 'sidebar-parent'}
        disableRipple
      >
        <ListItemIcon>
          <parent.Icon />
        </ListItemIcon>

        <NavigationItemText primary={parent.text.toUpperCase()} />
        {renderArrowIcons()}
      </NestedNavigationItemParent>

      <CollapsWrapper in={isOpen && isSidebarExpanded}>
        <List component="div" disablePadding>
          {childs?.map((child) => {
            const handleOnClick = (event) => {
              if (child.external) {
                event.preventDefault()
                window.open(child.to, '_blank')
                return null
              }
              if (!isSidebarExpanded) {
                return handleOpenCollapsedItem
              }
              return null
            }

            if (child.show === undefined || child.show) {
              return (
                <NestedNavigationItemWrapper
                  key={child.to}
                  component={link}
                  to={child.to}
                  activeClassName="active-link"
                  button
                  $expanded={isSidebarExpanded}
                  onClick={handleOnClick}
                >
                  {child.Icon && (
                    <ListItemIcon>
                      <child.Icon />
                    </ListItemIcon>
                  )}
                  <NavigationItemText primary={child.text} />
                </NestedNavigationItemWrapper>
              )
            }
            return null
          })}
        </List>
      </CollapsWrapper>
    </List>
  )
}
