/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormControlLabel } from '@mui/material';
import { Form } from '@leaf/components';

export default React.memo(({ label, labelPlacement = 'end', ...inputProps }) => (
  <FormControlLabel
    label={label}
    labelPlacement={labelPlacement}
    control={<Form.Generic.Checkbox {...inputProps} checked={Boolean(inputProps.checked)} />}
  />
));
