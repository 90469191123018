/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FormControl } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { Form, utility } from '@leaf/components'
import { disableEnterKey } from '@/utility/disableEnterKey'
import { useStateMachine } from 'little-state-machine'
import { triggerSubmitEvent } from '@/utility/form'
import { FormContent, FormItem, FormFooter, FormActions } from '../partials/viewHelpers'
import { WeekDays } from '../partials/WeekDays'
import {
  updateContract,
  setContractToDirty,
  setTimeframeVolumeStepValid,
  defaultValues as resetValues,
} from '../domain/stateMachine'

const VOLUME_TYPE = Object.freeze(['FIXED', 'VARIABLE'])
const VOLUME_FREQUENCY = Object.freeze(['DAILY', 'WEEKLY'])

const setDateForView = (date) => {
  // eslint-disable-next-line no-restricted-globals
  if (date instanceof Date && isFinite(date.getTime())) {
    return utility.date.formatDate(date, utility.date.VIEW_FORMAT)
  }
  return date
}

export function TimeframeVolumeForm({ next, back, abortContract }) {
  const { actions, getState } = useStateMachine({
    updateContract,
    setContractToDirty,
    setTimeframeVolumeStepValid,
  })
  const defaultValues = getState().newContract
  const firstRender = useRef(true)
  const [minEndDate, setMinEndDate] = useState(defaultValues.startDate)
  const formRef = useRef(null)

  const handleFormSubmit = (data, event) => {
    const formattedData = {
      ...data,
      startDate: setDateForView(data.startDate),
      endDate: setDateForView(data.endDate),
    }
    actions.updateContract(formattedData)
    if (event.nativeEvent.submitter) {
      next()
    }
  }
  const rhfApi = useForm({
    mode: 'all',
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    register,
    trigger,
    watch,
    reset,
    clearErrors,
    formState: { errors, isValid, isDirty },
    setValue,
  } = rhfApi

  const watchVolumeFrequency = watch('volumeFrequency')

  useEffect(() => {
    if (abortContract) {
      actions.updateAction(resetValues)
      reset(resetValues)
    }
  }, [abortContract, actions, reset])

  useEffect(() => {
    if (!firstRender.current) {
      if (watchVolumeFrequency === 'DAILY') {
        clearErrors('maxVolume')
        setValue('includeWeekends', false)
        setValue('minVolume', '')
        setValue('maxVolume', '')
      }
      trigger()
    }
    firstRender.current = false
  }, [watchVolumeFrequency, setValue, clearErrors, trigger])

  useEffect(() => {
    if (isDirty) {
      actions.setContractToDirty(isDirty)
    }
    if (isDirty && isValid) {
      actions.setTimeframeVolumeStepValid(isValid)
    }
  }, [isDirty, isValid, actions])

  return (
    <FormProvider {...rhfApi}>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        onKeyDown={(e) => disableEnterKey(e)}
        ref={formRef}
      >
        <FormContent>
          <FormItem>
            <Form.Library.Datepicker
              label='Start date'
              name='startDate'
              control={control}
              rules={{ required: true }}
              onAccept={(value) => {
                setMinEndDate(value)
                if (defaultValues.id) {
                  triggerSubmitEvent(formRef)
                }
              }}
            />
            <Form.Library.Datepicker
              label='End date'
              name='endDate'
              control={control}
              rules={{ required: "End Date can't be before Start Date" }}
              minDate={minEndDate}
              onAccept={() => {
                if (defaultValues.id) {
                  triggerSubmitEvent(formRef)
                }
              }}
            />

            <Controller
              name='volumeType'
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <Form.Generic.Autocomplete
                  value={value}
                  options={VOLUME_TYPE}
                  isOptionEqualToValue={(option, selected) => option === selected}
                  getOptionLabel={(option) => option}
                  onChange={(_, data) => {
                    onChange(data)
                    if (defaultValues.id) {
                      triggerSubmitEvent(formRef)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Volume Type*'
                      error={!!errors.volumeType}
                      helperText={errors.volumeType ? 'Required field' : null}
                    />
                  )}
                />
              )}
            />

            <Controller
              name='volumeFrequency'
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <Form.Generic.Autocomplete
                  value={value}
                  options={VOLUME_FREQUENCY}
                  isOptionEqualToValue={(option, selected) => option === selected}
                  getOptionLabel={(option) => option}
                  onChange={(_, data) => {
                    onChange(data)
                    if (defaultValues.id) {
                      triggerSubmitEvent(formRef)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Volume Frequency*'
                      error={!!errors.volumeFrequency}
                      helperText={errors.volumeFrequency ? 'Required field' : null}
                    />
                  )}
                />
              )}
            />
          </FormItem>
          <FormItem>
            <TextField
              label='Min Volume'
              name='minVolume'
              type='number'
              inputRef={register}
              onChange={() => {
                if (defaultValues.id) {
                  triggerSubmitEvent(formRef)
                }
              }}
              InputProps={{
                inputProps: { min: 0, step: 1 },
              }}
              variant='outlined'
              fullWidth
              disabled={watchVolumeFrequency === 'DAILY' || watchVolumeFrequency === ''}
            />

            <Controller
              name='maxVolume'
              control={control}
              rules={{ required: watchVolumeFrequency !== 'DAILY' }}
              render={(props) => (
                <TextField
                  label={
                    watchVolumeFrequency !== 'DAILY' && watchVolumeFrequency !== ''
                      ? 'Max Volume *'
                      : 'Max Volume'
                  }
                  onChange={() => {
                    if (defaultValues.id) {
                      triggerSubmitEvent(formRef)
                    }
                  }}
                  {...props}
                  InputProps={{
                    inputProps: { min: 1, step: 1 },
                  }}
                  fullWidth
                  error={!!errors.maxVolume}
                  helperText={errors.maxVolume ? 'Required field' : null}
                  disabled={watchVolumeFrequency === 'DAILY' || watchVolumeFrequency === ''}
                />
              )}
            />

            <FormControl component='fieldset' error={!!errors?.includeWeekends}>
              <Controller
                name='includeWeekends'
                defaultValue={defaultValues.includeWeekends}
                control={control}
                render={(props) => (
                  <FormControlLabel
                    label='Include weekends'
                    control={
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked)
                          if (defaultValues.id) {
                            triggerSubmitEvent(formRef)
                          }
                        }}
                        disabled={watchVolumeFrequency === 'DAILY' || watchVolumeFrequency === ''}
                      />
                    }
                  />
                )}
              />
            </FormControl>

            <WeekDays
              label='Day Of Flex Volume'
              name='volumePattern'
              defaultValue={watchVolumeFrequency === 'DAILY' ? defaultValues.volumePattern : ''}
              required={watchVolumeFrequency === 'DAILY'}
              disabled={watchVolumeFrequency === null || watchVolumeFrequency === 'WEEKLY'}
              formRef={formRef}
            />
          </FormItem>
        </FormContent>
        <FormFooter requiredText='* Fields are required'>
          <FormActions>
            <Button type='button' variant='outlined' size='large' onClick={back}>
              Previous
            </Button>
            <Button type='submit' variant='contained' size='large' disabled={!isValid}>
              continue
            </Button>
          </FormActions>
        </FormFooter>
      </form>
    </FormProvider>
  )
}
