import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Form } from '@leaf/components'

import FormButtons from '@/form/FormButtons'
import { FormControl, Grid } from '@mui/material'
import { isFirstStepZeroValues } from '@/domain/Sfsc'
import { notANumberMessage } from '@/form/validations'
import useGQL from '@/hooks/useGQL'
import { getFuelPriceIndexFSC } from './domain/companyFSCmodel'

const { Input: TextField, Select, ErrorMessage } = Form.Library

const isFirstStepZeroOptions = [
  { value: true, name: 'Yes' },
  { value: false, name: 'No' },
]

const companyCreateSchema = yup.object().shape({
  name: yup.string().required('Required'),
  fuelPriceIndexId: yup.number().required('Required'),
  peg: yup.number().required(notANumberMessage).min(0),
  increment: yup.number().required(notANumberMessage).min(0.00000000000000000000000000000001),
  bucketSize: yup.number().required(notANumberMessage).min(0.00000000000000000000000000000001),
  validFrom: yup.date().default(() => new Date()),
})

const defaultValues = {
  isFirstStepZero: true,
}

export default (props) => {
  const getGQLClient = useGQL()
  const { onClose, open, ourHandleSubmit, defaultEditValues, title, isEdit } = props

  const getInitialFormData = () => {
    if (isEdit)
      return {
        ...defaultEditValues,
        fuelPriceIndexId: defaultEditValues?.fuelPriceIndex.id,
        isFirstStepZero: isFirstStepZeroValues.YES === defaultEditValues?.isFirstStepZero,
      }

    return defaultValues
  }

  const { handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(companyCreateSchema),
    // TODO investigate react-hook-form bug why defaultEditValues are not populated through defaultValues
    defaultValues: getInitialFormData(),
  })

  const [fuelPriceIndices, setFuelPriceIndices] = React.useState([])

  const handleClose = () => {
    onClose()
  }

  React.useEffect(() => {
    getFuelPriceIndexFSC(getGQLClient).then(({ data }) => {
      setFuelPriceIndices(data)
    })
  }, [])

  React.useEffect(() => {
    reset(getInitialFormData())
  }, [isEdit, defaultEditValues])

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby='add-new-fsc' open={open}>
      <DialogTitle id='add-new-fsc'>{title} FSC</DialogTitle>
      <DialogContent>
        <form
          noValidate
          onSubmit={handleSubmit((data) => {
            ourHandleSubmit(data)
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id='name'
                  name='name'
                  label='Name'
                  control={control}
                  error={errors.name}
                />
                <ErrorMessage errors={errors} name='name' />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Select
                  id='fuelPriceIndexId'
                  name='fuelPriceIndexId'
                  label='Fuel Price Index'
                  options={fuelPriceIndices?.map((option) => ({
                    id: option.id,
                    label: option.name,
                  }))}
                  control={control}
                  error={errors.fuelPriceIndexId}
                />
                <ErrorMessage errors={errors} name='fuelPriceIndexId' />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField id='peg' name='peg' label='PEG' control={control} error={errors.peg} />
                <ErrorMessage errors={errors} name='peg' />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id='increment'
                  name='increment'
                  label='Increment'
                  control={control}
                  error={errors.increment}
                />
                <ErrorMessage errors={errors} name='increment' />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id='bucketSize'
                  name='bucketSize'
                  label='Bucket size'
                  control={control}
                  error={errors.bucketSize}
                />
                <ErrorMessage errors={errors} name='bucketSize' />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Select
                  id='isFirstStepZero'
                  name='isFirstStepZero'
                  label='First step zero'
                  options={isFirstStepZeroOptions?.map((option) => ({
                    id: option.value,
                    label: option.name,
                  }))}
                  control={control}
                  error={errors.isFirstStepZero}
                />
                <ErrorMessage errors={errors} name='isFirstStepZero' />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Form.Library.Datepicker
                  id='validFrom'
                  name='validFrom'
                  label='Valid from'
                  control={control}
                  error={errors.validFrom}
                />
                <ErrorMessage errors={errors} name='validFrom' />
              </FormControl>
            </Grid>
          </Grid>
          <FormButtons
            onCancelClick={handleClose}
            isEdit
            submitButton={isEdit ? 'Save' : undefined}
          />
        </form>
      </DialogContent>
    </Dialog>
  )
}
