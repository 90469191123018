/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormControl, InputAdornment, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { Form } from '@leaf/components';

export default React.memo(
  ({ readOnly, adornment, adornmentPosition = 'end', asTooltip, forcedEndAdornment, ...props }) => {
    const renderAdornment = () => {
      if (adornment) {
        if (asTooltip) {
          return (
            <Tooltip title={adornment}>
              <InputAdornment position={adornmentPosition}>
                <Info color="primary" />
              </InputAdornment>
            </Tooltip>
          );
        }
        return <InputAdornment position={adornmentPosition}>{adornment}</InputAdornment>;
      }
      return null;
    };

    return (
      <FormControl fullWidth>
        <Form.Generic.Input
          variant="outlined"
          InputProps={{
            readOnly,
            [`${adornmentPosition}Adornment`]: renderAdornment(adornment),
            ...(forcedEndAdornment && { endAdornment: forcedEndAdornment }),
          }}
          {...props}
        />
      </FormControl>
    );
  },
);
