import React, { useCallback, useMemo } from 'react'

import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Typography,
  Alert,
} from '@mui/material'
import { Form, H3PickerMap } from '@leaf/components'
import queryString from 'query-string'
import { useSnackbar } from 'notistack'
import { search } from '@/domain/geo/Geo'
import _, { debounce } from 'lodash'
import styled from '@emotion/styled'
import { OverlayLoader } from '@/contracts/shared/OverlayLoader'

const Warning = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  .MuiAlert-icon {
    align-self: center;
  }
`

export default ({ data, hideAreaPicker, type, index, onAreaChange, isLoading }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [selectedHexCells, setSelectedHexCells] = React.useState([])
  const [selectedPointLocation, setSelectedPointLocation] = React.useState()
  const [hasMultipleHexGroups, setHasMultipleHexGroups] = React.useState(false)
  const [isPointWithinHexes, setIsPointWithinHexes] = React.useState(false)
  const [h3CellsToSelect, setH3CellsToSelect] = React.useState()
  const [locations, setLocations] = React.useState()

  const onAddToAreaHandler = useCallback(() => {
    const queries = locations?.split(/\r?\n/).filter((q) => !_.isEmpty(q))
    const qs = queryString.stringify({
      queries,
      includeH3: true,
    })
    search(qs)
      .then((res) => {
        const cells = res
          .map(({ geocoded }) => geocoded?.h3.cells)
          .reduce((combinedCells, h3Cells = []) => {
            combinedCells.push(...h3Cells)
            return combinedCells
          }, [])
        if (cells.length === 0) {
          enqueueSnackbar('No areas found')
        }
        setH3CellsToSelect([...new Set(cells)])
      })
      .catch(enqueueSnackbar)
  }, [locations, enqueueSnackbar])

  const preselectedH3s = useMemo(() => data[index]?.[`${type}H3Cells`], [data, index, type])
  const { coordinates } = data[index]?.[`${type}Point`] ?? {}

  const debounceLocationsOnKeyDown = debounce((e) => {
    setLocations(e.target.value)
  }, 500)

  return (
    <Dialog open onClose={hideAreaPicker} fullWidth maxWidth='xl'>
      <DialogTitle>Adjust Location</DialogTitle>
      <OverlayLoader active={isLoading} />
      <DialogContent dividers>
        <Grid container height={600} spacing={2}>
          <Grid item xs={5}>
            <Typography variant='body'>
              Either add/subtract cells on the map, or provide list of individual zip
              codes/addresses to add:
            </Typography>
            <Warning severity='warning'>
              Only zip codes and full street addresses supported at this time. Place each zip code
              or address in a separate row.
            </Warning>
            <FormControl fullWidth margin='normal'>
              <Form.Generic.Input
                sx={{ '.MuiInputBase-root > textarea': { padding: (theme) => theme.spacing(2) } }}
                multiline
                rows={4}
                defaultValue={locations}
                onKeyDown={debounceLocationsOnKeyDown}
                name='locations'
                label='Locations'
              />
            </FormControl>
            <div>
              <Button variant='contained' onClick={onAddToAreaHandler} disabled={!locations}>
                ADD TO AREA
              </Button>
            </div>
          </Grid>
          <Grid item xs={7}>
            <H3PickerMap
              h3CellsToSelect={h3CellsToSelect}
              preselectedH3s={preselectedH3s}
              coordinates={coordinates}
              onH3CellSelect={setSelectedHexCells}
              onMarkerSet={setSelectedPointLocation}
              setHasMultipleHexGroups={setHasMultipleHexGroups}
              setIsPointWithinHexes={setIsPointWithinHexes}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={hideAreaPicker}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            if (!selectedPointLocation) {
              return enqueueSnackbar('Pinpoint is missing')
            }
            if (!isPointWithinHexes) {
              return enqueueSnackbar('Pinpoint must be inside the selected Area')
            }
            if (hasMultipleHexGroups) {
              return enqueueSnackbar('Area cannot have separated H3 cells')
            }
            return onAreaChange(
              {
                selectedHexCells,
                selectedPointLocation,
              },
              type,
              index
            )
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}
