import styled from '@emotion/styled';
import { DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

export const DialogTitleContainer = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DialogContentContainer = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fafafa;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const DialogContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing(3)};
  background: rgba(0, 21, 48, 0.04);
  width: 100%;
  border-radius: 5px;
`;

export const DialogContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const DownloadTemplateContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  color: #0064b0;
`;

export const DialogActionsContainer = styled(DialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fafafa;
  padding: 13px ${({ theme }) => theme.spacing(2)};
`;

export const UploadButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: 100%;

  & > span > label {
    min-width: 250px;
    height: 36px;
    width: 250px;
    border-radius: 4px;
    :hover {
      background-color: rgb(51, 100, 158);
    }
  }
`;

export const FileUploadedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 12px;
  }
`;

export const IconWrapper = styled(CheckCircle)`
  color: #4caf50;
`;

export const IconButtonDownload = styled(IconButton)`
  color: #0064b0;
`;

export const WarningText = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.error.light}`};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacing(1)}`};
`;
