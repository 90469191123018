import { memo, useEffect } from 'react'
import ReactGA from 'react-ga4'

const setReactGAProps = ({ page, title, companyType, companyId, isImpersonatedUser }) => {
  ReactGA.set({
    page,
    title,
    dimension1: companyType,
    dimension2: isImpersonatedUser?.toString(),
    dimension3: companyId?.toString(),
  })
}

const trackPageView = (isProd, params) => {
  if (isProd) {
    setTimeout(() => {
      setReactGAProps(params)
    }, 500)
  }
}

const sendPageView = (location) => (companyType, companyId, isImpersonatedUser) => {
  const pathName = location.pathname.slice(1)
  const pageName = pathName.charAt(0).toUpperCase() + pathName.slice(1)
  let tabName = ''
  const TAB_URL_ID = '?tab='
  if (location.search.includes(TAB_URL_ID)) {
    tabName = ` - TAB: ${location.search.split(TAB_URL_ID).pop().split('_').join(' ')}`
  }
  const title = `${pageName}${tabName}`
  const page = `${location.pathname}${location.search}`
  setReactGAProps({ title, page, companyType, companyId, isImpersonatedUser })
}

const Listener = memo(
  ({ isProd, trackingId, children, history, companyType, companyId, isImpersonatedUser }) => {
    useEffect(() => {
      if (trackingId && isProd) {
        ReactGA.initialize(trackingId)
      }
    }, [trackingId])
    useEffect(() => {
      let unsubscribe
      if (trackingId && isProd) {
        sendPageView(history.location)(companyType, companyId, isImpersonatedUser)
        unsubscribe = history.listen((location) =>
          sendPageView(location)(companyType, companyId, isImpersonatedUser)
        )
      }
      return () => unsubscribe?.()
    }, [history, companyType, trackingId, companyId, isImpersonatedUser])

    return children
  }
)

export { Listener, trackPageView }
