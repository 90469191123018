import React from 'react';
import { MultiRowInfo } from '@leaf/components';
import styled from '@emotion/styled';

const LeafLocation = styled.div`
  padding: ${({ theme }) => theme.spacing(1)};
  box-sizing: border-box;
  :hover {
    box-sizing: border-box;
    background-color: ${({ theme }) => `${theme.palette.divider}`};
    padding: ${({ theme }) => theme.spacing(1)};
    outline: 1px solid rgba(0, 21, 48, 0.08);
    border-radius: 2px;
  }
`;

export const options = {
  viewColumns: false,
  download: false,
  filter: true,
};

export const getColumns = ({ handleLocationUpdateDialogOpen, filterOptions }) => [
  {
    label: 'Execution Partner',
    name: 'executionPartnerName',
    options: {
      sort: false,
      filter: false,
    },
  },
  {
    label: 'Shipper',
    name: 'shipper',
    options: {
      ...filterOptions.shipper,
      sort: false,
    },
  },
  {
    label: 'Execution Partner Location',
    name: 'executionLocation',
    options: {
      sort: false,
      filter: false,
      customBodyRender: location => {
        const [primaryText, secondaryText] = location ?? [];
        return <MultiRowInfo primaryContent={[primaryText]} secondaryContent={[secondaryText]} />;
      },
    },
  },
  {
    label: 'Leaf location',
    name: 'location',
    options: {
      sort: false,
      filter: false,
      customBodyRender: location => {
        const { primaryLeafLocationData, secondaryLeafLocationData } = location ?? {};
        const WrapperComponent = ({ children }) => (
          <LeafLocation onClick={() => handleLocationUpdateDialogOpen(location)} role="button" tabIndex={0}>
            {children}
          </LeafLocation>
        );
        return (
          <WrapperComponent>
            <MultiRowInfo primaryContent={[primaryLeafLocationData]} secondaryContent={[secondaryLeafLocationData]} />
          </WrapperComponent>
        );
      },
    },
  },
  {
    label: 'Mapping required',
    name: 'isMappedLocation',
    options: {
      ...filterOptions.isMappedLocation,
      sort: false,
      customFilterListOptions: {
        render: () => 'Mapping required',
      },
      display: 'excluded',
    },
  },
];
