export const LOCATION_TYPE = {
  CUSTOMER: 'CUSTOMER',
  DISTRIBUTION_CENTER: 'DISTRIBUTION_CENTER',
  FACTORY: 'FACTORY',
  SUPPLIER: 'SUPPLIER',
  WAREHOUSE: 'WAREHOUSE',
};

export const LOCATION_TYPE_DISPLAY_NAMES = {
  CUSTOMER: 'Customer',
  DISTRIBUTION_CENTER: 'Distribution center',
  FACTORY: 'Factory',
  SUPPLIER: 'Supplier',
  WAREHOUSE: 'Warehouse',
};
