export default class CompanyPerformanceByLane {
  constructor({ ...response }) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      shipperFailed: this.shipperFailed || '-',
      carrierFailed: this.carrierFailed || '-',
      onTimePickupPercentage: typeof this.onTimePickupPercentage === 'number' ? `${this.onTimePickupPercentage}%` : '-',
      onTimeDeliveryPercentage:
        typeof this.onTimeDeliveryPercentage === 'number' ? `${this.onTimeDeliveryPercentage}%` : '-',
    };
  }
}
