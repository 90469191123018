import React, { ReactNode, Dispatch, useEffect, useState, ReactElement } from 'react'
import { AutocompleteRenderInputParams, Box, Button, Tooltip, Typography } from '@mui/material'
import { Form } from '@leaf/components'
import { useForm } from 'react-hook-form'
import { getShippers } from './domain/laneModel'
import useGQL from '@/hooks/useGQL'
import { useSnackbar } from 'notistack'
import { Geometry, GeoSeachResponse, search } from '@/domain/geo/Geo'
import queryString from 'query-string'
import _ from 'lodash'
import { SetStateAction } from 'react'
import { GraphQLClient } from 'graphql-request'

type FormValues = {
  company: string | undefined
  origin: string | undefined
  destination: string | undefined
  shouldShowTenderLocations: boolean
}

interface ExternalFilterProps {
  stateSetter: string
  dispatch: (action: { type: string; payload: any }) => {}
  tableState: any
}

const SwitchWrapperComponent = ({
  isSwitchEnabled = false,
  children,
}: {
  isSwitchEnabled: boolean
  children: ReactElement
}) =>
  !isSwitchEnabled ? (
    <Tooltip title='To activate facilities first select Company Name' placement='top'>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  )

export default ({ stateSetter, dispatch, tableState }: ExternalFilterProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const getGQLClient = useGQL()
  const [companyOptions, setCompanyOptions] = useState<DropdownValue[]>([])
  const [originGeo, setOriginGeo] = useState<Geometry | undefined>()
  const [destinationGeo, setDestinationGeo] = useState<Geometry | undefined>()
  const [shouldEnableTenderLocations, setShouldEnableTenderLocations] = useState<boolean>(false)

  const { control, watch } = useForm<FormValues>()

  const company = watch('company')

  useEffect(() => {
    getShippers(getGQLClient as () => Promise<GraphQLClient>)
      .then(setCompanyOptions)
      .catch(enqueueSnackbar)
  }, [])

  useEffect(() => {
    dispatch({
      type: stateSetter,
      payload: {
        ...tableState,
        filters: {
          ...tableState.filters,
          origin: originGeo,
          destination: destinationGeo,
        },
      },
    })
  }, [originGeo, destinationGeo])

  const onGetAreaHandler = (value: string, type: 'origin' | 'destination') => {
    let geoSetter: Dispatch<SetStateAction<Geometry | undefined>>
    if (type === 'origin') {
      geoSetter = setOriginGeo
    } else {
      geoSetter = setDestinationGeo
    }
    if (!value) {
      return geoSetter(undefined)
    }

    const qs = queryString.stringify({
      queries: [value],
      geoJson: true,
      includeH3: true,
    })

    search(qs)
      .then((res) => {
        if (res.length === 0) {
          enqueueSnackbar('No areas found')
        }
        const polygons = res.map((value: GeoSeachResponse) => value.geocoded?.h3.geometry)
        geoSetter(polygons[0])
      })
      .catch(enqueueSnackbar)
  }

  useEffect(() => {
    setShouldEnableTenderLocations(!!company)
  }, [company])

  const onDebounceChangeHandler = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>, type: 'origin' | 'destination') => {
      onGetAreaHandler(e.target.value, type)
    },
    500
  )

  return (
    <form>
      <Box m={3} display='flex' gap={4} alignContent='center' minHeight={40}>
        <Box display='flex' flexDirection='column' gap={1} width={250}>
          <Typography variant='caption'>Company Name</Typography>
          <Form.Library.Autocomplete
            name='company'
            control={control}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, selected: DropdownValue) => {
              dispatch({
                type: stateSetter,
                payload: {
                  ...tableState,
                  filters: {
                    ...tableState.filters,
                    company: selected,
                    shouldShowTenderLocations:
                      selected === null ? false : tableState.filters.shouldShowTenderLocations,
                  },
                },
              })
            }}
            getOptionLabel={(c: { label: string; value: number }) => c.label}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <Form.Generic.Input placeholder='Name' {...params} />
            )}
            options={companyOptions}
          />
        </Box>
        <Box display='flex' flexDirection='column' gap={1} width={250}>
          <Typography variant='caption'>Origin</Typography>
          <Form.Library.Input
            name='origin'
            control={control}
            placeholder='Zip code or street address'
            onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) =>
              onDebounceChangeHandler(e, 'origin')
            }
          />
        </Box>
        <Box display='flex' flexDirection='column' gap={1} width={250}>
          <Typography variant='caption'>Destination</Typography>
          <Form.Library.Input
            name='destination'
            control={control}
            placeholder='Zip code or street address'
            onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) =>
              onDebounceChangeHandler(e, 'destination')
            }
          />
        </Box>
        <SwitchWrapperComponent isSwitchEnabled={shouldEnableTenderLocations}>
          <Box display='flex' flexDirection='column' justifyContent='center' gap={1} width={250}>
            <Form.Library.Switch
              checked={tableState.filters.shouldShowTenderLocations}
              {...(!tableState.filters.company && { checked: false })}
              label='Show tender locations'
              name='shouldShowTenderLocations'
              control={control}
              disabled={!tableState.filters.company}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, selected: boolean) => {
                dispatch({
                  type: stateSetter,
                  payload: {
                    ...tableState,
                    filters: {
                      ...tableState.filters,
                      shouldShowTenderLocations: selected,
                    },
                  },
                })
              }}
            />
          </Box>
        </SwitchWrapperComponent>
      </Box>
    </form>
  )
}
