import { gql } from 'graphql-request'
import { utility } from '@leaf/components'

// NOTE: field names need to be snake case for correct mapping to be achieved
const WHERE = {
  SHIPPERS_VAR: gql`$shippers: [bigint!]`,
  SHIPPERS_CLAUSE: gql`shipper: { id: { _in: $shippers } }`,
  IS_MAPPED_LOCATION_VAR: gql`$is_mapped_location: Boolean!`,
  IS_MAPPED_LOCATION_CLAUSE: gql`mapped_location_id: { _is_null: $is_mapped_location }`,
}

const whereClause = ({ where, search }) => {
  let whereGQL = gql``
  const whereVarsKeys = Object.keys(where).filter(
    (k) =>
      (Array.isArray(where[k]) && where[k].length) ||
      typeof where[k] === 'string' ||
      typeof where[k] === 'boolean'
  )

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
      where: {
        _and: [
          ${whereVarsKeys.map((key) =>
            where[key]
              ? gql`{
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
              }`
              : ''
          )}

          ${
            search !== undefined && search !== ''
              ? gql`{
            _or: [
              ${
                utility.numbers.isNumber(search)
                  ? gql`{ id: { _eq: "${search}" } }`
                  : ''
              }
              ${
                utility.numbers.isNumber(search)
                  ? gql`{ zip: { _ilike: "${search}" } }`
                  : ''
              }
              ${
                utility.numbers.isNumber(search)
                  ? gql`{ mapped_location_id: { _eq: "${search}" } }`
                  : ''
              }
              {execution_partner: { name: { _ilike: "%${search}%" } }}
              {shipper: { name: { _ilike: "%${search}%" } }}
              {city: { _ilike: "%${search}%" } }
              {business_name: { _ilike: "%${search}%" } }
              {address: { _ilike: "%${search}%" } }
              {state: { _ilike: "%${search}%" } }
              {location: { city: { _ilike: "%${search}%" } } }
              {location: { state: { _ilike: "%${search}%" } } }
              ${
                utility.numbers.isNumber(search)
                  ? gql`{ location: { zip: { _eq: "${search}" } } }`
                  : ''
              }
            ]}`
              : ''
          }
        ]
      }
  `
  }
  return whereGQL
}

const whereVar = ({ where }) => gql`
  ${where.shippers ? WHERE.SHIPPERS_VAR : ``}
  ${where.is_mapped_location ? WHERE.IS_MAPPED_LOCATION_VAR : ``}
`

export const getExecutionPartnerLocationsGQL = (variables) => {
  const whereGQL = whereClause(variables)
  return gql`
    query getExecutionPartnerLocations(
      $limit: Int
      $orderBy: [execution_partner_location_order_by]
      $offset: Int
      ${whereVar(variables)}
    ) {
        executionPartnerLocation: execution_partner_location(
          limit: $limit
          order_by: $orderBy
          offset: $offset
          ${whereGQL}
        ) {
          id
          businessName: business_name
          city
          mappedLocationId: mapped_location_id
          zip
          state
          address
          location {
            city
            state
            zip
            businessName: business_name
            street
          }
          executionPartner: execution_partner {
            name
          }
          shipper {
            name
            id
          }
      }
      executionPartnerLocationAggregate: execution_partner_location_aggregate${
        whereGQL ? gql`( ${whereGQL} )` : ''
      } {
        aggregate {
          count
        }
      }
    }
  `
}
