import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { v4 } from 'uuid';
import { utility, CompanyAvatar, ContractType, Lane, USER_TYPE } from '@leaf/components';
import { Publish, CloudDownload } from '@mui/icons-material';

export const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`;

export const getOptions = (handleOpenImportDialog, handleDownloadContractMatches) => ({
  filter: true,
  customToolbar: () => (
    <>
      <Tooltip title="Download Contract Matches">
        <IconButton onClick={handleDownloadContractMatches}>
          <CloudDownload />
        </IconButton>
      </Tooltip>
      <Tooltip title="Import Contract Matches">
        <IconButton onClick={handleOpenImportDialog}>
          <Publish />
        </IconButton>
      </Tooltip>
    </>
  ),
});

export const getColumns = filterOptions => [
  {
    label: 'Enabled',
    name: 'is_enabled',
    options: {
      display: false,
      sort: false,
      filter: false,
      customBodyRender: enabled => (enabled ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Shipper Name',
    name: 'buy_contract.buyer',
    field: 'shipperName',
    options: {
      sort: false,
      ...filterOptions.shipperName,
      customBodyRender: buyer => <CompanyAvatar name={buyer.name} id={buyer.id} type={USER_TYPE.SHIPPER} isChip />,
    },
  },
  {
    label: 'LSP Name',
    name: 'sell_contract.seller',
    field: 'lspName',
    options: {
      sort: false,
      ...filterOptions.lspName,
      customBodyRender: seller => <CompanyAvatar name={seller.name} id={seller.id} type={USER_TYPE.CARRIER} isChip />,
    },
  },
  {
    label: 'LSP Contract Type',
    name: 'lspContractType',
    field: 'lspContractType',
    options: {
      sort: false,
      ...filterOptions.lspContractType,
      customBodyRender: type => <ContractType type={type} />,
    },
  },
  {
    label: 'Route',
    name: 'contractRoute',
    options: {
      filter: false,
      sort: false,
      customBodyRender: ({ legs }) =>
        legs?.map(({ lane: { origin, destination } }) => (
          <LaneWrapper key={v4()} isSingleRow={legs.length === 1}>
            <Lane displayAsGrid origin={{ primaryContent: [origin] }} destination={{ primaryContent: [destination] }} />
          </LaneWrapper>
        )),
    },
  },
  {
    label: 'Start Date',
    name: 'startDate',
    options: {
      filter: false,
    },
  },
  {
    label: 'End Date',
    name: 'endDate',
    options: {
      filter: false,
    },
  },
  {
    label: 'Assigned Weekly Volume',
    name: 'assigned_weekly_volume',
    options: {
      sort: true,
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Shipper Contract ID',
    name: 'buy_contract.id',
    options: {
      display: false,
      sort: false,
      filter: false,
    },
  },
  {
    label: 'LSP Contract ID',
    name: 'sell_contract.id',
    options: {
      display: false,
      sort: false,
      filter: false,
    },
  },
  {
    label: 'Range Date',
    name: 'rangeDate',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      ...filterOptions.rangeDate,
    },
  },
  {
    label: 'Range Assigned Weekly Volume',
    name: 'rangeAssignedWeeklyVolume',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      ...filterOptions.rangeAssignedWeeklyVolume,
    },
  },
];
