import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  DialogActions,
  Grid,
  Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Form, useLoading, LazySpinner, PolygonMap } from '@leaf/components'
import useGQL from '@/hooks/useGQL'
import { useSnackbar } from 'notistack'
import styled from '@emotion/styled'
import { getMatchedContract } from './domain/contractMatchModel'

const PolygonMapContainer = styled.div`
  height: 70vh;
`

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`

const LaneMarker = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ bgColor }) => `${bgColor || '#4a90e2'}`};
`

export default ({ handleFormSubmit, contractIdsToEdit, setContractIdsToEdit, isBuyerContract }) => {
  const getGQLClient = useGQL()
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, control, register, reset } = useForm()
  const [contract, setContract] = useState()
  const [canEnable, setCanEnable] = useState(false)

  const onClosePopup = useCallback(() => setContractIdsToEdit(), [setContractIdsToEdit])

  const [getContractToEdit, isContractToEditLoading] = useLoading(
    async (ids) =>
      getMatchedContract({ getGQLClient, ids, isBuyerContract })
        .then(({ data }) => {
          setContract(data)
        })
        .catch(enqueueSnackbar),
    true
  )

  useEffect(() => {
    if (contract) {
      setCanEnable(!contract.isEnabled)
      reset(contract)
    }
  }, [contract])

  useEffect(() => {
    if (contractIdsToEdit) {
      getContractToEdit(contractIdsToEdit)
    }
  }, [contractIdsToEdit])

  const getMapLegend = () => (
    <LegendContainer>
      <LaneMarker bgColor='#4A90E2' />
      <Typography>Focus Contract</Typography>
      <LaneMarker bgColor='#7B61FF' />
      <Typography>Match Contract</Typography>
    </LegendContainer>
  )

  return (
    <Dialog fullWidth maxWidth='xl' open onClose={onClosePopup}>
      <DialogTitle>EDIT CONTRACT MATCH</DialogTitle>
      {!isContractToEditLoading ? (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <input name='sellContractId' hidden ref={register()} />
          <input name='buyContractId' hidden ref={register()} />
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Form.Library.Switch
                        label='Enable'
                        name='isEnabled'
                        control={control}
                        disabled={!canEnable}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Form.Library.Switch
                        label='Is Incumbent Relationship?'
                        name='isIncumbent'
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Form.Library.Datepicker
                        label='Start date'
                        name='startDate'
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Form.Library.Datepicker label='End date' name='endDate' control={control} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Form.Library.Input
                        type='number'
                        name='assignedWeeklyVolume'
                        label='Weekly Match Volume'
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Form.Library.Input
                        sx={{
                          '.MuiInputBase-root > textarea': { padding: (theme) => theme.spacing(2) },
                        }}
                        multiline
                        rows={4}
                        name='notes'
                        label='Notes'
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <PolygonMapContainer>
                  <PolygonMap
                    data={contract.mapData}
                    shouldHideNummeratedMarker
                    legendComponent={getMapLegend()}
                  />
                </PolygonMapContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button variant='outlined' onClick={onClosePopup}>
              Cancel
            </Button>
            <Button type='submit' variant='contained'>
              Save
            </Button>
          </DialogActions>
        </form>
      ) : (
        <LazySpinner offset='50vh' />
      )}
    </Dialog>
  )
}
