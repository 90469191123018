/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import axios from 'axios'
import { GQL_SHIPPERS } from '@/graphql/filter-queries/GQL_COMPANIES'
import { getLanesGQL } from './GQL_LANES'
import { getTenderLocationsGQL } from './GQL_TENDER_LOCATIONS'

const mapLanes = (lanes) =>
  lanes.map((lane) => ({
    ...lane,
    actionsData: {
      id: lane.id,
    },
  }))

const getOrderBy = (sort) => {
  if (sort) {
    return [{ [sort.field ?? sort.name]: sort.order }]
  }
  return null
}

export const getLanes = (getGQLClient, tableState) => {
  const variables = {
    sort: tableState.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      company: tableState.filters.company ? [tableState.filters.company.value] : undefined,
      origin: tableState.filters.origin ? tableState.filters.origin : undefined,
      destination: tableState.filters.destination ? tableState.filters.destination : undefined,
    },
  }
  const { where, search, sort, ...rest } = variables

  return getGQLClient().then((client) => {
    const GQL = getLanesGQL({
      where,
      search,
      ...rest,
    })
    return client
      .request(GQL, { company: where.company, ...rest, orderBy: getOrderBy(sort) })
      .then((res) => ({
        limit: rest.limit,
        offset: rest.offset,
        orderBy: getOrderBy(sort),
        total: res.lane_aggregate.aggregate.count,
        data: mapLanes(res.lane),
      }))
  })
}

export const getTenderLocations = (getGQLClient, tableState) => {
  const variables = {
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      company: tableState.filters.company ? [tableState.filters.company.value] : undefined,
      origin: tableState.filters.origin ? tableState.filters.origin : undefined,
      destination: tableState.filters.destination ? tableState.filters.destination : undefined,
    },
  }
  const { where } = variables
  return getGQLClient().then((client) => {
    const GQL = getTenderLocationsGQL({
      where,
    })
    return client.request(GQL, { company: where.company }).then((res) => res.executionLane)
  })
}

export const getShippers = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_SHIPPERS)
      .then((res) => res.company.map((e) => ({ label: e.name, value: e.id })))
  )

export const saveLane = (lane) => axios.post(`routing/lanes`, lane)

export const importLanes = (file) => {
  const formData = new FormData()
  formData.append('file', file)

  return axios.post('routing/lanes/import', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    responseType: 'arraybuffer',
    timeout: 1000 * 300,
  })
}
