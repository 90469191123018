import React from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Progress = styled(LinearProgress)`
  height: 4px;
  background-color: lightgray;
  border-radius: 5;
  background-color: '#7bc67e';
`

const Label = ({ label }) => (
  <Box pb={1}>
    <Typography variant='body1'>{`${label}%`}</Typography>
  </Box>
)

export default ({ value = 0, label = '' }) => (
  <Box>
    {label && <Label label={label} />}
    <Progress variant='determinate' value={value} />
  </Box>
)
