import React from 'react';
import { IconButton } from '@mui/material';
import { Edit, Add, Delete } from '@mui/icons-material';

export default React.memo(({ onAdd, onEdit, onDelete, showEdit, disable }) => {
  if (disable) {
    return null;
  }
  return <>
    <IconButton id="add" onClick={onAdd} size="large">
      <Add />
    </IconButton>

    {showEdit ? (
      <>
        <IconButton onClick={onEdit} size="large">
          <Edit />
        </IconButton>

        <IconButton onClick={onDelete} size="large">
          <Delete />
        </IconButton>
      </>
    ) : null}
  </>;
});
