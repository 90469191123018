import Address from '@/domain/location/Address'

export default class OverviewLocation {
  constructor({ street, city, state, zipCode, geo, ...response }) {
    Object.assign(this, {
      ...response,
      address: new Address({ street, city, state, zipCode, geo }),
    })

    this.view = {
      address: this.address.mapAddressLong(),
      addressShort: this.address.mapAddress(),
      locationType: this.locationType ?? '-',
      businessName: this.businessName ?? '-',
    }
  }
}
