import axios from 'axios';
import { utility } from '@leaf/components';

export default class Ops {
  static importShipperOps(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post('planning/ops/shipper/import', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      timeout: 1000 * 300,
      onUploadProgress,
    });
  }

  static updateShipperOpsPlan(opsPlan) {
    // We need to adjust the error handling behavior for this API to get the raw error
    return axios
      .put('planning/ops/shipper', [opsPlan], {
        validateStatus: status => status < 500,
      })
      .then(response => {
        if (response.detail) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(`Error in ${response.detail[0].loc[2]}: ${response.detail[0].msg}`);
        }
        return Promise.resolve(response);
      });
  }

  static deleteShipperOpsPlan(id) {
    return axios.delete('planning/ops/shipper', {
      data: [id],
    });
  }

  static downloadOpsPlans(ids) {
    axios
      .post(
        `planning/ops/shipper/download`,
        {
          ids,
        },
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        utility.table.triggerBrowserDownload(response, 'opsPlans', 'xlsx');
      });
  }
}
