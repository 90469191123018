import React, { useContext, useState } from 'react'
import { ServerSideTable, ImportSummaryTable } from '@leaf/components'
import styled from '@emotion/styled'
import { StateContext } from '@/state/StateProvider'
import { SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import useGQL from '@/hooks/useGQL'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '@auth0/auth0-react'
import {
  contractMatchingQuickFilters,
  getContractMatches,
  saveMatchedContract,
} from './domain/contractMatchModel'
import EditContractMatch from './EditContractMatch'
import { getOptions, getColumns } from './table-config'

const Wrapper = styled.div`
  flex-grow: 1;
  .MuiPaper-root {
    height: 700px;
  }
  .MuiPaper-root > .MuiToolbar-root {
    background: #eee;
  }
`

export default ({ contractId, isBuyerContract }) => {
  const getGQLClient = useGQL()
  const { enqueueSnackbar } = useSnackbar()
  const { getAccessTokenSilently } = useAuth0()
  const [failedDataList, setFailedDataList] = useState()

  const {
    state: {
      tables: { shipperContractMatchesOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  const [contractIdsToEdit, setContractIdsToEdit] = useState()

  const handleChange = (setTableState) =>
    dispatch({
      type: SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE,
      payload: setTableState(tableState),
    })

  const handleEnableMatchContract = async (contract) => {
    try {
      const token = await getAccessTokenSilently()
      await saveMatchedContract(contract, token)
      setContractIdsToEdit()
    } catch ({ response }) {
      if (response?.data?.total === 0) {
        enqueueSnackbar('Update failed', { variant: 'error' })
      } else if (response?.data?.failed > 0) {
        setFailedDataList(response?.data)
      }
    } finally {
      dispatch({
        type: SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE,
        payload: { ...tableState },
      })
    }
  }

  const handleCloseSummaryDialog = () => {
    setFailedDataList(null)
  }

  return (
    <Wrapper>
      <ServerSideTable
        title='Contract Matches'
        customSearch={false}
        options={getOptions()}
        quickFilterComponents={[
          {
            label: 'Time',
            name: 'timeStatuses',
            values: ['PAST', 'ONGOING', 'FUTURE'],
            inputType: 'toggle-button',
          },
        ]}
        gqlQuickFiltersMeta={contractMatchingQuickFilters}
        columns={getColumns(handleEnableMatchContract, setContractIdsToEdit)}
        fn={() => getContractMatches({ getGQLClient, tableState, contractId, isBuyerContract })}
        tableState={tableState}
        setTableState={handleChange}
      />
      {contractIdsToEdit && (
        <EditContractMatch
          contractIdsToEdit={contractIdsToEdit}
          setContractIdsToEdit={setContractIdsToEdit}
          handleFormSubmit={(values) => {
            handleEnableMatchContract({ ...values })
          }}
          isBuyerContract={isBuyerContract}
        />
      )}
      {failedDataList && (
        <ImportSummaryTable importSummary={failedDataList} onClose={handleCloseSummaryDialog} />
      )}
    </Wrapper>
  )
}
