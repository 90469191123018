import React from 'react';
import { utility, ContractStatus, ContractType, NewTabLink } from '@leaf/components';
import { IconButton, Tooltip } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';

const { ActionButtons } = utility.table;

export const focusOpsPlansColumns = [
  { name: 'shipper.name', label: 'Shipper' },
  { name: 'route.legs.0.lane.originName', label: 'Origin Area' },
  { name: 'route.legs.0.lane.destinationName', label: 'Destination Area' },
  { name: 'equipmentType.name', label: 'Equipment Type' },
  { name: 'plannedWeeklyVolume', label: 'Planned Weekly Volume' },
  {
    name: 'plannedAllocationPct',
    label: 'Planned Allocation',
    options: { customBodyRender: pct => utility.numbers.toPercent(pct * 100) },
  },
  {
    name: 'plannedShipperRpm',
    label: 'Planned Shipper RPM',
    options: { customBodyRender: rpm => utility.numbers.formatNumberAsView(rpm, 2) },
  },
  {
    name: 'plannedStartDate',
    label: 'Planned Start Date',
    options: { customBodyRender: date => utility.date.backendStringToViewString(date) ?? '-' },
  },
  {
    name: 'plannedEndDate',
    label: 'Planned End Date',
    options: { customBodyRender: date => utility.date.backendStringToViewString(date) ?? '-' },
  },
  {
    name: 'plannedLeafTenderAcceptance',
    label: 'Planned Leaf Tender Acceptance',
    options: { customBodyRender: pct => utility.numbers.toPercent(pct * 100) },
  },
  {
    name: 'implementationPlanWeekSpot',
    label: 'Implementation Week (Spot)',
    options: { ...utility.table.rightAlignColumnProps(week => week ?? '-') },
  },
  {
    name: 'implementationPlanWeekRg',
    label: 'Implementation Week (Routing Guide)',
    options: { ...utility.table.rightAlignColumnProps(week => week ?? '-') },
  },
  {
    name: 'implementationPlanWeekFleet',
    label: 'Implementation Week (Fleet)',
    options: { ...utility.table.rightAlignColumnProps(week => week ?? '-') },
  },
  {
    name: 'implementationPlanWeekWeekOf',
    label: 'Implementation Week (Week of)',
    options: { ...utility.table.rightAlignColumnProps(week => week ?? '-') },
  },
  {
    name: 'implementationPlanWeekNetwork',
    label: 'Implementation Week (Network)',
    options: { ...utility.table.rightAlignColumnProps(week => week ?? '-') },
  },
  {
    name: 'implementationPlanWeekDayOf',
    label: 'Implementation Week (Day of)',
    options: { ...utility.table.rightAlignColumnProps(week => week ?? '-') },
  },
];

export const getContractsOpsPlansColumns = copy => [
  {
    name: 'id',
    label: 'Contract ID',
    options: {
      display: false,
    },
  },
  {
    name: 'accountName',
    label: 'Account Name',
  },
  {
    label: 'Contract Status',
    name: 'contractStatus',
    options: {
      customBodyRender: status => <ContractStatus status={status} />,
    },
  },
  {
    name: 'contractType',
    label: 'Contract Type',
    options: {
      customBodyRender: type => <ContractType type={type} />,
    },
  },
  {
    name: 'contractRoutes.0.route.legs.0.lane.originName',
    label: 'Origin Area',
  },
  {
    name: 'contractRoutes.0.route.legs.0.lane.destinationName',
    label: 'Destination Area',
  },
  {
    label: 'Start Date',
    name: 'startDate',
    options: {
      customBodyRender: date => utility.date.backendStringToViewString(date) ?? '-',
    },
  },
  {
    label: 'End Date',
    name: 'endDate',
    options: {
      customBodyRender: date => utility.date.backendStringToViewString(date) ?? '-',
    },
  },
  {
    label: 'Linehaul RPM',
    name: 'linehaulRpm',
    options: {
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'Weekly Volume',
    name: 'maxVolume',
    options: {
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      ...utility.table.rightStickyColumnProps,
      customBodyRender: actionsData => {
        const { id } = actionsData;
        const text = `${window.location.host}/contracts/${id}`;
        return (
          <ActionButtons id={`Actions-contract-${id}`}>
            <Tooltip title="Copy Contract Link">
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  copy(text);
                }}
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <NewTabLink url={`/contracts/${id}`} />
          </ActionButtons>
        );
      },
    },
  },
];
