/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useGQL from '@/hooks/useGQL'
import { useLoading, OverviewTable, utility } from '@leaf/components'
import { useSnackbar } from 'notistack'
import styled from '@emotion/styled'
import { getFocusContractVolumeDetails } from './domain/contractMatchModel'

const Wrapper = styled.div`
  .MuiPaper-root > .MuiToolbar-root {
    background: #eee;
  }
`

export default ({ contractId }) => {
  const { enqueueSnackbar } = useSnackbar()

  const getGQLClient = useGQL()
  const [focusContract, setFocusContract] = useState()

  const [getFocusContract, isFocusContractLoading] = useLoading(
    async (id) =>
      getFocusContractVolumeDetails(getGQLClient, id)
        .then(({ data }) => {
          setFocusContract(data)
        })
        .catch(enqueueSnackbar),
    true
  )

  useEffect(() => {
    if (contractId) {
      getFocusContract(contractId)
    }
  }, [contractId])

  return (
    <Wrapper>
      <OverviewTable
        title='Focus Contract'
        options={{ search: false, download: false, filter: false, viewColumns: false }}
        components={{ TableFooter: () => null }}
        columns={[
          { name: 'accountName', label: 'Account Name', options: { sort: false } },
          { name: 'origin', label: 'Origin Area', options: { sort: false } },
          { name: 'destination', label: 'Destination Area', options: { sort: false } },
          {
            name: 'dateRange',
            label: 'Date Range',
            options: {
              sort: false,
              customBodyRender: (dateRange) => {
                if (!dateRange) {
                  return null
                }
                const [startDate, endDate] = dateRange
                return `${startDate} - ${endDate}`
              },
            },
          },
          {
            name: 'maxExpected',
            label: 'Lifetime Expected Volume',
            options: {
              sort: false,
              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'weeklyMaxExpected',
            label: 'Lifetime Weekly Expected Volume',
            options: { sort: false, ...utility.table.rightAlignColumnProps() },
          },
          {
            name: 'allocatedUntilContractEnd',
            label: 'Matched Future Volume',
            options: { sort: false, ...utility.table.rightAlignColumnProps() },
          },
          {
            name: 'weeklyAllocatedUntilContractEnd',
            label: 'Weekly Matched Future Volume',
            options: { sort: false, ...utility.table.rightAlignColumnProps() },
          },
          {
            name: 'maxUnallocatedUntilContractEnd',
            label: 'Available Future Volume',
            options: { sort: false, ...utility.table.rightAlignColumnProps() },
          },
          {
            name: 'weeklyMaxUnallocatedUntilContractEnd',
            label: 'Available Weekly Future Volume',
            options: { sort: false, ...utility.table.rightAlignColumnProps() },
          },
        ]}
        data={[focusContract]}
        isLoading={isFocusContractLoading}
      />
    </Wrapper>
  )
}
