import axios from 'axios';
import { toCustomFilterOptions, utility } from '@leaf/components';

export default class AdaptBoard {
  constructor(response) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      annualLoads: utility.numbers.numberWithCommas(response.annualLoads) ?? '-',
      annualSpend: utility.numbers.formatNumber(response.annualSpend, utility.numbers.UNIT.CURRENCY) ?? '-',
      savings: utility.numbers.formatNumber(response.savings, utility.numbers.UNIT.CURRENCY) ?? '-',
      destination: response.destination ?? '-',
      laneId: response.laneId ?? '-',
      laneType: response.laneType || '-',
      origin: response.origin ?? '-',
      shipperGuid: response.shipperGuid ?? '-',
      shipperId: response.shipperId || '-',
      shipperName: response.shipperName ?? '-',
      status: response.status ?? '-',
      phases: {
        shipperId: response.shipperId,
        laneId: response.laneId,
        status: response.status,
        equipmentTypes: response.equipmentClass,
        startDate: response.startDate,
      },
    };
  }

  static getAll = async params =>
    axios.get(`view/adapt-board-lanes?${utility.pagination.toServerSideParams(params)}`).then(response => ({
      ...response,
      data: response.data.map(adaptBoard => new AdaptBoard(adaptBoard)),
    }));

  static getShipperSummary = () =>
    axios.get(`view/adapt-board/shipper-summary`).then(response => ({
      ...response,
    }));

  static getShipperSummaryPhases = shipperId =>
    axios.get(`view/adapt-board/shippers/${shipperId}/phase-split`).then(({ adaptBoardShipperPhaseSplit }) => ({
      data: adaptBoardShipperPhaseSplit.map(item => item),
    }));

  static patchPhaseStart = payload => axios.patch('analytics/adapt/board/lane/batch', payload);

  static getShippers = () =>
    axios.get(`view/adapt/lanes/shippers`).then(response => toCustomFilterOptions(response.shippers, 'name'));

  static getAllLanePhases = () =>
    axios
      .get(`view/adapt-board-lanes/shipper-dates`)
      .then(({ shipperPhaseDates }) => shipperPhaseDates.filter(item => item.phase));

  static getEnums(type, cancelToken) {
    return axios
      .get(`view/adapt-board-lanes/filters?name=${type}`, { cancelToken })
      .then(({ values }) => toCustomFilterOptions(values));
  }

  static download = async params =>
    axios
      .get(`view/adapt-board-lanes/download?${utility.pagination.toServerSideParams(params)}`)
      .then(res => utility.table.triggerBrowserDownload(res, 'adaptBoardLanesTableDownload'));
}
