/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react'
import { IconButton, Tooltip, FormControl, Select, MenuItem, Alert } from '@mui/material'
import { Add } from '@mui/icons-material'
import { cloneDeep } from 'lodash'

import {
  Page,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
  useTableCustomFilters,
  utility,
} from '@leaf/components'

import { getAll, getCompanies } from '@/domain/user/userQueries'
import { StateContext } from '@/state/StateProvider'
import { SET_USERS_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import AddEditUserDialog from '@/users/AddEditUserDialog'
import useGQL from '@/hooks/useGQL'
import { ROLES_AS_FILTER_OPTIONS } from '@/constants/roles'

const ActiveFilter = (filterList, onChange, index, column) => {
  let v = -1
  if (filterList[index].length) {
    v = filterList[index][0] ? 1 : 0
  }

  const handleChange = (event) => {
    let next = []
    if (event.target.value >= 0) {
      next = [Boolean(event.target.value)]
    }
    onChange(next, index, column)
  }

  return (
    <FormControl>
      <Select value={v} onChange={handleChange}>
        <MenuItem value={-1}>Active+Inactive</MenuItem>
        <MenuItem value={1}>Active</MenuItem>
        <MenuItem value={0}>Inactive</MenuItem>
      </Select>
    </FormControl>
  )
}

const getColumns = (filterOptions) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Company ID',
    name: 'company.id',
    options: { filter: false, sort: false, display: false },
  },
  {
    label: 'Company',
    name: 'company',
    options: {
      ...filterOptions.company,
      customBodyRender: (company) => company?.name,
    },
  },
  { label: 'First name', name: 'first_name', options: { filter: false } },
  { label: 'Last name', name: 'last_name', options: { filter: false } },
  { label: 'Email', name: 'email', options: { filter: false } },
  { label: 'Phone', name: 'phone_number', options: { filter: false, sort: false } },
  { label: 'Role', name: 'role', options: { ...filterOptions.role } },
  {
    label: 'Logins',
    name: 'login_count',
    options: { filter: false, ...utility.table.rightAlignColumnProps() },
  },
  {
    label: 'Last login',
    name: 'last_login_at',
    options: {
      filter: false,
      customBodyRender: (lastLogin) => utility.datetime.formatBackendDatetime(lastLogin),
    },
  },
  {
    label: 'Active',
    name: 'active',
    options: {
      display: false,
      ...utility.table.centerAlignColumnProps(utility.table.renderTrueFalse),
      filterType: 'custom',
      customFilterListOptions: {
        render: (status) => {
          if (status.length) {
            return status[0] ? 'Active' : 'Inactive'
          }
          return ''
        },
      },
      filterOptions: {
        display: ActiveFilter,
      },
    },
  },
]

const UserOverview = () => {
  const [open, setOpen] = React.useState(false)
  const [companies, setCompanies] = React.useState([])
  const [users, setUsers] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState(null)

  const getGQLClient = useGQL()
  const getFn = async () =>
    getCompanies(getGQLClient).then((response) => {
      setCompanies(response)
      return response
    })

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'company',
      filterOptionsGetter: getFn,
    },
    {
      inputType: 'dropdown',
      columnName: 'role',
      filterOptionsGetter: () => ROLES_AS_FILTER_OPTIONS,
    },
  ])

  const toggleDialog = () => {
    if (open && selectedUser) {
      setSelectedUser(null)
    }
    setOpen(!open)
  }

  const options = {
    filter: true,
    onRowClick: (_, rowMeta) => {
      const user = cloneDeep(users[rowMeta.dataIndex])
      if (user.company) {
        user.company = { label: user.company.name, value: user.company.id }
        user.company_id = user.company.value
      }
      setSelectedUser(user)
      toggleDialog()
    },
    customToolbar: () => (
      <Tooltip title='Add user'>
        <IconButton onClick={toggleDialog}>
          <Add />
        </IconButton>
      </Tooltip>
    ),
  }

  const {
    state: {
      tables: { usersOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  const handleChange = (setTableState) =>
    dispatch({ type: SET_USERS_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) })

  const onResolve = ({ data }) => setUsers(data)

  return (
    <Page title='Users'>
      <Alert
        severity='error'
        sx={{
          display: 'flex',
          width: '100vw',
          fontSize: '1.5em',
          fontWeight: 'bold',
        }}
      >
        There&#39;s a newer version of this page available{' '}
        <a href='https://admin2.leaflogistics.com/users'>here</a>. Please note that there will be no
        further updates or fixes, use it at your own risk.
      </Alert>

      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={options}
            columns={getColumns(filterOptions)}
            fn={() => getAll(getGQLClient, tableState)}
            onResolve={onResolve}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>

      {open && (
        <AddEditUserDialog
          title='Add user'
          open={open}
          onClose={toggleDialog}
          companies={companies}
          selectedUser={selectedUser}
        />
      )}
    </Page>
  )
}

export default UserOverview
