import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { Link as LinkIcon } from '@mui/icons-material'

import { utility, NewTabLink } from '@leaf/components'

const { ActionButtons } = utility.table
const { VITE_UI_CLIENT_APP_URL } = import.meta.env

export const getOptions = (history) => ({
  filter: true,
  onRowClick: (rowData) => {
    const id = rowData[0]
    history.push(`/planning/fleet-discovery/${id}`)
  },
  ...utility.table.getRowHoverActionProps('FleetDiscovery'),
})

export const getColumns = (filterOptions, copy) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Run Timestamp',
    name: 'run_ts',
    options: {
      filter: false,
      customBodyRender: (v) => utility.datetime.formatBackendDatetime(v),
    },
  },
  {
    label: 'Domicile',
    name: 'domicile',
    options: {
      ...filterOptions.domicile,
    },
  },
  {
    label: 'Trucks Used',
    name: 'run_summary.trucks_used',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: 'Scenario Est. Weekly Revenue',
    name: 'run_summary.total_revenue',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) => utility.numbers.formatNumberAsView(v, 2)),
    },
  },
  {
    label: 'Scenario Est. Weekly Margin',
    name: 'run_summary.total_margin',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) => utility.numbers.formatNumberAsView(v, 2)),
    },
  },
  {
    label: 'Scenario Weekly Loads',
    name: 'run_summary.fleet_weekly_loads',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: 'Scenario Weekly Truck Cost',
    name: 'run_summary.truck_cost',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) => utility.numbers.formatNumberAsView(v, 2)),
    },
  },
  {
    label: 'Mininum Lane Usage (%)',
    name: 'run_summary.lane_percent_usage_min',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) => utility.numbers.toPercent(v * 100)),
    },
  },
  {
    label: 'Max Shippers',
    name: 'run_summary.max_shippers',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: 'Max Miles',
    name: 'run_summary.max_miles',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: 'Max Origins',
    name: 'run_summary.max_origins',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: 'Max Destinations',
    name: 'run_summary.max_dests',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: 'Truck Limit',
    name: 'run_summary.truck_limit',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: '# of Permutations',
    name: 'run_summary.total_permutations',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: '# of Shippers',
    name: 'run_summary.distinct_shippers',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: '# of Lanes',
    name: 'run_summary.distinct_lanes',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps((v) =>
        utility.numbers.formatNumberAsView(v, 0, false)
      ),
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: (actionsData) => {
        const { id } = actionsData
        const text = `${VITE_UI_CLIENT_APP_URL}/share/fleet-opportunities/${id}`
        return (
          <ActionButtons id={`Actions-FleetDiscovery-${id}`}>
            <Tooltip title='Copy External Share Link'>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  copy(text)
                }}
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <NewTabLink url={`/planning/fleet-discovery/${id}`} />
          </ActionButtons>
        )
      },
    },
  },
]
