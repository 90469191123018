import { utility } from '@leaf/components';
import Address from '../location/Address';

export default class ShipmentTrackingEvent {
  constructor({ city, state, zip, eventTime, lat, lon, ...response }) {
    Object.assign(this, {
      eventTime: utility.datetime.transformIsoDateTime(eventTime),
      transformedAddress: new Address({ city, state, zipCode: zip }),
      lat,
      lon,
      ...response,
    });

    this.view = {
      eventTime: utility.datetime.normalizedUtcDateToViewStringTime(this.eventTime),
      latLon: `${this.lat}, ${this.lon}`,
      address: this.transformedAddress.mapAddress(),
    };
  }
}
