export const onChangeName = (setter, cb) => name => value => {
  setter(prev => {
    const next = {
      ...prev,
      [name]: value,
    };
    if (cb) cb(next);

    return next;
  });
};

export const onChangeEvent = (setter, cb) => event => {
  const { name, checked, value } = event.target;
  setter(prev => {
    const next = {
      ...prev,
      [name]: checked || value,
    };
    if (cb) cb(next);

    return next;
  });
};

export const onChange = setter => () => value => setter(() => value);

/**
 * Helper method to trigger a react submit event
 * @param {Node} formRef -- reference to the react form
 * @param {String} additionalData -- optional data to pass to the event
 */

export const triggerSubmitEvent = (formRef, additionalData = '') => {
  const submitEvent = new Event('submit', { detail: additionalData, cancelable: true, bubbles: true });
  formRef.current.dispatchEvent(submitEvent);
};
