/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { Form } from '@leaf/components';
import { css } from '@emotion/react';

const Wrapper = styled.div`
  .MuiAutocomplete-popper {
    margin-top: 10px !important;
  }
`;

const AutocompleteStyled = styled(Form.Generic.Autocomplete, {
  shouldForwardProp: prop => prop !== '$isHigherInput',
})`
  ${({ $isHigherInput }) =>
    $isHigherInput &&
    css`
      .MuiInputBase-root {
        height: 50px;
      }
    `}
`;
export default React.memo(
  ({
    id,
    name,
    options,
    value,
    getOptionLabel,
    isOptionEqualToValue,
    onChange,
    onChangeCb,
    multiple,
    renderOption,
    open,
    disablePortal,
    filterOptions,
    isHigherInput,
    ...props
  }) => {
    const handleChange = (_, v) => {
      onChange?.(name)(v);
      onChangeCb?.(v);
    };

    return (
      <Wrapper>
        <AutocompleteStyled
          id={id}
          name={name}
          options={options}
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          open={open}
          filterOptions={filterOptions}
          value={value}
          disablePortal
          onChange={handleChange}
          renderInput={params => <TextField name={name} {...params} {...props} />}
          multiple={multiple}
          $isHigherInput={isHigherInput}
          fullWidth
        />
      </Wrapper>
    );
  },
);
