import { utility } from '@leaf/components'

export default class NewSfsc {
  constructor({
    name,
    peg,
    bucketSize,
    increment,
    isFirstStepZero,
    fuelPriceIndexId,
    validFrom,
  }) {
    this.name = name
    this.peg = peg
    this.bucketSize = bucketSize
    this.increment = increment
    this.isFirstStepZero = isFirstStepZero
    this.fuelPriceIndexId = Number(fuelPriceIndexId)
    this.validFrom = `${utility.date.transformApiDate(validFrom)}T00:00:00`
  }
}
