import { gql } from 'graphql-request'

export const getContractDetailsGQL = (id) =>
  gql`
    query getContractDetails {
      contract(where: { id: { _eq: "${id}" } }) {
        id
        contract_type
        is_fleet
        is_backup_award
        end_date
        include_weekends
        dedicated_domiciles {
          city
          name
          id
          state
          street
          zip
        }
        notes
        start_date
        contract_status
        volume_type
        volume_frequency
        max_volume
        min_volume
        equipment_type {
          name
        }
        pricing_mechanism
        linehaul_rpm
        carrier_upcharge
        pricing_index
        pricing_index_premium
        pricing_index_premium_override_sun
        min_tender_lead_time_hrs
        fixed_fee_per_shipment
        minimum_linehaul_charge
        fuel_surcharge_schedule {
          name
        }
        asset_pricing
        asset_pricing_frequency
        min_chargeable_miles
        min_chargeable_miles_frequency
        volume_pattern
        seller {
          id
          name
        }
        buyer {
          id
          name
        }
        shipper_route_plans {
          shipper_route_plan_id
        }
        contract_accessorials {
          accessorial {
            name
            price
            unit
          }
        }
      }
    }
  `

export const getContractDetailsLaneDescriptionGQL = (id) =>
  gql`
    query getContractDetails {
      contract(where: { id: { _eq: "${id}" } }) {
        id
        buyerId: buyer_id
        sellerId: seller_id
        dedicated_domiciles {
          id
          city
          name
          state
          street
          zip
        }
        contractStatus: contract_status
        contractRoutes: contract_routes(where: {is_primary: {_eq: true}}) {
          route {
            legs(order_by: {sequence: asc}) {
              lane {
                destinationName: destination_name
                originName: origin_name
              }
            }
          }
        }
      }
    }
  `

export const getContractRoutesGQL = (id) =>
  gql`
    query getContractRoutes {
      contract(where: { id: { _eq: "${id}" } }) {
        id
        contractRoutes: contract_routes {
          isPrimary: is_primary
          route {
            avgDeadheadMiles: avg_deadhead_miles
            avgTotalMiles: avg_total_miles
            tripType: trip_type
            routeId: id
            legs(order_by: {sequence: asc}) {
              deadheadMiles:deadhead_miles
              deadheadPath:deadhead_path
              lane {
                destinationName: destination_name
                destinationH3Cells: destination_h3
                originName: origin_name
                originH3Cells: origin_h3
                leafMiles: leaf_miles
                laneId: id
                pickupType: pickup_type
                deliveryType: delivery_type
                pickupStops: origin_stops
                deliveryStops: destination_stops
                destinationPoint: destination_point
                destinationGeo: destination_geo
                originGeo: origin_geo
                originPoint: origin_point
                pathGeo: path
                path
                shipper {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  `
