import { gql } from 'graphql-request';

/**
 * Sample variable to search any of the fields
    {
        "where": {
            "_or": [
                {
                    "origin_name": {
                        "_ilike": "%casa%"
                    }
                },
                {
                    "destination_name": {
                        "_ilike": "%casa%"
                    }
                },
                {
                    "shipper": {
                        "name": {
                            "_ilike": "%casa%"
                        }
                    }
                },
                {
                    "origin_name": {
                        "_ilike": "%glen%"
                    }
                },
                {
                    "destination_name": {
                        "_ilike": "%glen%"
                    }
                },
                {
                    "shipper": {
                        "name": {
                            "_ilike": "%glen%"
                        }
                    }
                }
            ],
            "_and": [
                {
                    "_or": [
                        {
                            "origin_name": {
                                "_ilike": "%casa%"
                            }
                        },
                        {
                            "destination_name": {
                                "_ilike": "%casa%"
                            }
                        },
                        {
                            "shipper": {
                                "name": {
                                    "_ilike": "%casa%"
                                }
                            }
                        },
                        {
                            "origin_name": {
                                "_ilike": "%glen%"
                            }
                        },
                        {
                            "destination_name": {
                                "_ilike": "%glen%"
                            }
                        },
                        {
                            "shipper": {
                                "name": {
                                    "_ilike": "%glen%"
                                }
                            }
                        }
                    ]
                },
                {
                    "_and": [
                        {
                            "shipper": {
                                "id": {
                                    "_eq": 3001
                                }
                            }
                        }
                    ]
                }
            ]
        }
    }
  */
export const GQL_SEARCH_LANE = gql`
  query searchLaneQuery($where: lane_bool_exp) {
    lane(limit: 100, order_by: { shipper: { name: asc }, origin_name: asc, destination_name: asc }, where: $where) {
      id
      originName: origin_name
      destinationName: destination_name
      shipper {
        name
        id
      }
      createdAt: created_at
      leafMiles: leaf_miles
    }
  }
`;
