import React, { useState, useEffect } from "react";
import {
  SpacedContainer,
  PageContainer,
  PageContentLayout,
  DetailsOverview,
} from "@leaf/components";
import { Grid, Alert } from "@mui/material";
import useGQL from "@/hooks/useGQL";
import { getContractDetails } from "./domain/contractDetailsModel";
import {
  getCompanyGeneralDetails,
  getCompanyTimeAndVolumeDetails,
  getCompanyPricingDetails,
} from "./domain/contractDetailsDataMapper";

const ContractDetails = ({ contractId }) => {
  const [contract, setContract] = useState(null);

  const getGQLClient = useGQL();

  useEffect(() => {
    getContractDetails(getGQLClient, contractId).then(({ data }) => {
      setContract(data);
    });
  }, []);

  const getBadgeText = () => {
    if (contract?.seller?.name) return "Carrier Contract";
    if (contract?.buyer?.name) return "Shipper Contract";

    return "";
  };

  return (
    <PageContainer>
      <Alert
        severity="error"
        sx={{
          display: "flex",
          width: "100vw",
          fontSize: "1.5em",
          fontWeight: "bold",
        }}
      >
        There&#39;s a newer version of this page available{" "}
        <a href="https://admin2.leaflogistics.com/contracts">here</a>. Please
        note that there will be no further updates or fixes, use it at your own
        risk.
      </Alert>

      <SpacedContainer container spacing={2} autoWidth>
        <Grid item xs={4}>
          <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
            <DetailsOverview
              title="General"
              titleBadge={getBadgeText()}
              data={getCompanyGeneralDetails(contract)}
            />
          </PageContentLayout>
        </Grid>
        <Grid item xs={4}>
          <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
            <DetailsOverview
              title="Timeframe And Volume"
              data={getCompanyTimeAndVolumeDetails(contract)}
            />
          </PageContentLayout>
        </Grid>
        <Grid item xs={4}>
          <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
            <DetailsOverview
              title="Pricing"
              data={getCompanyPricingDetails(contract)}
            />
          </PageContentLayout>
        </Grid>
      </SpacedContainer>
    </PageContainer>
  );
};

export default ContractDetails;
