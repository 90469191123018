import styled from '@emotion/styled'
import { Drawer as MuiDrawer, Box } from '@mui/material'

const styles = (theme) => {
  const drawerWidth = 255

  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflow: 'visible',
      width: '60px',
    },
  }
}

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(96, 133, 162, 0.55);
  height: 60px;
  background-color: #0c2845;
  gap: ${({ theme }) => `calc(${theme.spacing(1)} / 2)`};
  flex: 0 0 60px;
  h4 {
    color: white;
    overflow: hidden;
  }
  img {
    height: 25px;
    margin: ${({ theme }) => theme.spacing(2)};
  }
  h4:after {
    content: '．';
    color: #1dd264;
  }
  position: sticky;
  top: 0;
  z-index: 10;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(96, 133, 162, 0.55);
  height: 60px;
  margin-top: auto;
  background-color: #0c2845;
  box-shadow: 0 1px 5px rgb(0, 0, 0, 0.12);
  .MuiIconButton-root {
    padding: 0;
  }
  svg {
    margin: ${({ theme }) => theme.spacing(1)};
    cursor: pointer;
    vertical-align: middle;
  }
  position: sticky;
  bottom: 0;
`

export const Drawer = styled(MuiDrawer)`
  ${({ theme, open }) => {
    const classes = styles(theme)

    return {
      ...classes.drawer,
      ...(open ? classes.drawerOpen : classes.drawerClose),
    }
  }}

  & > div {
    overflow-y: overlay;
  }

  .active-link {
    color: white;
    background-color: #142743;

    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }

  .MuiListItem-button:hover {
    background-color: ${({ theme }) => `${theme.palette.primary.main}`};
    color: rgba(255, 255, 255, 0.8);
    position: relative;

    .MuiSvgIcon-root {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  &.sidebar-collapsed {
    .MuiList-padding {
      position: relative;

      .MuiListItemText-root,
      .MuiTypography-root,
      .MuiCollapse-root {
        visibility: hidden;
        opacity: 0;
      }

      &:hover {
        width: 255px;

        .sidebar-parent:first-child {
          background-color: #142743 !important;
          color: rgba(255, 255, 255, 0.8) !important;

          .MuiSvgIcon-root {
            color: rgba(255, 255, 255, 0.8) !important;
          }
        }

        .MuiCollapse-root {
          visibility: visible !important;
          height: auto !important;
          background-color: #123250;
          color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 100%;
          left: 59px;
          width: 196px;
          z-index: 10;
          border-bottom-right-radius: 4px;
        }

        .MuiListItemText-root,
        .MuiTypography-root,
        .MuiCollapse-root {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .MuiList-padding {
    padding: 0;
  }

  .MuiDrawer-paper {
    background-color: #183b5a;
    color: rgba(255, 255, 255, 0.5);
    overflow-x: hidden;
    .MuiSvgIcon-root {
      color: rgba(255, 255, 255, 0.5);
    }
    .MuiDivider-root {
      background-color: rgba(255, 255, 255, 0.1);
    }
    ${({ theme, open }) => {
      const classes = styles(theme)
      return open ? classes.drawerOpen : classes.drawerClose
    }}
  }
`

export const ToggleNavbar = styled(Box)`
  ${({ open, theme }) =>
    !open
      ? `margin: 0 auto;`
      : `
			margin-left: auto;
			padding: ${theme.spacing(1)};
		`};
`

export const ToggleSidebar = styled(Box)`
  margin-left: 46px;
  overflow: hidden;
`
