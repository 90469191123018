/* eslint-disable no-param-reassign */
import axios from 'axios'
import { transformError } from '@/error'

export const setupAxios = (getAccessTokenSilently) => {
  axios.defaults.baseURL = import.meta.env.VITE_API

  axios.interceptors.request.use(
    async (config) => {
      if (getAccessTokenSilently) {
        const token = await getAccessTokenSilently()
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => Promise.reject(transformError(error))
  )

  axios.interceptors.response.use(
    (response) => response.data,
    (error) => {
      console.log(JSON.stringify(error.response))
      return Promise.reject(transformError(error))
    }
  )
}
