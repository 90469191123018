/**
 * This function validate a string like UUID.
 * we are not using UUID validator since the backend
 * uses different version of UUIDs
 * ref: https://www.rfc-editor.org/rfc/rfc4122#section-3
 *
 * @param {string} uuid
 * @returns boolean
 */
export function isLikeUUID(uuid) {
  const regExpLikeUUIID = /^[0-9a-zA-Z-]{36}$/;
  const out = regExpLikeUUIID.test(uuid);
  return out;
}
