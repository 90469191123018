/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Avatar, Chip, Tooltip } from '@mui/material'
import styled from '@emotion/styled'
import { USER_TYPE_COLORS } from '../UserType/UserType'

const AvatarWrapper = styled(Avatar)`
  height: ${({ height }) => `${height}`};
  width: ${({ width }) => `${width}`};
  margin-right: ${({ theme }) => theme.spacing(1)};
`

const ChipWrapper = styled(Chip)`
  ${({ type }) => `
    background-color: ${USER_TYPE_COLORS[type]?.bgColor} !important;
    color: ${USER_TYPE_COLORS[type]?.textColor} !important;
  `}
`

const TooltipWrapper = ({ title, children }) =>
  title ? <Tooltip title={title}>{children}</Tooltip> : <>{children}</>

const CompanyAvatar = ({ id, name, type, isChip = false, width = 40, height = 40 }) => {
  const src = `https://assets.leaflogistics.com/logos/${id}_logo_image.png`
  const CustomAvatar = <AvatarWrapper alt={name} src={src} width={width} height={height} />

  if (isChip) {
    return (
      <TooltipWrapper title={type}>
        <ChipWrapper
          id='company-avatar-chip'
          label={name}
          avatar={CustomAvatar}
          variant='outlined'
          type={type}
        />
      </TooltipWrapper>
    )
  }
  return CustomAvatar
}

export default CompanyAvatar
