/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * OverlayLoader will dsiplay inside a container
 * that has a position relative
 * @param {boolean} active
 * @returns {JSX} an overlay with a spinner
 */
export const OverlayLoader = React.forwardRef((props, ref) => {
  const { active, ...rest } = props;
  return (
    <Backdrop
      ref={ref}
      open={active}
      sx={{ background: 'rgba(100%,100%,100%, 0.7)', position: 'absolute', zIndex: 999 }}
      {...rest}
    >
      <CircularProgress />
    </Backdrop>
  );
});
