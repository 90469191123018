import axios from 'axios';

export default class FuelPriceIndex {
  constructor(response) {
    Object.assign(this, response);
  }

  static getFuelPriceIndices = async () =>
    axios.get('jupiter/admin/fuel-price-indices').then(response => response.fuelPriceIndices);
}
