import { CONTRACT_TYPE, utility, TRIP_TYPE_DISPLAY_NAMES, TRIP_TYPE } from '@leaf/components';
import { getOpsPlanDetailsGQL, getOpsPlanContractsGQL } from './GQL_OPS_PLANS_DETAILS';

const mapOpsPlanDetails = shipperOps => ({
  ...shipperOps,
  routeId: shipperOps.route.id,
  routes: [
    {
      ...shipperOps.route,
      tripType: TRIP_TYPE_DISPLAY_NAMES[shipperOps.route?.tripType],
      displayOriginName: shipperOps.route.legs[0].lane?.originName,
      displayDestinationName:
        shipperOps?.route.tripType === TRIP_TYPE.ROUND_TRIP
          ? shipperOps.route.legs[0].lane?.destinationName
          : shipperOps.route.legs[shipperOps.route.legs.length - 1].lane?.destinationName,
      legs: shipperOps.route.legs.map(leg => ({
        origin: leg?.lane?.originName,
        pickupType: leg?.lane?.pickupType,
        destination: leg?.lane?.destinationName,
        deliveryType: leg?.lane?.deliveryType,
        miles: utility.format.miles(leg?.lane?.leafMiles),
        laneId: leg?.lane?.id,
        deadheadMiles: utility.format.miles(leg?.deadheadMiles) ?? '',
        originGeo: leg?.lane?.originGeo,
        destinationGeo: leg?.lane?.destinationGeo,
        pathGeo: leg?.lane?.path,
        originH3: leg?.lane?.originH3,
        destinationH3: leg?.lane?.destinationH3,
      })),
    },
  ],
});

export const getOpsPlanDetails = (getGQLClient, id) => {
  const GQL = getOpsPlanDetailsGQL(id);
  return getGQLClient()
    .then(client => client.request(GQL))
    .then(res => mapOpsPlanDetails(res.shipperOps?.[0]));
};

const mapOpsPlanContracts = contracts =>
  contracts.map(contract => ({
    ...contract,
    contractType:
      contract.contractType === CONTRACT_TYPE.DEDICATED && contract.isFleet
        ? CONTRACT_TYPE.FLEET
        : contract.contractType,
    accountName: contract.buyer?.name ?? contract.seller.name,
    actionsData: {
      id: contract.id,
    },
  }));

export const getOpsPlanContracts = (getGQLClient, id, variables) => {
  const GQL = getOpsPlanContractsGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL, variables).then(res => ({
      limit: variables.limit,
      offset: variables.offset,
      total: res.contract_aggregate.aggregate.count,
      data: mapOpsPlanContracts(res.contract),
    })),
  );
};
