import React from 'react';
import styled from '@emotion/styled';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';

import { utility, CompanyAvatar, Lane, USER_TYPE } from '@leaf/components';

const { ActionButtons } = utility.table;

const TableCellWrapper = styled(TableCell)`
  &&& {
    position: sticky;
    top: 0px;
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
`;

const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
`;

const TableCellItem = styled.span`
  width: 230px;
`;

export const getOptions = () => ({
  selectableRows: 'none',
  print: false,
  filter: true,
  download: false,
  ...utility.table.getRowHoverActionProps('lane'),
});

export const getColumns = (filterOptions, handleEditOpen) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Shipper',
    name: 'shipper',
    options: {
      ...filterOptions.shipper,
      sort: true,
      display: true,
      customBodyRender: ({ id, name }) => <CompanyAvatar name={name} id={id} type={USER_TYPE.SHIPPER} isChip />,
    },
  },
  {
    label: 'Shipper ID',
    name: 'shipper.id',
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: 'lane',
    options: {
      filter: false,
      sort: false,
      customHeadRender: () => (
        <TableCellWrapper>
          <TableCellContainer>
            <TableCellItem>Origin</TableCellItem>
            <TableCellItem>Destination</TableCellItem>
          </TableCellContainer>
        </TableCellWrapper>
      ),
      customBodyRender: ({ origin, destination }) => (
        <Lane
          displayAsGrid
          origin={{ primaryContent: [origin.primaryContent], secondaryContent: [origin.secondaryContent] }}
          destination={{
            primaryContent: [destination.primaryContent],
            secondaryContent: [destination.secondaryContent],
          }}
        />
      ),
    },
  },
  {
    label: 'Leaf Miles',
    name: 'leafDistance',
    field: 'leaf_distance',
    options: {
      filter: false,
      sort: true,
      display: true,
      ...utility.table.rightAlignColumnProps(miles => utility.format.miles(miles)),
    },
  },
  {
    label: 'Shipper Miles',
    name: 'shipper_distance',
    options: {
      filter: false,
      sort: true,
      display: true,
      ...utility.table.rightAlignColumnProps(miles => utility.format.miles(miles)),
    },
  },
  {
    label: 'Reference RPM',
    name: 'reference_rpm',
    options: {
      filter: false,
      sort: true,
      display: true,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'Estimated Tolls',
    name: 'tolls',
    options: {
      filter: false,
      sort: true,
      display: true,
      ...utility.table.rightAlignColumnProps(tolls => utility.numbers.formatNumberAsView(tolls, 2)),
    },
  },
  {
    label: 'Driving Time (hours)',
    name: 'drivingTimeMinutes',
    field: 'driving_time_minutes',
    options: {
      filter: false,
      sort: true,
      display: true,
      ...utility.table.rightAlignColumnProps(minutes =>
        typeof minutes === 'number' ? `${utility.format.hours(minutes * 60)} hrs` : '-',
      ),
    },
  },
  {
    label: 'CO2 Reduction (per shipment)',
    name: 'co2_reduction_per_shipment',
    options: {
      filter: false,
      sort: true,
      display: true,
      ...utility.table.rightAlignColumnProps(co2 => (typeof co2 === 'number' ? `${co2} tons` : '-')),
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: actionsData => {
        const { selectedLane } = actionsData;
        return (
          <ActionButtons id={`Actions-lane-${selectedLane.id}`}>
            <Tooltip title="Edit lane">
              <IconButton onClick={() => handleEditOpen(selectedLane)}>
                <Edit />
              </IconButton>
            </Tooltip>
          </ActionButtons>
        );
      },
    },
  },
];
