import { ExpandMore } from '@mui/icons-material';
import { Typography, IconButton } from '@mui/material';
import styled from '@emotion/styled';

export const Container = styled.div`
  height: 116px;
  background-color: ${({ $selectedShipper }) => ($selectedShipper ? '#f5f5f5' : 'none')};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.actions.border}`};
  border-top: ${({ $selectedShipper }) => ($selectedShipper ? '3px solid #0064b0' : 'none')};
  display: flex;
  flex-direction: column;
  padding: 18px 16px 9px 16px;

  .hover-state {
    display: ${({ $selectedShipper }) => ($selectedShipper ? 'block' : 'none')};
  }

  .selected-state {
    transform: ${({ $selectedShipper }) => $selectedShipper && 'rotate(180deg)'};
  }

  &: hover {
    background-color: #f5f5f5;

    .hover-state {
      display: block;
    }
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme, mb }) => theme.spacing(mb ?? 0)};
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const ProgressWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const TypographyScoped = styled(Typography)`
  font-size: 12px;
  color: ${({ $bgColor }) => ($bgColor ? `${$bgColor}` : '#000')};
  opacity: ${({ $opacity }) => ($opacity ? `${$opacity}` : '0')};
`;

export const ExpandButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  height: ${({ theme }) => theme.spacing(1)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconButtonTopWrapper = styled(IconButton)`
  position: absolute;
  bottom: -16px;

  &: hover {
    background-color: rgba(0, 0, 0, 0);
  }
`;

export const IconButtonWrapper = styled(IconButton)`
  &: hover {
    background-color: rgba(0, 0, 0, 0);
  }
`;

export const ExpandMoreIconWrapper = styled(ExpandMore)`
  color: #0064b0;
`;

export const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;
