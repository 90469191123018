import { GQL_CARRIERS } from '@/graphql/filter-queries/GQL_COMPANIES'
import { utility } from '@leaf/components'
import { getContractShipmenstGQL } from './GQL_CONTRACT_SHIPMENTS'

const mapContractShipments = (shipments) =>
  shipments.map((shipment) => ({
    ...shipment,
    origin: [
      [`${shipment.origin.city}, ${shipment.origin.state} ${shipment.origin.zip}`],
      [`${shipment.origin.street} ${shipment.origin.businessName}`],
    ],
    destination: [
      [`${shipment.destination.city}, ${shipment.destination.state} ${shipment.destination.zip}`],
      [`${shipment.destination.street} ${shipment.destination.businessName}`],
    ],
    shipperMiles: shipment.lane?.shipperMiles,
    leafMiles: shipment.lane?.drivingData?.leafMiles,
    equipmentTypeName: shipment.equipmentType.name,
    status: shipment.shipmentExecutionStatus,
    carrier: shipment.carrierTripTender?.company,
    executionPartnerName: shipment.executionPartnerCompany?.name,
    legNumber: shipment.legNumber,
    tripId: shipment.carrierTripTender?.tripId,
    poNumber: shipment.poNumber ?? '-',
    pickupDate: utility.date.formatStringDate(
      shipment.originAppointmentTime ??
        shipment.originInitialAppointmentTime ??
        shipment.expectedShipmentDate,
      utility.date.VIEW_FORMAT
    ),
  }))

const getOrderBy = (sort) => {
  if (sort) {
    return [{ [sort.field ?? sort.name]: sort.order }]
  }
  return null
}

export const getContractShipments = ({ getGQLClient, tableState, contractId }) => {
  const variables = {
    sort: tableState.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      carrier: tableState.filters.carrier ? tableState.filters.carrier : undefined,
      status: tableState.filters.status ? tableState.filters.status : undefined,
    },
  }
  const { where, search, sort, ...rest } = variables

  return getGQLClient().then((client) => {
    const GQL = getContractShipmenstGQL({
      where,
      search,
      contractId,
      ...rest,
    })
    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then((res) => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.shipmentTenderAggregate.aggregate.count,
      data: mapContractShipments(res.shipmentTender),
    }))
  })
}

export const getCarriers = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_CARRIERS)
      .then((res) => res.company.map((e) => ({ label: e.name, value: e.id })))
  )
