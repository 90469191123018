import React from 'react'
import { PhaseSelect, Lane, utility, PHASE_TYPE } from '@leaf/components'
import AdaptBoard from '@/domain/adapt/AdaptBoard'

// Workaround for server-side table not knowing the data beforehand.
const LANE_ID_INDEX = 2
const SHIPPER_GUID_INDEX = 1
// Columns must have boolean value for explorer capability as last element.
const HAS_EXPLORER_INDEX = 15

const checkExplorer = (row) => {
  const hasExplorer = row[HAS_EXPLORER_INDEX]
  return typeof hasExplorer === typeof true && hasExplorer
}

export const getExplorerUrl = (row) => {
  if (checkExplorer(row)) {
    return `/adapt/explorer/companies/${row[SHIPPER_GUID_INDEX]}/lanes/${row[LANE_ID_INDEX]}`
  }

  return null
}

export const getColumns = (tableState, filterOptions, lanePhases, updateLanePhaseList) => [
  {
    label: 'ID',
    name: 'shipperId',
    options: { filter: false },
  },
  {
    label: 'GUID',
    name: 'shipperGuid',
    options: { filter: false },
  },
  {
    label: 'Lane ID',
    name: 'laneId',
    options: { filter: false },
  },
  {
    label: 'Shipper logo',
    name: 'shipperLogo',
    options: {
      viewColumns: false,
      filter: false,
    },
  },
  {
    label: 'Shipper',
    name: 'shipperName',
    options: {
      ...filterOptions.shipperName,
      viewColumns: false,
    },
  },
  {
    label: 'Origin',
    name: 'origin',
    options: { viewColumns: false, filter: false },
  },
  {
    name: 'laneType',
    label: 'Lane type',
    options: {
      viewColumns: false,
      filter: false,
    },
  },
  {
    label: 'Destination',
    name: 'destination',
    options: { viewColumns: false, filter: false },
  },
  {
    label: 'Lane',
    name: 'lane',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (_, rowData) => (
        <Lane
          displayAsGrid
          type={rowData.rowData[6]}
          origin={{ primaryContent: [rowData.rowData[5]] }}
          destination={{ primaryContent: [rowData.rowData[7]] }}
          shipper={{
            guid: rowData.rowData[1],
            id: rowData.rowData[0],
            image: rowData.rowData[3],
            name: rowData.rowData[4],
          }}
        />
      ),
    },
  },
  {
    label: 'Annual Loads',
    name: 'view.annualLoads',
    field: 'annualLoads',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Annual Spend',
    name: 'view.annualSpend',
    field: 'annualSpend',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Annual Cost Savings',
    name: 'view.savings',
    field: 'savings',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Equipment Type',
    name: 'equipmentClass',
    options: { display: true, ...filterOptions.equipmentClass },
  },
  {
    label: 'Phases',
    name: 'view.phases',
    field: 'phase',
    options: {
      ...filterOptions.phase,
      customFilterListOptions: {
        render: (v) => v.map((l) => l).join(' - '),
      },
      customBodyRender: ({ shipperId, laneId, status, equipmentTypes, startDate }) => {
        let filteredLanePhases = []
        if (lanePhases.length) {
          filteredLanePhases = lanePhases
            .map((item) => {
              if (item.shipperId === shipperId) {
                return {
                  shipperId,
                  startDate: item.startDate,
                  phase: item.phase,
                  laneId,
                }
              }

              return null
            })
            .filter((item) => item)

          filteredLanePhases = filteredLanePhases.filter(
            (item, index) =>
              item.phase &&
              item.phase !== PHASE_TYPE.UNASSIGNED &&
              item.phase !== PHASE_TYPE.OUT_OF_SCOPE &&
              filteredLanePhases.findIndex(
                (innerItem) => innerItem.startDate === item.startDate
              ) === index
          )
        }

        const handleStopPropagation = (event) => {
          event.stopPropagation()
        }

        return (
          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
          <div onClick={handleStopPropagation}>
            <PhaseSelect
              startDate={startDate}
              shipperId={shipperId}
              laneIds={[laneId]}
              status={status}
              data={filteredLanePhases}
              equipmentTypes={[equipmentTypes]}
              patchData={AdaptBoard.patchPhaseStart}
              updateLanePhases={updateLanePhaseList}
              variant='outlined'
            />
          </div>
        )
      },
    },
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      ...filterOptions.status,
    },
  },
  {
    label: 'Has explorer',
    name: 'hasExplorer',
    options: {
      display: 'excluded',
    },
  },
]
