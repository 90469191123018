import { gql } from 'graphql-request'
import { isLikeUUID } from '@/utility/uuid'

export const GQL_SHIPPERS = gql`
  query getShippers {
    execution_lane(distinct_on: shipper_id) {
      shipper {
        id
        name
      }
    }
  }
`

const WHERE = {
  SHIPPER_ID_VAR: gql`$shipper_id: [bigint!]`,
  SHIPPER_ID_CLAUSE: gql`shipper_id: { _in: $shipper_id }`,
}

const whereClause = ({ where, search }) => {
  let whereGQL = gql``
  const whereVarsKeys = Object.keys(where).filter((k) => Array.isArray(where[k]) && where[k].length)

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: {
          ${whereVarsKeys.map((key) =>
            where[key]
              ? gql`
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                `
              : ''
          )}

          ${
            search !== undefined && search !== ''
              ? gql`
            _or: [
              ${isLikeUUID(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
              { shipper: { name: { _ilike: "%${search}%" } } }
              { origin: { city: { _ilike: "%${search}%" } } }
              { origin: { state: { _ilike: "%${search}%" } } }
              { origin: { zip: { _ilike: "%${search}%" } } }
              { origin: { street: { _ilike: "%${search}%" } } }
              { origin: { business_name: { _ilike: "%${search}%" } } }
              { destination: { city: { _ilike: "%${search}%" } } }
              { destination: { state: { _ilike: "%${search}%" } } }
              { destination: { zip: { _ilike: "%${search}%" } } }
              { destination: { street: { _ilike: "%${search}%" } } }
              { destination: { business_name: { _ilike: "%${search}%" } } }
            ]
            `
              : ''
          }
        }
      }
    `
  }
  return whereGQL
}

const whereVar = ({ where }) => gql`
  ${where.shipper_id ? WHERE.SHIPPER_ID_VAR : ``}
`

export const getExecutionLanesGQL = (variables) => {
  const whereGQL = whereClause(variables)
  return gql`
    query getExecutionLanes(
      $orderBy: [execution_lane_order_by]
      $limit: Int
      $offset: Int
      ${whereVar(variables)}
    ) {
      executionLanes: execution_lane(
        order_by: $orderBy
        limit: $limit
        offset: $offset
        ${whereGQL}
      ) {
        id
        shipper {
          id
          name
        }
        origin {
          city
          state
          zip
          street
          business_name
        }
        destination {
          city
          state
          zip
          street
          business_name
        }
        shipper_distance
        reference_rpm
        co2_reduction_per_shipment
        drivingData: driving_data {
          leafDistance: leaf_miles
          tolls
          drivingTimeMinutes: driving_time_minutes
        }
      }
      stats: execution_lane_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `
}
