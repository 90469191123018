export default class CompanyPerformanceByContractTier {
  constructor({ ...response }) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      shipperFailed: this.shipperFailed || '-',
      carrierFailed: this.carrierFailed || '-',
    };
  }
}
