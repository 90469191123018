import { get } from 'lodash';

export const generateShareLink = type => {
  switch (type) {
    case 'opportunity_details': {
      return opportunityId => `share/opportunities/${opportunityId}`;
    }
    default:
      throw new Error('Type for generating a share link not found');
  }
};

export const extractOriginDestinationFromLanes = ({
  lanes,
  originFieldName = 'origin',
  destinationFieldName = 'destination',
}) => {
  if (!Array.isArray(lanes)) {
    return {};
  }
  const firstLane = lanes[0];
  const lastLane = lanes.at(-1);
  if (lanes.length === 1) {
    return {
      origin: get(firstLane, originFieldName),
      destination: get(firstLane, destinationFieldName),
    };
  }
  return {
    origin: get(firstLane, originFieldName),
    destination: get(lastLane, destinationFieldName),
  };
};

export const areAllPropertiesEmpty = object => {
  if (!object) return true;

  return Object.values(object).every(o => o === null || o === '' || o === undefined);
};

export const groupBy = (array, key) =>
  array.reduce((result, item) => {
    // eslint-disable-next-line no-param-reassign
    result[item[key]] = item;
    return result;
  }, {});

export const metersToMiles = (meters = 0) => meters * 1609.34;
