import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Page,
  SHIPMENT_STATUS_DISPLAY_NAMES,
  useTableCustomFilters,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
  CONTRACT_TYPE_DISPLAY_NAMES,
} from '@leaf/components'
import { StateContext } from '@/state/StateProvider'
import { SET_SHIPMENTS_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import initialState from '@/state/initialState'
import useGQL from '@/hooks/useGQL'
import Shipment from '@/domain/shipment/Shipment'
import {
  getExecutionPartnerNames,
  getLegNumbers,
  getOrigins,
  getShipments,
  getDestinations,
  getShippers,
  getCarriers,
  getEquipmentTypes,
  getPickupDates,
} from '../domain/shipmentsModel'
import { getColumns, getOptions } from './table-config'

export default withRouter(({ history }) => {
  const getGQLClient = useGQL()

  const {
    state: {
      tables: { shipmentsOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  useEffect(() => {
    const unregister = history.listen((location) => {
      const toShipmentDetails = location.pathname.startsWith('/shipments/')
      const toShipmentsOverview = location.pathname.startsWith('/shipments')
      if (!toShipmentDetails && !toShipmentsOverview) {
        dispatch({
          type: SET_SHIPMENTS_OVERVIEW_TABLE_STATE,
          payload: initialState.tables.shipmentsOverview,
        })
      }
    })
    return () => unregister()
  })

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'executionPartnerName',
      filterOptionsGetter: () => getExecutionPartnerNames(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'legNumber',
      filterOptionsGetter: () => getLegNumbers(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'origin',
      filterOptionsGetter: () => getOrigins(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'destination',
      filterOptionsGetter: () => getDestinations(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'carrierId',
      filterOptionsGetter: () => getCarriers(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'shipperId',
      filterOptionsGetter: () => getShippers(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'status',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(SHIPMENT_STATUS_DISPLAY_NAMES).map((key) => ({
            label: SHIPMENT_STATUS_DISPLAY_NAMES[key],
            value: key,
          }))
        ),
    },
    {
      inputType: 'autocomplete',
      columnName: 'contractType',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(CONTRACT_TYPE_DISPLAY_NAMES).map((key) => ({
            label: CONTRACT_TYPE_DISPLAY_NAMES[key],
            value: key,
          }))
        ),
    },
    {
      inputType: 'autocomplete',
      columnName: 'equipmentType',
      filterOptionsGetter: () => getEquipmentTypes(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'pickupDate',
      filterOptionsGetter: () => getPickupDates(getGQLClient),
    },
  ])

  const handleChange = (setTableState) =>
    dispatch({ type: SET_SHIPMENTS_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) })

  const handleDownloadShipments = async () => {
    const { data } = await getShipments(getGQLClient, tableState, true)

    Shipment.downloadShipments(data.map((shipmentTender) => shipmentTender.id))
  }

  return (
    <Page title='Shipments'>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(history, handleDownloadShipments)}
            columns={getColumns(filterOptions, tableState)}
            fn={() => getShipments(getGQLClient, tableState)}
            quickFilterComponents={[
              {
                label: 'Status',
                name: 'statusQuickFilter',
                values: ['ACTIVE', 'CANCELED'],
                inputType: 'toggle-button',
              },
            ]}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  )
})
