import { utility, CONTRACT_TYPE } from '@leaf/components'
import { gql } from 'graphql-request'
import { GQL_EQUIPMENT_TYPES } from '@/graphql/filter-queries/GQL_EQUIPMENT_TYPES'
import { GQL_ACCESSORIALS } from '@/graphql/filter-queries/GQL_ACCESSORIALS'
import { GQL_COMPANIES } from '@/graphql/filter-queries/GQL_COMPANIES'
import { getShipperContractsGQL } from './GQL_SHIPPER_CONTRACTS'

const mapContracts = (contracts) =>
  contracts.map((c) => {
    // Workaround for dedicated domiciles
    const displayRoutes = c.dedicated_domiciles?.length
      ? c.dedicated_domiciles.map((dd) => ({ type: 'DOMICILE', domicile: dd }))
      : c.contract_routes.map(({ route: { legs } }) => legs.map((l) => ({ type: 'LANE', ...l })))[0]

    const r = {
      ...c,
      routes: c.contract_routes_aggregate.aggregate.count,
      contractType:
        c.contractType === CONTRACT_TYPE.DEDICATED && c.isFleet
          ? CONTRACT_TYPE.FLEET
          : c.contractType,
      displayRoutes,
      accountName: {
        shipper: c.shipper,
        carrier: c.carrier,
      },
      startDate: utility.date.backendStringToViewString(c.startDate),
      endDate: utility.date.backendStringToViewString(c.endDate),
      linehaulRpm: utility.numbers.formatNumberAsView(c.linehaulRpm, 2),
      minimumLinehaulCharge: utility.numbers.formatNumberAsView(c.minimumLinehaulCharge, 2),
      fixedFeePerShipment: utility.numbers.formatNumberAsView(c.fixedFeePerShipment, 2),
      carrierUpcharge: utility.numbers.toPercent(c?.carrierUpcharge),
      maxVolume: utility.numbers.formatNumberAsView(c.maxVolume, 0, false),
      assetPricing: c.assetPricing,
      assetPricingFrequency: c.assetPricingFrequency,
      tripTypes: c.contract_routes?.[0]?.route.tripType,
      equipmentType: c.equipment_type?.name ?? '-',
      accessorials: c.contract_accessorials.map(({ accessorial }) => accessorial.name),
      actionsData: {
        id: c.id,
      },
    }

    return r
  })

const getOrderBy = (sort) => {
  if (sort) {
    return sort.name !== 'contractType'
      ? [{ [sort.field ?? sort.name]: sort.order }]
      : [{ [sort.field ?? sort.name]: sort.order }, { is_fleet: sort.order }]
  }
  return null
}

const getDate = (dates, index) => {
  if (dates?.length === 2) {
    return Array.isArray(dates[index]) ? dates[index][0] : dates[index]
  }
  return undefined
}

export const getContracts = (getGQLClient, tableState, forDownload = false) => {
  const rangeStartDate = getDate(tableState.filters.rangeDate, 0)
  const rangeEndDate = getDate(tableState.filters.rangeDate, 1)
  const hasRangeDates = !!rangeStartDate || !!rangeEndDate
  const rangeDates = {
    range_start_date: !rangeStartDate ? utility.date.transformApiDate(new Date()) : rangeStartDate,
    range_end_date: !rangeEndDate ? utility.date.transformApiDate(new Date()) : rangeEndDate,
  }
  const variables = {
    sort: tableState.sort,
    ...(!forDownload && { limit: tableState.rowsPerPage }),
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      contract_statuses: tableState.quickFilters.contractStatuses?.length
        ? tableState.quickFilters.contractStatuses
        : undefined,
      equipment_type_ids: tableState.filters.equipmentType?.length
        ? tableState.filters.equipmentType
        : undefined,
      accessorial_ids: tableState.filters.accessorials?.length
        ? tableState.filters.accessorials
        : undefined,
      account_name: tableState.filters.accountName?.length
        ? tableState.filters.accountName
        : undefined,
      trip_types: tableState.filters.tripTypes?.length ? tableState.filters.tripTypes : undefined,
      ...(hasRangeDates && rangeDates),
    },
    varsToRemap: {
      time_statuses: tableState.quickFilters.timeStatuses?.length
        ? tableState.quickFilters.timeStatuses
        : undefined,
      company_types: tableState.quickFilters.companyTypes?.length
        ? tableState.quickFilters.companyTypes
        : undefined,
      origin: tableState.filters.origin?.length ? tableState.filters.origin : undefined,
      destination: tableState.filters.destination?.length
        ? tableState.filters.destination
        : undefined,
      contract_types: tableState.filters.contract_type?.length
        ? tableState.filters.contract_type
        : undefined,
    },
    gqlQuickFiltersMeta: tableState.gqlQuickFiltersMeta,
  }
  const { varsToRemap, where, gqlQuickFiltersMeta, search, sort, ...rest } = variables

  const GQL = getShipperContractsGQL({
    varsToRemap,
    where,
    gqlQuickFiltersMeta,
    search,
    ...rest,
  })
  return getGQLClient().then((client) =>
    client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then((res) => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.contract_aggregate.aggregate.count,
      data: mapContracts(res.contract),
    }))
  )
}

/** For isNestedQuery = true, you have to specify an explicit query string. Otherwise a query will be generated from the returned array */
export const shipperContractsQuickFilters = {
  origin: {
    metaGenerator: (selectedValues) => {
      const [lat, lon, radius] = selectedValues
      return gql`
        {
          contract_routes: {
            route: {
              legs: {
                lane: {
                  origin_geo: {
                    _cast: {
                      geography: {
                        _stDWithin: {
                          distance: ${utility.functions.metersToMiles(radius)},
                          from: {
                            type: "Point", coordinates:[${lon},${lat}]
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    },
    isNestedQuery: true,
  },
  destination: {
    metaGenerator: (selectedValues) => {
      const [lat, lon, radius] = selectedValues
      return gql`
        {
          contract_routes: {
            route: {
              legs: {
                lane: {
                  destination_geo: {
                    _cast: {
                      geography: {
                        _stDWithin: {
                          distance: ${utility.functions.metersToMiles(radius)},
                          from: {
                            type: "Point", coordinates:[${lon},${lat}]
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    },
    isNestedQuery: true,
  },
  time_statuses: {
    metaGenerator: (selectedValues) => {
      const past = [
        {
          field: 'end_date',
          name: '_lt',
          value: new Date().toISOString(),
          type: 'string',
        },
      ]
      const ongoing = [
        {
          field: 'end_date',
          name: '_gte',
          value: new Date().toISOString(),
          type: 'string',
        },
        {
          field: 'start_date',
          name: '_lte',
          value: new Date().toISOString(),
          type: 'string',
        },
      ]
      const future = [
        {
          field: 'start_date',
          name: '_gt',
          value: new Date().toISOString(),
          type: 'string',
        },
      ]
      return [
        ...(selectedValues.includes('PAST') ? [past] : []),
        ...(selectedValues.includes('ONGOING') ? [ongoing] : []),
        ...(selectedValues.includes('FUTURE') ? [future] : []),
      ]
    },
  },
  company_types: {
    metaGenerator: (selectedValues) => {
      if (['SHIPPER', 'LSP'].every((v) => selectedValues.includes(v))) {
        return [
          [
            {
              field: 'buyer_id',
              name: '_is_null',
              value: false,
              type: 'boolean',
            },
          ],
          [
            {
              field: 'seller_id',
              name: '_is_null',
              value: false,
              type: 'boolean',
            },
          ],
        ]
      }
      if (selectedValues.includes('SHIPPER')) {
        return [
          [
            {
              field: 'buyer_id',
              name: '_is_null',
              value: false,
              type: 'boolean',
            },
          ],
        ]
      }
      if (selectedValues.includes('LSP')) {
        return [
          [
            {
              field: 'seller_id',
              name: '_is_null',
              value: false,
              type: 'boolean',
            },
          ],
        ]
      }
      return [[]]
    },
  },
  contract_types: {
    metaGenerator: (selectedValues) => {
      if (!selectedValues) {
        return [[]]
      }
      return selectedValues.map((type) =>
        type === CONTRACT_TYPE.DEDICATED || type === CONTRACT_TYPE.FLEET
          ? [
              {
                field: 'contract_type',
                name: '_eq',
                value: CONTRACT_TYPE.DEDICATED,
                type: 'string',
              },
              {
                field: 'is_fleet',
                name: '_eq',
                value: type === CONTRACT_TYPE.FLEET,
                type: 'boolean',
              },
            ]
          : [
              {
                field: 'contract_type',
                name: '_eq',
                value: type,
                type: 'string',
              },
            ]
      )
    },
  },
}

export const getEquipmentTypes = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_EQUIPMENT_TYPES)
      .then((res) => res.equipment_type.map((e) => ({ label: e.name, value: e.id })))
  )

export const getAccessorials = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_ACCESSORIALS)
      .then((res) => res.accessorial.map((e) => ({ label: e.name, value: e.id })))
  )

export const getCompanies = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_COMPANIES)
      .then((res) => res.company.map((e) => ({ label: e.name, value: e.id })))
  )
