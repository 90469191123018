import axios from 'axios';

import { utility, toCustomFilterOptions } from '@leaf/components';

export default class AdaptBoardNetworkMoves {
  constructor(response) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      id: response.networkMoveId,
      l2Name: response.l2Name ?? '-',
      l2OwnerName: response.l2OwnerName ?? '-',
      lanes: [
        {
          lane: response.l0Name,
          owner: response.l0OwnerName,
          shipper: { name: response.l0OwnerName, image: response.l0OwnerLogo },
        },
        {
          lane: response.l1Name,
          owner: response.l1OwnerName,
          shipper: { name: response.l1OwnerName, image: response.l1OwnerLogo },
        },
        {
          lane: response.l2Name,
          owner: response.l2OwnerName,
          shipper: { name: response.l2OwnerName, image: response.l2OwnerLogo },
        },
      ],
      loadsImpacted: response.loadsImpacted ?? '-',
      costReduction: response.costReduction ?? '-',
      CO2Reduction: response.CO2Reduction ?? '-',
    };

    const [l0Origin, l0Destination] = response.l0Name.split(' - ');
    this.view.l0Origin = l0Origin;
    this.view.l0Destination = l0Destination;
    this.view.l0ShipperName = response.l0OwnerName;
    this.view.l0ShipperLogo = response.l0OwnerLogo;

    const [l1Origin, l1Destination] = response.l1Name.split(' - ');
    this.view.l1Origin = l1Origin;
    this.view.l1Destination = l1Destination;
    this.view.l1ShipperName = response.l1OwnerName;
    this.view.l1ShipperLogo = response.l1OwnerLogo;

    if (response.l2Name) {
      const [l2Origin, l2Destination] = response.l2Name.split(' - ');
      this.view.l2Origin = l2Origin;
      this.view.l2Destination = l2Destination;
      this.view.l2ShipperName = response.l2OwnerName;
      this.view.l2ShipperLogo = response.l2OwnerLogo;
    }
  }

  static getAll = async params =>
    axios.get(`view/adapt-board-network-moves?${utility.pagination.toServerSideParams(params)}`).then(response => ({
      ...response,
      data: response.data.map(datum => new AdaptBoardNetworkMoves(datum)),
    }));

  static get = async id =>
    axios.get(`view/adapt/network-moves/${id}`).then(response => new AdaptBoardNetworkMoves(response));

  static getShippers = () =>
    axios
      .get(`view/adapt-board-network-moves/shippers`)
      .then(response => toCustomFilterOptions(response.shippers, 'shipperName'));

  static getAllShipperDates = () =>
    axios.get(`view/adapt-board-network-moves/shipper-dates`).then(response => ({
      ...response,
    }));

  static download = async params =>
    axios
      .get(`view/adapt-board-network-moves/download?${utility.pagination.toServerSideParams(params)}`)
      .then(res => utility.table.triggerBrowserDownload(res, 'adaptBoardNetworkMovesTableDownload'));

  static getFilters = cancelToken =>
    axios.get(`view/adapt-board-network-moves/filters`, { cancelToken }).then(response => ({
      moveType: toCustomFilterOptions(response.moveType),
      networkType: toCustomFilterOptions(response.networkType),
      equipmentClass: toCustomFilterOptions(response.equipmentClass),
      owners: response.owners.map(owner => ({ label: owner.name, value: owner.name })),
    }));
}
