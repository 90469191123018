import React from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import { SupervisedUserCircle } from '@mui/icons-material';

const LoginAsCompanyAdminAction = ({ onClick, renderAsButton = false }) => (
  <Tooltip title="Login as company admin">
    {renderAsButton ? (
      <Button variant="outlined" onClick={onClick}>
        <SupervisedUserCircle />
      </Button>
    ) : (
      <IconButton onClick={onClick} size="large">
        <SupervisedUserCircle />
      </IconButton>
    )}
  </Tooltip>
);

export default ({ onLoginAsCompanyAdminClick, renderAsButton }) => (
  <>
    <LoginAsCompanyAdminAction onClick={onLoginAsCompanyAdminClick} renderAsButton={renderAsButton} />
  </>
);
