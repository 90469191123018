import React, { useContext } from 'react'
import { Page, ServerSideTable, PageContentLayout, PageContainer, utility } from '@leaf/components'
import useGQL from '@/hooks/useGQL'
import { StateContext } from '@/state/StateProvider'
import { SET_FUEL_PRICES_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import { getFuelPrices } from './domain/fuelPriceModel'

const columns = [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Fuel price',
    name: 'price',
    options: { filter: false, ...utility.table.rightAlignColumnProps() },
  },
  { label: 'Fuel Price Index', name: 'indexName', options: { filter: false, sort: false } },
  {
    label: 'Valid from',
    name: 'validFrom',
    field: 'valid_from',
    options: { filter: false },
  },
]

export default () => {
  const getGQLClient = useGQL()

  const {
    state: {
      tables: { fuelPricesOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  const handleChange = (setTableState) =>
    dispatch({ type: SET_FUEL_PRICES_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) })

  return (
    <Page title='Fuel Prices'>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={{
              sortOrder: {
                name: tableState.sort.name,
                direction: tableState.sort.order,
              },
            }}
            columns={columns}
            fn={() => getFuelPrices(getGQLClient, tableState)}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  )
}
