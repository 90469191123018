import React from 'react'
import { Typography } from '@mui/material'
import { v4 } from 'uuid'

export const styles = {
  primaryText: {
    fontWeight: 'bold',
    verticalAlign: 'top',
  },
  secondaryText: {
    color: '#183b5a',
  },
}

const MultiRowInfo = ({ primaryContent, secondaryContent, hasNoWrap = false }) => {
  return (
    <div>
      <div>
        {!!primaryContent?.length &&
          primaryContent.map((content) => (
            <div sx={styles.primaryText} key={v4()}>
              <Typography variant='body2' noWrap={hasNoWrap}>
                {content}
              </Typography>
            </div>
          ))}
      </div>
      <div>
        {!!secondaryContent?.length &&
          secondaryContent.map((content) => (
            <div sx={styles.secondaryText} key={v4()}>
              <Typography variant='body1' noWrap={hasNoWrap}>
                {content}
              </Typography>
            </div>
          ))}
      </div>
    </div>
  )
}

export default MultiRowInfo
