import _ from 'lodash';
import { getFleetDiscoveryGQL, GQL_DOMICILES } from './GQL_FLEET_DISCOVERY_OVERVIEW';

const getOrderBy = sort => {
  if (!sort) {
    // By default, sort by run date descending
    return [{ run_ts: 'desc' }];
  }
  // Sort on basic fields (with support for nested fields)
  return [_.set({}, sort.field ?? sort.name, sort.order)];
};

export const getFleetDiscovery = (getGQLClient, tableState) => {
  const variables = {
    sort: tableState.sort,
    search: tableState.search,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    where: {
      domicile: tableState.filters.domicile ? tableState.filters.domicile : undefined,
    },
  };
  const { where, search, sort, ...rest } = variables;

  return getGQLClient().then(client => {
    const GQL = getFleetDiscoveryGQL({
      where,
      search,
      ...rest,
    });
    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then(res => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.fleet_run_definition_aggregate.aggregate.count,
      data: res.fleet_run_definition.map(r => ({
        ...r,
        actionsData: {
          id: r.id,
        },
      })),
    }));
  });
};

export const getDomiciles = getGQLClient =>
  getGQLClient()
    .then(client => client.request(GQL_DOMICILES))
    .then(res => res.fleet_run_definition.map(e => ({ label: e.domicile, value: e.domicile })));
