/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import { Grid, Box, Button, TextField } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { Form, PolygonMap } from '@leaf/components'
import { disableEnterKey } from '@/utility/disableEnterKey'
import { useStateMachine } from 'little-state-machine'
import { useSnackbar } from 'notistack'
import { OverlayLoader } from '@/contracts/shared/OverlayLoader'
import _ from 'lodash'
import { saveLane } from '@/lanes/overview/domain/laneModel'
import { FormActions } from '../partials/viewHelpers'
import { LegFields } from '../partials/LegFields'
import {
  legInitialValues,
  updateRouteCount,
  trackNewEditRoutes,
  trackDeleteRoutes,
} from '../domain/stateMachine'
import { ROUTE_CREATE_EVENTS } from './constants'

import {
  saveRoutes,
  attachRouteToContract,
  detachRouteFromContract,
  saveLanes,
  isLaneEdited,
  cleanLaneDataforAPI,
  getPath,
} from '../domain/contractModel'
import H3PickerDialog from './H3PickerDialog'

const syncGeoData = async (lanes, setMapData, callback, enqueueSnackbar) => {
  for (let i = 0; i < lanes.length; i += 1) {
    const lane = lanes[i]
    if (!lane.originPoint?.coordinates || !lane.destinationPoint?.coordinates) {
      setMapData(() => {
        if (!lane.originPoint?.coordinates) {
          return lanes.map((l, index) => {
            if (index === i) {
              return { ...l, ...lane, originGeo: lane.originGeo }
            }
            return lanes[index]
          })
        }
        if (!lane.destinationPoint?.coordinates) {
          return lanes.map((l, index) => {
            if (index === i) {
              return { ...l, ...lane, destinationGeo: lane.destinationGeo }
            }
            return lanes[index]
          })
        }
        return lanes
      })
      callback()
    } else {
      const payload = [lane.originPoint, lane.destinationPoint]
      await getPath(payload)
        .then((res) => {
          setMapData(() =>
            lanes.map((l, index) => {
              if (index === i) {
                lanes[i].leafMiles = res[0].distance
                return { ...l, ...lane, pathGeo: res[0].geometry }
              }
              return l
            })
          )
          if (i === lanes.length - 1) {
            callback()
          }
        })
        .catch(enqueueSnackbar)
    }
  }
}

// HARDCODE:
const ROUTE_MOVE_TYPE = ['ONE_WAY', 'ROUND_TRIP', 'CIRCUIT', 'CONTINUOUS_MOVE', 'SHORTY']
const DELIVERY_PICKUP_TYPES = ['ANY', 'LIVE', 'DROP', 'UNKNOWN']
/**
 * Create and Edit routes
 * @param {fucntion} callbackAction - A callback function to notify parent component
 * @param {object} editingRoute - The route to be edited
 * @param {boolean} editModeFlow - The flag to endicate whether to save the data or keep it memory.
 * @returns {JSX} The view to create a route.
 */
export function CreateRoute({
  callbackAction,
  editingRoute,
  editModeFlow,
  isStandaloneLaneForm = false,
}) {
  const { getState, actions } = useStateMachine({
    updateRouteCount,
    trackNewEditRoutes,
    trackDeleteRoutes,
  })
  const [mapData, setMapData] = React.useState([])
  const [areaPicker, setAreaPicker] = React.useState({
    shouldShow: false,
    index: undefined,
    type: undefined,
    isLoading: false,
  })

  const liveLanes = React.useRef([{}])
  const newLanes = React.useRef([{}])
  const legBoxRef = React.useRef()
  const { enqueueSnackbar } = useSnackbar()
  const { newContract: contract, LANE_OPTIONS, ROUTE_COUNT } = getState()

  let existingTripType = null
  let legsToEdit = []
  const mapRouteLegs = []

  if (editingRoute) {
    existingTripType = editingRoute.tripType.toUpperCase().replace(' ', '_')

    // this check is to see if a lane was supplied outside the contract route context e.i. lane management screen
    if (!_.isEmpty(editingRoute?.editLegs[0].lane)) {
      legsToEdit = editingRoute?.editLegs.map(({ lane }) => {
        mapRouteLegs.push(lane)
        const withLaneSelector = {
          ...lane,
          laneSelector: lane,
          shipper: lane.shipper
            ? {
                value: lane.shipper.id,
                label: lane.shipper.name,
              }
            : undefined,
        }

        return withLaneSelector
      })
    }
  }

  const availableLegs = legsToEdit.length > 0 ? legsToEdit : legInitialValues

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      tripType: existingTripType,
      legs: availableLegs,
    },
  })

  const { fields, remove, append } = useFieldArray({
    name: 'legs',
    control,
    defaultLaneValues: availableLegs,
    focusAppend: true,
  })

  const hideAreaPicker = useCallback(() => setAreaPicker({ shouldShow: false }), [])

  const showAreaPicker = useCallback(
    (index, type) => setAreaPicker({ shouldShow: true, index, type }),
    []
  )

  const areLanesH3CellValid = useCallback(
    (lanes) => {
      for (let i = 0; i < lanes.length; i += 1) {
        const apiData = cleanLaneDataforAPI(lanes[i])
        if (!apiData.originH3Cells || apiData.originH3Cells.length === 0) {
          enqueueSnackbar(`Origin H3 cells are empty for lane ${i}. Please select at least one.`)
          return false
        }
        if (!apiData.destinationH3Cells || apiData.destinationH3Cells.length === 0) {
          enqueueSnackbar(
            `Destination H3 cells are empty for lane ${i}. Please select at least one.`
          )
          return false
        }
      }
      return true
    },
    [enqueueSnackbar]
  )

  const attachOneWayLaneToContract = async (data) => {
    const isPrimary = (editingRoute && editingRoute.isPrimary) || ROUTE_COUNT === 0
    const res = await attachRouteToContract(data.contractId, liveLanes.current[0].laneId, {
      isPrimary,
    })
      .then((response) => {
        actions.updateRouteCount(ROUTE_COUNT + 1)
        if (editModeFlow) {
          actions.trackNewEditRoutes(liveLanes.current[0].laneId)
        }
        return response
      })
      .catch((error) => {
        enqueueSnackbar(`attachOneWayLaneToContract -- ${error}`)
      })

    return res !== undefined
  }

  const attachModifyOneWayLaneToContract = async (data) => {
    // new changes are in 'data' param since 'data' is values from submitted form and 'newLanes.current[0]' hold initial values which we need to preserve
    const apiData = cleanLaneDataforAPI({ ...newLanes.current[0], ...data.legs[0] })
    let contractRes

    if (!apiData.originH3Cells || apiData.originH3Cells.length === 0) {
      enqueueSnackbar('Origin H3 cells are empty. Please select at least one.')
      return undefined
    }
    if (!apiData.destinationH3Cells || apiData.destinationH3Cells.length === 0) {
      enqueueSnackbar('Destination H3 cells are empty. Please select at least one.')
      return undefined
    }

    let removePreviousRoute
    const laneRes = await saveLanes(apiData).catch((error) => {
      enqueueSnackbar(`saveLanes -- ${error}`)
    })

    if (laneRes) {
      const isPrimary = (editingRoute && editingRoute.isPrimary) || ROUTE_COUNT === 0
      contractRes = await attachRouteToContract(data.contractId, laneRes.id, { isPrimary })
        .then((response) => {
          actions.updateRouteCount(ROUTE_COUNT + 1)
          if (editModeFlow) {
            actions.trackNewEditRoutes(laneRes.id)
          }
          return response
        })
        .catch((error) => {
          enqueueSnackbar(`attachRouteToContract -- ${error}`)
        })
    }

    if (contractRes && editingRoute) {
      // Removes previous route from contract
      removePreviousRoute = await detachRouteFromContract(data.contractId, editingRoute.id, {})
      if (removePreviousRoute) {
        actions.trackDeleteRoutes(editingRoute.id)
      }
      // SUCCESS: on editmode
      return removePreviousRoute !== undefined
    }

    // SUCCESS: on creation
    return contractRes !== undefined
  }

  const attachMultipleLanesToContract = async (data) => {
    const laneIds = []
    const { tripType, contractId } = data
    let attachRouteRes
    let removePreviousRoute

    if (!areLanesH3CellValid(newLanes.current)) {
      return undefined
    }

    for (let i = 0; i < fields.length; i += 1) {
      if (
        fields[i].formType === 'FROM_SCRATCH' ||
        isLaneEdited(liveLanes.current[i], newLanes.current[i])
      ) {
        // Case 2.A: Lane was changed -- need to save lane and capture ID
        const apiData = cleanLaneDataforAPI({ ...newLanes.current[i], ...data.legs[i] })

        const laneRes = await saveLanes(apiData).catch((error) => {
          enqueueSnackbar(`saveLanes. ${error}`)
        })

        if (laneRes) {
          laneIds.push(laneRes.id)
        }
      } else {
        // Case 2.B: Lane unchanged, just capture ID
        laneIds.push(newLanes.current[i].laneId)
      }
    }

    //  Case 2 - Step 1: Save all lanes ids
    const routeRes = await saveRoutes({ tripType, lanes: laneIds }).catch((error) => {
      enqueueSnackbar(`Saving routes failded. ${error}`)
    })

    // Case 2 - Step 2: Attach routes to contract
    if (routeRes) {
      const isPrimary = (editingRoute && editingRoute.isPrimary) || ROUTE_COUNT === 0
      attachRouteRes = await attachRouteToContract(contractId, routeRes.id, { isPrimary })
        .then((response) => {
          actions.updateRouteCount(ROUTE_COUNT + 1)
          if (editModeFlow) {
            actions.trackNewEditRoutes(routeRes.id)
          }
          return response
        })
        .catch((error) => {
          enqueueSnackbar(`Attaching routes to contract failded. ${error}`)
        })
    }

    if (editingRoute && attachRouteRes) {
      // Removes previous route from contract
      removePreviousRoute = await detachRouteFromContract(contractId, editingRoute.id, {})
      if (removePreviousRoute) {
        actions.trackDeleteRoutes(editingRoute.id)
      }
      // SUCCESS: on editmode
      return removePreviousRoute !== undefined
    }

    // SUCCESS: on creation
    return attachRouteRes !== undefined
  }

  const handleFormSubmit = async (data) => {
    if (!isStandaloneLaneForm) {
      let success = false

      // Case 1.A -- select 1 lane and no changes to the lane
      if (
        fields.length === 1 &&
        !isLaneEdited(liveLanes.current[0], {
          originH3Cells: newLanes.current[0].originH3Cells,
          destinationH3Cells: newLanes.current[0].destinationH3Cells,
          originPoint: newLanes.current[0].originPoint,
          destinationPoint: newLanes.current[0].destinationPoint,
          ...data.legs[0],
        })
      ) {
        success = await attachOneWayLaneToContract(data)
      }

      // Case 1.B -- select 1 lane and user changes lane details
      if (
        fields.length === 1 &&
        isLaneEdited(liveLanes.current[0], {
          originH3Cells: newLanes.current[0].originH3Cells,
          destinationH3Cells: newLanes.current[0].destinationH3Cells,
          originPoint: newLanes.current[0].originPoint,
          destinationPoint: newLanes.current[0].destinationPoint,
          ...data.legs[0],
        })
      ) {
        success = await attachModifyOneWayLaneToContract(data)
      }

      // Case 2 -- User select more than 1 lane & can change any of the lanes.
      if (fields.length > 1) {
        success = await attachMultipleLanesToContract(data)
      }

      if (success) {
        enqueueSnackbar(`Successfuly attached Route(s) to contract!`, { variant: 'success' })
        callbackAction(ROUTE_CREATE_EVENTS.ROUTES_CHANGES)
      }
    } else {
      // single lane create/edit scenario
      const { legs } = data
      const lane = cleanLaneDataforAPI({ ...newLanes.current[0], ...legs[0] })
      saveLane(lane)
        .then(() => {
          callbackAction()
        })
        .catch(enqueueSnackbar)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={(e) => disableEnterKey(e)}>
        <Grid container sx={{ mt: -2 }}>
          <Grid item sx={{ position: 'relative', minWidth: '620px' }} xs>
            <OverlayLoader active={isSubmitting} />
            {/* <MileSummary /> */}
            <input type='hidden' name='contractId' defaultValue={contract?.id} ref={register()} />

            <Box sx={{ p: 2, backgroundColor: '#F5F5F5' }}>
              {!isStandaloneLaneForm && (
                <>
                  <Controller
                    name='tripType'
                    control={control}
                    rules={{ required: true }}
                    render={({ value, onChange }) => (
                      <Form.Generic.Autocomplete
                        value={value}
                        options={ROUTE_MOVE_TYPE}
                        isOptionEqualToValue={(option, selected) => option === selected}
                        getOptionLabel={(option) => option}
                        onChange={(__, data) => onChange(data)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Route Move Type*'
                            error={!!errors.tripType}
                            helperText={errors.tripType ? 'Required field' : null}
                          />
                        )}
                      />
                    )}
                  />
                  <Button
                    onClick={() => {
                      append(legInitialValues, true)
                      setTimeout(() => {
                        legBoxRef.current.scroll({ top: 3000, behavior: 'smooth' })
                      }, 300)
                    }}
                    startIcon={<AddCircleIcon />}
                    sx={{ my: 2 }}
                  >
                    Add leg
                  </Button>
                </>
              )}
              <Box ref={legBoxRef} sx={{ overflowX: 'scroll', height: '60vh' }}>
                {fields.map((field, index) => (
                  <LegFields
                    key={field.id}
                    {...{
                      index,
                      control,
                      field,
                      errors,
                      clearErrors,
                      remove,
                      deliveryPickupOptions: DELIVERY_PICKUP_TYPES,
                      defaultLaneOptions: LANE_OPTIONS,
                      setMapData,
                      mapData,
                      setValue,
                      newLanes,
                      editMode: editingRoute !== null && !_.isEmpty(editingRoute?.editLegs[0].lane),
                      liveLanes,
                      watch,
                      mapRouteLegs,
                      buyerId: contract?.buyerId,
                      showAreaPicker,
                      isStandaloneLaneForm,
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            {/*  */}
            {/* CordinateType = [float, float] */}
            {/* NOTE: data shape
            [
              {
                destinationGeo: {
                  type: string,
                  cordinates: [CordinateType]
                },
                originGeo: {
                  type: string,
                  cordinates: [CordinateType]
                },
                pathGeo: {
                  type: string,
                  crs: {
                    type: string,
                    properties: {
                      name: string
                    }
                  }
                  cordinates: [CordinateType]
                }
              }
            ]

          */}
            <PolygonMap data={mapData} />
          </Grid>
        </Grid>

        <FormActions sx={{ p: 2, borderTop: '1px solid #e5e5e5' }}>
          <Button
            type='button'
            size='large'
            variant='outlined'
            onClick={() => {
              callbackAction(ROUTE_CREATE_EVENTS.CANCEL)
            }}
          >
            Cancel
          </Button>
          <Button type='submit' size='large' variant='contained'>
            Save
          </Button>
        </FormActions>
      </form>
      {areaPicker.shouldShow && (
        <>
          <H3PickerDialog
            data={newLanes.current.map((lane) => ({
              originPoint: lane.originPoint,
              originH3Cells: lane.originH3Cells,
              destinationPoint: lane.destinationPoint,
              destinationH3Cells: lane.destinationH3Cells,
            }))}
            hideAreaPicker={hideAreaPicker}
            onAreaChange={(data, type, index) => {
              setAreaPicker((prevState) => ({ ...prevState, isLoading: true }))
              const extendedFields = {
                [`${type}H3Cells`]: data.selectedHexCells.h3,
                [`${type}Geo`]: {
                  coordinates: data.selectedHexCells.geo[0],
                  type: 'Polygon',
                },
              }
              if (data.selectedPointLocation) {
                const [lng, lat] = data.selectedPointLocation
                extendedFields[`${type}Point`] = {
                  ...newLanes.current[index]?.[`${type}Point`],
                  coordinates: [lat, lng],
                }
              }
              newLanes.current[index] = {
                ...newLanes.current[index],
                ...extendedFields,
              }
              syncGeoData(newLanes.current, setMapData, hideAreaPicker, enqueueSnackbar)
            }}
            type={areaPicker.type}
            index={areaPicker.index}
            isLoading={areaPicker.isLoading}
          />
        </>
      )}
    </>
  )
}
