import { utility, TRIP_TYPE_DISPLAY_NAMES } from '@leaf/components';
import { getFleetDiscoveryDetailsGQL, getFleetDiscoveryDetailsRoutesGQL } from './GQL_FLEET_DISCOVERY_DETAILS';

export const getFleetDiscoveryDetails = (getGQLClient, id) => {
  const GQL = getFleetDiscoveryDetailsGQL(id);
  return getGQLClient()
    .then(client => client.request(GQL))
    .then(res => res.fleetRunDefinition?.[0]);
};

const mapFleetDiscoveryDetailsRoutes = data => ({
  routes: data.routes.map(route => ({
    deadheadMiles: utility.format.miles(route.deadheadMiles),
    totalMiles: utility.format.miles(route.miles),
    tripType: TRIP_TYPE_DISPLAY_NAMES.CONTINUOUS_MOVE,
    displayOriginName: route.lanes[0].lane?.origin?.name,
    displayDestinationName: route.lanes[route.lanes?.length - 1].lane?.destination?.name,
    id: route.id,
    legs: route.lanes.map(leg => ({
      origin: leg?.lane?.origin?.name,
      destination: leg?.lane?.destination?.name,
      miles: utility.format.miles(leg?.lane?.leafMiles),
      laneId: leg?.adaptLaneId,
      deadheadMiles: utility.format.miles(route.deadheadMiles) ?? '',
      originGeo: leg?.lane?.origin?.geometry,
      destinationGeo: leg?.lane?.destination?.geometry,
      pathGeo: leg?.lane?.geometry,
    })),
  })),
});

export const getFleetDiscoveryDetailsRoutes = (getGQLClient, id) => {
  const GQL = getFleetDiscoveryDetailsRoutesGQL(id);
  return getGQLClient()
    .then(client => client.request(GQL))
    .then(res => mapFleetDiscoveryDetailsRoutes(res));
};
