import React, { useEffect } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import PrivateRoute from '@/navigation/PrivateRoute'
import NotFound from '@/navigation/NotFound'

import { getCurrentUserMetadata } from '@/authentication/token'
import { FullStory, GoogleAnalytics, USER_TYPE } from '@leaf/components'
import Companies, { CompanyDetails } from '@/companies'
import FuelPriceOverview from '@/fuel-prices'
import Users from '@/users'
import { ShipmentDetails, ShipmentOverview } from '@/shipments'
import { UploadView, ShipperNetworkView, MultiNetworkView } from '@/tools'
import {
  AdaptLanes,
  AdaptNetworkMoveOverview,
  AdaptNetworkMoveDetails,
  AdaptBoardOverview,
} from '@/adapt'
import CreateContract from '@/contracts/new-contract'
import DashboardView from '@/dashboard/DashboardView'
import ExplorerView from '@/components/ExplorerView'
import PartnerLocationsExecutionOverview from '@/executions/partner-locations/PartnerLocationsExecutionOverview'
import LocationsOverview from '@/executions/locations/LocationsOverview'
import ExecutionLanesOverview from '@/executions/lanes/ExecutionLanesOverview'
import PCMilerDistanceLookup from '@/tools/pc-miler-distance-lookup/PCMilerDistanceLookup'
import { useAuth0 } from '@auth0/auth0-react'
import { ShipperContractsOverview, ContractMatchingOverview } from '@/contracts'
import ShipperContractsDetails from '@/contracts/details'
import {
  OpsPlansOverview,
  OpsPlansDetails,
  FleetDiscoveryOverview,
  FleetDiscoveryDetails,
} from '@/planning'
import LanesOverview from '@/lanes/overview/LanesOverview'

export const DEFAULT_ROUTE = '/dashboard'

const Continue = () =>
  window.history.state?.returnTo ? (
    <Redirect
      to={{
        pathname: window.history.state.returnTo,
      }}
    />
  ) : (
    <Redirect
      to={{
        pathname: DEFAULT_ROUTE,
      }}
    />
  )

export default withRouter(({ history }) => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0()

  useEffect(() => {
    FullStory.initialize('admin', user?.email)
  }, [user])

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently?.().then((res) => {
        const userMetadata = getCurrentUserMetadata(res)
        const isLeafAdmin = userMetadata.role === USER_TYPE.LE_ADMIN
        if (!isLeafAdmin) {
          window.location.assign(`${import.meta.env.VITE_UI_CLIENT_APP_URL}`)
        }
      })
    }
  }, [isAuthenticated, getAccessTokenSilently])

  return (
    <GoogleAnalytics.Listener
      isProd={import.meta.env.PROD}
      trackingId={import.meta.env.VITE_GA_TRACKING_ID}
      userType='ADMIN'
      history={history}
    >
      <Switch>
        <Redirect exact from='/' to={DEFAULT_ROUTE} />

        <PrivateRoute exact path='/continue'>
          <Continue />
        </PrivateRoute>

        <PrivateRoute exact path='/contracts'>
          <ShipperContractsOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/contracts/create-contract'>
          <CreateContract />
        </PrivateRoute>
        <PrivateRoute exact path='/contracts/create-contract/:id'>
          <CreateContract />
        </PrivateRoute>
        <PrivateRoute exact path='/contracts/create-contract/:id/route'>
          <CreateContract />
        </PrivateRoute>
        <PrivateRoute exact path='/contracts/:id'>
          <ShipperContractsDetails />
        </PrivateRoute>
        <PrivateRoute exact path='/contract-matching'>
          <ContractMatchingOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/companies'>
          <Companies />
        </PrivateRoute>

        <PrivateRoute path='/companies/:id'>
          <CompanyDetails />
        </PrivateRoute>

        <PrivateRoute path='/dashboard'>
          <DashboardView name='performance' />
        </PrivateRoute>

        <PrivateRoute exact path='/users'>
          <Users />
        </PrivateRoute>

        <PrivateRoute exact path='/lane-management'>
          <LanesOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/shipments'>
          <ShipmentOverview />
        </PrivateRoute>

        <PrivateRoute path='/shipments/:id'>
          <ShipmentDetails />
        </PrivateRoute>

        <PrivateRoute exact path='/fuel-prices'>
          <FuelPriceOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/tools/adapt-upload'>
          <UploadView />
        </PrivateRoute>
        <PrivateRoute exact path='/tools/shipper-network'>
          <ShipperNetworkView />
        </PrivateRoute>
        <PrivateRoute exact path='/tools/multi-network'>
          <MultiNetworkView />
        </PrivateRoute>

        <PrivateRoute exact path='/tools/pc-miler-distance-lookup'>
          <PCMilerDistanceLookup />
        </PrivateRoute>

        <PrivateRoute exact path='/partner-locations-execution'>
          <PartnerLocationsExecutionOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/execution-locations'>
          <LocationsOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/execution-lanes'>
          <ExecutionLanesOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/adapt/lanes'>
          <AdaptLanes />
        </PrivateRoute>

        <PrivateRoute exact path='/adapt/network-moves'>
          <AdaptNetworkMoveOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/adapt/network-moves/:id'>
          <AdaptNetworkMoveDetails />
        </PrivateRoute>

        <PrivateRoute exact path='/adapt/explorer/companies/:companyId/lanes/:laneId'>
          <ExplorerView />
        </PrivateRoute>

        <PrivateRoute exact path='/adapt/board'>
          <AdaptBoardOverview />
        </PrivateRoute>

        <PrivateRoute exact path='/planning/ops-plans'>
          <OpsPlansOverview />
        </PrivateRoute>
        <PrivateRoute exact path='/planning/ops-plans/:id'>
          <OpsPlansDetails />
        </PrivateRoute>

        <PrivateRoute exact path='/planning/fleet-discovery'>
          <FleetDiscoveryOverview />
        </PrivateRoute>
        <PrivateRoute exact path='/planning/fleet-discovery/:id'>
          <FleetDiscoveryDetails />
        </PrivateRoute>

        <Route exact path='/404'>
          <NotFound />
        </Route>

        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </GoogleAnalytics.Listener>
  )
})
