import { GQL_SHIPPERS } from '@/graphql/filter-queries/GQL_COMPANIES'
import { utility } from '@leaf/components'
import { getContractTripsGQL } from './GQL_CONTRACT_TRIPS'

const mapContractTrips = (trips) =>
  trips.map((trip) => ({
    ...trip,
    origin: [
      [`${trip.originCity}, ${trip.originState} ${trip.originZip}`],
      [`${trip.originStreet} ${trip.originBusinessName}`],
    ],
    destination: [
      [`${trip.destinationCity}, ${trip.destinationState} ${trip.destinationZip}`],
      [`${trip.destinationStreet} ${trip.destinationBusinessName}`],
    ],
    equipmentTypeName: trip.equipmentType.name,
    status: trip.shipmentExecutionStatus,
    shipper: trip.shipmentTenders?.[0]?.company,
    executionPartnerName: trip.executionPartnerCompany?.name,
    tripId: trip.tripId,
    stops: trip.stops ?? '-',
    startDate:
      utility.date.formatStringDate(
        trip.shipmentTenders?.[0]?.originAppointmentTime ??
          trip.shipmentTenders?.[0]?.originInitialAppointmentTime ??
          trip.shipmentTenders?.[0]?.expectedShipmentDate,
        utility.date.VIEW_FORMAT
      ) ?? '-',
  }))

const getOrderBy = (sort) => {
  if (sort) {
    return [{ [sort.field ?? sort.name]: sort.order }]
  }
  return null
}

export const getContractTrips = ({ getGQLClient, tableState, contractId }) => {
  const variables = {
    sort: tableState.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      shipper: tableState.filters.shipper ? tableState.filters.shipper : undefined,
      status: tableState.filters.status ? tableState.filters.status : undefined,
    },
  }
  const { where, search, sort, ...rest } = variables

  return getGQLClient().then((client) => {
    const GQL = getContractTripsGQL({
      where,
      search,
      contractId,
      ...rest,
    })
    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then((res) => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.carrierTripTenderAggregate.aggregate.count,
      data: mapContractTrips(res.carrierTripTender),
    }))
  })
}

export const getShippers = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_SHIPPERS)
      .then((res) => res.company.map((e) => ({ label: e.name, value: e.id })))
  )
