import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import { utility } from '@leaf/components'

const { ActionButtons } = utility.table

export const getOptions = (handleAddOpen) => ({
  selectableRows: 'none',
  print: false,
  filter: true,
  download: false,
  ...utility.table.getRowHoverActionProps('location'),
  customToolbar: () => (
    <Tooltip title="Add New Location">
      <IconButton onClick={handleAddOpen}>
        <Add />
      </IconButton>
    </Tooltip>
  ),
})

export const getColumns = (filterOptions, handleEditOpen) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Shipper name',
    name: 'shipperName',
    options: {
      ...filterOptions.shipperName,
      sort: true,
    },
  },
  {
    label: 'Location Name',
    name: 'shipperLocationName',
    field: 'shipper_location_name',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    label: 'Location Reference',
    name: 'shipperLocationReference',
    field: 'shipper_location_reference',
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    label: 'Location Type',
    name: 'locationType',
    field: 'location_type',
    options: {
      ...filterOptions.locationType,
      sort: true,
    },
  },
  {
    label: 'Street',
    name: 'street',
    options: { filter: false, sort: true, display: false },
  },
  {
    label: 'City',
    name: 'city',
    options: { filter: false, sort: true, display: false },
  },
  {
    label: 'State',
    name: 'state',
    options: { filter: false, sort: true, display: false },
  },
  {
    label: 'Zip',
    name: 'zipCode',
    field: 'zip',
    options: { filter: false, sort: true, display: false },
  },
  {
    label: 'Country',
    name: 'country',
    options: { filter: false, sort: true, display: false },
  },
  {
    label: 'Address',
    name: 'addressName',
    options: {
      sort: false,
    },
  },
  {
    label: 'Consignor/Consignee',
    name: 'businessName',
    field: 'business_name',
    options: { filter: false, sort: true },
  },
  {
    label: 'Shipper ID',
    name: 'shipperId',
    field: 'shipper_id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: (actionsData) => {
        const { id } = actionsData
        return (
          <ActionButtons id={`Actions-location-${id}`}>
            <Tooltip title="Edit location">
              <IconButton onClick={() => handleEditOpen(id)}>
                <Edit />
              </IconButton>
            </Tooltip>
          </ActionButtons>
        )
      },
    },
  },
]
