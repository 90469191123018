import { gql } from 'graphql-request'

const WHERE = {
  COMPANY_ID_VAR: gql`$company_id: [bigint!]`,
  COMPANY_ID_CLAUSE: gql`company: { id: { _in: $company_id } }`,
  ROLE_VAR: gql`$role: [String!]`,
  ROLE_CLAUSE: gql`role: { _in: $role }`,
  ACTIVE_VAR: gql`$active: [Boolean!]`,
  ACTIVE_CLAUSE: gql`active: { _in: $active }`,
}

const whereClause = ({ where, search }) => {
  let whereGQL = gql``
  const whereVarsKeys = Object.keys(where).filter(
    (k) => Array.isArray(where[k]) && where[k].length
  )

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: {
          ${whereVarsKeys.map((key) =>
            where[key]
              ? gql`
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                `
              : ''
          )}
          ${
            search !== undefined && search !== ''
              ? gql`
            _or: [
              {first_name: { _ilike: "%${search}%" }}
              {last_name: { _ilike: "%${search}%" }}
              {email: { _ilike: "%${search}%" }}
              {phone_number: { _ilike: "%${search}%" }}
              {company: {name: { _ilike: "%${search}%" }}}
            ]`
              : ''
          }
        }
      }
    `
  }
  return whereGQL
}

const whereVar = ({ where }) => gql`
  ${where.company_id ? WHERE.COMPANY_ID_VAR : ``}
  ${where.role ? WHERE.ROLE_VAR : ``}
  ${where.active ? WHERE.ACTIVE_VAR : ``}
`

export const getUsers = (variables) => {
  const whereGQL = whereClause(variables)

  return gql`
    query getUsers($limit: Int, $orderBy: [user_order_by], $offset: Int, ${whereVar(
      variables
    )}) {
      user(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        id
        first_name
        last_name
        email
        phone_number
        role
        default_app
        login_count
        last_login_at
        active
        company {
          name
          id
        }
      }
      user_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `
}
