import React from 'react'
import { withRouter } from 'react-router-dom'
import { ServerSideTable, useTableCustomFilters } from '@leaf/components'
import { StateContext } from '@/state/StateProvider'
import {
  SET_ADAPT_BOARD_NETWORK_MOVES_STATE,
  SET_ADAPT_BOARD_NETWORK_MOVES_FILTER_STATE,
} from '@/state/stateReducer'
import AdaptBoardNetworkMoves from '@/domain/adapt/AdaptBoardNetworkMoves'

import { getColumns } from './adaptNetworkMoveOverviewColumns'

const getOptions = (history) => ({
  filter: true,
  download: false,
  onRowClick: (rowData) => {
    const id = rowData[1]

    history.push(`/adapt/network-moves/${id}`)
  },
})

const AdaptBoardNetworkMovesTable = ({ history, selectedShipper, setSelectedShipper }) => {
  const [filters, setFilters] = React.useState({
    moveType: [],
    networkType: [],
    equipmentClass: [],
    owners: [],
  })
  const {
    state: {
      tables: { adaptBoardNetworkMoves: tableState },
    },
    dispatch,
  } = React.useContext(StateContext)

  React.useEffect(() => {
    AdaptBoardNetworkMoves.getFilters().then(setFilters)
  }, [])

  const tableFilters = React.useMemo(
    () => [
      {
        inputType: 'autocomplete',
        columnName: 'shipperName',
        filterOptionsGetter: () => Promise.resolve(filters.owners),
      },
      {
        inputType: 'autocomplete',
        columnName: 'moveType',
        filterOptionsGetter: () => Promise.resolve(filters.moveType),
      },
      {
        inputType: 'autocomplete',
        columnName: 'equipmentClass',
        filterOptionsGetter: () => Promise.resolve(filters.equipmentClass),
      },
      {
        inputType: 'autocomplete',
        columnName: 'networkType',
        filterOptionsGetter: () => Promise.resolve(filters.networkType),
      },
    ],
    [filters]
  )
  const [filterOptions] = useTableCustomFilters(tableFilters, true)

  const handleChange = (setTableState) =>
    dispatch({ type: SET_ADAPT_BOARD_NETWORK_MOVES_STATE, payload: setTableState(tableState) })

  React.useEffect(() => {
    dispatch({ type: SET_ADAPT_BOARD_NETWORK_MOVES_FILTER_STATE, payload: selectedShipper })
  }, [selectedShipper])

  React.useEffect(() => {
    if (!tableState.filters?.shipperName?.find((item) => item === selectedShipper)) {
      setSelectedShipper(undefined)
    }
  }, [tableState])

  return (
    <ServerSideTable
      options={getOptions(history)}
      columns={getColumns(tableState, filterOptions)}
      fn={AdaptBoardNetworkMoves.getAll}
      tableState={tableState}
      setTableState={handleChange}
    />
  )
}

export default withRouter(AdaptBoardNetworkMovesTable)
