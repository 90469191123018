import React from 'react';
import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import GenericTextarea from '../generic/Textarea';

const Textarea = styled(GenericTextarea)`
  width: 100%;
  border-color: ${({ theme }) => `${theme.palette.actions.border}`};
  border-radius: 4px;
  resize: none;

  .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;

export default ({ name, control, error, ...rest }) => (
  <Controller
    as={<Textarea multiline minRows={4} variant="outlined" error={error} {...rest} />}
    name={name}
    control={control}
  />
);
