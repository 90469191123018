import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography } from '@mui/material';

export default ({ message, title = 'Please confirm your action', open, handleClose, handleConfirmation }) => (
  <Dialog fullWidth onClose={handleClose} open={open}>
    <DialogTitle>
      <Typography variant="h6">{title}</Typography>
    </DialogTitle>

    <DialogContent>
      <Typography variant="body1">{message}</Typography>
    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose} color="secondary" variant="contained">
        Cancel
      </Button>

      <Button onClick={handleConfirmation} color="primary" variant="contained">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);
