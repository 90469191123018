import { getCompanyDetailsGQL } from './GQL_COMPANY_DETAILS';

export const getCompanyDetails = (getGQLClient, id) => {
  const GQL = getCompanyDetailsGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(({ company }) => ({
      data: {
        ...company[0],
        accountTeamMembers: company[0].accountTeamMembers.map(item => ({ user: item.user })),
      },
    })),
  );
};
