import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import useGQL from '@/hooks/useGQL'
import { StateContext } from '@/state/StateProvider'
import { SET_EXECUTION_LANES_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import {
  Page,
  useTableCustomFilters,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
} from '@leaf/components'

import { getColumns, getOptions } from './table-config'
import { getExecutionLanes, getShippers } from './domain/executionLanesModel'
import EditExecutionLaneDialog from './EditExecutionLaneDialog'

const ExecutionLanesOverview = () => {
  const {
    state: {
      tables: { executionLanesOverview: tableState },
    },
    dispatch,
  } = React.useContext(StateContext)

  const [openEdit, setOpenEdit] = useState(false)
  const [selectedLane, setSelectedLane] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const getGQLClient = useGQL()

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'shipper',
      filterOptionsGetter: () => getShippers(getGQLClient),
    },
  ])

  const handleEditOpen = (lane) => {
    setSelectedLane(lane)
    setOpenEdit(true)
  }

  const handleEditClose = (refreshPage = false) => {
    setSelectedLane(null)
    setOpenEdit(false)
    if (refreshPage) {
      dispatch({ type: SET_EXECUTION_LANES_OVERVIEW_TABLE_STATE, payload: { ...tableState } })
    }
  }

  const handleChange = (setTableState) =>
    dispatch({ type: SET_EXECUTION_LANES_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) })

  return (
    <Page title='Execution Lanes'>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          {openEdit && (
            <EditExecutionLaneDialog
              open={openEdit}
              onClose={handleEditClose}
              selectedLane={selectedLane}
            />
          )}
          <ServerSideTable
            options={getOptions()}
            columns={getColumns(filterOptions, handleEditOpen)}
            fn={() => getExecutionLanes(getGQLClient, tableState)}
            tableState={tableState}
            setTableState={handleChange}
            errorHandler={enqueueSnackbar}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  )
}

export default withRouter(ExecutionLanesOverview)
