import {
  toPercent,
  formatNumber,
  formatNumberAsView,
  toFixed,
  humanizeToMillion,
  humanizeToThousands,
  toFixedNumber,
  toPercentage,
  UNIT,
} from './numbers';

export const formattingTypes = {
  DOLLAR: 'DOLLAR',
  DOLLAR_UNROUNDED: 'DOLLAR_UNROUNDED',
  RATE_PER_MILE: 'RATE_PER_MILE',
  PERCENT: 'PERCENT',
  TONS: 'TONS',
  INTEGER: 'INTEGER',
  // legacy
  DOLLARS_IN_MILLIONS: 'DOLLARS_IN_MILLIONS',
  DOLLARS_IN_THOUSANDS: 'DOLLARS_IN_THOUSANDS',
  TONNES_IN_THOUSANDS: 'TONNES_IN_THOUSANDS',
  DOLLARS_PER_MILE: 'DOLLARS_PER_MILE',
  THOUSANDS: 'THOUSANDS',
  NO_FORMATTING: 'NO_FORMATTING',
  PERCENTAGE: 'PERCENTAGE',
};

export const formattingFns = {
  [formattingTypes.PERCENT]: value => `${toPercent(value)}`,
  [formattingTypes.DOLLAR]: value => `${formatNumber(value)}`,
  [formattingTypes.DOLLAR_UNROUNDED]: value => `${formatNumber(value, UNIT.CURRENCY, false)}`,
  [formattingTypes.RATE_PER_MILE]: value => `$${formatNumberAsView(parseFloat(value || 0), 2, false)}/mi`,
  [formattingTypes.TONS]: value => {
    const formattedValue = formatNumber(value, null);
    return `${formattedValue}${formattedValue === '-' ? '' : 't'}`;
  },
  [formattingTypes.INTEGER]: value => `${formatNumber(value, null)}`,
  // legacy
  [formattingTypes.DOLLARS_IN_MILLIONS]: (value, numberOfDecimalsToFix) =>
    `$${humanizeToMillion(value, numberOfDecimalsToFix)}`,
  [formattingTypes.DOLLARS_IN_THOUSANDS]: (value, numberOfDecimalsToFix) =>
    `$${humanizeToThousands(value, numberOfDecimalsToFix)}`,
  [formattingTypes.TONNES_IN_THOUSANDS]: (value, numberOfDecimalsToFix) =>
    `${humanizeToThousands(value, numberOfDecimalsToFix)} t`,
  [formattingTypes.DOLLARS_PER_MILE]: (value, numberOfDecimalsToFix) => `$${toFixed(value, numberOfDecimalsToFix)}/mi`,
  [formattingTypes.THOUSANDS]: (value, numberOfDecimalsToFix) => humanizeToThousands(value, numberOfDecimalsToFix),
  [formattingTypes.PERCENTAGE]: (value, numberOfDecimalsToFix) =>
    `${toFixedNumber(toPercentage(value), numberOfDecimalsToFix)}%`,
  [formattingTypes.NO_FORMATTING]: value => value,
};
