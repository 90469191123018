export default class NewLocation {
  constructor(
    {
      shipperId,
      businessName,
      type,
      operatingHours,
      address,
      contactName,
      contactEmail,
      contactPhone,
      accessRequirement,
      appointmentRequirement,
      trailerPoolStorageRequirement,
      shipperLocationName,
      shipperLocationReference,
    },
    id
  ) {
    if (!id) {
      this.shipperId = Number(shipperId)
      this.city = address.city
      this.state = address.state
      this.zip = address.zipCode
      this.street = address.address
    }

    this.businessName = businessName
    this.operatingHours = operatingHours
    this.contactName = contactName
    this.contactEmail = contactEmail
    this.contactPhone = contactPhone
    this.locationType = type
    this.accessRequirements = accessRequirement
    this.appointmentRequirements = appointmentRequirement
    this.trailerPoolStorageRequirements = trailerPoolStorageRequirement
    this.shipperLocationName = shipperLocationName
    this.shipperLocationReference = shipperLocationReference
  }
}
