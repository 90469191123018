import { GQL_FUEL_PRICE_INDEX } from '@/graphql/filter-queries/GQL_FUEL_PRICE_INDEX'
import { utility } from '@leaf/components'
import { getCompanyFSCGQL } from './GQL_COMPANY_FSC'

const mapFscResponse = (fuelSurchargeSchedules) =>
  fuelSurchargeSchedules.map((fsc) => ({
    ...fsc,
    increment: fsc.increment,
    bucketSize: fsc.bucketSize,
    isFirstStepZero: fsc.isFirstStepZero ? 'Yes' : 'No',
    fuelPriceIndexName: fsc.fuelPriceIndex?.name,
    created: utility.date.formatStringDate(fsc.created, utility.date.VIEW_FORMAT),
    validFrom: utility.date.formatStringDate(fsc.validFrom, utility.date.VIEW_FORMAT),
  }))

export const getCompanyFSC = (getGQLClient, id) => {
  const GQL = getCompanyFSCGQL(id)
  return getGQLClient().then((client) =>
    client.request(GQL).then((res) => ({
      data: mapFscResponse(res.fuelSurchargeSchedule),
    }))
  )
}

export const getFuelPriceIndexFSC = (getGQLClient) =>
  getGQLClient().then((client) =>
    client.request(GQL_FUEL_PRICE_INDEX).then((res) => ({
      data: res.fuelPriceIndex,
    }))
  )
