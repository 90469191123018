/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@mui/material';

import { Today } from '@mui/icons-material';
import styled from '@emotion/styled';
import { utility, Form } from '@leaf/components';

const DateIcon = styled(Today)`
  color: ${({ disabled }) => `${!disabled ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.24)'}`};
  margin-right: ${({ theme }) => `${theme.spacing(2)}`};
`;

export default React.memo(
  ({ name, onChange, onBlur, useFieldNameHandler = true, inputVariant = 'outlined', error, ...props }) => {
    const handleChange = useFieldNameHandler ? onChange && onChange(name) : onChange;
    return (
      <Form.Generic.Datepicker
        onChange={handleChange}
        renderInput={inputProps => (
          <TextField
            fullWidth
            variant={inputVariant}
            onBlur={onBlur}
            {...inputProps}
            InputProps={{
              endAdornment: <DateIcon fontSize="small" disabled={inputProps.disabled} />,
            }}
            error={error}
          />
        )}
        format={utility.date.VIEW_FORMAT}
        autoOk
        {...props}
      />
    );
  },
);
