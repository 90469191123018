import axios from 'axios';
import { utility } from '@leaf/components';

export default class Contract {
  static importContracts(file, ignoreWarning = false, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    const URL = ignoreWarning ? 'contracting/contracts/import?ignore_warnings=true' : 'contracting/contracts/import';

    return axios.post(URL, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      timeout: 1000 * 300,
      onUploadProgress,
    });
  }

  static downloadContracts(contracts) {
    return axios
      .post(
        `view/contracts/download`,
        {
          ids: contracts,
        },
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        utility.table.triggerBrowserDownload(response, 'contracts', 'xlsx');
      });
  }
}
