import React from 'react'
import axios from 'axios'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import { Box } from '@mui/material'

import { Explorer, Page, BackButton } from '@leaf/components'

import Beta from '@/components/Beta'

const ExplorerWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  padding: 16px;
`

const ExplorerView = ({
  history,
  match: {
    params: { companyId, laneId },
  },
}) => {
  if (!companyId) {
    return <Page title='Adapt Lane Explorer' />
  }
  const onNotFound = () => history.push('/404')

  return (
    <Page
      title='Adapt Lane Explorer'
      ComponentTitleLeft={
        <Box display='flex'>
          <BackButton history={history} />
        </Box>
      }
      isUppercase={false}
      ComponentTitleRight={<Beta />}
    >
      <ExplorerWrapper>
        <Explorer
          client={axios}
          companyId={companyId}
          laneId={laneId}
          mapboxUrl={`${import.meta.env.VITE_MAPBOX}`}
          options={{ view: 'ADMIN' }}
          onNotFound={onNotFound}
        />
      </ExplorerWrapper>
    </Page>
  )
}

export default withRouter(ExplorerView)
