/* eslint-disable no-underscore-dangle */
import React from 'react';

import {
  DetailsOverview,
  PageContainer,
  SpacedContainer,
  USER_TYPE_DISPLAY_NAMES,
  PageContentLayout,
} from '@leaf/components';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import {
  getCompanyGeneralDetails,
  getCompanyContactDetails,
  getCompanyTypeSpecificDetails,
} from './companyDetailsData';

const SplitHeightContainer = styled(PageContentLayout)`
  &&& {
    height: calc((100vh - 120px - 48px) / 2);
  }
`;

const InnerGridContainer = styled(Grid)`
  gap: 16px;
`;

export default ({ data }) => (
  <PageContainer>
    <SpacedContainer container spacing={2} autoWidth>
      <Grid item xs={6}>
        <PageContentLayout hasSubheader withoutSpacing>
          <DetailsOverview
            title={`${USER_TYPE_DISPLAY_NAMES[data.type]} details`}
            data={getCompanyGeneralDetails(data)}
          />
        </PageContentLayout>
      </Grid>

      <Grid item xs={6}>
        <InnerGridContainer container>
          <Grid item xs={12}>
            <SplitHeightContainer withoutSpacing fullHeight={false}>
              <DetailsOverview title="Contact" data={getCompanyContactDetails(data)} />
            </SplitHeightContainer>
          </Grid>

          <Grid item xs={12}>
            <SplitHeightContainer withoutSpacing fullHeight={false}>
              <DetailsOverview
                title={`${USER_TYPE_DISPLAY_NAMES[data.type]}-specific fields`}
                data={getCompanyTypeSpecificDetails(data)}
              />
            </SplitHeightContainer>
          </Grid>
        </InnerGridContainer>
      </Grid>
    </SpacedContainer>
  </PageContainer>
);
