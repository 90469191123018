import React from 'react';
import { LinearProgress, Typography, Box } from '@mui/material';

export default ({ progress }) => (
  <Box width={1}>
    <Box display="flex" alignItems="center">
      <Box width={1} mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="secondary">{`${progress}%`}</Typography>
      </Box>
    </Box>
  </Box>
);
