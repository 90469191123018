import React from 'react'
import FullStory, { setUserVars } from 'react-fullstory'

const Component = ({ isProd, org }) => {
  if (!isProd || !org) {
    return null
  }
  return <FullStory org={org} />
}

export const initialize = (app, email) => {
  if (!app || !email) {
    return
  }
  console.debug('FullStory initialize', { app, email })
  setUserVars({
    app,
    email,
  })
}

export default Component
