/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import useGQL from '@/hooks/useGQL'
import {
  Page,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
  Form,
  PolygonMap,
  theme,
  CompanyAvatar,
  UserType,
  DELIVERY_DISPLAY_NAMES,
  utility,
  useCopyToClipboard,
} from '@leaf/components'
import { StateContext } from '@/state/StateProvider'
import { SET_LANES_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { getLanes, getTenderLocations } from './domain/laneModel'
import ExternalFilters from './ExternalFilters'
import styled from '@emotion/styled'
import { Add as AddIcon, Publish } from '@mui/icons-material'
import { useState } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import initialState from '@/state/initialState'
import { Link as LinkIcon, LibraryAdd as DuplicateAndEditIcon } from '@mui/icons-material'
import { CreateRoute } from '@/contracts/new-contract/routes-information/CreateRoute'
import { StateMachineProvider } from 'little-state-machine'
import ImportLanesDialog from './import/ImportLanesDialog'

const { ActionButtons } = utility.table

const Search = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(3)}`};
  .MuiTextField-root {
    width: 250px;
  }
`

const getColumns = (handleCopyLaneId, handleEditLaneOpen) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Shipper',
    name: 'shipper',
    options: {
      customBodyRender: (shipper) =>
        shipper ? (
          <CompanyAvatar id={shipper.id} name={shipper.name} type={UserType.SHIPPER} isChip />
        ) : (
          '-'
        ),
      filter: false,
      sort: false,
    },
  },
  { label: 'Origin', name: 'originName', options: { filter: false, sort: false } },
  { label: 'Destination', name: 'destinationName', options: { filter: false, sort: false } },
  {
    label: 'Leaf distance',
    name: 'leafMiles',
    options: {
      filter: false,
      sort: false,
      ...utility.table.rightAlignColumnProps((miles) => utility.format.miles(miles)),
    },
  },
  {
    label: 'Pickup type',
    name: 'pickupType',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (pickupType) => DELIVERY_DISPLAY_NAMES[pickupType],
    },
  },
  {
    label: 'Delivery type',
    name: 'deliveryType',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (deliveryType) => DELIVERY_DISPLAY_NAMES[deliveryType],
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: (actionsData) => {
        const { id } = actionsData
        return (
          <ActionButtons id={`Actions-lane-management-${id}`}>
            <Tooltip title='Duplicate & Edit Lane'>
              <IconButton onClick={() => handleEditLaneOpen(id)}>
                <DuplicateAndEditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Copy lane ID'>
              <IconButton
                size='small'
                onClick={(event) => {
                  event.stopPropagation()
                  handleCopyLaneId(id)
                }}
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </ActionButtons>
        )
      },
    },
  },
]

const NON_HOVERED_LANE_COLORS = {
  originColor: '#BDBDBD',
  destinationColor: '#BDBDBD',
  pathColor: '#BDBDBD',
}

const EMPTY_LANE_COLORS = {
  originColor: undefined,
  destinationColor: undefined,
  pathColor: undefined,
}

export default () => {
  const history = useHistory()
  const [pageData, setPageData] = useState()
  const [mapData, setMapData] = useState()
  const [tenderLocations, setTenderLocations] = useState()
  const [filterGeos, setFilterGeos] = useState([])
  const [hoveredLaneIndex, setHoveredLaneIndex] = useState(-1)
  const [createLaneDialogOpen, setCreateLaneDialogOpen] = useState()
  const [openImportDialog, setOpenImportDialog] = useState(false)

  const handleCopyLaneId = useCopyToClipboard(
    (successMessage) => enqueueSnackbar(successMessage, { variant: 'success' }),
    (errorMessage) => enqueueSnackbar(errorMessage, { variant: 'error' }),
    'Copied Lane ID to clipboard',
    'Failed to copy Lane ID to clipboard'
  )

  const getGQLClient = useGQL()

  const {
    state: {
      tables: { lanesOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  const { enqueueSnackbar } = useSnackbar()

  const getLanesToShowOnMap = useCallback(
    (array, filterGeos) => {
      return [
        ...(array?.map((data) => ({
          pathGeo: data.pathGeo,
          pathColor: data.pathColor ?? theme.palette.primary.light,
          originGeo: data.originGeo,
          originColor: data.originColor ?? theme.palette.primary.main,
          destinationGeo: data.destinationGeo,
          destinationColor: data.destinationColor ?? theme.palette.primary.dark,
        })) ?? []),
        ...filterGeos,
      ]
    },
    [pageData, filterGeos]
  )

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true)
  }

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false)
  }

  const debounceHover = useCallback(_.debounce(setHoveredLaneIndex, 100), [])

  const onLaneRowMouseEnter = useCallback((rowIndex) => {
    debounceHover(rowIndex)
  }, [])
  const onLaneRowMouseLeave = useCallback(() => debounceHover(-1), [])

  const options = useMemo(
    () => ({
      filter: false,
      download: false,
      viewColumns: false,
      search: false,
      setRowProps: (row, dataIndex, rowIndex) => {
        return {
          onMouseOver: () => {
            onLaneRowMouseEnter(rowIndex)
            utility.table.onMouseEnterRow('lane-management', undefined, row)()
          },
          onMouseLeave: () => {
            onLaneRowMouseLeave()
            utility.table.onMouseLeaveRow('lane-management', undefined, row)()
          },
        }
      },
    }),
    [hoveredLaneIndex]
  )

  useEffect(() => {
    setMapData(pageData)
  }, [pageData])

  useEffect(() => {
    if (hoveredLaneIndex !== -1 && pageData?.length) {
      const lane = pageData[hoveredLaneIndex]
      return setMapData(() => {
        const array = pageData.map((l) => ({
          ...l,
          ...NON_HOVERED_LANE_COLORS,
        }))
        array.splice(hoveredLaneIndex, 1)
        array.push({
          ...lane,
          originColor: 'green',
          destinationColor: 'darkgreen',
          pathColor: 'green',
        })
        return array
      })
    }
    setMapData(() => {
      const array =
        pageData?.map((l) => ({
          ...l,
          ...EMPTY_LANE_COLORS,
        })) ?? []
      return array
    })
  }, [hoveredLaneIndex])

  useEffect(() => {
    const unregister = history.listen((location) => {
      const path = location.pathname.startsWith('/lanes-management')
      if (!path) {
        dispatch({
          type: SET_LANES_OVERVIEW_TABLE_STATE,
          payload: initialState.tables.lanesOverview,
        })
      }
    })
    return () => unregister()
  }, [])

  const debounceOnSearchHandler = _.debounce((e) => {
    dispatch({
      type: SET_LANES_OVERVIEW_TABLE_STATE,
      payload: { ...tableState, search: e.target.value },
    })
  }, 500)

  const handleEditLaneOpen = (laneId) => {
    setCreateLaneDialogOpen(pageData?.find(({ id }) => id === laneId))
  }

  return (
    <Page title='Lane Management'>
      <PageContainer>
        <PageContentLayout>
          <Search>
            <Form.Generic.Input name='search' label='Search' onKeyDown={debounceOnSearchHandler} />
            <Box marginLeft='auto' display='flex' gap={2}>
              <Tooltip title='Import Lanes'>
                <IconButton onClick={handleOpenImportDialog}>
                  <Publish />
                </IconButton>
              </Tooltip>
              <Button
                variant='contained'
                startIcon={<AddIcon />}
                onClick={() => setCreateLaneDialogOpen({})}
              >
                Create Lane
              </Button>
            </Box>
          </Search>
          <Divider />
          <ExternalFilters
            stateSetter={SET_LANES_OVERVIEW_TABLE_STATE}
            dispatch={dispatch}
            tableState={tableState}
          />
          <Box height='calc(100vh - 319px)' display='flex'>
            <Box width='50%'>
              <ServerSideTable
                customSearch={false}
                options={options}
                columns={getColumns(handleCopyLaneId, handleEditLaneOpen)}
                onPageRowsLoadedCb={setPageData}
                fn={() => {
                  setFilterGeos([])
                  setTenderLocations([])
                  const { shouldShowTenderLocations } = tableState.filters
                  let firstPromise
                  if (shouldShowTenderLocations) {
                    firstPromise = getTenderLocations(getGQLClient, tableState)
                  } else {
                    firstPromise = Promise.resolve([])
                  }
                  return Promise.all([firstPromise, getLanes(getGQLClient, tableState)])
                    .then((res) => {
                      const origins = res[0]?.map((r) => {
                        const { coordinates } = r.origin.geo
                        return [coordinates[1], coordinates[0]]
                      })
                      const destinations = res[0]?.map((r) => {
                        const { coordinates } = r.destination.geo
                        return [coordinates[1], coordinates[0]]
                      })
                      setTenderLocations([...origins, ...destinations])
                      const filters = []
                      if (tableState.filters.origin) {
                        filters.push({
                          originGeo: tableState.filters.origin,
                          originColor: theme.palette.error.main,
                        })
                      }
                      if (tableState.filters.destination) {
                        filters.push({
                          destinationGeo: tableState.filters.destination,
                          destinationColor: theme.palette.error.main,
                        })
                      }
                      setTimeout(() => {
                        setFilterGeos(filters)
                      })
                      return res[1]
                    })
                    .catch(enqueueSnackbar)
                }}
                tableState={tableState}
                dispatch={dispatch}
                stateSetterAction={SET_LANES_OVERVIEW_TABLE_STATE}
                errorHandler={enqueueSnackbar}
              />
            </Box>
            <Box width='50%'>
              <PolygonMap
                data={getLanesToShowOnMap(mapData, filterGeos)}
                markers={tenderLocations}
                shouldHideNummeratedMarker
                legendComponent={
                  <Typography color={theme.palette.secondary.main}>
                    Note that the map only shows the current page of the table.
                  </Typography>
                }
              />
            </Box>
          </Box>
          <ImportLanesDialog
            open={openImportDialog}
            handleCloseDialog={handleCloseImportDialog}
            title='IMPORT LANES'
          />
        </PageContentLayout>
      </PageContainer>
      {
        <StateMachineProvider>
          <Dialog
            open={!!createLaneDialogOpen}
            fullWidth
            maxWidth='xl'
            onClose={() => setCreateLaneDialogOpen(undefined)}
          >
            <DialogTitle>Create Lane</DialogTitle>
            <DialogContent sx={{ p: 0 }} dividers>
              <CreateRoute
                editingRoute={
                  createLaneDialogOpen
                    ? { tripType: '', editLegs: [{ lane: createLaneDialogOpen }] }
                    : undefined
                }
                isStandaloneLaneForm
                callbackAction={() => {
                  setCreateLaneDialogOpen(undefined)
                }}
              />
            </DialogContent>
          </Dialog>
        </StateMachineProvider>
      }
    </Page>
  )
}
