import axios from 'axios';
import { utility, ON_TIME_STATUS } from '@leaf/components';
import { getShipmentDetailsGQL, getShipmentTrackingEventGQL } from './GQL_SHIPMENT_DETAILS';

const calculateStatus = (arrivalTime, appointmentTime) => {
  if (appointmentTime == null || arrivalTime == null) {
    return ON_TIME_STATUS.NOT_AVAILABLE;
  }

  return utility.date.isDateBefore(
    utility.date.addMinutesOnDateTime(new Date(appointmentTime), 120),
    new Date(arrivalTime),
  )
    ? ON_TIME_STATUS.LATE
    : ON_TIME_STATUS.ON_TIME;
};

const mapShipmentDetails = data => {
  const originArrivalTime = data.originArrivalTime
    ? utility.time.transformPickerTime(new Date(data.originArrivalTime))
    : null;
  const originDepartureTime = data.originDepartureTime
    ? utility.time.transformPickerTime(new Date(data.originDepartureTime))
    : null;
  const destinationArrivalTime = data.destinationArrivalTime
    ? utility.time.transformPickerTime(new Date(data.destinationArrivalTime))
    : null;
  const destinationDepartureTime = data.destinationDepartureTime
    ? utility.time.transformPickerTime(new Date(data.destinationDepartureTime))
    : null;
  return {
    ...data,
    originPrimaryContent: `${data?.originBusinessName}`,
    originSecondaryContent: `${data?.originAddressName}, ${data?.originCity} ${data?.originState} ${data?.originZip}`,
    destinationPrimaryContent: `${data?.destinationBusinessName}`,
    destinationSecondaryContent: `${data?.destinationAddressName}, ${data?.destinationCity} ${data?.destinationState} ${data?.destinationZip}`,
    pickupInOutTime:
      !originArrivalTime && !originDepartureTime ? '-' : `${originArrivalTime ?? '/'} - ${originDepartureTime ?? '/'}`,
    deliveryInOutTime:
      !destinationArrivalTime && !destinationDepartureTime
        ? '-'
        : `${destinationArrivalTime ?? '/'} - ${destinationDepartureTime ?? '/'}`,
    pickupStatus: calculateStatus(data.originArrivalTime, data.originAppointmentTime),
    deliveryStatus: calculateStatus(data.destinationArrivalTime, data.destinationAppointmentTime),
    pathGeo: data?.lane?.drivingData?.geo?.geometry,
    originGeo: data?.origin?.geo,
    destinationGeo: data?.destination?.geo,
    contractType: {
      idShipments: data.carrierTripTender?.id,
      type: data.carrierTripTender?.sellContract?.contractType,
      spotConfirmed: data.carrierTripTender?.spotConfirmed,
    },
    shipperInvoices: data?.shipperInvoices.map(invoice => ({
      ...invoice,
      invoiceDate: utility.date.formatDate(new Date(invoice.invoiceDate), utility.date.VIEW_FORMAT),
      invoiceNumber: utility.numbers.formatNumberAsView(invoice.invoiceNumber, 2, false),
      invoiceTotal: utility.numbers.formatNumberAsView(invoice.invoiceTotal, 2, true),
      linehaul: utility.numbers.formatNumberAsView(invoice.linehaul, 2, true),
      fuel: utility.numbers.formatNumberAsView(invoice.fuel, 2, true),
      accessorialTotal: utility.numbers.formatNumberAsView(invoice.accessorialTotal, 2, true),
    })),
    shipperInvoicesLinehaulTotal: utility.numbers.formatNumberAsView(
      data.shipperInvoices.reduce((accumulator, shipperInvoices) => accumulator + shipperInvoices?.linehaul, 0),
      2,
      true,
    ),
    shipperInvoicesFuelTotal: utility.numbers.formatNumberAsView(
      data.shipperInvoices.reduce((accumulator, shipperInvoices) => accumulator + shipperInvoices?.fuel, 0),
      2,
      true,
    ),
    shipperInvoicesAccessorialsTotal: utility.numbers.formatNumberAsView(
      data.shipperInvoices.reduce((accumulator, shipperInvoices) => accumulator + shipperInvoices?.accessorialTotal, 0),
      2,
      true,
    ),
    shipperInvoicesTotal: utility.numbers.formatNumberAsView(
      data.shipperInvoices.reduce((accumulator, shipperInvoices) => accumulator + shipperInvoices?.invoiceTotal, 0),
      2,
      true,
    ),
    carrierInvoices: data?.carrierTripTender?.carrierInvoices.map(invoice => ({
      ...invoice,
      invoiceDate: utility.date.formatDate(new Date(invoice.invoiceDate), utility.date.VIEW_FORMAT),
      invoiceNumber: utility.numbers.formatNumberAsView(invoice.invoiceNumber, 2, false),
      invoiceTotal: utility.numbers.formatNumberAsView(invoice.invoiceTotal, 2, true),
      linehaul: utility.numbers.formatNumberAsView(invoice.linehaul, 2, true),
      fuel: utility.numbers.formatNumberAsView(invoice.fuel, 2, true),
      accessorialTotal: utility.numbers.formatNumberAsView(invoice.accessorialTotal, 2, true),
    })),
    carrierInvoicesLinehaulTotal: utility.numbers.formatNumberAsView(
      data?.carrierTripTender?.carrierInvoices?.reduce(
        (accumulator, carrierInvoice) => accumulator + carrierInvoice?.linehaul,
        0,
      ),
      2,
      true,
    ),
    carrierInvoicesFuelTotal: utility.numbers.formatNumberAsView(
      data?.carrierTripTender?.carrierInvoices?.reduce(
        (accumulator, carrierInvoice) => accumulator + carrierInvoice?.fuel,
        0,
      ),
      2,
      true,
    ),
    carrierInvoicesAccessorialsTotal: utility.numbers.formatNumberAsView(
      data?.carrierTripTender?.carrierInvoices?.reduce(
        (accumulator, carrierInvoice) => accumulator + carrierInvoice?.accessorialTotal,
        0,
      ),
      2,
      true,
    ),
    carrierInvoicesTotal: utility.numbers.formatNumberAsView(
      data?.carrierTripTender?.carrierInvoices?.reduce(
        (accumulator, carrierInvoice) => accumulator + carrierInvoice?.invoiceTotal,
        0,
      ),
      2,
      true,
    ),
    shipmentTagEntries: data?.shipmentTagEntries.map(tag => ({
      ...tag,
      linehaulRPM: utility.numbers.formatNumberAsView(tag.linehaulRPM, 2, true),
      totalCost: utility.numbers.formatNumberAsView(tag.totalCost, 2, true),
      expectedTotalCost: tag.contractPricingMechanism === 'INDEX' ? utility.numbers.formatNumberAsView(tag.totalCost, 2, true) : null,
      expectedLeafMargin: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.shipperTotalCost - tag.totalCost, 2, true),
      fulfilledWeekly: `${utility.numbers.toFixed(utility.numbers.toPercentage(tag.fulfilledWeekly), 0)}%`,
      fulfilledLifetime: `${utility.numbers.toFixed(utility.numbers.toPercentage(tag.fulfilledLifetime), 0)}%`,
      lsp: tag?.carrier?.name,
      isFleet: tag.isFleet,
      isNetworkMove: tag.isNetworkMove,
    })),
  };
};

const mapShipmentTrackingEvents = data =>
  data.map(trackingEvent => ({
    ...trackingEvent,
    latlong: `${trackingEvent?.latitude} / ${trackingEvent?.longitude}`,
    linehaulRPM: utility.numbers.formatNumberAsView(trackingEvent?.linehaulRPM, 2, true),
    totalCost: utility.numbers.formatNumberAsView(trackingEvent?.totalCost, 2, true),
  }));

export const getShipmentById = (getGQLClient, id) => {
  const GQL = getShipmentDetailsGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapShipmentDetails(res.shipmentTender[0]),
    })),
  );
};

export const getShipmentTrackingEvent = (getGQLClient, id) => {
  const GQL = getShipmentTrackingEventGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapShipmentTrackingEvents(res.shipmentTrackingEvent),
    })),
  );
};
