import React from 'react'
import { withRouter } from 'react-router-dom'

import { Alert } from '@mui/material'

import {
  Page,
  useTableCustomFilters,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
} from '@leaf/components'

import { StateContext } from '@/state/StateProvider'
import { SET_ADAPT_LANES_STATE, RESET_ADAPT_LANES_STATE } from '@/state/stateReducer'

import AdaptLane from '@/domain/adapt/AdaptLane'

import Beta from '@/components/Beta'

import getColumns from './adaptLanesColumns'
import getOptions from './adaptLanesOptions'

const AdaptLanes = ({ history }) => {
  const {
    state: {
      tables: { adaptLanes: tableState },
    },
    dispatch,
  } = React.useContext(StateContext)

  // Reset global state if navigating away from adapt lanes or explorer.
  React.useEffect(() => {
    const unregister = history.listen((location) => {
      // Going to explorer
      const toExplorer = location.pathname.startsWith('/adapt/explorer')
      // Going back from explorer
      const toAdaptLanes = location.pathname.startsWith('/adapt/lanes')
      if (!toExplorer && !toAdaptLanes) {
        dispatch({ type: RESET_ADAPT_LANES_STATE })
      }
      return () => unregister()
    })
  })

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'shipperId',
      filterOptionsGetter: AdaptLane.getShippers,
    },
  ])

  const handleChange = (setTableState) =>
    dispatch({
      type: SET_ADAPT_LANES_STATE,
      payload: setTableState(tableState),
    })

  return (
    <Page title='Adapt Lanes' ComponentTitleRight={<Beta />}>
      <Alert
        severity='error'
        sx={{
          display: 'flex',
          width: '100vw',
          fontSize: '1.5em',
          fontWeight: 'bold',
        }}
      >
        There&#39;s a newer version of this page available{' '}
        <a href='https://admin2.leaflogistics.com/adapt/lanes'>here</a>. Please note that there will
        be no further updates or fixes, use it at your own risk.
      </Alert>

      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(tableState, history)}
            columns={getColumns(tableState, filterOptions)}
            fn={AdaptLane.getAll}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  )
}

export default withRouter(AdaptLanes)
