import React, { useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import {
  Page,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
  useTableCustomFilters,
  useCopyToClipboard,
} from '@leaf/components'

import useGQL from '@/hooks/useGQL'
import { StateContext } from '@/state/StateProvider'
import { SET_OPS_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import Ops from '@/domain/planning/Ops'
import { getColumns, getOptions } from './table-config'
import { getOps, getCompanies, getEquipmentTypes } from './domain/opsPlansModel'
import ImportOpsPlansDialog from './import/ImportOpsPlansDialog'

export default withRouter(({ history }) => {
  const getGQLClient = useGQL()
  const [openImportDialog, setOpenImportDialog] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const copy = useCopyToClipboard(
    (successMessage) => enqueueSnackbar(successMessage, { variant: 'success' }),
    (errorMessage) => enqueueSnackbar(errorMessage, { variant: 'error' })
  )

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'company',
      filterOptionsGetter: () => getCompanies(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'equipmentType',
      filterOptionsGetter: () => getEquipmentTypes(getGQLClient),
    },
  ])

  const {
    state: {
      tables: { opsPlansOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  const handleChange = (setTableState) =>
    dispatch({ type: SET_OPS_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) })

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true)
  }

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false)
  }

  const handleDownloadOpsPlans = async () => {
    const { data } = await getOps(getGQLClient, tableState, true)

    Ops.downloadOpsPlans(data.map((op) => op.id))
  }

  return (
    <Page title='Ops Plans'>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(history, handleDownloadOpsPlans, handleOpenImportDialog)}
            columns={getColumns(filterOptions, copy)}
            fn={() => getOps(getGQLClient, tableState)}
            tableState={tableState}
            setTableState={handleChange}
          />
          <ImportOpsPlansDialog
            open={openImportDialog}
            handleCloseDialog={handleCloseImportDialog}
            title='IMPORT OPS PLANS'
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  )
})
