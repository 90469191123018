import { utility } from '@leaf/components';
import { getFuelPricesGQL } from './GQL_FUEL_PRICES';

const mapFuelPrices = fuelPrices =>
  fuelPrices.map(fuelPrice => ({
    id: fuelPrice.id,
    price: utility.numbers.formatNumberAsView(fuelPrice.price, 2),
    indexName: fuelPrice.index.name,
    validFrom: utility.date.backendStringToViewString(fuelPrice.validFrom),
  }));

const getOrderBy = sort => {
  if (sort) {
    return [{ [sort.field ?? sort.name]: sort.order }];
  }
  return null;
};

export const getFuelPrices = (getGQLClient, tableState) => {
  const variables = {
    sort: tableState.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
  };
  const { where, search, sort, ...rest } = variables;

  return getGQLClient().then(client => {
    const GQL = getFuelPricesGQL({
      where,
      search,
      ...rest,
    });
    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then(res => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.fuelPriceAggregate.aggregate.count,
      data: mapFuelPrices(res.fuelPrice),
    }));
  });
};
