import Address from '@/domain/location/Address'
import Contact from '@/domain/location/Contact'
import Fee from '@/domain/location/Fee'
import Requirement from '@/domain/location/Requirement'
import Shipper from '@/domain/Shipper'
import axios from 'axios'
import { utility } from '@leaf/components'
import { isEmpty, omitBy } from 'lodash'
import OverviewLocation from './OverviewLocation'

export default class Location {
  constructor({ shipper, address, contact, fee, requirement, ...response }) {
    Object.assign(this, {
      ...response,
      shipper: shipper && new Shipper(shipper),
      address:
        address && typeof address === 'object'
          ? new Address(address)
          : new Address({
              street: response.street || '-',
              city: response.city || '-',
              country: response.country || '-',
              zipCode: response.zipCode || '-',
              state: response.state || '-',
            }),
      contact: contact && new Contact(contact),
      fee: fee && new Fee(fee),
      requirement: requirement && new Requirement(requirement),
    })

    this.view = {
      id: this.id,
      businessName: response.businessName || '-',
      locationType: response.locationType || '-',
      addressName: this.address ? this.address.mapAddressLong() : '',
    }
  }

  static getAll = async (params, ClassModel = OverviewLocation) =>
    axios
      .get(`view/locations?${utility.pagination.toServerSideParams(params)}`)
      .then((response) => ({
        ...response,
        data: response.data.map((location) => new ClassModel(location)),
      }))

  static getOne = (guid) => axios.get(`jupiter/admin/locations/${guid}`)

  static getLocation(searchTerm) {
    if (!searchTerm) return Promise.resolve([])
    const q = searchTerm.replaceAll(',', '')
    return axios.get(`geo/addresses/search?query=${q}`)
  }

  static getDistance(stops) {
    return axios.get(`geo/driving-routes?stops=${stops}`).then((response) => response[0])
  }

  static getAddresses = (shipperId) =>
    axios
      .get(
        `view/locations/address-names?${new URLSearchParams(
          omitBy({ shipper_id: shipperId }, isEmpty)
        )}`
      )
      .then(
        (response) =>
          response['address-names']?.map((address) => ({
            value: address,
            label: address,
          })) ?? []
      )

  static getShippers = () =>
    axios
      .get('view/locations/shippers')
      .then(
        ({ shippers }) =>
          shippers?.map(({ shipperName }) => ({ value: shipperName, label: shipperName })) ?? []
      )

  getDetails() {
    const { businessName, address, type } = this
    return `${businessName ? `${businessName},` : ''} ${address.street || ''} ${
      type ? `(${type})` : ''
    }`
  }
}
