import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  Button,
  Typography,
  Box,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Form, UserType, UserRole, utility, theme } from '@leaf/components'
import { geoStates } from '@/constants/states'
import useGQL from '@/hooks/useGQL'
import { getUsers } from '../overview/domain/companyModel'
import { sortByField } from '@/utility/types/array'
import { onChange } from '@/utility/form'
import AdaptCompanyData from '@/domain/company/AdaptCompanyData'
import styled from '@emotion/styled'

const { Input, ErrorMessage, Datepicker, Select, Autocomplete, Switch } = Form.Library
const companyTypeOptions = [
  { label: UserType.SHIPPER, value: UserType.SHIPPER },
  { label: UserType.CARRIER, value: UserType.CARRIER },
]

interface Props {
  open: boolean
  onClose: () => void
  handleCreateCompany: any
  editCompany: any
}

const validationSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
})

interface Company {
  runDate?: Date
  windowStartDate?: Date
  windowEndDate?: Date
  hasAdapt?: boolean
  use4KitesIntegration?: boolean
  useTrimbleIntegration?: boolean
  name: string
  type: string
  transactionFee: number
  phone?: string
  registeredName?: string
  registeredState?:
    | {
        key: string
        value: string
        text: string
        content: string
        lat: number
        lng: number
        division: string
      }
    | undefined
  tenderEmailAddress?: string
  tenderPhoneNumber?: string
  accountTeamMembers?: Array<number>
  usDotNumber?: string
  usMcNumber?: string
  scacCode?: string
  useProject44Integration?: boolean
}

const ButtonGroup = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5px;
`

export default ({ onClose, open, handleCreateCompany, editCompany }: Props) => {
  const getGQLClient = useGQL()
  const [leAdmins, setLeAdmins] = React.useState([])
  const [areUsDotNumberAndUsMcNumberPopulated, setAreUsDotNumberAndUsMcNumberPopulated] =
    React.useState(false)

  const leAdminsSorted = leAdmins?.sort(sortByField('firstName'))

  const getInitialFormData = () => {
    return {
      name: editCompany?.name,
      type: editCompany ? editCompany.type : UserType.SHIPPER,
      transactionFee: editCompany?.transactionFee,
      phone: editCompany?.phone,
      registeredName: editCompany?.registeredName,
      registeredState: geoStates.find((state) =>
        state.content.includes(editCompany?.registeredState)
      ),
      tenderEmailAddress: editCompany?.tenderEmailAddress,
      tenderPhoneNumber: editCompany?.tenderPhoneNumber,
      accountTeamMembers: editCompany?.accountTeamMembers?.map((teamMember: { user: any }) => ({
        ...teamMember.user,
      })),
      ...(editCompany?.type === UserType.SHIPPER
        ? {
            runDate: editCompany?.runDate,
            windowStartDate: editCompany?.windowStartDate,
            windowEndDate: editCompany?.windowEndDate,
            hasAdapt: editCompany?.hasAdapt,
            use4KitesIntegration: editCompany?.use4KitesIntegration,
            useTrimbleIntegration: editCompany?.useTrimbleIntegration,
          }
        : {
            scacCode: editCompany?.scacCode,
            usDotNumber: editCompany?.usDotNumber,
            usMcNumber: editCompany?.usMcNumber,
            useProject44Integration: editCompany?.useProject44Integration,
          }),
    }
  }

  const { handleSubmit, control, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getInitialFormData(),
  })
  const usDotNumberWatch = watch('usDotNumber')
  const usMcNumberWatch = watch('usMcNumber')

  React.useEffect(() => {
    getUsers(getGQLClient, UserRole.LE_ADMIN).then((resp: any) => {
      setLeAdmins(resp)
    })
  }, [])

  React.useEffect(() => {
    if (editCompany) {
      reset(getInitialFormData())
    }
  }, [editCompany])

  React.useEffect(() => {
    setAreUsDotNumberAndUsMcNumberPopulated(Boolean(usDotNumberWatch && usMcNumberWatch))
    setValue('useProject44Integration', Boolean(usDotNumberWatch && usMcNumberWatch))
  }, [usDotNumberWatch, usMcNumberWatch])

  const handleCompanySave = (values: Company) => {
    const runDate = utility.date.transformApiDate(values?.runDate)
    const windowStartDate = utility.date.transformApiDate(values?.windowStartDate)
    const windowEndDate = utility.date.transformApiDate(values?.windowEndDate)
    const adaptData = {
      runDate,
      windowStartDate,
      windowEndDate,
      hasAdapt: editCompany ? values.hasAdapt : false,
    }

    if (editCompany?.id) {
      AdaptCompanyData.write(editCompany?.id, adaptData)
    }

    handleCreateCompany({ ...values }, adaptData)
  }

  const getGridItem = (size = 12, children: JSX.Element) => (
    <Grid item xs={size}>
      <FormControl fullWidth>{children}</FormControl>
    </Grid>
  )

  return (
    <Dialog onClose={onClose} open={open} maxWidth='md' fullWidth>
      <DialogTitle>Company</DialogTitle>
      <form
        onSubmit={handleSubmit((data) => {
          handleCompanySave(data)
        })}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input name='name' label='Name' control={control} error={errors.name} />
                <ErrorMessage errors={errors} name='name' />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  name='type'
                  label='Type'
                  options={companyTypeOptions.map((company) => ({
                    label: company.label,
                    id: company.value,
                  }))}
                  control={control}
                  error={errors.type}
                  disabled={editCompany}
                />
                <ErrorMessage errors={errors} name='type' />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input
                  name='transactionFee'
                  label='Transaction fee [%]'
                  type='number'
                  control={control}
                  error={errors.transactionFee}
                />
                <ErrorMessage errors={errors} name='transactionFee' />
              </FormControl>
            </Grid>
            {getGridItem(6, <Input name='phone' label='Phone' control={control} />)}
            {getGridItem(
              6,
              <Input name='registeredName' label='Registered name' control={control} />
            )}
            {getGridItem(
              6,
              <Autocomplete
                name='registeredState'
                label='Registered state'
                options={geoStates}
                getOptionLabel={(option: { content: string }) => option.content}
                control={control}
              />
            )}
            {getGridItem(
              6,
              <Input name='tenderEmailAddress' label='Tender E-mail address' control={control} />
            )}
            {getGridItem(
              6,
              <Input name='tenderPhoneNumber' label='Tender Phone number' control={control} />
            )}
            {getGridItem(
              12,
              <Autocomplete
                name='accountTeamMembers'
                label='Account team members'
                options={leAdminsSorted}
                getOptionLabel={(option: { firstName: string; lastName: string }) =>
                  `${option.firstName} ${option.lastName}`
                }
                onChange={(_: any, data: any) => setValue('accountTeamMembers', data)}
                control={control}
                multiple
              />
            )}

            {editCompany?.type === UserType.SHIPPER && (
              <>
                <Grid item xs={12}>
                  <Typography variant='h5'>Shipper-specific fields</Typography>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                  {getGridItem(4, <Datepicker name='runDate' label='Run date' control={control} />)}
                  {getGridItem(
                    4,
                    <Datepicker
                      name='windowStartDate'
                      label='Window start date'
                      control={control}
                    />
                  )}
                  {getGridItem(
                    4,
                    <Datepicker name='windowEndDate' label='Window end date' control={control} />
                  )}
                </Grid>

                {getGridItem(12, <Switch name='hasAdapt' label='Adapt' control={control} />)}
                {getGridItem(
                  12,
                  <Switch
                    name='use4KitesIntegration'
                    label='4Kites integration'
                    control={control}
                  />
                )}
                {getGridItem(
                  12,
                  <Switch
                    name='useTrimbleIntegration'
                    label='Trimble integration'
                    control={control}
                  />
                )}
              </>
            )}
            {editCompany?.type === UserType.CARRIER && (
              <>
                <Grid item xs={12}>
                  <Typography variant='h5'>Carrier-specific fields</Typography>
                </Grid>

                {getGridItem(12, <Input name='scacCode' label='SCAC code' control={control} />)}
                {getGridItem(6, <Input name='usDotNumber' label='DOT number' control={control} />)}
                {getGridItem(6, <Input name='usMcNumber' label='MC number' control={control} />)}
                {getGridItem(
                  12,
                  <Switch
                    name='useProject44Integration'
                    label='Project44 integration'
                    disabled={!areUsDotNumberAndUsMcNumberPopulated}
                    control={control}
                  />
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button color='secondary' variant='contained' onClick={onClose}>
              Cancel
            </Button>
            <Button color='primary' type='submit' variant='contained'>
              Save
            </Button>
          </ButtonGroup>
        </DialogActions>
      </form>
    </Dialog>
  )
}
