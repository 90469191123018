/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Map as MapContainer, TileLayer, Marker, Polygon } from 'react-leaflet'
import leaflet from 'leaflet'
import * as h3 from 'h3-js'
import theme from '../theme'

const Map = styled(MapContainer)`
  height: 100%;
  position: relative;
`

const HEX_RESOLUTION = 5
const HEX_SELECTION_COLOR = theme.palette.primary.main
const HEX_COLOR = theme.palette.disabled
const HEX_REMOVED_COLOR = 'red'
const MAIN_HEX_COLOR = '#476587'
const H3_BOUNDS_THRESHOLD = 5
const H3_CELL_SELECTION_ZOOM_TRIGGER = 10

const generateInitialCellsMeta = (cells) =>
  cells?.map((cell) => ({
    value: cell.value ?? cell,
    isPreselected: true,
    isNew: cell.isNew,
    isDeleted: cell.isDeleted,
  })) ?? []

const getSelectedCellValues = (cells) => cells.map(({ value }) => value)

const getBoundaryCells = ({ map, selectedHexCells }) => {
  let cellIds
  if (map) {
    const currentZoomLevel = map.getZoom()
    if (currentZoomLevel >= H3_CELL_SELECTION_ZOOM_TRIGGER) {
      const { lat, lng } = map.getBounds().getCenter()
      const mainHex = h3.latLngToCell(lat, lng, HEX_RESOLUTION)
      const hexCellsDisk = h3.gridDisk(mainHex, H3_BOUNDS_THRESHOLD)
      cellIds = hexCellsDisk.concat(
        getSelectedCellValues(selectedHexCells).filter((cell) => !hexCellsDisk.includes(cell))
      )
    } else {
      cellIds = getSelectedCellValues(selectedHexCells)
    }
  }
  return cellIds?.map((cellId) => h3.cellToBoundary(cellId, false))
}

const H3Polygon = ({ polygon, hexCell, selectedHexCells, setSelectedHexCells }) => {
  const onClickHandler = useCallback(
    (e) => {
      const { lat, lng } = e.latlng
      const hex = h3.latLngToCell(lat, lng, HEX_RESOLUTION)

      const hexMeta = selectedHexCells.find((cell) => cell.value === hex)

      if (!hexMeta) {
        e.target.setStyle({ color: HEX_SELECTION_COLOR })
        setSelectedHexCells((prevState) => [...prevState, { value: hex, isNew: true }])
      } else if (hexMeta.isDeleted) {
        e.target.setStyle({ color: MAIN_HEX_COLOR })
        setSelectedHexCells((prevState) => [
          ...prevState.filter(({ value }) => value !== hex),
          { value: hex, isPreselected: true, isDeleted: false },
        ])
      } else if (hexMeta.isNew) {
        e.target.setStyle({ color: HEX_COLOR })
        setSelectedHexCells((prevState) => prevState.filter(({ value }) => value !== hex))
      } else if (hexMeta.isPreselected) {
        e.target.setStyle({ color: HEX_REMOVED_COLOR })
        setSelectedHexCells((prevState) => [
          ...prevState.filter(({ value }) => value !== hex),
          { value: hex, isPreselected: true, isDeleted: true },
        ])
      }
    },
    [selectedHexCells, setSelectedHexCells]
  )

  const hexMeta = selectedHexCells.find((cell) => cell.value === hexCell)
  const style = { opacity: 0.2, fillOpacity: 0.1, color: HEX_COLOR }
  if (!hexMeta) {
    style.fillColor = HEX_COLOR
  } else if (hexMeta.isDeleted) {
    style.fillColor = HEX_REMOVED_COLOR
  } else if (hexMeta.isNew) {
    style.fillColor = HEX_SELECTION_COLOR
    style.fillOpacity = 0.3
  } else if (hexMeta.isPreselected) {
    style.fillColor = MAIN_HEX_COLOR
    style.fillOpacity = 0.4
  }

  return (
    <Polygon
      {...style}
      onclick={onClickHandler}
      positions={polygon}
      onmouseover={(e) => e.target.setStyle({ color: HEX_SELECTION_COLOR, opacity: 0.3 })}
      onmouseout={(e) => e.target.setStyle({ color: HEX_COLOR, opacity: 0.2 })}
    />
  )
}

const H3CellPicker = ({
  preselectedH3s,
  h3CellsToSelect,
  onH3CellSelect,
  mapRef,
  setMarkerLocation,
  markerLocation,
  setHasMultipleHexGroups,
  setIsPointWithinHexes,
}) => {
  const [selectedHexCells, setSelectedHexCells] = useState(generateInitialCellsMeta(preselectedH3s))
  const [boundaryCells, setBoundaryCells] = useState([])

  useEffect(() => {
    const hexes = getSelectedCellValues(selectedHexCells.filter(({ isDeleted }) => !isDeleted))
    const selectedPolygons = h3.cellsToMultiPolygon([...new Set(hexes)], true)
    onH3CellSelect?.({ h3: selectedHexCells, geo: selectedPolygons })
    if (setHasMultipleHexGroups) {
      // if polygons.length is greated than one means there are multiple hex groups which are seperated
      if (selectedPolygons.length > 1) {
        return setHasMultipleHexGroups(true)
      }
      return setHasMultipleHexGroups(false)
    }
    return undefined
  }, [onH3CellSelect, selectedHexCells, setHasMultipleHexGroups])

  useEffect(() => {
    if (markerLocation && selectedHexCells?.length) {
      let surroundingHexFound = false
      const [lat, lng] = markerLocation
      const hexSurroundingPoint = h3.latLngToCell(lat, lng, HEX_RESOLUTION)
      for (let i = 0; i < selectedHexCells.length; i += 1) {
        const hex = selectedHexCells[i]
        if (!hex.isDeleted && hexSurroundingPoint === hex.value) {
          surroundingHexFound = true
          break
        }
      }
      setIsPointWithinHexes(surroundingHexFound)
    }
  }, [markerLocation, selectedHexCells, setIsPointWithinHexes])

  useEffect(() => {
    if (selectedHexCells?.length === 1 && !markerLocation) {
      const [lat, lng] = h3.cellToLatLng(selectedHexCells[0].value)
      setMarkerLocation([lng, lat])
    }
    const map = mapRef.current.leafletElement
    const onMoveEndHandler = () => {
      setBoundaryCells(
        getBoundaryCells({
          map,
          selectedHexCells,
        })
      )
    }
    if (map) {
      onMoveEndHandler()
      map.on('moveend', onMoveEndHandler)
    }
    return () => {
      map.off('moveend', onMoveEndHandler)
    }
  }, [mapRef, selectedHexCells, setMarkerLocation, markerLocation])

  useEffect(() => {
    if (h3CellsToSelect?.length > 0) {
      const map = mapRef.current?.leafletElement
      const [hexLat, hexLng] = h3.cellToLatLng(h3CellsToSelect[0])
      map.panTo(new leaflet.LatLng(hexLat, hexLng))
      setMarkerLocation((prevState) => {
        if (!prevState) {
          return [hexLng, hexLat]
        }
        return prevState
      })
      setSelectedHexCells((prevState) => [
        ...prevState.filter(({ value }) => !h3CellsToSelect.includes(value)),
        ...h3CellsToSelect.map((cell) => ({ value: cell, isNew: true })),
      ])
      Object.keys(map._layers).forEach((key) => {
        const { _latlngs } = map._layers[key]
        const position = _latlngs?.[0].map((lngs) => {
          const { lat, lng } = lngs || {}
          return [lat, lng]
        })
        if (position) {
          const hex = h3.polygonToCells(position, 5)?.[0]
          if (!preselectedH3s?.includes(hex) && h3CellsToSelect.find((cell) => hex === cell)) {
            map._layers[key].setStyle({ color: HEX_SELECTION_COLOR })
          }
        }
      })
    }
  }, [h3CellsToSelect, mapRef, preselectedH3s, setMarkerLocation])

  return boundaryCells.map((polygon) => {
    const [hex] = h3.polygonToCells(polygon, HEX_RESOLUTION)
    return (
      <div key={`${hex}`}>
        <H3Polygon
          polygon={polygon}
          hexCell={hex}
          preselectedH3s={preselectedH3s}
          selectedHexCells={selectedHexCells}
          setSelectedHexCells={setSelectedHexCells}
        />
      </div>
    )
  })
}

export default ({
  preselectedH3s,
  coordinates,
  h3CellsToSelect,
  onMarkerSet,
  onH3CellSelect,
  setHasMultipleHexGroups,
  setIsPointWithinHexes,
}) => {
  const mapRef = useRef()

  const [lng, lat] = coordinates ?? []
  const hasMarker = !!lat && !!lng
  const [markerLocation, setMarkerLocation] = useState(hasMarker ? [lng, lat] : undefined)

  useEffect(() => {
    onMarkerSet?.(markerLocation)
  }, [onMarkerSet, markerLocation])

  useEffect(() => {
    if (hasMarker) {
      mapRef.current?.leafletElement.panTo(new leaflet.LatLng(lat, lng))
    }
  }, [lat, lng, hasMarker])

  const options = useMemo(
    () => ({
      center: [39.5, -98.35],
      maxBoundsViscosity: 1.0,
      maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
      zoom: 10,
      minZoom: 7,
      maxZoom: 12,
      attributionControl: false,
    }),
    []
  )

  const onMarkerChangeAddHandler = useCallback((e) => {
    setMarkerLocation([e.target.getLatLng().lng, e.target.getLatLng().lat])
  }, [])

  const onMarkerChangeDragHandler = useCallback((e) => {
    setMarkerLocation([e.target.getLatLng().lat, e.target.getLatLng().lng])
  }, [])

  return (
    <Map ref={mapRef} {...options}>
      {mapRef.current?.leafletElement._events.moveend?.length}
      <H3CellPicker
        preselectedH3s={preselectedH3s}
        h3CellsToSelect={h3CellsToSelect}
        coordinates={coordinates}
        onH3CellSelect={onH3CellSelect}
        mapRef={mapRef}
        setMarkerLocation={setMarkerLocation}
        markerLocation={markerLocation}
        setHasMultipleHexGroups={setHasMultipleHexGroups}
        setIsPointWithinHexes={setIsPointWithinHexes}
      />
      <TileLayer url='https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibG9naXN0aWNzZXhjaGFuZ2UiLCJhIjoiY2prbDB4cHJ2MXdldTNwbWwxdHNuaWFoYyJ9.6jlvQU7fxCoDDYwS9C6uVQ' />
      {markerLocation && (
        <Marker
          position={markerLocation}
          draggable
          onadd={onMarkerChangeAddHandler}
          ondragend={onMarkerChangeDragHandler}
        />
      )}
    </Map>
  )
}
