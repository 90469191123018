/**
 * Prevent "Enter" key from submitting a form.
 * One must add an keyDown listerner in the form
 * e.g.: onKeyDown={e => disableEnterKey(e)}
 * @param {KeyboardEvent} event
 */
export const disableEnterKey = event => {
  // exxcept when typing in a textara
  if (event.target.type.toLowerCase() === 'textarea') {
    return;
  }
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};
