/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import styled from '@emotion/styled'
import { Map as MapContainer, TileLayer, GeoJSON, Marker } from 'react-leaflet'
import leaflet from 'leaflet'
import { v4 } from 'uuid'
import MapMarkerNumerated from '../MapMarkerNumerated'
import { calculateLaneColor, getMarkerClass } from '../PolylineMap/mapUtils'
import { getRouteOverviewColor } from '../constants'

const magic = {
  origin: {
    opacity: 1,
    fillOpacity: 0.6,
    weight: 1,
  },
  destination: {
    opacity: 0.6,
    fillOpacity: 0.2,
    weight: 1,
  },
  path: {
    opacity: 1,
    fillOpacity: 1,
    weight: 4,
  },
}

const Map = styled(MapContainer)`
  height: 100%;
  position: relative;
`

const LegendContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 1%;
`

export default ({ data, markers = [], shouldHideNummeratedMarker = false, legendComponent }) => {
  let bounds = null
  if (data?.length > 0) {
    const geoData = data
      .filter((item) => !!item.pathGeo || !!item.originGeo || item.destinationGeo)
      .map((item) => item.pathGeo || item.originGeo || item.destinationGeo)
    const geoJson = Array.isArray(geoData)
      ? {
          type: 'FeatureCollection',
          features: geoData,
        }
      : geoData
    const featureMap = leaflet.geoJSON(geoJson)
    bounds = featureMap.getBounds()
    if (!bounds.isValid()) {
      // default bounds
      bounds = leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200))
    }
  }

  const options = {
    center: [39.5, -98.35],
    maxBoundsViscosity: 1.0,
    maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
    bounds,
    zoom: 4,
    minZoom: 2,
    maxZoom: 12,
    attributionControl: false,
  }

  const getIconItem = (index, iconNumber) =>
    leaflet.divIcon({
      className: getMarkerClass(index),
      html: ReactDOMServer.renderToString(
        <MapMarkerNumerated
          markerNumber={iconNumber}
          color={calculateLaneColor(index)}
          iconWidth={32}
          iconHeight={32}
        />
      ),
    })

  const getMarkerPosition = (polygonGeo) => {
    if (!polygonGeo) {
      return undefined
    }
    const featureMap = leaflet.geoJSON(polygonGeo)
    const markerPosition = featureMap.getBounds().getCenter()

    return markerPosition
  }

  const getLaneColor = (hover, laneIndex) => {
    if (data.every((item) => !item.hover)) {
      return getRouteOverviewColor(laneIndex)
    }
    if (hover) return getRouteOverviewColor(laneIndex)

    return '#e0e0e0'
  }

  return (
    <Map {...options}>
      <TileLayer url='https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibG9naXN0aWNzZXhjaGFuZ2UiLCJhIjoiY2prbDB4cHJ2MXdldTNwbWwxdHNuaWFoYyJ9.6jlvQU7fxCoDDYwS9C6uVQ' />
      {data &&
        data.map((item, index) => (
          <React.Fragment key={v4()}>
            {item.originGeo && (
              <>
                <GeoJSON
                  key={v4()}
                  data={item.originGeo}
                  color={item.originColor ?? getRouteOverviewColor(index)}
                  opacity={magic.origin.opacity}
                  fillColor={item.originColor ?? getRouteOverviewColor(index)}
                  fillOpacity={magic.origin.fillOpacity}
                  weight={magic.origin.weight}
                />
                {!shouldHideNummeratedMarker && (
                  <Marker
                    position={getMarkerPosition(item.originGeo)}
                    icon={getIconItem(index, index * 2 + 1)}
                  />
                )}
              </>
            )}
            {item.destinationGeo && (
              <>
                <GeoJSON
                  key={v4()}
                  data={item.destinationGeo}
                  color={item.destinationColor ?? getRouteOverviewColor(index)}
                  opacity={magic.destination.opacity}
                  fillColor={item.destinationColor ?? getRouteOverviewColor(index)}
                  fillOpacity={magic.destination.fillOpacity}
                  weight={magic.destination.weight}
                />
                {!shouldHideNummeratedMarker && (
                  <Marker
                    position={getMarkerPosition(item.destinationGeo)}
                    icon={getIconItem(index, index * 2 + 2)}
                  />
                )}
              </>
            )}
            <GeoJSON
              key={v4()}
              data={item.pathGeo}
              color={item.pathColor ?? getLaneColor(item.hover, item.routeIndex ?? index)}
              opacity={magic.path.opacity}
              fillColor={item.pathColor ?? getLaneColor(item.hover, item.routeIndex ?? index)}
              fillOpacity={magic.path.fillOpacity}
              weight={magic.path.weight}
            />
          </React.Fragment>
        ))}
      {markers.map((marker, i) => (
        <Marker position={marker} key={i} />
      ))}
      {legendComponent && <LegendContainer>{legendComponent}</LegendContainer>}
    </Map>
  )
}
