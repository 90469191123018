/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const CustomDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomDialog({
  title,
  cancelCopy = 'No',
  acceptCopy = 'Yes',
  open,
  handleClose,
  children,
  defaultActions = true,
  ...rest
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      {...rest}
    >
      <CustomDialogTitle id="confirm-dialog-title" onClose={handleClose}>
        {title}
      </CustomDialogTitle>
      <DialogContent dividers sx={{ p: 0 }} id="confirm-dialog-description">
        {children}
      </DialogContent>
      {defaultActions && (
        <DialogActions sx={{ p: 2 }}>
          <Button variant="outlined" onClick={handleClose}>
            {cancelCopy}
          </Button>
          <Button variant="contained" onClick={handleClose} autoFocus data-accept>
            {acceptCopy}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
