import { useCallback, useState } from 'react';

const useLoading = (action, initial = false) => {
  const [loading, setLoading] = useState(initial);
  const doAction = useCallback(
    (...args) => {
      setLoading(true);
      return action(...args).finally(() => setLoading(false));
    },
    [action],
  );

  return [doAction, loading];
};

export default useLoading;
