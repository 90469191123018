/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react'
import { Grid, Typography, Box, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { triggerSubmitEvent } from '@/utility/form'

/**
 * It generates input for weekdays and stores the values
 * into a single hidden input field as "MONDAY=3,WEDNESDAY=1,SATURDAY=2".
 * The form must be wrapped by "FormProvider" since this component
 * relies on the form context (useFormContext).
 *
 * NOTE: the formRef is required when on edit mode update
 * the form values in memory.
 *
 * @param {string} label optional default to "Day Of Flex Volume"
 * @param {string} name the name of the input field defaults to "volumePattern"
 * @param {string} defaultValue the default values
 * @param {boolean} disabled whether inputs are disabled or not
 * @param {boolean} required whether inputs are required or not
 * @param {React.useRef} formRef optional reference to form to trigger a submit as needed.
 * @returns {JSX} days of the weeks as input fields
 */
export const WeekDays = ({
  label = 'Day Of Flex Volume',
  name: inputName = 'volumePattern',
  defaultValue = '',
  required,
  disabled,
  formRef,
}) => {
  const { control, register, setValue, clearErrors, errors, trigger } = useFormContext()
  const [emptyDays, setEmptyDays] = useState(defaultValue === null || defaultValue === '')
  const trackAllDaysChanged = useRef({})
  const firstRender = useRef(true)
  const weekdaysLabel = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  const weekdaysMap = {
    SUNDAY: '',
    MONDAY: '',
    TUESDAY: '',
    WEDNESDAY: '',
    THURSDAY: '',
    FRIDAY: '',
    SATURDAY: '',
  }

  if (firstRender.current) {
    if (defaultValue) {
      defaultValue
        .replace(/ /g, '')
        .split(',')
        .forEach((day) => {
          const [currentDay, currentValue] = day.split('=')
          weekdaysMap[currentDay] = currentValue
          trackAllDaysChanged.current[currentDay] = `${currentDay}=${currentValue}`
        })
    }
    firstRender.current = false
  }

  const arrDays = Object.entries(weekdaysMap)

  const clearDayErrors = () => {
    arrDays.map((day) => clearErrors(day[0]))
  }
  const handleBlur = (e) => {
    const { value, name } = e.target
    trackAllDaysChanged.current[name] = `${name.toUpperCase()}=${value}`

    const stringifyValues = Object.values(trackAllDaysChanged.current).reduce((acc, current) => {
      const hasValue = current.split('=')[1] !== ''
      let out = ''
      if (acc === '' && hasValue) {
        out = `${current}`
      } else if (current !== '' && hasValue) {
        out = `${acc},${current}`
      } else {
        out = `${acc}`
      }
      return out
    }, '')
    setValue(inputName, stringifyValues)
    triggerSubmitEvent(formRef)

    if (stringifyValues !== '') {
      setEmptyDays(false)
      clearDayErrors()
    } else {
      setEmptyDays(true)
    }
  }

  const stillRequired = (day) => {
    if (required && emptyDays) {
      return true
    }
    trigger(day)
    return false
  }

  if (!required && Object.keys(errors).length === 7 && emptyDays) {
    clearDayErrors()
  }
  return (
    <Box>
      <Typography variant='h6' sx={{ mt: '4px' }}>
        {label} {!disabled ? '*' : null}
      </Typography>
      <input name={inputName} type='hidden' ref={register} />
      <Grid container gap={1} sx={{ margin: '36px 0 0 0' }}>
        {arrDays.map(([day, value], index) => (
          <Grid item xs key={day}>
            <Controller
              name={day}
              defaultValue={value}
              rules={{ required: stillRequired(day) }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label={weekdaysLabel[index]}
                  value={props.value}
                  type='text'
                  variant='outlined'
                  fullWidth
                  disabled={disabled}
                  onChange={props.onChange}
                  onBlur={handleBlur}
                  error={!!errors[day]}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
