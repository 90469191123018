import React from 'react'
import { FormControl, FormControlLabel, Checkbox } from '@mui/material'
import styled from '@emotion/styled'
import { v4 } from 'uuid'
import { format } from 'date-fns'

import { utility, Lane, LocationFilter } from '@leaf/components'
import AdaptLane from '@/domain/adapt/AdaptLane'

const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`

const WiderFormControl = styled(FormControl)`
  width: 256px;
`

const HasExplorerFilter = (filterList, onChange, index, column) => {
  // eslint-disable-next-line react/destructuring-assignment
  const isChecked = filterList[index][0]
  const handleChange = (event) => {
    const value = event.target.checked ? [true] : []
    onChange(value, index, column)
  }
  return (
    <WiderFormControl>
      <FormControlLabel
        label='Only with explorer'
        control={<Checkbox checked={isChecked} onChange={handleChange} />}
      />
    </WiderFormControl>
  )
}

const getColumns = (tableState, filterOptions) => {
  const hasExplorerFilterActive = !!tableState.filters?.hasExplorer?.includes(true)

  // Before modifying anything here think about the side-effects.
  const columns = []

  // ALWAYS INDEX 0
  columns.push({
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      display: tableState.display.id,
    },
  })

  // Now comes the shipper data
  columns.push(
    // INDEX 1
    {
      label: 'Shipper name',
      name: 'view.shipperName',
      options: {
        filter: false,
        display: tableState.display['view.shipperName'],
      },
    },
    // ALWAYS INDEX 2
    {
      label: 'Shipper ID',
      name: 'shipperId',
      options: {
        filter: false,
        ...filterOptions.shipperId,
        display: tableState.display.shipperId,
      },
    }
  )

  // Optional INDEX 3 AND 4
  columns.push(
    {
      label: 'Parent Powerlane ID',
      name: 'view.parentId',
      field: 'parentId',
      options: {
        filter: false,
        display: hasExplorerFilterActive ? 'excluded' : tableState.display['view.parentId'],
      },
    },
    {
      label: 'Type',
      name: 'laneType',
      options: {
        filterOptions: {
          names: ['PTP', 'POWER'],
        },
        display: hasExplorerFilterActive ? 'excluded' : tableState.display.laneType,
      },
    }
  )

  // Rest of the columns
  columns.push(
    {
      label: 'Lane',
      name: 'view.lanes',
      options: {
        filter: false,
        customBodyRender: (lanes) =>
          lanes?.map(({ origin, destination, laneType, shipper }) => (
            <LaneWrapper key={v4()} isSingleRow={lanes.length === 1}>
              <Lane
                displayAsGrid
                type={laneType}
                origin={{ primaryContent: [origin] }}
                destination={{ primaryContent: [destination] }}
                shipper={shipper}
              />
            </LaneWrapper>
          )),
      },
    },
    {
      label: 'Leaf Miles',
      name: 'view.leafMiles',
      field: 'leafMiles',
      options: {
        filter: false,
        ...utility.table.rightAlignColumnProps(),
        display: tableState.display['view.leafMiles'],
      },
    },
    {
      label: 'Shipper Miles',
      name: 'view.companyMiles',
      field: 'companyMiles',
      options: {
        filter: false,
        ...utility.table.rightAlignColumnProps(),
        display: tableState.display['view.companyMiles'],
      },
    },
    {
      label: 'Equipment Classes',
      name: 'view.equipmentClasses',
      options: {
        filter: false,
        sort: false,
        display: tableState.display['view.equipmentClasses'],
      },
    },
    {
      label: 'Total annualized spend',
      name: 'view.totalSpend',
      field: 'totalSpend',
      options: {
        filter: false,
        ...utility.table.rightAlignColumnProps(),
        display: tableState.display['view.totalSpend'],
      },
    },
    {
      label: 'Total annualized shipments',
      name: 'view.totalShipments',
      field: 'totalShipments',
      options: {
        filter: false,
        ...utility.table.rightAlignColumnProps(),
        display: tableState.display['view.totalShipments'],
      },
    },
    {
      label: 'First batch seen',
      name: 'batchDate',
      options: {
        filter: false,
        sortCompare: utility.table.compareDates,
        display: tableState.display.batchDate,
      },
    },
    {
      label: 'Last batch seen',
      name: 'batchDateLatest',
      options: {
        filter: false,
        sortCompare: utility.table.compareDates,
        display: tableState.display.batchDateLatest,
      },
    },
    {
      label: 'Explorer period',
      name: 'view.period',
      options: {
        filter: false,
        sort: false,
        display: tableState.display['view.period'],
      },
    },
    {
      label: 'Transit Time (hours)',
      name: 'view.leafTransitTime',
      field: 'leafTransitTime',
      options: {
        filter: false,
        ...utility.table.rightAlignColumnProps(),
        display: tableState.display['view.leafTransitTime'],
      },
    },
    {
      label: 'Actions',
      name: 'foo',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (_, tableMeta) => {
          const hasExplorer = tableMeta.rowData[tableMeta.rowData.length - 1]
          if (!hasExplorer) {
            return null
          }
          const laneId = tableMeta.rowData[0]
          const equipmentClass = tableMeta.rowData[8]?.toUpperCase()
          const batchDate = format(new Date(tableMeta.rowData[12]), 'yyyy-MM-dd')

          if (!laneId || !equipmentClass || !batchDate) {
            return null
          }
          return (
            <a
              href={`https://admin2.leaflogistics.com/lane-explorer?id=${laneId}&batchDate=${batchDate}&equipmentClass=${equipmentClass.toUpperCase()}`}
            >
              Explorer
            </a>
          )
        },
      },
    },
    {
      label: 'Origin',
      name: 'origin',
      field: 'origin_radius',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v?.length === 4) return `Origin: ${v[3]} (${v[2]} miles)`

            return undefined
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <LocationFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              getLocations={AdaptLane.getLocation}
            />
          ),
        },
      },
    },
    {
      label: 'Destination',
      name: 'destination',
      field: 'destination_radius',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v?.length === 4) return `Destination: ${v[3]} (${v[2]} miles)`

            return undefined
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <LocationFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              getLocations={AdaptLane.getLocation}
            />
          ),
        },
      },
    }
  )

  // ALWAYS LAST INDEX
  columns.push({
    name: 'hasExplorer',
    options: {
      sort: false,
      display: 'excluded',
      filterType: 'custom',
      filterList: tableState.filters.hasExplorer,
      customFilterListOptions: {
        render: () => 'Only with explorer',
      },
      filterOptions: {
        display: HasExplorerFilter,
      },
    },
  })

  return columns
}

export default getColumns
