import { useState } from 'react'

export function useSteps(steps) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  function next() {
    setCurrentStepIndex((prevIndex) => {
      if (prevIndex >= steps.length - 1) return prevIndex
      return prevIndex + 1
    })
  }
  function back() {
    setCurrentStepIndex((prevIndex) => {
      if (prevIndex <= 0) return prevIndex
      return prevIndex - 1
    })
  }
  function goTo(index) {
    setCurrentStepIndex(index)
  }
  return {
    currentStepIndex,
    StepView: steps[currentStepIndex].component,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
  }
}
