const createShadow = opacity => `0 0 8px  rgba(37, 40, 43, ${opacity})`;

// MUI expacts 25 shadows to be provided
const shadows = [
  'none',
  createShadow(0.07),
  createShadow(0.12),
  createShadow(0.22),
  createShadow(0.32),
  createShadow(0.42),
  ...Array(19).fill('none'),
];

export default shadows;
