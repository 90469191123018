/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { StateMachineProvider, createStore } from 'little-state-machine'
import { useGQLClientWithAuth } from '@/hooks/useGQL'
import { useSnackbar } from 'notistack'
import {
  getContractById,
  useContractOptions,
  setDataForContractFormView,
} from './domain/contractModel'
import { defaultValues } from './domain/stateMachine'
import { Notice } from './partials/Notice'
import { NewContractFlow } from './NewContractFlow'

export default withRouter((props) => {
  const { history, match, location } = props
  const { enqueueSnackbar } = useSnackbar()

  const editContractId = match.params.id
  const duplicateContract = location.search.toLowerCase().includes('duplicate')
  const [existingContractValues, setExistingContractValues] = useState()

  const [secureClient] = useGQLClientWithAuth()
  const [contractOptions, loading, error] = useContractOptions()
  const [contractRouteCount, setContractRouteCount] = useState(0)

  useEffect(() => {
    if (editContractId && secureClient && contractOptions) {
      getContractById(secureClient, { _eq: editContractId }).then(({ contract }) => {
        setContractRouteCount(contract[0].contractRoutes.length)
        // remove contract id if this is a duplication
        const purgeData = duplicateContract ? { ...contract[0], id: null } : contract[0]
        const viewData = setDataForContractFormView(purgeData, contractOptions)
        setExistingContractValues(viewData)
      })
    }
  }, [editContractId, secureClient, contractOptions, duplicateContract])

  createStore({
    newContract: editContractId ? existingContractValues : defaultValues,
    newEditRoutes: [],
    deleteRoutes: [],
    isContractDirty: false,
    isGeneralStepValid: false,
    isTimeframeVolumeStepValid: false,
    isPricingStepValid: false,
    dedicatedDomicile: existingContractValues?.dedicatedDomiciles[0],
    CONTRACT_FORM_OPTIONS: contractOptions,
    LANE_OPTIONS: [],
    ROUTE_COUNT: contractRouteCount,
  })

  useEffect(() => {
    const navigateAway = history.listen((historyLocation) => {
      // clear store if user navigates aways from contract flow
      if (!historyLocation.pathname.includes('route')) {
        sessionStorage.clear()
      }
    })

    return () => {
      navigateAway()
    }
  }, [history])

  if (loading) {
    return <Notice />
  }
  if (error) {
    enqueueSnackbar(error)
  }

  const readyToRender = (editContractId && existingContractValues) || !editContractId
  if (!readyToRender) {
    return null
  }

  return (
    <StateMachineProvider>{readyToRender && <NewContractFlow {...props} />}</StateMachineProvider>
  )
})
