import { gql } from 'graphql-request'

export const GQL_CONTRACT_DEFAULT = gql`
  query getContractDefaults {
    equipment: old_core_equipment_type {
      id
      label: name
    }
    contractParties: company {
      label: name
      id
      type: company_type
    }
    accessorial {
      id
      label: name
    }
    fuelSurchargeSchedule: fuel_surcharge_schedule {
      id
      name
      companyId: company_id
      equipmentTypeId: equipment_type_id
    }
  }
`
