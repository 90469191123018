/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import GenericInput from '../generic/Input';

export default ({ name, control, error, controllerProps, ...rest }) => (
  <Controller
    as={<GenericInput rowsMin={4} variant="outlined" error={error} {...rest} />}
    name={name}
    control={control}
    {...controllerProps}
  />
);
