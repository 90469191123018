import { gql } from 'graphql-request';

export const getUsersByRoleGQL = role => gql`
  query getUsersByRole {
    user(where: { role: { _eq: "${role}" } }) {
      id
      firstName: first_name
      lastName: last_name
    }
  }
`;
