import React, { useContext, useState } from 'react'
import { useSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

import {
  Page,
  ServerSideTable,
  useTableCustomFilters,
  PageContentLayout,
  PageContainer,
  CONTRACT_TYPE,
  CONTRACT_TYPE_DISPLAY_NAMES,
} from '@leaf/components'
import Beta from '@/components/Beta'

import { SET_CONTRACT_MATCHING_OVERVIEW_TABLE_STATE } from '@/state/stateReducer'
import { StateContext } from '@/state/StateProvider'

import useGQL from '@/hooks/useGQL'
import ContractMatch from '@/domain/contract/ContractMatch'
import {
  getCompanies,
  getContractMatches,
  contractMatchingQuickFilters,
} from './domain/contractMatchingModel'
import { getColumns, getOptions } from './table-config'
import ImportContractMatchesDialog from './import/ImportContractMatchesDialog'

export default withRouter(() => {
  const {
    state: {
      tables: { contractMatchingOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  const getGQLClient = useGQL()
  const [openImportDialog, setOpenImportDialog] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'shipperName',
      filterOptionsGetter: () => getCompanies(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'lspName',
      filterOptionsGetter: () => getCompanies(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'lspContractType',
      filterOptionsGetter: () =>
        Object.keys(CONTRACT_TYPE).map((e) => ({
          label: CONTRACT_TYPE_DISPLAY_NAMES[e],
          value: e,
        })),
    },
    {
      inputType: 'daterange',
      columnName: 'rangeDate',
      customFilterListOptions: {
        render: (v) => v.map((l) => l).join(' - '),
      },
    },
    {
      inputType: 'min-max',
      columnName: 'rangeAssignedWeeklyVolume',
    },
  ])

  const handleChange = (setTableState) =>
    dispatch({
      type: SET_CONTRACT_MATCHING_OVERVIEW_TABLE_STATE,
      payload: setTableState(tableState),
    })

  const handleDownloadContractMatches = async () => {
    const { data } = await getContractMatches(getGQLClient, tableState, true)
    ContractMatch.downloadContractMatches(
      data.map((match) => ({
        buy_contract_id: match.buy_contract.id,
        sell_contract_id: match.sell_contract.id,
      }))
    )
  }

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true)
  }

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false)
  }

  return (
    <Page title='Contract Matching' ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(handleOpenImportDialog, handleDownloadContractMatches)}
            columns={getColumns(filterOptions)}
            fn={() => getContractMatches(getGQLClient, tableState)}
            quickFilterComponents={[
              {
                label: 'Enabled',
                name: 'isEnabled',
                values: ['YES', 'NO'],
                inputType: 'toggle-button',
              },
              {
                label: 'Time',
                name: 'timeStatuses',
                values: ['PAST', 'ONGOING', 'FUTURE'],
                inputType: 'toggle-button',
              },
            ]}
            gqlQuickFiltersMeta={contractMatchingQuickFilters}
            tableState={tableState}
            setTableState={handleChange}
            errorHandler={enqueueSnackbar}
          />
          <ImportContractMatchesDialog
            open={openImportDialog}
            handleCloseDialog={handleCloseImportDialog}
            title='IMPORT CONTRACT MATCHES'
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  )
})
