import React from 'react'
import axios from 'axios'
import styled from '@emotion/styled'
import leaflet from 'leaflet'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Checkbox,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import _ from 'lodash'

import { useForm, Controller } from 'react-hook-form'
import { Map as LeafletMap, TileLayer } from 'react-leaflet'

import { Page, PageContentLayout, PageContent, PageContainer } from '@leaf/components'

import Beta from '@/components/Beta'

const Map = styled(LeafletMap)`
  height: 100%;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  position: relative;
`

const COLORS = ['#8397B1', '#56CCF2', '#A695FF']

export default () => {
  const [shippers, setShippers] = React.useState([{ id: 1331, name: 'Albertsons' }])
  const [type, setType] = React.useState('path')
  const [isLoading, setLoading] = React.useState(false)
  const [lanes, setLanes] = React.useState([])

  const mapRef = React.useRef()

  const { control, watch } = useForm({
    defaultValues: { shipperSelect: [shippers[0].id] },
  })

  React.useEffect(() => {
    axios.get('view/adapt/lanes/shippers').then(({ shippers: response }) => setShippers(response))
  }, [])

  const selectedShippers = watch('shipperSelect')

  React.useEffect(() => {
    setLoading(true)

    Promise.all(selectedShippers.map((id) => axios.get(`view/adapt/lanes/shippers/${id}/network`)))
      .then((allLanes) =>
        setLanes(
          selectedShippers.map((selectedShipper, i) => ({
            id: selectedShipper,
            lanes: allLanes[i].lanes,
          }))
        )
      )
      .finally(() => setLoading(false))
  }, [selectedShippers])

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const { current = {} } = mapRef
    const { leafletElement: map } = current

    if (map && lanes.length) {
      lanes.forEach((shipperLane, i) =>
        leaflet
          .geoJSON(
            {
              type: 'FeatureCollection',
              features: shipperLane.lanes.map((lane) => ({
                type: 'Feature',
                id: lane.id,
                name: `${lane.shipperId}: ${lane.origin} - ${lane.destination}`,
                geometry: lane[type],
              })),
            },
            {
              style: () => ({ weight: 1.5, color: COLORS[i] }),
              onEachFeature: (feature, layer) => {
                layer.bindTooltip(`${feature.name}`)

                layer.on('mouseover', () => {
                  layer.setStyle({
                    color: 'red',
                  })
                  layer.bringToFront()
                })
                layer.on('mouseout', () => {
                  layer.setStyle({
                    color: COLORS[i],
                  })
                  layer.bringToBack()
                })
              },
            }
          )
          .addTo(map)
      )

      return () =>
        map.eachLayer((layer) => {
          if (layer.feature) {
            layer.remove()
          }
        })
    }
  }, [lanes, type])

  const handleTypeChange = (__, newType) => {
    if (newType) {
      setType(newType)
    }
  }

  const options = {
    center: [39.5, -98.35],
    maxBoundsViscosity: 1.0,
    maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
    zoom: 4,
    minZoom: 4,
    attributionControl: false,
  }
  return (
    <Page title='Leaf Grid Explorer' ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout noStyling fullHeight standaloneView>
          <Grid item xs={12}>
            <PageContent withPadding>
              <Grid container spacing={2}>
                <Grid style={{ display: 'flex', justifyContent: 'space-evenly' }} item xs={12}>
                  <Controller
                    name='shipperSelect'
                    control={control}
                    as={(field) => (
                      <FormControl>
                        <InputLabel>Shipper</InputLabel>

                        <Select
                          {...field}
                          renderValue={(selection) =>
                            selection.map((id) => _.find(shippers, { id })?.name).join(', ')
                          }
                          multiple
                        >
                          {shippers.map((s) => (
                            <MenuItem key={s.id} value={s.id}>
                              <Checkbox checked={selectedShippers.includes(s.id)} />
                              <ListItemText primary={s.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <ToggleButtonGroup value={type} onChange={handleTypeChange} exclusive>
                    <ToggleButton value='path'>Lane path</ToggleButton>
                    <ToggleButton value='directPath'>Direct path</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} style={{ height: '75vh' }}>
                  {isLoading && (
                    <Grid
                      style={{ display: 'flex', height: '100%' }}
                      justifyContent='center'
                      alignItems='center'
                      alignContent='center'
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  <Map {...options} ref={mapRef} isLoading={isLoading} preferCanvas>
                    <TileLayer url={import.meta.env.VITE_MAPBOX} />
                  </Map>
                </Grid>
              </Grid>
            </PageContent>
          </Grid>
        </PageContentLayout>
      </PageContainer>
    </Page>
  )
}
