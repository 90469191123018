import { gql } from 'graphql-request'
import { utility } from '@leaf/components'

const WHERE = {
  COMPANY_VAR: gql`$company: [bigint!]`,
  COMPANY_CLAUSE: gql`shipper_id: { _in: $company }`,
}

const whereClause = ({ where }) => {
  let whereGQL = gql``
  const { origin, destination, ...rest } = where
  const whereVarsKeys = Object.keys(rest).filter(
    (k) => Array.isArray(rest[k]) && rest[k].length
  )

  if (whereVarsKeys.length || origin || destination) {
    whereGQL = gql`
    where: {
        _and: [
          ${whereVarsKeys.map((key) =>
            where[key]
              ? gql`
                 { ${WHERE[`${key.toUpperCase()}_CLAUSE`]}}
                `
              : ''
          )}

          ${
            origin
              ? gql`{
                _and: {
                  origin: {
                    geo: {
                      _cast: {
                        geography: {
                          _stDWithin: {
                            distance: ${utility.functions.metersToMiles(50)}, 
                            from: {
                              type: "Polygon", coordinates:${JSON.stringify(
                                origin.coordinates
                              )}
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }`
              : ''
          }

          ${
            destination
              ? gql`{
                _and: {
                  destination: {
                    geo: {
                      _cast: {
                        geography: {
                          _stDWithin: {
                            distance: ${utility.functions.metersToMiles(50)}, 
                            from: {
                              type: "Polygon", coordinates:${JSON.stringify(
                                destination.coordinates
                              )}
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }`
              : ''
          }
        ]
      }
    `
  }
  return whereGQL
}

const whereVar = ({ where }) => gql`
  ${where.company ? WHERE.COMPANY_VAR : ``}
`

export const getTenderLocationsGQL = (variables) => {
  const whereGQL = whereClause(variables)
  const whereVars = whereVar(variables)
  return gql`
    query getTenderLocations${whereVars.trim() ? `(${whereVars})` : ''} {
      executionLane: execution_lane${whereGQL ? gql`( ${whereGQL} )` : ''} {
        origin {
          geo
        }
        destination {
          geo
        }
      }
    }
  `
}
