/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Autocomplete } from '@mui/material';

export default ({ async, ...props }) => {
  if (async) {
    return <Autocomplete {...props} filterOptions={x => x} />;
  }
  return <Autocomplete {...props} />;
};
