import { gql } from 'graphql-request';

export const getFleetDiscoveryDetailsRoutesGQL = id =>
  gql`
    query getFleetDiscoveryDetailsMeta2 {
      routes: fleet_permutation(where: { run_id: { _eq: "${id}" } }) {
        miles
        deadheadMiles: deadhead_miles
        id
        lanes: permutation_lanes {
          adaptLaneId: adapt_lane_id
          lane {
            company {
              id
              name
            }
            destination {
              name
              geometry
            }
            origin {
              name
              geometry
            }
            leafMiles: leaf_miles
            geometry
          }
        }
      }
    }
  `;

export const getFleetDiscoveryDetailsGQL = id =>
  gql`
    query getFleetDiscoveryDetails {
      fleetRunDefinition: fleet_run_definition(where: {id: {_eq: "${id}"}}) {
        id
        runTs: run_ts
        domicile
        runSummary: run_summary {
          distinctLanes: distinct_lanes
          distinctShippers: distinct_shippers
          totalPermutations: total_permutations
          totalMargin: total_margin
          totalRevenue: total_revenue
          trucksUsed: trucks_used
          fleetWeeklyLoads: fleet_weekly_loads
          lanePercentUsageMin: lane_percent_usage_min
          maxDests: max_dests
          maxMiles: max_miles
          maxOrigins: max_origins
          maxShippers: max_shippers
          truckCost: truck_cost
          truckLimit: truck_limit
        }
        laneParameters: lane_parameters {
          adaptLaneId: adapt_lane_id
          parameterType: parameter_type
        }
        permutations {
          id
          deadheadMiles: deadhead_miles
          margin
          miles
          optimalRuns: optimal_runs
          revenue
          permutationLanes: permutation_lanes {
            adaptLaneId: adapt_lane_id
            laneSequence: lane_sequence
          }
        }
        shipperSummary: shipper_summary {
          companyId: company_id
          distinctLanes: distinct_lanes
          fleetWeeklyLoads: fleet_weekly_loads
          totalPermutations: total_permutations
          trucksused: trucks_used
        }
        laneSummary: lane_summary {
          adaptLaneId: adapt_lane_id
          fleetWeeklyLoads:fleet_weekly_loads
          fleetAnnualizedLoads: fleet_annualized_loads
          laneAnnualizedLoads: lane_annualized_loads
          laneUtilizationPct: lane_utilization_pct
          totalPermutations: total_permutations
        }
      }
    }
	`;
