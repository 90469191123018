import React, { memo, useState, useEffect } from 'react'
import axios from 'axios'

const HolisticsDashboard = () => {
  const [holisticsEmbedCode, setHolisticsEmbedCode] = useState()
  const [holisticsToken, setHolisticsToken] = useState()

  useEffect(() => {
    if (!holisticsEmbedCode) {
      axios.get('tendering/token/holistics').then(({ embedCode, token }) => {
        setHolisticsToken(token)
        setHolisticsEmbedCode(embedCode)
      })
    }
  }, [holisticsEmbedCode, setHolisticsEmbedCode, setHolisticsToken])

  return holisticsEmbedCode ? (
    <iframe
      src={`https://us.holistics.io/embed/${holisticsEmbedCode}?_token=${holisticsToken}`}
      id="embedded-iframe"
      title="Dashboard"
      style={{
        border: '0',
        width: '100%',
        height: 'calc(100vh - 50px)',
      }}
      allowFullScreen
    />
  ) : null
}

const HolisticsDashboardMemo = memo(HolisticsDashboard)
export { HolisticsDashboardMemo as HolisticsDashboard }
