import axios from 'axios'
import { getExecutionLanesGQL, GQL_SHIPPERS } from './GQL_EXECUTION_LANES'

const EXECUTION_LANES_URL = import.meta.env.VITE_LANES_EXECUTION_API

const mapExecutionLanes = (executionLanes) =>
  executionLanes.map((lane) => ({
    ...lane,
    lane: {
      origin: {
        primaryContent: `${lane.origin?.city}, ${lane.origin?.state} ${lane.origin?.zip}`,
        secondaryContent: `${lane.origin?.street}`,
      },
      destination: {
        primaryContent: `${lane.destination?.city}, ${lane.destination?.state} ${lane.destination?.zip}`,
        secondaryContent: `${lane.destination?.street}`,
      },
    },
    actionsData: {
      selectedLane: lane,
    },
    leafDistance: lane?.drivingData?.leafDistance,
    tolls: lane?.drivingData?.tolls,
    drivingTimeMinutes: lane?.drivingData?.drivingTimeMinutes,
  }))

const getOrderBy = (sort) => {
  if (!sort) {
    return []
  }
  // Sort on nested fields
  if (sort.name === 'shipper') {
    return [{ shipper: { name: sort.order } }]
  }
  // Sort on basic fields
  return [{ [sort.field ?? sort.name]: `${sort.order}_nulls_last` }]
}

export const getExecutionLanes = (getGQLClient, tableState, forDownload = false) => {
  const variables = {
    sort: tableState.sort,
    search: tableState.search,
    ...(!forDownload && {
      limit: tableState.rowsPerPage,
      offset: tableState.page * tableState.rowsPerPage,
    }),
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      shipper_id: tableState.filters.shipper ? tableState.filters.shipper : undefined,
    },
  }
  const { where, search, sort, ...rest } = variables

  return getGQLClient().then((client) => {
    const GQL = getExecutionLanesGQL({
      where,
      search,
      ...rest,
    })
    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then((res) => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.stats.aggregate.count,
      data: mapExecutionLanes(res.executionLanes),
    }))
  })
}

export const getShippers = (getGQLClient) =>
  getGQLClient()
    .then((client) => client.request(GQL_SHIPPERS))
    .then((res) =>
      res.execution_lane.map((e) => ({
        label: e.shipper.name,
        value: e.shipper.id,
      }))
    )

export const updateExecutionLane = (laneId, data) =>
  axios.patch(
    `lanes/${laneId}`,
    {
      shipper_miles: data?.shipperDistance,
      co2_reduction: data?.co2ReductionPerShipment,
      reference_rate: data?.referenceRpm,
    },
    { baseURL: EXECUTION_LANES_URL }
  )
