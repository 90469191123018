/* eslint-disable react/jsx-props-no-spreading */
import { Form, useLoading } from '@leaf/components'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import Location from '@/domain/location/Location'
import { Grid, TextField, CircularProgress } from '@mui/material'
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet'
import leaflet from 'leaflet'
import styled from '@emotion/styled'
import { Controller } from 'react-hook-form'
import _ from 'lodash'

const Map = styled(LeafletMap)`
  height: 250px;
  position: relative;
`

export default ({ control, register, errors, defaultValues, triggerChange }) => {
  const defaultDomicileValues =
    defaultValues && !_.isEmpty(defaultValues)
      ? {
          address: defaultValues.street,
          zipCode: defaultValues.zip,
          city: defaultValues.city,
          state: defaultValues.state,
          latitude: defaultValues.geo.coordinates[0],
          longitude: defaultValues.geo.coordinates[1],
        }
      : undefined
  const [domicileAddressOptions, setDomicileAddressOptions] = useState(
    defaultDomicileValues ? [defaultDomicileValues] : []
  )
  const [domicileAddress, setDomicileAddress] = useState(defaultDomicileValues)

  const { enqueueSnackbar } = useSnackbar()
  const [getLocation, isLoading] = useLoading(async (value) =>
    Location.getLocation(value).then(setDomicileAddressOptions).catch(enqueueSnackbar)
  )

  const getAddress = (location) => {
    if (!location) {
      return null
    }
    if (location.address) {
      const option = `${location.address} ${location.city} ${location.state} ${location.zipCode}`
      return option
    }
    return `${location.city} ${location.state} ${location.zipCode}`
  }

  const handleInputChange = (value) => {
    if (value.length >= 3) {
      getLocation(value)
    } else {
      setDomicileAddressOptions([])
    }
  }

  const options = {
    center: [39.5, -98.35],
    maxBoundsViscosity: 1.0,
    maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
    zoom: 4,
    minZoom: 4,
    attributionControl: false,
  }

  return (
    <Grid container>
      <Grid xs={6}>
        <Grid container>
          <Grid xs={12} sx={{ mb: 2 }}>
            <TextField
              label='Domicile Name *'
              name='domicileName'
              defaultValue={defaultValues.name}
              inputRef={register}
              onChange={() => {
                triggerChange()
              }}
              fullWidth
              sx={{ '& textarea': { m: 2 } }}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name='domicileAddress'
              control={control}
              rules={{ required: true }}
              render={({ onChange }) => (
                <Form.Generic.Autocomplete
                  value={domicileAddress}
                  options={domicileAddressOptions}
                  isOptionEqualToValue={(option, selected) => option === selected}
                  getOptionLabel={getAddress}
                  onChange={(__, data) => {
                    onChange(data)
                    setDomicileAddress(data)
                    triggerChange()
                  }}
                  onInputChange={(__, value) => handleInputChange(value)}
                  renderInput={(params) => (
                    <Form.Generic.Input
                      {...params}
                      label='Domicile Address *'
                      error={errors.domicileAddress}
                      helperText={errors.domicileAddress ? 'Required field' : null}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  async
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} sx={{ pl: 2 }}>
        <Map {...options}>
          <TileLayer url={import.meta.env.VITE_MAPBOX} />
          {domicileAddress && (
            <Marker position={[domicileAddress.latitude, domicileAddress.longitude]} />
          )}
        </Map>
      </Grid>
    </Grid>
  )
}
