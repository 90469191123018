import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Page,
  ServerSideTable,
  useTableCustomFilters,
  BoardInfoPanel,
  SpacedContainer,
  PageContentLayout,
  PHASE_STATUS_DISPLAY_NAMES,
  PhaseSelect,
  Tabs,
  PHASE_TYPE,
} from '@leaf/components'
import { StateContext } from '@/state/StateProvider'
import {
  SET_ADAPT_BOARD_STATE,
  SET_ADAPT_BOARD_FILTER_STATE,
  SET_ADAPT_BOARD_LANE_PHASES_STATE,
} from '@/state/stateReducer'
import AdaptBoard from '@/domain/adapt/AdaptBoard'
import Beta from '@/components/Beta'
import styled from '@emotion/styled'
import { Box, Select, MenuItem, Grid } from '@mui/material'
import AdaptBoardNetworkMovesTable from './adapt-board-network-moves'

import { getColumns, getExplorerUrl } from './adaptBoardOverviewColumns'

const Actions = styled.div`
  justify-content: center;
  margin-right: 15px;
`

const LeftSidePanelHead = styled(Box)`
  height: 60px;
  padding: 20px 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.actions.border}`};
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgb(255, 255, 255);
`

const SelectWrapper = styled(Select)`
  .MuiSelect-root {
    background-color: ${({ theme }) => `${theme.palette.background.main}`};
  }
`

const selectItems = [
  { key: 'annualSpend', value: 'Annualized spend' },
  { key: 'numberOfLanes', value: 'Lanes' },
  { key: 'annualLoads', value: 'Annual loads' },
]

const AdaptBoardOverview = ({ location, history }) => {
  const [shippers] = React.useState([])
  const [selectedRows, setSelectedRows] = React.useState([])
  const [selectedItem, setSelectedItem] = React.useState('annualSpend')
  const [shipperSummary, setShipperSummary] = React.useState([])
  const [selectedPageData, setSelectedPageData] = React.useState(null)
  const [isShipperSummaryLoading, setIsShipperSummaryLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      tables: { adaptBoard: tableState },
      lanePhases: { lanePhasesByShipper: lanePhases },
    },
    dispatch,
  } = React.useContext(StateContext)

  const initSelectedShipper = () => {
    if (tableState.filters?.shipperName?.length > 0) {
      return tableState.filters?.shipperName[0]
    }

    return undefined
  }

  const [selectedShipper, setSelectedShipper] = React.useState(initSelectedShipper)

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'shipperName',
      filterOptionsGetter: AdaptBoard.getShippers,
    },
    {
      inputType: 'autocomplete',
      columnName: 'status',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(PHASE_STATUS_DISPLAY_NAMES).map((key) => ({
            label: PHASE_STATUS_DISPLAY_NAMES[key],
            value: key,
          }))
        ),
    },
    {
      inputType: 'daterange',
      columnName: 'phase',
    },
    {
      inputType: 'autocomplete',
      columnName: 'equipmentClass',
      filterOptionsGetter: () => AdaptBoard.getEnums('equipment_class'),
    },
  ])

  const filterAllLanePhases = () =>
    lanePhases.filter(
      (item, index) =>
        item.phase &&
        item.phase !== PHASE_TYPE.UNASSIGNED &&
        item.phase !== PHASE_TYPE.OUT_OF_SCOPE &&
        lanePhases.findIndex((innerItem) => innerItem.startDate === item.startDate) === index
    )

  const updateLanePhaseList = () => {
    AdaptBoard.getAllLanePhases()
      .then((data) => {
        dispatch({
          type: SET_ADAPT_BOARD_LANE_PHASES_STATE,
          payload: data.sort((a, b) => a.phase.localeCompare(b.phase)),
        })
        enqueueSnackbar(`Phase successfully updated`, { variant: 'success' })
      })
      .catch(enqueueSnackbar)

    dispatch({ type: SET_ADAPT_BOARD_STATE, payload: { ...tableState } })
  }

  const getFilteredPageData = () => {
    if (selectedPageData?.length && selectedRows?.length) {
      let selectedLaneIds = []
      let selectedEquipmentTypes = []
      selectedRows.forEach((item) => {
        selectedLaneIds = [...selectedLaneIds, selectedPageData[item].laneId]
        selectedEquipmentTypes = [...selectedEquipmentTypes, selectedPageData[item].equipmentClass]
      })
      return { laneIds: selectedLaneIds, equipmentTypes: selectedEquipmentTypes }
    }

    return []
  }

  const renderCustomToolbarSelect = () => {
    const { laneIds, equipmentTypes } = getFilteredPageData()
    return (
      <Actions>
        <PhaseSelect
          data={filterAllLanePhases()}
          patchData={AdaptBoard.patchPhaseStart}
          updateLanePhases={updateLanePhaseList}
          laneIds={laneIds}
          equipmentTypes={equipmentTypes}
          displayEmpty
          variant='outlined'
        />
      </Actions>
    )
  }

  const options = {
    filter: true,
    download: false,
    selectableRows: 'multiple',
    onRowSelectionChange: (_, __, rowsSelected) => setSelectedRows(rowsSelected),
    customToolbarSelect: renderCustomToolbarSelect,
    onRowClick: (row) => {
      const explorerUrl = getExplorerUrl(row)
      if (explorerUrl) {
        history.push(explorerUrl)
      }
    },
  }

  React.useEffect(() => {
    setIsShipperSummaryLoading(true)
    AdaptBoard.getAllLanePhases()
      .then((data) => {
        dispatch({
          type: SET_ADAPT_BOARD_LANE_PHASES_STATE,
          payload: data.sort((a, b) => a.phase.localeCompare(b.phase)),
        })
      })
      .catch(enqueueSnackbar)
    AdaptBoard.getShipperSummary()
      .then(({ adaptBoardShipperSummary }) => {
        setIsShipperSummaryLoading(false)
        setShipperSummary(adaptBoardShipperSummary)
      })
      .catch(enqueueSnackbar)
  }, [])

  const handleChange = (setTableState) =>
    dispatch({ type: SET_ADAPT_BOARD_STATE, payload: setTableState(tableState) })

  filterOptions.owner = { options: shippers }

  const handleChangeSelect = ({ target }) => {
    setSelectedItem(target.value)
  }

  const handleSelectedShipper = (shipperName) => {
    setSelectedShipper((prevValues) => {
      if (prevValues?.includes(shipperName)) {
        return undefined
      }

      return shipperName
    })
  }

  React.useEffect(() => {
    if (
      selectedShipper &&
      (!tableState.filters?.shipperName ||
        tableState.filters?.shipperName?.length === 0 ||
        tableState.filters?.shipperName[0] !== selectedShipper)
    ) {
      dispatch({ type: SET_ADAPT_BOARD_FILTER_STATE, payload: selectedShipper })
    }
    if (
      !selectedShipper &&
      (tableState.filters?.shipperName || tableState.filters?.shipperName?.length > 0)
    ) {
      dispatch({ type: SET_ADAPT_BOARD_FILTER_STATE, payload: selectedShipper })
    }
  }, [selectedShipper])

  React.useEffect(() => {
    if (tableState.filters?.shipperName?.length > 0 && !selectedShipper) {
      setSelectedShipper(...tableState.filters?.shipperName)
    }
    if (
      (!tableState.filters?.shipperName || tableState.filters?.shipperName?.length === 0) &&
      selectedShipper
    ) {
      setSelectedShipper(undefined)
    }
  }, [tableState])

  const tabs = [
    {
      label: 'Lanes',
      content: (
        <ServerSideTable
          options={options}
          columns={getColumns(tableState, filterOptions, lanePhases, updateLanePhaseList)}
          fn={AdaptBoard.getAll}
          tableState={tableState}
          setTableState={handleChange}
          onPageRowsLoadedCb={setSelectedPageData}
        />
      ),
    },
    {
      label: 'Network moves',
      content: (
        <AdaptBoardNetworkMovesTable
          selectedShipper={selectedShipper}
          setSelectedShipper={setSelectedShipper}
        />
      ),
    },
  ]

  return (
    <Page title='Adapt Board' ComponentTitleRight={<Beta />}>
      <SpacedContainer container spacing={2}>
        <Grid item xs={3}>
          <PageContentLayout withoutSpacing fullHeight={false}>
            <LeftSidePanelHead>
              <SelectWrapper value={selectedItem} onChange={handleChangeSelect}>
                {selectItems.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </SelectWrapper>
            </LeftSidePanelHead>
            <BoardInfoPanel
              data={shipperSummary}
              selectedTotalValue={selectedItem}
              getPhaseData={AdaptBoard.getShipperSummaryPhases}
              handleSelectedShipper={handleSelectedShipper}
              selectedShipper={selectedShipper}
              isDataLoading={isShipperSummaryLoading}
            />
          </PageContentLayout>
        </Grid>
        <Grid item xs={9}>
          <PageContentLayout withoutSpacing fullHeight={false}>
            <Tabs
              hasPadding={false}
              location={location}
              history={history}
              mainUrlPath='/adapt/board'
              tabs={tabs}
            />
          </PageContentLayout>
        </Grid>
      </SpacedContainer>
    </Page>
  )
}
export default withRouter(AdaptBoardOverview)
