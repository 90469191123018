import axios from 'axios';

export interface Geometry {
  type: string;
  coordinates: any[];
}
export interface GeoSeachResponse {
  query: string;
  geocoded: {
    geometry: Geometry;
    centroid: string;
    h3: {
      cells: string[];
      geometry: Geometry;
      centroid: string;
    };
  };
}
export const search = (params: string): Promise<GeoSeachResponse[]> => axios.get(`/geo/geocode/search?${params}`);
