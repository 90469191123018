import { gql } from 'graphql-request';

export const getCompanyDetailsGQL = id => gql`
  query getCompanyDetails {
    company(where: { id: { _eq: "${id}" } }) {
      id
      name
      createdDate: created_at
      phone
      registeredName: registered_name
      registeredState: registered_state
      scacCode: scac_code
      tenderEmailAddress: tender_email_address
      tenderPhoneNumber: tender_phone_number
      transactionFee: transaction_fee
      type: company_type
      usDotNumber: us_dot_number
      usMcNumber: us_mc_number
      use4KitesIntegration: use_4kites_integration
      useProject44Integration: use_project44_integration
      useTrimbleIntegration: use_trimble_integration
      accountTeamMembers: account_team_members {
        user {
          id
          firstName: first_name
          lastName: last_name
        }
      }
    }
  }
`;
