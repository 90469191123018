import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

const Center = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export default ({ children }) => <Center>{children}</Center>;
