import React from 'react';
import { Box, Typography } from '@mui/material';

import { ON_TIME_STATUS_DISPLAY_NAMES } from '../constants/OnTimeStatus';

const OnTimeStatus = ({ status }) => (
  <Box display="flex" alignItems="center">
    <Typography variant="body1">{ON_TIME_STATUS_DISPLAY_NAMES[status]}</Typography>
  </Box>
);

export default OnTimeStatus;
