import { gql } from 'graphql-request'

const WHERE = {
  SHIPPERS_VAR: gql`$shippers: [bigint!]`,
  SHIPPERS_CLAUSE: gql`company: { id: { _in: $shippers } }`,
}

const whereClause = ({ where, search }) => {
  let whereGQL = gql``
  const whereVarsKeys = Object.keys(where).filter(
    (k) => Array.isArray(where[k]) && where[k].length
  )

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
      where: {
          _and: {
            ${whereVarsKeys.map((key) =>
              where[key]
                ? gql`
                    ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                  `
                : ''
            )}
  
            ${
              search !== undefined && search !== ''
                ? gql`
              _or: [
                {city: { _ilike: "%${search}%" }}
                {country: { _ilike: "%${search}%" }}
                {state: { _ilike: "%${search}%" }}
                {street: { _ilike: "%${search}%" }}
                {zip: { _ilike: "%${search}%" }}
                {business_name: { _ilike: "%${search}%" }}
                {shipper_location_name: { _ilike: "%${search}%" }}
                {shipper_location_reference: { _ilike: "%${search}%" }}
                {company: {name: { _ilike: "%${search}%" }}}
              ]`
                : ''
            }
          }
        }
      `
  }
  return whereGQL
}

const whereVar = ({ where }) => gql`
  ${where.shippers ? WHERE.SHIPPERS_VAR : ``}
`

export const getExecutionLocationsGQL = (variables) => {
  const whereGQL = whereClause(variables)
  return gql`
      query getExecutionLocations(
        $limit: Int
        $orderBy: [execution_location_order_by]
        $offset: Int
        ${whereVar(variables)}
      ) {
        executionLocation: execution_location(
            limit: $limit
            order_by: $orderBy
            offset: $offset
            ${whereGQL}
          ) {
            id
            city
            country
            state
            street
            zip
            businessName: business_name
            shipperId: shipper_id
            locationType: location_type
            company {
              name
            }
            shipperLocationName: shipper_location_name
            shipperLocationReference: shipper_location_reference
        }
        executionLocationAggregate: execution_location_aggregate${
          whereGQL ? gql`( ${whereGQL} )` : ''
        } {
          aggregate {
            count
          }
        }
      }
    `
}

export const getDistinctShippersGQL = () => gql`
  query getDistinctShippers {
    executionLocation: execution_location(distinct_on: shipper_id) {
      company {
        id
        name
      }
    }
  }
`

export const getExecutionLocationByIdGQL = (id) => gql`
  query getExecutionLocationById {
    executionLocation: execution_location(where: { id: { _eq: ${id} } }) {
      id
      city
      country
      state
      street
      zip
      geo
      businessName: business_name
      shipperId: shipper_id
      contactEmail: contact_email
      contactName: contact_name
      contactPhone: contact_phone
      accessRequirement: access_requirements
      appointmentRequirement: appointment_requirements
      operatingHours: operating_hours
      locationType: location_type
      trailerPoolStorageRequirement: trailer_pool_storage_requirements
      shipperLocationName: shipper_location_name
      shipperLocationReference: shipper_location_reference
    }
  }
`
