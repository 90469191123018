import React from 'react';
import { Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import styled from '@emotion/styled';

import AlignText from '../AlignText';
import { money } from './format';

const NegativeValue = styled(Typography)`
  text-align: right;
  color: ${({ theme }) => `${theme.palette.error.light}`};
`;

const PositiveValue = styled(Typography)`
  text-align: right;
  color: ${({ theme }) => `${theme.palette.success.main}`};
`;

export const renderTrueFalse = v => (v ? <Check htmlColor="green" /> : <Close htmlColor="red" />);

export const emptyLabel = () => undefined;

export const sortCompare = (value1 = 0, value2 = 0, order) => (value1 < value2 ? -1 : 1) * (order === 'desc' ? -1 : 1);

export const compareNumbers =
  order =>
  ({ data: v1 }, { data: v2 }) => {
    const n1 = Math.round(Math.abs(v1)) ?? 0;
    const n2 = Math.round(Math.abs(v2)) ?? 0;
    return (n1 - n2) * (order === 'asc' ? 1 : -1);
  };

export const compareDates =
  order =>
  ({ data: date }, { data: nextDate }) => {
    const date1 = date === '-' ? undefined : new Date(date);
    const date2 = nextDate === '-' ? undefined : new Date(nextDate);

    return (date1 < date2 ? -1 : 1) * (order === 'desc' ? -1 : 1);
  };

export const rightAlignColumnProps = (renderFn = displayValue => displayValue) => ({
  customBodyRender: body => <AlignText position="right">{renderFn(body)}</AlignText>,
  setCellHeaderProps: () => ({
    className: 'right-aligned',
  }),
});

export const centerAlignColumnProps = (renderFn = displayValue => displayValue) => ({
  customBodyRender: body => <AlignText position="center">{renderFn(body)}</AlignText>,
  setCellHeaderProps: () => ({
    className: 'center-aligned',
  }),
});

export const onMouseEnterRow =
  (name = 'buttons', idColumnIndex = 0, row) =>
  () => {
    const control = document.getElementById(`Actions-${name}-${row[idColumnIndex]}`);
    if (control) {
      control.style.opacity = '1';
      control.parentElement.parentElement.style.backgroundColor = '#eeeeee';
    }
  };

export const onMouseLeaveRow =
  (name = 'buttons', idColumnIndex = 0, row) =>
  () => {
    const control = document.getElementById(`Actions-${name}-${row[idColumnIndex]}`);
    if (control) {
      control.style.opacity = '0';
      control.parentElement.parentElement.style.backgroundColor = 'initial';
    }
  };

// idColumnIndex is the index of the column which cell value is used for the hover button id
export const getRowHoverActionProps = (name = 'buttons', idColumnIndex = 0) => ({
  setRowProps: row => ({
    onMouseEnter: onMouseEnterRow(name, idColumnIndex, row),
    onMouseLeave: onMouseLeaveRow(name, idColumnIndex, row),
  }),
});

export const triggerBrowserDownload = (data, fileName = 'exportedData', fileType = 'csv') => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${fileType}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const signed = v => {
  if (typeof v !== 'number') {
    return '-';
  }
  const sign = Math.sign(Number(v));
  const n = money(v);
  if (sign >= 0) {
    return <PositiveValue variant="caption">{n}</PositiveValue>;
  }
  return <NegativeValue variant="caption">{n}</NegativeValue>;
};

export const rightStickyColumnProps = {
  setCellProps: () => ({
    className: 'right-sticky-column',
  }),
  // setCellHeaderProps: () => ({
  // className: 'right-sticky-column',
  // }),
};

export const ActionButtons = styled.div`
  opacity: 0;
  text-align: right;
`;

export const dateComparator =
  order =>
  ({ data: date }, { data: nextDate }) =>
    compareDates(date, nextDate, order);
