/* eslint-disable no-restricted-globals */
import { gql } from 'graphql-request';

const whereClause = ({ search }) => {
  let whereGQL = gql``;

  if (search !== undefined && search !== '') {
    whereGQL = gql`
    where: {
        _and: {

          ${
            search !== undefined && search !== ''
              ? gql`
            _or: [
              ${!isNaN(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
              {fuel_price_index: {name: { _ilike: "%${search}%" }}}
            ]`
              : ''
          }
        }
      }
    `;
  }
  return whereGQL;
};

export const getFuelPricesGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getFuelPrices($limit: Int, $orderBy: [fuel_price_order_by], $offset: Int) {
      fuelPrice: fuel_price(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        id
        price
        validFrom: valid_from
        index: fuel_price_index {
          name
        }
      }
      fuelPriceAggregate: fuel_price_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};
