import React from 'react'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import styled from '@emotion/styled'
import { isAfter } from 'date-fns'

import GeneralData from './GeneralData'

import { EQUIPMENT_CLASSES } from '../domain/EquipmentClass'
import Meta from '../domain/Meta'
import Summary from '../domain/Summary'
import Volume from '../domain/Volume'
import WeeklyVolume from '../domain/WeeklyVolume'
import DetailVolume from '../domain/DetailVolume'
import Spend from '../domain/Spend'
import CarrierSpend from '../domain/CarrierSpend'

import { CarrierRatesChart, RateHistoryChart } from '../features/spend'
import {
  DailyVolumeChart,
  DailyDistributionChart,
  LaneVolumeChart,
  LaneVolumeTable,
  WeeklyVolumeHeatmap,
} from '../features/volume'

import ChartCard from '../components/ChartCard'

const LastChart = styled(Grid)`
  margin-bottom: 5em;
`

const hasData = (data, equipmentClass) => {
  if (!data) {
    return false
  }
  if (!data[equipmentClass]) {
    return false
  }
  if (Array.isArray(data[equipmentClass])) {
    return data[equipmentClass].length > 0
  }
  return Object.keys(data[equipmentClass]).length > 0
}

const Explorer = ({
  client,
  companyId,
  laneId,
  mapboxUrl,
  options,
  onNotFound,
}) => {
  const [missing, setMissing] = React.useState(false)
  const [meta, setMeta] = React.useState(null)
  const [equipmentClass, setEquipmentClass] = React.useState(null)
  const [uom, setUom] = React.useState(
    options.view === 'SHIPPER' ? 'shipper' : 'lsp'
  )
  const [summary, setSummary] = React.useState(null)
  const [volumes, setVolumes] = React.useState(null)
  const [weeklyVolumes, setWeeklyVolumes] = React.useState(null)
  const [detailVolumes, setDetailVolumes] = React.useState(null)
  const [spends, setSpends] = React.useState(null)
  const [carrierSpends, setCarrierSpends] = React.useState(null)

  React.useEffect(() => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    setEquipmentClass(params.get('e') || 'dry')

    Meta.get(client, companyId, laneId)
      .then(setMeta)
      .catch(() => setMissing(true))

    Summary.get(client, companyId, laneId)
      .then(setSummary)
      .catch(() => setMissing(true))

    Volume.get(client, companyId, laneId).then((data) => {
      setVolumes(data)
    })

    WeeklyVolume.get(client, companyId, laneId).then((data) => {
      setWeeklyVolumes(data)
    })

    DetailVolume.get(client, companyId, laneId).then(setDetailVolumes)

    Spend.get(client, companyId, laneId).then(setSpends)

    CarrierSpend.get(client, companyId, laneId).then(setCarrierSpends)
  }, [companyId, laneId])

  if (missing) {
    onNotFound()
    return null
  }

  const handleEquipmentClassChange = (_, newEquipmentClass) => {
    if (newEquipmentClass && equipmentClass !== newEquipmentClass) {
      setEquipmentClass(newEquipmentClass)
    }
  }
  const EquipmentClass = () => (
    <ToggleButtonGroup
      value={equipmentClass}
      onChange={handleEquipmentClassChange}
      exclusive
    >
      {summary.equipmentClasses.map((ec) => (
        <ToggleButton key={ec} value={ec}>
          {EQUIPMENT_CLASSES[ec]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )

  const handleUomChange = (_, newUom) => {
    if (newUom && uom !== newUom) {
      setUom(newUom)
    }
  }
  const Uom = () => (
    <ToggleButtonGroup value={uom} onChange={handleUomChange} exclusive>
      <ToggleButton value="lsp">LSP</ToggleButton>
      <ToggleButton value="shipper">Shipper</ToggleButton>
    </ToggleButtonGroup>
  )

  const hasSummary = hasData(summary, equipmentClass)
  const hasVolumes = hasData(volumes, equipmentClass)
  const hasWeeklyVolumes = hasData(weeklyVolumes, equipmentClass)
  const hasDetailVolumes = hasData(detailVolumes, equipmentClass)
  const hasSpends = hasData(spends, equipmentClass)
  const hasCarrierSpends = hasData(carrierSpends, equipmentClass)

  const showVolumeByLane =
    hasDetailVolumes &&
    meta &&
    isAfter(new Date(meta.batchDateLatest), new Date('2022-06-30T00:00:00'))

  return (
    <Grid container spacing={4} alignItems="stretch">
      {hasSummary && (
        <GeneralData
          data={{ ...summary[equipmentClass], ...meta }}
          companyId={companyId}
          laneId={laneId}
          mapboxUrl={mapboxUrl}
          EquipmentClass={EquipmentClass}
          Uom={Uom}
          isAdmin={options.view === 'ADMIN'}
        />
      )}

      {hasVolumes && (
        <>
          <Grid item xs={6}>
            <ChartCard title="Daily shipment volume">
              <DailyVolumeChart data={volumes[equipmentClass]} />
            </ChartCard>
          </Grid>

          <Grid item xs={6}>
            <ChartCard title="Daily shipment distribution">
              <DailyDistributionChart data={volumes[equipmentClass]} />
            </ChartCard>
          </Grid>
        </>
      )}

      {hasWeeklyVolumes && (
        <Grid item xs={12}>
          <ChartCard title="Weekly shipment volume" centerContent>
            <WeeklyVolumeHeatmap data={weeklyVolumes[equipmentClass]} />
          </ChartCard>
        </Grid>
      )}

      {showVolumeByLane && (
        <Grid item xs={12}>
          <ChartCard title="Volume by lane" centerContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <LaneVolumeChart data={detailVolumes[equipmentClass]} />
              </Grid>

              <Grid item xs={6}>
                <LaneVolumeTable data={detailVolumes[equipmentClass]} />
              </Grid>
            </Grid>
          </ChartCard>
        </Grid>
      )}

      {hasSpends && (
        <Grid item xs={12}>
          <ChartCard title="Rate history">
            <RateHistoryChart data={spends[equipmentClass]} uom={uom} />
          </ChartCard>
        </Grid>
      )}

      {hasCarrierSpends && (
        <LastChart item xs={12}>
          <ChartCard title="Carrier rates">
            <CarrierRatesChart data={carrierSpends[equipmentClass]} uom={uom} />
          </ChartCard>
        </LastChart>
      )}
    </Grid>
  )
}

export default Explorer
