import React from 'react'
import { v4 } from 'uuid'

import { Map as LeafletMap, Marker, TileLayer, GeoJSON, Popup } from 'react-leaflet'
import styled from '@emotion/styled'
import leaflet from 'leaflet'

const Map = styled(LeafletMap)`
  height: 100%;
  position: relative;
`

// eslint-disable-next-line no-underscore-dangle
delete leaflet.Icon.Default.prototype._getIconUrl

export const transformCoordsToPointGeometryType = (latitude, longitude) => ({
  type: 'Point',
  // Map expects coordinates in the following order [latitude, longitude]
  coordinates: [latitude, longitude],
})

export default ({
  data,
  markerPosition,
  hasMarker,
  isFeatureCollection,
  draggable,
  updateMarker,
}) => {
  // TODO: Handle types to be consistent
  const geoJson = isFeatureCollection
    ? {
        type: 'FeatureCollection',
        features: data, // type []
      }
    : data // type {}

  let bounds = null
  if (!hasMarker) {
    const feature = leaflet.geoJSON(geoJson)
    bounds = feature?.getBounds()
  }

  const options = {
    center: [39.5, -98.35],
    maxBoundsViscosity: 1.0,
    maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
    bounds: bounds?.isValid() ? bounds : null,
    zoom: 3,
    minZoom: 2,
    attributionControl: false,
  }

  return (
    <Map {...options}>
      <TileLayer url={import.meta.env.VITE_MAPBOX} />
      <GeoJSON data={geoJson} key={v4()} />
      {hasMarker && markerPosition.length !== 0 && (
        <Marker position={markerPosition} draggable={draggable} ondragend={updateMarker}>
          <Popup>
            {markerPosition[0]},{markerPosition[1]}
          </Popup>
        </Marker>
      )}
    </Map>
  )
}
