import React from 'react';
import styled from '@emotion/styled';
import {
  ShipmentStatus,
  OnTimeStatus,
  CompanyAvatar,
  USER_TYPE,
  Lane,
  ContractType,
  CONTRACT_TYPE,
  utility,
} from '@leaf/components';
import { TableCell, Tooltip, IconButton } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import ContractTypeSwitch from '../shared/ContractTypeSwitch';

const TableCellWrapper = styled(TableCell)`
  &&& {
    position: sticky;
    top: 0px;
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
`;

const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
`;

const TableCellItem = styled.span`
  width: 230px;
`;

export const getOptions = (history, handleDownloadShipments) => ({
  filter: true,
  onRowClick: rowData => {
    const id = rowData[0];
    history.push(`/shipments/${id}`);
  },
  customToolbar: () => (
    <Tooltip title="Download Shipments">
      <IconButton onClick={handleDownloadShipments}>
        <CloudDownload />
      </IconButton>
    </Tooltip>
  ),
});

export const getColumns = (filterOptions, tableState) => [
  {
    label: 'GUID',
    name: 'id',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Leaf Load #',
    name: 'executionPartnerShipmentId',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Shipper',
    name: 'shipper',
    field: 'company',
    options: {
      ...filterOptions.shipperId,
      customBodyRender: shipper =>
        shipper && <CompanyAvatar name={shipper?.name} id={shipper?.id} type={USER_TYPE.SHIPPER} isChip />,
    },
  },
  {
    label: 'Carrier',
    name: 'carrier',
    field: 'carrier_trip_tender',
    options: {
      ...filterOptions.carrierId,
      customBodyRender: carrier =>
        carrier && <CompanyAvatar name={carrier?.name} id={carrier?.id} type={USER_TYPE.CARRIER} isChip />,
    },
  },
  {
    name: 'lanes',
    options: {
      filter: false,
      sort: false,
      customHeadRender: () => (
        <TableCellWrapper>
          <TableCellContainer>
            <TableCellItem>Origin</TableCellItem>
            <TableCellItem>Destination</TableCellItem>
          </TableCellContainer>
        </TableCellWrapper>
      ),
      customBodyRender: ({ origin, destination }) => (
        <Lane
          displayAsGrid
          origin={{ primaryContent: [origin.primaryContent], secondaryContent: [origin.secondaryContent] }}
          destination={{
            primaryContent: [destination.primaryContent],
            secondaryContent: [destination.secondaryContent],
          }}
        />
      ),
    },
  },
  {
    label: 'LSP Contract Type',
    name: 'contractType',
    options: {
      ...filterOptions.contractType,
      sort: false,
      customBodyRender: ({ id, type, spotConfirmed }) => {
        if (spotConfirmed) {
          return <ContractTypeSwitch id={id} type={CONTRACT_TYPE.SPOT} />;
        }
        if (!type) {
          return <ContractTypeSwitch id={id} type={type} />;
        }

        return <ContractType type={type} />;
      },
    },
  },
  {
    label: 'Contract Type',
    name: 'contractType',
    field: 'contract_type_other',
    options: {
      display: 'excluded',
      filter: false,
      sort: false,
      customBodyRender: ({ type }) => <ContractType type={type} />,
    },
  },
  {
    label: 'Execution Partner Name',
    name: 'executionPartnerName',
    options: {
      display: false,
      sort: false,
      ...filterOptions.executionPartnerName,
    },
  },
  {
    label: 'Status',
    name: 'shipmentExecutionStatus',
    options: {
      ...filterOptions.status,
      sort: false,
      customBodyRender: status => <ShipmentStatus status={status} />,
    },
  },
  {
    label: 'Trip Load Number',
    name: 'tripId',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Shipper’s Shipment #',
    name: 'shipperShipmentId',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Leg Number',
    name: 'legNumber',
    options: {
      display: false,
      sort: false,
      ...filterOptions.legNumber,
      filterList: tableState.filters?.legNumber,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Pickup Date',
    name: 'pickupAppointment',
    field: 'origin_appointment_time',
    options: {
      ...filterOptions.pickupDate,
    },
  },
  {
    label: 'Pickup Status',
    name: 'pickupStatus',
    options: {
      display: false,
      sort: false,
      filter: false,
      customBodyRender: status => <OnTimeStatus status={status} />,
    },
  },
  {
    label: 'Delivery Status',
    name: 'deliveryStatus',
    options: {
      display: false,
      sort: false,
      filter: false,
      customBodyRender: status => <OnTimeStatus status={status} />,
    },
  },
  {
    label: 'Equipment Type',
    name: 'equipmentType',
    options: {
      ...filterOptions.equipmentType,
      sort: false,
    },
  },
  {
    label: 'Transportation Mode',
    name: 'transportationMode',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Origin',
    name: 'origin',
    options: {
      filter: false,
      sort: false,
      ...filterOptions.origin,
      display: 'excluded',
    },
  },
  {
    label: 'Destination',
    name: 'destination',
    options: {
      filter: false,
      sort: false,
      ...filterOptions.destination,
      display: 'excluded',
    },
  },
];
