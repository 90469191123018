/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react'
import { Page, PageContentLayout, PageContainer } from '@leaf/components'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import DialogContentText from '@mui/material/DialogContentText'
import Beta from '@/components/Beta'
import { Button, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useStateMachine } from 'little-state-machine'
import { useSnackbar } from 'notistack'
import { OverlayLoader } from '@/contracts/shared/OverlayLoader'
import { TabContent } from './partials/TabContent'
import CustomDialog from '../shared/CustomDialog'

import { ContractSteps } from './contract-information/Steps'
import { SelectRoutes } from './routes-information/SelectRoutes'
import { updateContract, untrackNewEditRoutes, untrackDeleteRoutes } from './domain/stateMachine'
import {
  prepareContractDataForAPI,
  saveContract,
  changeDomicile,
  upsertContract,
  detachRouteFromContract,
  attachRouteToContract,
} from './domain/contractModel'

function a11yProps(index) {
  return {
    'id': `contract-tab-${index}`,
    'aria-controls': `contract-tabpanel-${index}`,
    'data-tabindex': index,
  }
}
const ROUTE_PATH = '/contracts/create-contract/:id/route'

export const NewContractFlow = ({ history, match, location }) => {
  const { enqueueSnackbar } = useSnackbar()
  const editDone = useRef(false)
  const url = match.path
  const tabBasedUrl = url.indexOf(ROUTE_PATH) !== -1 ? 1 : 0
  const duplicateContract = location.search.toLowerCase().includes('duplicate')
  const [currentTab, setCurrentTab] = useState(tabBasedUrl)
  const [openDialog, setOpenDialog] = useState(false)
  const [openReviewImpact, setOpenReviewImpact] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { actions, getState } = useStateMachine({
    updateContract,
    untrackNewEditRoutes,
    untrackDeleteRoutes,
  })
  const {
    isContractDirty,
    isGeneralStepValid,
    isTimeframeVolumeStepValid,
    isPricingStepValid,
    newEditRoutes,
    deleteRoutes,
  } = getState()

  const determinePageTitle = () => {
    if (duplicateContract) {
      return 'Duplicate Contract'
    }
    return match.params.id ? 'Edit Contract' : 'New Contract'
  }

  const callNextTab = () => {
    setCurrentTab(currentTab + 1)
  }

  const goBack = () => {
    if (match.params.id) {
      history.push(`/contracts/${match.params.id}?tab=details`)
    } else {
      history.push('/contracts')
    }
  }

  const undoAllRouteChanges = async (routeList) => {
    const results = routeList.map((routeId) =>
      detachRouteFromContract(match.params.id, routeId, {})
        .then(() => {
          actions.untrackNewEditRoutes(routeId)
        })
        .catch((error) => {
          console.log(`undoAllRouteChanges failded. ${error}`)
        })
    )
    return Promise.all(results)
  }

  const revertDeleteRoutes = async (routeList) => {
    const results = routeList.map((routeId) =>
      attachRouteToContract(match.params.id, routeId, {})
        .then(() => {
          actions.untrackDeleteRoutes(routeId)
        })
        .catch((error) => {
          console.log(`revertDeleteRoutes failded. ${error}`)
        })
    )
    return Promise.all(results)
  }
  const undoCurrentChanges = async () => {
    setIsSubmitting(true)
    const cleanUpOk = Promise.all([
      undoAllRouteChanges(newEditRoutes),
      revertDeleteRoutes(deleteRoutes),
    ])
    if (cleanUpOk) {
      sessionStorage.clear()
      setIsSubmitting(false)
      goBack()
    }
  }

  const handleAbort = (e) => {
    setOpenDialog(!openDialog)
    const confirm = e.target.getAttribute('data-accept')
    if (confirm) {
      undoCurrentChanges()
    }
  }

  const handleUpdateSuccess = () => {
    editDone.current = true
    setIsSubmitting(false)
    enqueueSnackbar(`Contract updated successfully!`, {
      variant: 'success',
    })
    sessionStorage.clear()
    goBack()
  }

  const updateContractDetails = () => {
    setIsSubmitting(true)
    const apiData = prepareContractDataForAPI(getState().newContract)
    // TODO: Delete oldRoute that was edited

    upsertContract(match.params.id, apiData)
      .then(() => {
        if (!!apiData.sellerId && apiData.isFleet) {
          const { dedicatedDomicile } = getState()
          let newDomicileData = {}
          if (typeof apiData.domicileAddress === 'string') {
            newDomicileData = {
              contractId: apiData.id,
              ...dedicatedDomicile,
              geo: JSON.stringify(dedicatedDomicile.geo),
              name: apiData.domicileName,
            }
          } else {
            newDomicileData = {
              contractId: apiData.id,
              geo: JSON.stringify({
                type: 'Point',
                coordinates: [
                  apiData?.domicileAddress?.latitude,
                  apiData?.domicileAddress?.longitude,
                ],
              }),
              street: apiData?.domicileAddress?.address,
              zip: apiData?.domicileAddress?.zipCode,
              state: apiData?.domicileAddress?.state,
              city: apiData?.domicileAddress?.city,
              name: apiData?.domicileName,
            }
          }

          console.log({ newDomicileData })
          changeDomicile(apiData.id, newDomicileData, apiData.dedicatedDomiciles[0])
            .then(handleUpdateSuccess)
            .catch((error) => {
              // enqueueSnackbar(error)
              console.log(error)
            })
        } else {
          handleUpdateSuccess()
        }
      })
      .catch((error) => {
        console.log(error)
        setIsSubmitting(false)
        // BREAKS AND NEEDS TO BE FIXED
        //  enqueueSnackbar(error);
      })
  }

  const saveNewContractDetails = () => {
    const successCallback = (res) => {
      setIsSubmitting(false)
      actions.updateContract({ id: res.id })
      enqueueSnackbar(`Contract saved successfully!`, { variant: 'success' })
      if (currentTab === 0 || (currentTab === 0 && duplicateContract)) {
        callNextTab()
      } else {
        goBack()
      }
    }
    setIsSubmitting(true)

    const { domicileName, domicileAddress, ...apiData } = prepareContractDataForAPI(
      getState().newContract
    )
    saveContract(apiData)
      .then((res) => {
        if (apiData.isFleet && domicileName && domicileAddress) {
          changeDomicile(res.id, {
            contractId: res.id,
            geo: JSON.stringify({
              type: 'Point',
              coordinates: [domicileAddress.latitude, domicileAddress.longitude],
            }),
            street: domicileAddress.address,
            zip: domicileAddress.zipCode,
            state: domicileAddress.state,
            city: domicileAddress.city,
            name: domicileName,
          })
            .then(() => {
              successCallback(res)
            })
            .catch((error) => {
              setIsSubmitting(false)
              enqueueSnackbar(error)
            })
        } else {
          successCallback(res)
        }
      })
      .catch((error) => {
        setIsSubmitting(false)
        enqueueSnackbar(error)
      })
  }

  const determineSaveOrUpdate = () => {
    if (match.params.id && !duplicateContract) {
      updateContractDetails()
    } else if (currentTab === 0) {
      // SAVE: new/duplicate contract
      saveNewContractDetails()
    } else {
      // done
      goBack()
    }
  }

  useEffect(() => {
    sessionStorage.clear()
  }, [])

  useEffect(() => {
    const navigateAway = history.listen((historyLocation) => {
      // clear store if user navigates aways from contract flow
      if (!historyLocation.pathname.includes('route')) {
        sessionStorage.clear()
      }
    })

    return () => {
      navigateAway()
    }
  }, [history])

  const isReadyToSave = () => {
    // ???
    if (match.params.id) {
      return (
        (isContractDirty && getState().newContract.fscId !== '') ||
        newEditRoutes.length > 0 ||
        deleteRoutes.length > 0
      )
    }
    return isGeneralStepValid && isTimeframeVolumeStepValid && isPricingStepValid
  }

  const switchTab = (event) => {
    const tabIndex = Number(event.target.getAttribute('data-tabindex'))
    if (tabIndex === currentTab) {
      return
    }
    setCurrentTab(tabIndex)
  }

  const isDoneButtonRequired = () => {
    let isDoneRequired = false
    if (currentTab === 1 && !match.params.id) {
      isDoneRequired = true
    } else if (currentTab === 1 && duplicateContract) {
      isDoneRequired = true
    }
    return isDoneRequired
  }

  const saveButtonText = () => {
    if (match.params.id && !duplicateContract) {
      return 'Review contract changes and save'
    }
    return isDoneButtonRequired() ? 'Done' : 'Save'
  }

  return (
    <Page title={determinePageTitle()} ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout hasSubheader fullHeight={false}>
          <OverlayLoader active={isSubmitting} />
          <Tabs
            value={currentTab}
            aria-label='Create a new contract'
            variant='fullWidth'
            centered={false}
            sx={{ mb: 3 }}
          >
            <Tab
              label='1. Create Contract'
              disabled={!match.params.id}
              onClick={switchTab}
              {...a11yProps(0)}
              sx={{
                paddingTop: '24px',
                paddingBottom: '24px',
              }}
            />
            <Tab
              label='2. Select Route'
              disabled={!match.params.id}
              onClick={switchTab}
              {...a11yProps(1)}
            />
          </Tabs>
          <TabContent value={currentTab} index={0}>
            <ContractSteps callNextTab={callNextTab} abortContract={false} />
          </TabContent>
          <TabContent value={currentTab} index={1}>
            <SelectRoutes />
          </TabContent>
        </PageContentLayout>
        <Stack
          spacing={2}
          direction='row'
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '12px 16px',
            backgroundColor: '#fff',
            borderTop: '1px solid #ccc',
            position: 'fixed',
            right: '0',
            left: '0',
          }}
        >
          <Typography>
            * All new shipper contracts will be created with Leaf as the Carrier
          </Typography>
          {!isDoneButtonRequired() && (
            <Button variant='contained' size='large' color='secondary' onClick={handleAbort}>
              Cancel
            </Button>
          )}
          <Button
            variant='contained'
            size='large'
            onClick={determineSaveOrUpdate}
            disabled={!isReadyToSave()}
          >
            {saveButtonText()}
          </Button>
        </Stack>
        <CustomDialog open={openDialog} title='Cancel Contract' handleClose={handleAbort}>
          <DialogContentText sx={{ p: '0 16px' }}>
            <Typography sx={{ mb: 2 }}>
              {match.params.id
                ? 'Are you sure you want to cancel your modifications?'
                : 'Are you sure you want to cancel the creation of this contract?'}
            </Typography>
            <Typography sx={{ mb: 2 }}>
              {match.params.id
                ? 'You will not be able to recover modifications made since the last time the contract was saved.'
                : 'You will not be able to recover it and your data will be lost.'}
            </Typography>
          </DialogContentText>
        </CustomDialog>
      </PageContainer>
    </Page>
  )
}
