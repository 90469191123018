import React from 'react'
import { Dialog, Typography, IconButton, Button, Box } from '@mui/material'
import { Close, Publish, Clear, CloudDownload, Warning } from '@mui/icons-material'
import { UploadButton, ImportSummaryTable, LazySpinner } from '@leaf/components'
import { useSnackbar } from 'notistack'
import ContractMatch from '@/domain/contract/ContractMatch'
import {
  DialogTitleContainer,
  DialogContentContainer,
  DialogContentPanel,
  UploadButtonContainer,
  FileUploadedContainer,
  IconWrapper,
  DialogContentTitle,
  DownloadTemplateContainer,
  DialogActionsContainer,
  IconButtonDownload,
  WarningText,
} from './ImportContractMatchesDialog.styled'

export default ({ open, handleCloseDialog, title }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [uploadedFile, setUploadedFile] = React.useState()
  const [failedDataList, setFailedDataList] = React.useState()
  const [isImporting, setIsImporting] = React.useState(false)
  const [importProgress, setImportProgress] = React.useState(0)
  const [openSummaryDialog, setOpenSummaryDialog] = React.useState(false)

  const handleDownload = () => {
    ContractMatch.downloadContractMatches([])
  }

  const handleUploadFile = (file) => {
    setUploadedFile(file)
  }

  const handleCloseImportDialog = () => {
    setUploadedFile(null)
    handleCloseDialog()
  }

  const handleValidateUploadedFile = async () => {
    if (uploadedFile) {
      try {
        setIsImporting(true)
        const data = await ContractMatch.importContractMatches(uploadedFile, true, (progress) => {
          const { loaded, total } = progress
          const percent = Math.floor((loaded * 100) / total)
          if (percent < 100) {
            setImportProgress(percent)
          }
        })
        if (data?.total === 0) {
          // Empty file
          enqueueSnackbar('Imported file is empty, please add data.', { variant: 'error' })
        } else {
          // Display import summary
          setImportProgress(100)
          setFailedDataList(data)
          setOpenSummaryDialog(true)
        }
      } catch (error) {
        enqueueSnackbar(error)
        setUploadedFile(null)
      } finally {
        setIsImporting(false)
        setTimeout(() => {
          setImportProgress(0)
        }, 1000)
      }
    }
  }

  const handleImportContractMatches = async () => {
    await ContractMatch.importContractMatches(uploadedFile, false)
    setImportProgress(100)
    setUploadedFile(null)
    handleCloseImportDialog()
    enqueueSnackbar('Contract matches imported successfully!', { variant: 'success' })
  }

  const renderUploadSuccessfully = () => (
    <FileUploadedContainer>
      <IconWrapper />
      <Typography variant='h6'>{uploadedFile?.name ? uploadedFile?.name : 'undefined'}</Typography>
      <Typography>{uploadedFile?.size ? `${uploadedFile?.size}b` : 'undefined'}</Typography>
      <IconButton onClick={() => handleUploadFile(null)} size='large'>
        <Clear color='disabled' />
      </IconButton>
    </FileUploadedContainer>
  )

  const handleCloseSummaryDialog = () => {
    setOpenSummaryDialog(false)
    setFailedDataList(null)
  }

  return (
    <Dialog fullWidth maxWidth='md' onClose={handleCloseImportDialog} open={open}>
      <DialogTitleContainer>
        <Typography variant='body2'>{title}</Typography>
        <IconButton onClick={handleCloseImportDialog}>
          <Close />
        </IconButton>
      </DialogTitleContainer>
      <DialogContentContainer dividers>
        {isImporting ? (
          <LazySpinner height='250px' />
        ) : (
          <Box height={250} width='100%'>
            <DialogContentPanel>
              <DialogContentTitle>
                <Typography variant='body2'>Import CSV file</Typography>
                <DownloadTemplateContainer onClick={handleDownload}>
                  <IconButtonDownload>
                    <CloudDownload />
                  </IconButtonDownload>
                  <Typography variant='body2'>Template</Typography>
                </DownloadTemplateContainer>
              </DialogContentTitle>
              <Typography variant='body1'>Upload Guid</Typography>
              <Typography>Maximum Rows Permitted: 500</Typography>
              <br />
              <WarningText variant='caption'>
                <Warning /> Note: Disabling match relationships will impact historical data and
                reporting.
              </WarningText>
            </DialogContentPanel>
            <UploadButtonContainer>
              {uploadedFile ? (
                renderUploadSuccessfully()
              ) : (
                <UploadButton
                  variant='contained'
                  color='primary'
                  icon={<Publish />}
                  label='CHOOSE A FILE'
                  handleUpload={handleUploadFile}
                  fileTypes={['.xls,.xlsx,.csv']}
                />
              )}
            </UploadButtonContainer>
          </Box>
        )}
      </DialogContentContainer>
      <DialogActionsContainer>
        <Button variant='outlined' onClick={handleCloseImportDialog}>
          CANCEL
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleValidateUploadedFile}
          disabled={!uploadedFile || isImporting}
        >
          IMPORT
        </Button>
      </DialogActionsContainer>
      {openSummaryDialog && (
        <ImportSummaryTable
          importSummary={failedDataList}
          onClose={handleCloseSummaryDialog}
          importProgress={importProgress}
          isLoading={isImporting}
          submitData={handleImportContractMatches}
        />
      )}
    </Dialog>
  )
}
