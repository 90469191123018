/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import styled from '@emotion/styled';
import { CameraAltRounded } from '@mui/icons-material';

import { CompanyAvatar } from '@leaf/components';

/**
 * Upload company logo to AWS S3 with a presigned URL
 * @param {string} companyId
 * @param {string} files
 * @returns the filepath to uploaded image
 */
export async function uploadImage(companyId, files) {
  const formData = new FormData();
  let presignedObject;
  let uploadResult;

  // Create a local instance of axios
  // to remove the header Authorization
  // AWS S3 complains about it
  const localAxios = axios.create();
  delete localAxios.defaults.headers.common.Authorization;

  try {
    // Get the AWS S3 presigned URL
    presignedObject = await axios.post(`/logos/${companyId}`);

    Object.entries(presignedObject.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    // !IMPORTANT: append file at the end
    formData.append('file', files[0]);

    // Upload to the "presignedObject.url"
    uploadResult = await localAxios.post(presignedObject.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    return { failed: true };
  }
  return uploadResult;
}

const AvatarWrapper = styled.div`
  position: relative;
  .MuiAvatar-root {
    height: 100px;
    width: 100px;
  }
  .MuiSvgIcon-root {
    position: absolute;
    top: 6px;
    right: 0px;
    z-index: 1;
    height: 16px;
    color: gray;
    cursor: pointer;
  }
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 2px;
  right: -6px;
  z-index: 1;
  border: 1px solid gray;
`;

function AvatarUpload({ name, id }) {
  const { enqueueSnackbar } = useSnackbar();

  if (!id) {
    return null;
  }

  const handlePhotoUpload = async event => {
    const { files } = event.currentTarget;

    const result = await uploadImage(id, files);

    if (result.failed) {
      enqueueSnackbar('Upload failed');
    } else {
      enqueueSnackbar('Success. The logo will be refreshed within the next 15 minutes!', {
        variant: 'success',
      });
    }
  };

  return (
    <AvatarWrapper>
      <input hidden accept="image/png" type="file" onChange={handlePhotoUpload} name="file" id="companyLogo" />
      <label aria-label="upload picture" htmlFor="companyLogo">
        <Circle title="Upload image">
          <CameraAltRounded />
        </Circle>
      </label>
      <CompanyAvatar name={name} id={id} />
    </AvatarWrapper>
  );
}

export default AvatarUpload;
