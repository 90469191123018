import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const OFFSET = 60;
const WIDTH = 432;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => `${theme.palette.background.default}`};
  min-width: ${({ shouldFitContainerWidth }) => (shouldFitContainerWidth ? `100%` : `${WIDTH}px`)};
  overflow-x: hidden;
  overflow-y: auto;
  height: ${({ shouldFitContainerHeight, offsetCount }) =>
    shouldFitContainerHeight ? `calc(100vh - 152px - ${offsetCount * OFFSET}px)` : '100%'};
  ${({ shouldFitContainerHeight, showHorizontalLine }) =>
    !shouldFitContainerHeight &&
    showHorizontalLine &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: ${WIDTH}px;
        border-right: 1px solid #e5e5e5;
        height: 100%;
      }
    `}
`;

const LANE_CARD_HEIGHT = 200;
const LANE_CARD_SPACING = 16;
export const LANE_CARD_HEIGHT_WITH_SPACING = LANE_CARD_HEIGHT + LANE_CARD_SPACING * 2;

export const Route = styled.div`
  height: calc(100% - 114px);
  width: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 36px;
  margin-top: 56px;
  background-color: ${({ color }) => `${color}`};
  border-radius: 4px;
  ::before {
    content: ${({ position }) => `'${position}'`};
    position: relative;
    left: -13px;
    top: -38px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: bold;
    line-height: 30px;
    padding-left: 1px;
    background-color: ${({ color }) => `${color}`};
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  ::after {
    content: ${({ position }) => `'${position + 1}'`};
    position: relative;
    left: -13px;
    top: 38px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: bold;
    line-height: 30px;
    padding-left: 1px;
    background-color: ${({ color }) => `${color}`};
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
`;

export const Powerlane = styled.div`
  height: calc(100% - 114px);
  width: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 36px;
  margin-top: 56px;
  position: relative;
  span {
    background-color: ${({ color }) => `${color}` || '#56ccf2'};
    width: 2px;
    height: 130%;
  }
  .powerlane-1 {
    position: absolute;
    left: 6px;
    top: -8px;
  }
  .powerlane-2 {
    position: absolute;
    left: 1px;
    top: -8px;
    svg {
      position: absolute;
      top: 38px;
      left: -14px;
      z-index: 9;
      background: white;
      border-radius: 50%;
      padding: 4px;
      box-shadow: rgb(37 40 43 / 12%) 0px 0px 8px;
      transform: rotateZ(90deg);
    }
  }
  .powerlane-3 {
    position: absolute;
    left: -4px;
    top: -8px;
  }
  ::before {
    content: ${({ position }) => `'${position}'`};
    position: relative;
    left: -13px;
    top: -38px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: bold;
    line-height: 30px;
    padding-left: 1px;
    background-color: ${({ color }) => `${color}`};
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  ::after {
    content: ${({ position }) => `'${position + 1}'`};
    position: relative;
    left: -13px;
    top: 38px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: bold;
    line-height: 30px;
    padding-left: 1px;
    background-color: ${({ color }) => `${color}`};
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
`;

export const RouteCard = styled(Box)`
  width: auto;
  height: ${LANE_CARD_HEIGHT}px;
  margin: ${LANE_CARD_SPACING}px;
  cursor: ${({ $hasExplorer }) => ($hasExplorer ? 'pointer' : 'default')};
  border-radius: 4px;
  display: flex;
  background-color: white;
  position: relative;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${LANE_CARD_HEIGHT}px;
        background: white;
        border-radius: 4px;
        opacity: 0.7;
        cursor: 'default';
      }
    `};
`;

export const Lane = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  margin-left: 30px;
`;

export const Distance = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.38);
  .MuiTypography-root {
    white-space: nowrap;
  }
  span {
    > .MuiTypography-root {
      margin-right: 8px;
    }
  }
`;

export const HorizontalLine = styled.div`
  background: #ccc;
  height: 1px;
  width: 100%;
  margin-left: 16px;
`;

export const Deadhead = styled.div`
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  line-height: 0;
  margin: 16px 0;
  .MuiTypography-root {
    background: ${({ theme }) => `${theme.palette.background.default}`};
    padding: 0 10px;
    line-height: 0;
  }
`;

export const TooltipWrapper = styled.div`
  > .MuiTypography-body1 {
    color: ${({ theme }) => `${theme.palette.primary.main}`};
    cursor: pointer;
  }
`;
