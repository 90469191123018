/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';
import styled from '@emotion/styled';

import { NavigationMenu, LazySpinner } from '@leaf/components';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import getMenuItems from './MenuItems';

const Root = styled.div`
  display: flex;
`;

const Content = styled.main`
  flex-grow: 1;
  min-height: 100vh;
  overflow-x: hidden;
`;

export default withAuthenticationRequired(
  ({ children, ...rest }) => (
    <Root>
      <Route {...rest}>
        <>
          <NavigationMenu menuItems={getMenuItems()} menuTitle="ADMIN" />
          <Content>{children}</Content>
        </>
      </Route>
    </Root>
  ),
  {
    onRedirecting: () => <LazySpinner />,
  },
);
