import axios from 'axios';
import { utility } from '@leaf/components';

export default class ContractMatch {
  static importContractMatches(file, readOnly, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    if (readOnly) {
      return axios.post('contracting/contract-matches/validate/import', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        timeout: 1000 * 300,
        onUploadProgress,
        validateStatus: status => status < 500,
      });
    }

    return axios.post('contracting/contract-matches/import', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      timeout: 1000 * 300,
      onUploadProgress,
    });
  }

  static downloadContractMatches(contract_matches) {
    return axios
      .post(
        `view/contract-matches/download`,
        {
          contract_matches,
        },
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        utility.table.triggerBrowserDownload(response, 'contractsMatches', 'xlsx');
      });
  }
}
