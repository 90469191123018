import { gql } from 'graphql-request'
import { utility } from '@leaf/components'

const WHERE = {
  COMPANY_VAR: gql`$company: [bigint!]`,
  COMPANY_CLAUSE: gql`shipper_id: { _in: $company }`,
}

const isUUID = (uuid) =>
  uuid?.length === 36 && [...uuid.matchAll(/–|-/g)].length === 4

const whereClause = ({ where, search }) => {
  let whereGQL = gql``
  const { origin, destination, ...rest } = where
  const whereVarsKeys = Object.keys(rest).filter(
    (k) => Array.isArray(rest[k]) && rest[k].length
  )

  if (
    whereVarsKeys.length ||
    (search !== undefined && search !== '') ||
    origin ||
    destination
  ) {
    whereGQL = gql`
    where: {
        _and: [
          ${whereVarsKeys.map((key) =>
            where[key]
              ? gql`
                 { ${WHERE[`${key.toUpperCase()}_CLAUSE`]}}
                `
              : ''
          )}

          ${
            search !== undefined && search !== ''
              ? gql`{
                _or: [
                  ${isUUID(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
                  {destination_name: { _ilike: "%${search}%" }}
                  {origin_name: { _ilike: "%${search}%" }}
                  {shipper: {name: { _ilike: "%${search}%" }}}
                ]
              }`
              : ''
          }

          ${
            origin
              ? gql`{
                _and: {
                  origin_geo: {
                    _cast: {
                      geography: {
                        _stDWithin: {
                          distance: ${utility.functions.metersToMiles(50)}, 
                          from: {
                            type: "Polygon", coordinates:${JSON.stringify(
                              origin.coordinates
                            )}
                          }
                        }
                      }
                    }
                  }
                }
              }`
              : ''
          }

          ${
            destination
              ? gql`{
                _and: {
                  destination_geo: {
                    _cast: {
                      geography: {
                        _stDWithin: {
                          distance: ${utility.functions.metersToMiles(50)}, 
                          from: {
                            type: "Polygon", coordinates:${JSON.stringify(
                              destination.coordinates
                            )}
                          }
                        }
                      }
                    }
                  }
                }
              }`
              : ''
          }
        ]
      }
    `
  }
  return whereGQL
}

const whereVar = ({ where }) => gql`
  ${where.company ? WHERE.COMPANY_VAR : ``}
`

export const getLanesGQL = (variables) => {
  const whereGQL = whereClause(variables)
  return gql`
    query getLanes($limit: Int, $orderBy: [lane_order_by], $offset: Int, ${whereVar(
      variables
    )}) {
      lane(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        id
        originName: origin_name
        destinationName: destination_name
        originGeo: origin_geo
        originH3Cells: origin_h3
        originPoint: origin_point
        destinationGeo: destination_geo
        destinationH3Cells: destination_h3
        destinationPoint: destination_point
        pathGeo: path
        leafMiles: leaf_miles
        deliveryType: delivery_type
        pickupType: pickup_type
        shipper {
          name
          id
        }
      }
      lane_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `
}
