import React, { useEffect, useMemo } from 'react'
import { Page, Tabs, BackButton, USER_TYPE, PageContentLayout } from '@leaf/components'
import { Alert, Box, Grid, Typography, Button, Tooltip } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { withRouter } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Loader from '@/components/Loader'
import Company from '@/domain/company/Company'
import General from '@/companies/details/CompanyDetailsGeneral'
import styled from '@emotion/styled'
import LoginAsUserActions from '@/components/impersonation/LoginAsUserActions'
import AdaptCompanyData from '@/domain/company/AdaptCompanyData'
import useGQL from '@/hooks/useGQL'
import AddEditCompanyDialog from '../add-edit-company/AddEditCompanyDialog'
import CompanyDetailsShipperFuelSurcharge from './CompanyDetailsShipperFuelSurcharge'
import { getCompanyDetails } from './domain/companyDetailsModel'

const CompanyActions = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing(3)};
  top: 70px;
  z-index: 1;
  gap: ${({ theme }) => theme.spacing(1)};
  display: flex;
  svg {
    color: rgba(0, 0, 0, 0.54);
  }
`

const CompanyDetails = ({
  match: {
    params: { id },
  },
  history,
  location,
}) => {
  const [company, setCompany] = React.useState(null)
  const [isCompanyLoading, setIsCompanyLoading] = React.useState(false)
  const [editDialog, setEditDialog] = React.useState({ isOpen: false })
  const getGQLClient = useGQL()

  const { enqueueSnackbar } = useSnackbar()

  const getCompany = () => {
    setIsCompanyLoading(true)
    getCompanyDetails(getGQLClient, id).then(({ data }) => {
      setIsCompanyLoading(false)
      AdaptCompanyData.read(data.id).then((adaptCompanyData) => {
        setCompany({
          ...data,
          ...adaptCompanyData,
        })
      })
    })
  }

  useEffect(() => {
    getCompany()
  }, [])

  useEffect(() => {
    if (!editDialog.isOpen && editDialog.shouldRefetch) {
      getCompany()
    }
  }, [editDialog])

  const tabs = useMemo(
    () =>
      company?.type === USER_TYPE.SHIPPER
        ? [
            {
              label: 'Details',
              content: <General data={(company && { ...company, ...company.view }) || {}} />,
            },
            {
              label: 'FSC',
              content: <CompanyDetailsShipperFuelSurcharge companyId={id} />,
            },
          ]
        : [
            {
              label: 'Details',
              content: <General data={(company && { ...company, ...company.view }) || {}} />,
            },
          ],
    [company, id]
  )

  if (isCompanyLoading) {
    return <Loader />
  }

  const handleCloseEditDialog = (shouldRefetch = false) => {
    setEditDialog({
      isOpen: false,
      shouldRefetch,
    })
  }

  const handleOpenEditDialog = () => {
    setEditDialog({
      isOpen: true,
    })
  }

  const handleImpersonationAsCompanyAdmin = () => Company.impersonateAsCompanyAdmin(id)

  const handleEditCompany = (editedCompany) => {
    Company.update(editedCompany, company.id)
      .then(() => {
        getCompany()
        handleCloseEditDialog(true)
      })
      .catch(enqueueSnackbar)
  }
  return (
    <Page
      ComponentTitleLeft={
        <Box display='flex'>
          <BackButton history={history} path='/companies' />
          {company && (
            <Box display='flex' flexDirection='column' justifyContent='center' marginLeft='16px'>
              <Typography variant='body2'>
                {company.name} #{company.id}
              </Typography>
            </Box>
          )}
        </Box>
      }
      isUppercase={false}
    >
      <Alert
        severity='error'
        sx={{
          display: 'flex',
          width: '100vw',
          fontSize: '1.5em',
          fontWeight: 'bold',
        }}
      >
        There&#39;s a newer version of this page available{' '}
        <a href={`https://admin2.leaflogistics.com/companies/${company?.id}`}>here</a>. Please note
        that there will be no further updates or fixes, use it at your own risk.
      </Alert>

      <Grid container>
        <Grid item xs={12}>
          <PageContentLayout withoutSpacing fullHeight noStyling>
            <CompanyActions>
              <LoginAsUserActions
                onLoginAsCompanyAdminClick={handleImpersonationAsCompanyAdmin}
                renderAsButton
              />
              <Tooltip title='Edit Company'>
                <Button variant='outlined' onClick={handleOpenEditDialog}>
                  <Edit />
                </Button>
              </Tooltip>
            </CompanyActions>
            <Tabs
              location={location}
              history={history}
              mainUrlPath={`/companies/${id}`}
              tabs={tabs}
            />
          </PageContentLayout>
        </Grid>
      </Grid>
      {editDialog.isOpen && (
        <AddEditCompanyDialog
          open
          onClose={handleCloseEditDialog}
          handleCreateCompany={handleEditCompany}
          editCompany={{ ...company }}
        />
      )}
    </Page>
  )
}

export default withRouter(CompanyDetails)
