import { useLocation, NavLink } from 'react-router-dom'
import {
  Construction,
  ExitToApp,
  Dashboard,
  Explore,
  PendingActions as PlanningIcon,
  LocalShipping as ShippingIcon,
  Business,
  AssignmentTurnedIn as ContractsIcon,
  Factory,
} from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'

import { isUserAllowedToAccessAdaptBoard } from '@/features'

export default () => {
  const location = useLocation()
  const { logout, user } = useAuth0()

  return [
    {
      text: 'Dashboard',
      icon: Dashboard,
      to: '/dashboard',
      link: NavLink,
      location,
    },
    {
      text: 'Accounts',
      icon: Business,
      childs: [
        { to: '/companies', text: 'Companies' },
        { to: '/users', text: 'Users' },
        { to: '/locations', text: 'Locations', show: false },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Adapt',
      icon: Explore,
      childs: [
        { to: '/adapt/lanes', text: 'Lanes' },
        { to: '/adapt/network-moves', text: 'Network Moves' },
        {
          to: '/adapt/board',
          text: 'Board',
          show: isUserAllowedToAccessAdaptBoard(user?.email),
        },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Planning',
      icon: PlanningIcon,
      to: '/planning',
      childs: [
        {
          external: true,
          to: 'https://admin2.leaflogistics.com/planning/plans',
          text: 'Lane Plans',
        },
        { to: '/planning/ops-plans', text: 'Ops Plans' },
        { to: '/lane-management', text: 'Lane Management' },
        { to: '/planning/fleet-discovery', text: 'Fleet Discovery' },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Contracting',
      to: '/contracts',
      icon: ContractsIcon,
      childs: [
        { to: '/contracts', text: 'Contracts' },
        { to: '/contract-matching', text: 'Contract Matching' },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Execution',
      icon: ShippingIcon,
      childs: [
        { to: '/shipments', text: 'Shipments' },
        { to: '/execution-lanes', text: 'Lanes' },
        { to: '/partner-locations-execution', text: 'Location Mapping' },
        { to: '/fuel-prices', text: 'Fuel prices' },
        { to: '/execution-locations', text: 'Locations' },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Tools',
      icon: Construction,
      link: NavLink,
      childs: [
        { to: '/tools/pc-miler-distance-lookup', text: 'PC*Miler' },
        { to: '/tools/shipper-network', text: 'Shipper Network' },
        { to: '/tools/multi-network', text: 'Multi Network' },
        {
          external: true,
          to: 'https://graph.leaflogistics.com/',
          text: 'Graph Explorer',
        },
        {
          external: true,
          to: 'https://price.leaflogistics.com/',
          text: 'Price Explorer',
        },
        {
          external: true,
          to: 'https://upload.leaflogistics.com/',
          text: 'Easy Upload',
        },
        { to: '/tools/adapt-upload', text: 'Company Metrics Upload' },
      ],
      location,
    },
    {
      text: 'Settings',
      icon: Factory,
      link: NavLink,
      childs: [
        {
          external: true,
          to: 'https://admin2.leaflogistics.com/equipment-types',
          text: 'Equipment Types',
        },
        {
          external: true,
          to: 'https://admin2.leaflogistics.com/fuel-surcharge-schedules',
          text: 'Fuel Surcharge Schedules',
        },
      ],
    },
    {
      text: 'LOGOUT',
      icon: ExitToApp,
      click: () => {
        logout({
          returnTo: window.location.origin,
        })
      },
    },
  ]
}
