/* eslint-disable no-console */
/* eslint-disable dot-notation */
/* eslint-disable prefer-destructuring */
import axios from 'axios'
import { omit } from 'lodash'
import { useSecureQuery } from '@/hooks/useGQL'
import { utility } from '@leaf/components'
import { GQL_SHIPPERS } from '@/graphql/filter-queries/GQL_COMPANIES'
import queryString from 'query-string'
import { GQL_CONTRACT_DEFAULT } from './GQL_CONTRACT_DEFAULT'
import { GQL_CONTRACT_BY_ID } from './GQL_CONTRACT_BY_ID'
import { GQL_LANE_BY_ID } from './GQL_LANE_BY_ID'
import { GQL_SEARCH_LANE } from './GQL_SEARCH_LANE'
import { GQL_ROUTES_BY_ID } from './GQL_ROUTES_BY_ID'

const HEX_PREFIX_TO_REMOVE = '\\x0'

const laneMapper = (lanes) =>
  lanes.map((lane) => ({
    ...lane,
    shipper: lane.shipper
      ? {
          value: lane.shipper.id,
          label: lane.shipper.name,
        }
      : undefined,
  }))

export const useContractOptions = () => {
  const [data, loading, error] = useSecureQuery(GQL_CONTRACT_DEFAULT)
  return [data, loading, error]
}

export const useLaneById = (variables) => {
  const [data, loading, error] = useSecureQuery(GQL_LANE_BY_ID, variables)
  return [data, loading, error]
}

export const getContractById = (secureClient, variables) =>
  secureClient
    .request(GQL_CONTRACT_BY_ID, variables)
    .then((res) => res)
    .catch((error) => {
      throw new Error(error)
    })

/**
 * @param {function} secureClient the GraphQL client with access token
 * @param {object} variables with the shape: {"where": {"id": {"_eq": "UUID"}}}
 *
 * @returns a Promise
 */
export const getLaneById = (secureClient, variables) =>
  secureClient
    .request(GQL_LANE_BY_ID, variables)
    .then((res) => laneMapper(res.lane))
    .catch((error) => {
      throw new Error(error)
    })

export const getRouteById = (getGQLClient, variables) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_ROUTES_BY_ID, variables)
      .then((res) => res)
      .catch((error) => {
        throw new Error(error)
      })
  )

export const prepareContractDataForAPI = (data) => {
  // format from MM/DD/YYYY(view) to YYYY/MM/DD(api)
  // TODO use date functions from `components` package
  const formatViewDateToApiDate = (date) => {
    const [month, day, year] = date.split('/')
    return `${year}-${month}-${day}`
  }

  const apiData = {}
  const cleanData = omit(data, [
    'contractRoutes',
    'contractPartyFlag',
    'contractParty',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ])

  Object.entries(cleanData).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      apiData[key] = value.map((v) => v.id)
    } else if (key === 'domicileAddress') {
      apiData[key] = value
    } else if (value instanceof Object) {
      apiData[key] = value.id
    } else if (key === 'startDate' || key === 'endDate') {
      apiData[key] = formatViewDateToApiDate(value)
    } else {
      apiData[key] = value === '' ? null : value
    }
  })
  return apiData
}

export const cleanLaneDataforAPI = (leg = {}) => {
  const ORIGIN_POINT_COORDINATES = 'originPoint'
  const DESTINATION_POINT_COORDINATES = 'destinationPoint'
  const otherApiRequiredFields = [
    'deliveryStops',
    'deliveryType',
    'destinationH3Cells',
    'destinationName',
    'originH3Cells',
    'originName',
    'pickupStops',
    'pickupType',
    'shipper',
  ]
  const cleanLaneDataForApi = {
    originPoint: {},
    destinationPoint: {},
  }

  Object.entries(leg).forEach(([key, value]) => {
    if (key === ORIGIN_POINT_COORDINATES || key === DESTINATION_POINT_COORDINATES) {
      cleanLaneDataForApi[key]['longitude'] = value.coordinates?.[0] ?? value.longitude
      cleanLaneDataForApi[key]['latitude'] = value.coordinates?.[1] ?? value.latitude
    } else if (key === 'shipper') {
      cleanLaneDataForApi.shipperId = value.value
    } else if (key === 'originH3Cells' || key === 'destinationH3Cells') {
      cleanLaneDataForApi[key] =
        typeof value?.[0] === 'string'
          ? value
          : value?.filter((cell) => !cell.isDeleted).map((cell) => cell.value)
    } else if (otherApiRequiredFields.includes(key)) {
      cleanLaneDataForApi[key] = value
    }
  })

  return cleanLaneDataForApi
}

export const setDataForContractFormView = (contract, options) => {
  let contractPartyFlag
  // map accessorialIds
  const accessorialIds = contract.accessorialIds.map((item) => ({
    id: item.accessorial.id,
    label: item.accessorial.label,
  }))
  // map contractParty
  const contractParty = options.contractParties.find((party) => {
    if (contract.buyerId !== null) {
      contractPartyFlag = 'SHIPPER'
      return party.id === contract.buyerId
    }
    contractPartyFlag = 'CARRIER'
    return party.id === contract.sellerId
  })
  // map fuelSurchargeSchedule
  const fscId = options.fuelSurchargeSchedule.find((item) => item.id === contract.fscId)

  const isFleet = contract.isFleet || false
  const shipperRoutePlanId =
    contract.shipperRoutePlans.length > 0 ? contract.shipperRoutePlans[0].id : null
  const cleanContract = omit(contract, ['shipperRoutePlans'])
  const startDate = utility.date.backendStringToViewString(contract.startDate)
  const endDate = utility.date.backendStringToViewString(contract.endDate)
  return {
    ...cleanContract,
    accessorialIds,
    contractParty,
    contractPartyFlag,
    shipperRoutePlanId,
    isFleet,
    fscId,
    startDate,
    endDate,
  }
}

export const isLaneEdited = (originalLane = {}, newLane = {}) => {
  if (originalLane.originName !== newLane.originName) {
    return true
  }
  if (originalLane.destinationName !== newLane.destinationName) {
    return true
  }
  if (originalLane.pickupType !== newLane.pickupType) {
    return true
  }
  if (originalLane.pickupStops !== newLane.pickupStops) {
    return true
  }
  if (originalLane.deliveryType !== newLane.deliveryType) {
    return true
  }
  if (originalLane.deliveryStops !== newLane.deliveryStops) {
    return true
  }
  const originalOriginH3Cells = originalLane.originH3Cells?.map((cell) =>
    typeof cell === 'string'
      ? cell.split(HEX_PREFIX_TO_REMOVE)[1]
      : cell?.value.split(HEX_PREFIX_TO_REMOVE)[1]
  )
  const filteredOriginCells =
    typeof newLane.originH3Cells?.[0] === 'string'
      ? newLane.originH3Cells
      : newLane.originH3Cells?.filter((cell) => !cell.isDeleted).map((cell) => cell.value)
  const newOriginH3Cells = (filteredOriginCells ?? newLane.laneSelector.originH3Cells).map((lane) =>
    lane.includes(HEX_PREFIX_TO_REMOVE) ? lane.split(HEX_PREFIX_TO_REMOVE)[1] : lane
  )
  if (
    originalOriginH3Cells.length !== newOriginH3Cells.length ||
    !originalOriginH3Cells.every((cell) => newOriginH3Cells.includes(cell))
  ) {
    return true
  }
  const originalDestinationH3Cells = originalLane.destinationH3Cells?.map((cell) =>
    typeof cell === 'string'
      ? cell.split(HEX_PREFIX_TO_REMOVE)[1]
      : cell?.value.split(HEX_PREFIX_TO_REMOVE)[1]
  )
  const filteredDestinationCells =
    typeof newLane.destinationH3Cells?.[0] === 'string'
      ? newLane.destinationH3Cells
      : newLane.destinationH3Cells?.filter((cell) => !cell.isDeleted).map((cell) => cell.value)
  const newDestinationH3Cells = (
    filteredDestinationCells ?? newLane.laneSelector.destinationH3Cells
  ).map((lane) =>
    lane.includes(HEX_PREFIX_TO_REMOVE) ? lane.split(HEX_PREFIX_TO_REMOVE)[1] : lane
  )
  if (
    originalDestinationH3Cells.length !== newDestinationH3Cells.length ||
    !originalDestinationH3Cells.every((cell) => newDestinationH3Cells.includes(cell))
  ) {
    return true
  }
  const originalOriginPoint = originalLane.originPoint?.coordinates
  const newOriginPoint =
    newLane.originPoint?.coordinates ?? newLane.laneSelector.originPoint.coordinates
  if (
    originalOriginPoint?.[0] !== newOriginPoint?.[0] ||
    originalOriginPoint?.[1] !== newOriginPoint?.[1]
  ) {
    return true
  }
  const originalDestinationPoint = originalLane.destinationPoint?.coordinates
  const newDestinationPoint =
    newLane.destinationPoint?.coordinates ?? newLane.laneSelector.destinationPoint.coordinates
  if (
    originalDestinationPoint?.[0] !== newDestinationPoint?.[0] ||
    originalDestinationPoint?.[1] !== newDestinationPoint?.[1]
  ) {
    return true
  }
  if (originalLane.shipper !== newLane.shipper) {
    return true
  }
  return false
}

export const saveContract = (data) =>
  axios.post(`contracting/contracts`, data).then((response) => response)

export const upsertContract = (id, data) =>
  axios.put(`contracting/contracts/${id}`, data).then((response) => response)

export const saveRoutes = (data) => axios.post(`routing/routes`, data).then((response) => response)

export const saveLanes = (data) => axios.post(`routing/lanes`, data).then((response) => response)

export const changeDomicile = (contractId, domicile, domicileId) => {
  if (domicileId) {
    return axios.put(`contracting/contracts/${contractId}/domiciles/${domicileId}`, domicile)
  }
  return axios.post(`contracting/contracts/${contractId}/domiciles`, domicile)
}

/**
 * Attach routeId to contract
 * @param {string} contractId as an UUID
 * @param {string} routeId as an UUID
 * @param {object} data with the shape of {isPrimary: true}
 * @returns a Promise
 */
export const attachRouteToContract = (contractId, routeId, data) =>
  axios
    .post(`contracting/contracts/${contractId}/routes/${routeId}`, data)
    .then((response) => response)

/**
 * Detach routeId from contract
 * @param {string} contractId as an UUID
 * @param {string} routeId as an UUID
 * @param {object} data with the shape of {isPrimary: true}
 * @returns a Promise
 */
export const detachRouteFromContract = (contractId, routeId, data) =>
  axios
    .delete(`contracting/contracts/${contractId}/routes/${routeId}`, data)
    .then((response) => response)
    .catch((error) => error)

/**
 * Search lanes by origin, destination or UUI
 * @param {function} secureClient the GraphQL client with access token
 * @param {object} variables with the shape:
 * {"_or":
 *    [
 *      {"origin_name": {"_ilike": "%5%"}},
 *      {"destination_name": {"_ilike": "%34%"}},
 *      {"id": {"_eq": "01848027-d4cc-5bc0-2d6e-c5ba5a712e4b"}}
 *    ]
 * }
 * @returns a Promise
 */
export const searchLane = (secureClient, variables) =>
  secureClient
    .request(GQL_SEARCH_LANE, variables)
    .then((res) => res)
    .catch((error) => {
      throw new Error(error)
    })

export const searchLaneV2 = (getGQLClient, variables) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_SEARCH_LANE, variables)
      .then((res) => res)
      .catch((error) => {
        throw new Error(error)
      })
  )

export const getShippers = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_SHIPPERS)
      .then((res) => res.company.map((e) => ({ label: e.name, value: e.id })))
  )

export const getImpactReport = (request) =>
  axios.post('/contracting/contracts/modification-impact', request)

export const getPath = (coordinates) => {
  const stops = coordinates.map((stop) => stop.coordinates.toString())
  const qs = queryString.stringify(
    {
      stops,
    },
    { arrayFormat: 'separator', arrayFormatSeparator: ';' }
  )
  return axios.get(`geo/driving-routes?${qs}`)
}
