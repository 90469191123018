import _ from 'lodash';
import initialState from './initialState';

export const SET_ADAPT_LANES_STATE = 'set.adapt-lanes.state';
export const RESET_ADAPT_LANES_STATE = 'reset.adapt-lanes.state';
export const SET_ADAPT_NETWORK_MOVES_STATE = 'set.adapt-network-moves.state';
export const RESET_ADAPT_NETWORK_MOVES_STATE = 'reset.adapt-network-moves.state';
export const SET_PARTNER_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE =
  'set.partner-locations-execution-overview.table.state';
export const SET_SHIPMENTS_OVERVIEW_TABLE_STATE = 'set.shipments-overview.table.state';
export const SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE = 'set.shipper-contracts-overview.table.state';
export const SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE = 'set.shipper-contract-matches-overview.table.state';
export const SET_CONTRACT_MATCHING_OVERVIEW_TABLE_STATE = 'set.contract-matching-overview.table.state';
export const SET_CONTRACT_SHIPMENTS_TABLE_STATE = 'set.contract-shipments.table.state';
export const SET_LANES_OVERVIEW_TABLE_STATE = 'set.lanes-overview.table.state';
export const SET_CONTRACT_TRIPS_TABLE_STATE = 'set.contract-trips.table.state';
export const SET_COMPANY_DETAILS_LOCATIONS_OVERVIEW_TABLE_STATE = 'set.company-details-locations-overview.table.state';
export const SET_COMPANIES_OVERVIEW_TABLE_STATE = 'set.companies-overview.table.state';
export const SET_FUEL_PRICES_OVERVIEW_TABLE_STATE = 'set.fuel-prices-overview.table.state';
export const SET_ADAPT_BOARD_STATE = 'set.adapt-board.state';
export const SET_ADAPT_BOARD_FILTER_STATE = 'set.adapt-board.filter.state';
export const SET_ADAPT_BOARD_LANE_PHASES_STATE = 'set.adapt-board.lane-phase.state';
export const SET_ADAPT_BOARD_NETWORK_MOVES_STATE = 'set.adapt-board.network-moves.state';
export const SET_ADAPT_BOARD_NETWORK_MOVES_FILTER_STATE = 'set.adapt-board.network-moves.filter.state';
export const SET_OPS_OVERVIEW_TABLE_STATE = 'set.ops-overview.table.state';
export const SET_OPS_CONTRACTS_TABLE_STATE = 'set.ops-contracts.table.state';
export const SET_FLEET_DISCOVERY_OVERVIEW_TABLE_STATE = 'set.fleet-discovery-overview.table.state';
export const SET_USERS_OVERVIEW_TABLE_STATE = 'set.users.table.state';
export const SET_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE = 'set.locations-execution-overview.table.state';
export const SET_EXECUTION_LANES_OVERVIEW_TABLE_STATE = 'set.execution.lane-overview.table.state';

export default (state, action) => {
  switch (action.type) {
    case SET_ADAPT_LANES_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptLanes: action.payload,
        },
      };
    }
    case SET_ADAPT_NETWORK_MOVES_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptNetworkMoves: action.payload,
        },
      };
    }
    case SET_PARTNER_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          partnerLocationsExecutionOverview: action.payload,
        },
      };
    }
    case RESET_ADAPT_LANES_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptLanes: _.cloneDeep(initialState.tables.adaptLanes),
        },
      };
    }
    case RESET_ADAPT_NETWORK_MOVES_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptNetworkMoves: _.cloneDeep(initialState.tables.adaptNetworkMoves),
        },
      };
    }
    case SET_SHIPMENTS_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          shipmentsOverview: action.payload,
        },
      };
    }
    case SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          shipperContractsOverview: action.payload,
        },
      };
    }
    case SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          shipperContractMatchesOverview: action.payload,
        },
      };
    }
    case SET_CONTRACT_MATCHING_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          contractMatchingOverview: action.payload,
        },
      };
    }
    case SET_CONTRACT_SHIPMENTS_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          contractShipmentsOverview: action.payload,
        },
      };
    }
    case SET_LANES_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          lanesOverview: action.payload,
        },
      };
    }
    case SET_CONTRACT_TRIPS_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          contractTripsOverview: action.payload,
        },
      };
    }
    case SET_COMPANY_DETAILS_LOCATIONS_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          companyDetailsLocationsOverview: action.payload,
        },
      };
    }
    case SET_COMPANIES_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          companiesOverview: action.payload,
        },
      };
    }
    case SET_FUEL_PRICES_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          fuelPricesOverview: action.payload,
        },
      };
    }
    case SET_ADAPT_BOARD_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptBoard: action.payload,
        },
      };
    }
    case SET_ADAPT_BOARD_FILTER_STATE: {
      let filter = {};
      if (!action.payload) {
        const { shipperName, ...newFilter } = state.tables.adaptBoard.filters;
        filter = { ...newFilter };
      } else {
        filter = { ...state.tables.adaptBoard.filters, shipperName: [action.payload] };
      }
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptBoard: {
            ...state.tables.adaptBoard,
            filters: {
              ...filter,
            },
          },
        },
      };
    }
    case SET_ADAPT_BOARD_LANE_PHASES_STATE: {
      return {
        ...state,
        lanePhases: {
          ...state.lanePhases,
          lanePhasesByShipper: action.payload,
        },
      };
    }
    case SET_ADAPT_BOARD_NETWORK_MOVES_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptBoardNetworkMoves: action.payload,
        },
      };
    }
    case SET_ADAPT_BOARD_NETWORK_MOVES_FILTER_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          adaptBoardNetworkMoves: {
            ...state.tables.adaptBoardNetworkMoves,
            filters: {
              ...state.tables.adaptBoardNetworkMoves.filters,
              ...(action.payload ? { shipperName: [action.payload] } : { shipperName: [] }),
            },
          },
        },
      };
    }
    case SET_OPS_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          opsPlansOverview: action.payload,
        },
      };
    }
    case SET_OPS_CONTRACTS_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          opsPlanContracts: action.payload,
        },
      };
    }
    case SET_FLEET_DISCOVERY_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          fleetDiscoveryOverview: action.payload,
        },
      };
    }
    case SET_USERS_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          usersOverview: action.payload,
        },
      };
    }
    case SET_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          executionLocationsOverview: action.payload,
        },
      };
    }
    case SET_EXECUTION_LANES_OVERVIEW_TABLE_STATE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          executionLanesOverview: action.payload,
        },
      };
    }
    default:
      throw new Error('Action not defined');
  }
};
