import React, { useContext } from 'react'
import { useSnackbar } from 'notistack'
import {
  ServerSideTable,
  useTableCustomFilters,
  SHIPMENT_STATUS_DISPLAY_NAMES,
} from '@leaf/components'
import { StateContext } from '@/state/StateProvider'
import { SET_CONTRACT_TRIPS_TABLE_STATE } from '@/state/stateReducer'
import useGQL from '@/hooks/useGQL'
import { getCarrierColumns } from './table-config'
import { getContractTrips, getShippers } from './domain/contractTripsModel'

export default ({ contractId }) => {
  const { enqueueSnackbar } = useSnackbar()
  const getGQLClient = useGQL()

  const {
    state: {
      tables: { contractTripsOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext)

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'shipper',
      filterOptionsGetter: () => getShippers(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'status',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(SHIPMENT_STATUS_DISPLAY_NAMES).map((key) => ({
            label: SHIPMENT_STATUS_DISPLAY_NAMES[key],
            value: key,
          }))
        ),
    },
  ])

  return (
    <>
      <ServerSideTable
        options={{ filter: true }}
        columns={getCarrierColumns(filterOptions)}
        fn={() => getContractTrips({ getGQLClient, tableState, contractId })}
        tableState={tableState}
        dispatch={dispatch}
        stateSetterAction={SET_CONTRACT_TRIPS_TABLE_STATE}
        errorHandler={enqueueSnackbar}
      />
    </>
  )
}
