import React from 'react'
import { Chip, IconButton, Tooltip, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { v4 } from 'uuid'
import {
  ContractStatus,
  ContractType,
  NewTabLink,
  utility,
  Lane,
  CompanyAvatar,
  USER_TYPE,
  TripType,
  Button,
  LocationFilter,
} from '@leaf/components'
import { Link as LinkIcon, Add as AddIcon, Publish, CloudDownload } from '@mui/icons-material'
import AdaptLane from '@/domain/adapt/AdaptLane'

const { ActionButtons } = utility.table
const { VITE_UI_CLIENT_APP_URL } = import.meta.env

const RouteChip = styled(Chip)`
  border-radius: 20%;
  background-color: #001530;
  color: white;
  font-weight: 500;
`

export const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`

export const getOptions = (history, handleOpenImportDialog, handleDownloadContracts) => ({
  filter: true,
  customToolbar: () => (
    <>
      <Tooltip title='Download Contracts'>
        <IconButton onClick={handleDownloadContracts}>
          <CloudDownload />
        </IconButton>
      </Tooltip>
      <Tooltip title='Import Contracts'>
        <IconButton onClick={handleOpenImportDialog}>
          <Publish />
        </IconButton>
      </Tooltip>
      <Button
        sx={{ ml: 2 }}
        variant='contained'
        startIcon={<AddIcon />}
        onClick={() => {
          history.push('/contracts/create-contract/')
        }}
      >
        new contract
      </Button>
    </>
  ),
  onRowClick: (rowData) => {
    const id = rowData[0]
    history.push(`/contracts/${id}`)
  },
  ...utility.table.getRowHoverActionProps('contract'),
})

export const getColumns = (filterOptions, copy) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Contract Status',
    name: 'contractStatus',
    field: 'contract_status',
    options: {
      filter: false,
      customBodyRender: (status) => <ContractStatus status={status} />,
    },
  },
  {
    label: 'Contract Type',
    name: 'contractType',
    field: 'contract_type',
    options: {
      sort: true,
      display: true,
      ...filterOptions.contractType,
      customBodyRender: (type) => <ContractType type={type} />,
    },
  },
  {
    label: 'Contract Weekly Volume',
    name: 'maxVolume',
    options: {
      filter: false,
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Equipment Type',
    name: 'equipmentType',
    options: {
      ...filterOptions.equipmentType,
      display: false,
      sort: false,
    },
  },
  {
    label: 'Move Type',
    name: 'tripTypes',
    options: {
      ...filterOptions.tripTypes,
      sort: false,
      display: false,
      customBodyRender: (type) => <TripType type={type} />,
    },
  },
  {
    label: 'Accessorials',
    name: 'accessorials',
    options: {
      ...filterOptions.accessorials,
      sort: false,
      display: false,
      customBodyRender: (accessorials) => accessorials.join(', '),
    },
  },
  {
    label: 'Routes',
    name: 'routes',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (routes) => <RouteChip size='small' label={routes} />,
    },
  },
  {
    label: 'Display Route',
    name: 'displayRoutes',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (routes) =>
        routes?.map((r) => {
          if (r.type === 'DOMICILE') {
            return <Typography sx={{ fontWeight: 'bold' }}>{r.domicile.name}</Typography>
          }
          const { laneType, origin, destination, shipper } = r.lane
          return (
            <LaneWrapper key={v4()} isSingleRow={routes.length === 1}>
              <Lane
                displayAsGrid
                type={laneType}
                origin={{ primaryContent: [origin] }}
                destination={{ primaryContent: [destination] }}
                shipper={shipper}
              />
            </LaneWrapper>
          )
        }),
    },
  },
  {
    label: 'Range Date',
    name: 'rangeDate',
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
      ...filterOptions.rangeDate,
      customFilterListOptions: {
        render: (v) => v.map((l) => l).join(' - '),
      },
    },
  },
  {
    label: 'Origin',
    name: 'origin',
    options: {
      sort: false,
      display: 'excluded',
      filterType: 'custom',
      customFilterListOptions: {
        render: (v) => {
          if (v?.length === 4) return `Origin: ${v[3]} (${v[2]} miles)`
          return undefined
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
          <LocationFilter
            filterList={filterList}
            onChange={onChange}
            index={index}
            column={column}
            getLocations={AdaptLane.getLocation}
            defaultRadius={100}
          />
        ),
      },
    },
  },
  {
    label: 'Destination',
    name: 'destination',
    options: {
      sort: false,
      display: 'excluded',
      filterType: 'custom',
      customFilterListOptions: {
        render: (v) => {
          if (v?.length === 4) return `Destination: ${v[3]} (${v[2]} miles)`
          return undefined
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
          <LocationFilter
            filterList={filterList}
            onChange={onChange}
            index={index}
            column={column}
            getLocations={AdaptLane.getLocation}
            defaultRadius={100}
          />
        ),
      },
    },
  },
  {
    label: 'Account Name',
    name: 'accountName',
    options: {
      ...filterOptions.accountName,
      sort: false,
      customBodyRender: ({ shipper, carrier }) => {
        if (shipper) {
          return (
            <CompanyAvatar name={shipper.name} id={shipper.id} type={USER_TYPE.SHIPPER} isChip />
          )
        }
        return <CompanyAvatar name={carrier.name} id={carrier.id} type={USER_TYPE.CARRIER} isChip />
      },
    },
  },
  {
    label: 'Start Date',
    name: 'startDate',
    field: 'start_date',
    options: {
      filter: false,
    },
  },
  {
    label: 'End Date',
    name: 'endDate',
    field: 'end_date',
    options: {
      filter: false,
    },
  },
  {
    label: 'Linehaul RPM',
    name: 'linehaulRpm',
    field: 'linehaul_rpm',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Minimum Linehaul Charge',
    name: 'minimumLinehaulCharge',
    field: 'minimum_linehaul_charge',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Fixed Fee per Shipment',
    name: 'fixedFeePerShipment',
    field: 'fixed_fee_per_shipment',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Carrier Upcharge',
    name: 'carrierUpcharge',
    field: 'carrier_upcharge',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Rate Per Asset',
    name: 'assetPricing',
    options: {
      filter: false,
      sort: false,
      display: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Asset Pricing Frequency',
    name: 'assetPricingFrequency',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: (actionsData) => {
        const { id } = actionsData
        const text = `${VITE_UI_CLIENT_APP_URL}/share/opportunities/${id}`
        return (
          <ActionButtons id={`Actions-contract-${id}`}>
            <Tooltip title='Copy Contract Link'>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  copy(text)
                }}
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <NewTabLink url={`/contracts/${id}`} />
          </ActionButtons>
        )
      },
    },
  },
]
