/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import GenericSelect from '../generic/Select';

export default ({
  name,
  label,
  control,
  error,
  options,
  variant = 'outlined',
  disabled = false,
  controllerProps,
  helperText,
}) => (
  <Controller
    as={
      <GenericSelect
        options={options}
        label={label}
        variant={variant}
        error={error}
        disabled={disabled}
        helperText={helperText}
        fullWidth
      />
    }
    name={name}
    control={control}
    {...controllerProps}
  />
);
