import React from 'react'

import Center from '@/components/Center'

export default () => (
  <Center>
    <h1>404</h1>
    <p>This is not the web page you are looking for.</p>
  </Center>
)
