import React from 'react'
import AvatarUpload from '@/components/AvatarUpload'
import { USER_TYPE, utility } from '@leaf/components'
import { Chip } from '@mui/material'
import { v4 } from 'uuid'

export const getCompanyGeneralDetails = (data) => [
  {
    type: 'row',
    data: {
      label: 'Logo',
      value: <AvatarUpload name={data.name} id={data.id} />,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Account team members',
      value: ' ',
    },
  },
  {
    type: 'row',
    data: {
      label: (
        <>
          {data.accountTeamMembers?.length
            ? data.accountTeamMembers?.map(({ user }) => (
                <Chip label={`${user.firstName} ${user.lastName}`} key={user.id} />
              ))
            : '-'}
        </>
      ),
      value: ' ',
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Transaction fee [%]',
      value: utility.numbers.toFixed(utility.numbers.toPercentage(data.transactionFee), 2),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Registered name',
      value: data.registeredName,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Registered state',
      value: data.registeredState ?? '-',
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: (
        <div>
          {data.claimedDomainsArray?.map((domain) => (
            <Chip key={v4()} label={domain} />
          ))}
        </div>
      ),
      value: ' ',
    },
  },
]

export const getCompanyContactDetails = (data) => [
  {
    type: 'row',
    data: {
      label: 'Tender E-mail address',
      value: data.tenderEmailAddress,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Tender phone',
      value: data.tenderPhoneNumber,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Phone',
      value: data.phone,
    },
  },
  {
    type: 'divider',
  },
]

export const getCompanyTypeSpecificDetails = (data) =>
  data.type === USER_TYPE.SHIPPER
    ? [
        {
          type: 'row',
          data: {
            label: 'Adapt enabled',
            value: data.hasAdapt ? 'Yes' : 'No',
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'Lane Explorer - Run date',
            value: utility.date.normalizedUtcDateToViewString(data.runDate),
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'Lane Explorer - Window start date',
            value: utility.date.normalizedUtcDateToViewString(data.windowStartDate),
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'Lane Explorer - Window end date',
            value: utility.date.normalizedUtcDateToViewString(data.windowEndDate),
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: '4Kites Integration',
            value: data.use4KitesIntegration ? 'Yes' : 'No',
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'Trimble Integration',
            value: data.useTrimbleIntegration ? 'Yes' : 'No',
          },
        },
        {
          type: 'divider',
        },
      ]
    : [
        {
          type: 'row',
          data: {
            label: 'DOT number',
            value: data.usDotNumber,
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'MC number',
            value: data.usMcNumber,
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'SCAC code',
            value: data.scacCode,
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'row',
          data: {
            label: 'Project44 Integration',
            value: data.useProject44Integration ? 'Yes' : 'No',
          },
        },
        {
          type: 'divider',
        },
      ]
