import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Link as LinkIcon, Publish, CloudDownload } from '@mui/icons-material';
import styled from '@emotion/styled';
import { v4 } from 'uuid';

import { utility, CompanyAvatar, Lane, NewTabLink } from '@leaf/components';

const { ActionButtons } = utility.table;

const LaneWrapper = styled.div`
  margin-bottom: ${({ isSingleRow, theme }) => `${!isSingleRow ? theme.spacing(1) : 0}`};
  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }
`;

export const getOptions = (history, handleDownloadOpsPlans, handleOpenImportDialog) => ({
  filter: true,
  customToolbar: () => (
    <>
      <Tooltip title="Download Ops Plans">
        <IconButton onClick={handleDownloadOpsPlans}>
          <CloudDownload />
        </IconButton>
      </Tooltip>
      <Tooltip title="Import Ops Plans">
        <IconButton onClick={handleOpenImportDialog}>
          <Publish />
        </IconButton>
      </Tooltip>
    </>
  ),
  onRowClick: rowData => {
    const id = rowData[0];
    history.push(`/planning/ops-plans/${id}`);
  },
  ...utility.table.getRowHoverActionProps('OpsPlans'),
});

export const getColumns = (filterOptions, copy) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Shipper ID',
    name: 'company.id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Company',
    name: 'company',
    options: {
      ...filterOptions.company,
      download: false,
      customBodyRender: ({ id, name, type }) => <CompanyAvatar name={name} id={id} type={type} isChip />,
    },
  },
  {
    label: 'Route ID',
    name: 'route.id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Route',
    name: 'route',
    options: {
      filter: false,
      sort: false,
      download: false,
      customBodyRender: ({ legs }) =>
        legs?.map(({ lane: { origin, destination } }) => (
          <LaneWrapper key={v4()} isSingleRow={legs.length === 1}>
            <Lane displayAsGrid origin={{ primaryContent: [origin] }} destination={{ primaryContent: [destination] }} />
          </LaneWrapper>
        )),
    },
  },
  {
    label: 'Equipment Type ID',
    name: 'equipmentType.id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Equipment Type',
    name: 'equipmentType',
    options: {
      ...filterOptions.equipmentType,
      download: false,
      customBodyRender: ({ name }) => name,
    },
  },
  {
    label: 'Adapt Lane ID',
    name: 'adaptLaneId',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Adapt Run Date',
    name: 'adaptRunDate',
    field: 'adapt_run_date',
    options: {
      filter: false,
      display: false,
      customBodyRender: date => utility.date.backendStringToViewString(date) ?? '-',
    },
  },
  {
    label: 'Adapt Weekly Volume',
    name: 'adaptWeeklyVolume',
    field: 'adapt_weekly_volume',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Adapt Target Shipper RPM',
    name: 'adaptTargetShipperRpm',
    field: 'adapt_target_shipper_rpm',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'Planned Weekly Volume',
    name: 'plannedWeeklyVolume',
    field: 'planned_weekly_volume',
    options: {
      filter: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Planned Allocation % from Shipper',
    name: 'plannedAllocationPct',
    field: 'planned_allocation_pct',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'Planned Shipper RPM',
    name: 'plannedShipperRpm',
    field: 'planned_shipper_rpm',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'Planned Start Date',
    name: 'plannedStartDate',
    field: 'planned_start_date',
    options: {
      filter: false,
      customBodyRender: date => utility.date.backendStringToViewString(date) ?? '-',
    },
  },
  {
    label: 'Planned End Date',
    name: 'plannedEndDate',
    field: 'planned_end_date',
    options: {
      filter: false,
      customBodyRender: date => utility.date.backendStringToViewString(date) ?? '-',
    },
  },
  {
    label: 'Planned Leaf Tender Acceptance',
    name: 'plannedLeafTenderAcceptance',
    field: 'planned_leaf_tender_acceptance_pct',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'Maximum Capacity (Spot/Yellow)',
    name: 'maxCapacityPctSpot',
    field: 'max_capacity_pct_spot',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'Maximum Capacity (Routing Guide/Blue)',
    name: 'maxCapacityPctRg',
    field: 'max_capacity_pct_rg',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'Maximum Capacity (Fleet)',
    name: 'maxCapacityPctFleet',
    field: 'max_capacity_pct_fleet',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'Maximum Capacity (Flex Weekly)',
    name: 'maxCapacityPctFlexWeekly',
    field: 'max_capacity_pct_flex_weekly',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'Maximum Capacity (Network Move)',
    name: 'maxCapacityPctNetworkMove',
    field: 'max_capacity_pct_network_move',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'Maximum Capacity (Flex Day Of)',
    name: 'maxCapacityPctFlexDayOf',
    field: 'max_capacity_pct_flex_day_of',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(pct => utility.numbers.toPercent(pct * 100)),
    },
  },
  {
    label: 'LSP Target Linehaul RPM (Spot/Yellow)',
    name: 'lspTargetLinehaulRpmSpot',
    field: 'lsp_target_linehaul_rpm_spot',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'LSP Target Linehaul RPM (Routing Guide/Blue)',
    name: 'lspTargetLinehaulRpmRg',
    field: 'lsp_target_linehaul_rpm_rg',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'LSP Target Linehaul RPM (Fleet)',
    name: 'lspTargetLinehaulRpmFleet',
    field: 'lsp_target_linehaul_rpm_fleet',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'LSP Target Linehaul RPM (Flex Weekly)',
    name: 'lspTargetLinehaulRpmFlexWeekly',
    field: 'lsp_target_linehaul_rpm_flex_weekly',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'LSP Target Linehaul RPM (Network Move)',
    name: 'lspTargetLinehaulRpmNetworkMove',
    field: 'lsp_target_linehaul_rpm_network_move',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'LSP Target Linehaul RPM (Flex Day Of)',
    name: 'lspTargetLinehaulRpmFlexDayOf',
    field: 'lsp_target_linehaul_rpm_flex_day_of',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(rpm => utility.numbers.formatNumberAsView(rpm, 2)),
    },
  },
  {
    label: 'Implementation Week (Spot/Yellow)',
    name: 'implementationPlanWeekSpot',
    field: 'implementation_plan_week_spot',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(week => week ?? '-'),
    },
  },
  {
    label: 'Implementation Week (Routing Guide/Blue)',
    name: 'implementationPlanWeekRg',
    field: 'implementation_plan_week_rg',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(week => week ?? '-'),
    },
  },
  {
    label: 'Implementation Week (Fleet)',
    name: 'implementationPlanWeekFleet',
    field: 'implementation_plan_week_fleet',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(week => week ?? '-'),
    },
  },
  {
    label: 'Implementation Week (Flex Weekly)',
    name: 'implementationPlanWeekFlexWeekly',
    field: 'implementation_plan_week_flex_weekly',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(week => week ?? '-'),
    },
  },
  {
    label: 'Implementation Week (Network Move)',
    name: 'implementationPlanWeekNetworkMove',
    field: 'implementation_plan_week_network_move',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(week => week ?? '-'),
    },
  },
  {
    label: 'Implementation Week (Flex Day Of)',
    name: 'implementationPlanWeekFlexDayOf',
    field: 'implementation_plan_week_flex_day_of',
    options: {
      filter: false,
      display: false,
      ...utility.table.rightAlignColumnProps(week => week ?? '-'),
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: actionsData => {
        const { id } = actionsData;
        const text = `${window.location.host}/planning/ops-plans/${id}`;
        return (
          <ActionButtons id={`Actions-OpsPlans-${id}`}>
            <Tooltip title="Copy Ops Plan Link">
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  copy(text);
                }}
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <NewTabLink url={`/planning/ops-plans/${id}`} />
          </ActionButtons>
        );
      },
    },
  },
];
