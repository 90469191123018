import React, { useState, useContext } from "react";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { Alert } from "@mui/material";

import {
  Page,
  ServerSideTable,
  useTableCustomFilters,
  PageContentLayout,
  PageContainer,
  useCopyToClipboard,
  TRIP_TYPE_DISPLAY_NAMES,
  CONTRACT_TYPE,
  CONTRACT_TYPE_DISPLAY_NAMES,
} from "@leaf/components";
import Beta from "@/components/Beta";

import { SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE } from "@/state/stateReducer";
import { StateContext } from "@/state/StateProvider";

import useGQL from "@/hooks/useGQL";
import Contract from "@/domain/contract/Contract";
import {
  getAccessorials,
  getCompanies,
  getContracts,
  getEquipmentTypes,
  shipperContractsQuickFilters,
} from "./domain/shipperContractModel";
import { getColumns, getOptions } from "./table-config";
import ImportContractDialog from "./import/ImportContractDialog";

export default withRouter(({ history }) => {
  const {
    state: {
      tables: { shipperContractsOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const getGQLClient = useGQL();
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const copy = useCopyToClipboard(
    (successMessage) => enqueueSnackbar(successMessage, { variant: "success" }),
    (errorMessage) => enqueueSnackbar(errorMessage, { variant: "error" })
  );

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: "autocomplete",
      columnName: "equipmentType",
      filterOptionsGetter: () => getEquipmentTypes(getGQLClient),
    },
    {
      inputType: "autocomplete",
      columnName: "accessorials",
      filterOptionsGetter: () => getAccessorials(getGQLClient),
    },
    {
      inputType: "autocomplete",
      columnName: "accountName",
      filterOptionsGetter: () => getCompanies(getGQLClient),
    },
    {
      inputType: "autocomplete",
      columnName: "tripTypes",
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(TRIP_TYPE_DISPLAY_NAMES).map((key) => ({
            label: TRIP_TYPE_DISPLAY_NAMES[key],
            value: key,
          }))
        ),
    },
    {
      inputType: "autocomplete",
      columnName: "contractType",
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(CONTRACT_TYPE).map((key) => ({
            label: CONTRACT_TYPE_DISPLAY_NAMES[key],
            value: key,
          }))
        ),
    },
    {
      inputType: "daterange",
      columnName: "rangeDate",
    },
  ]);

  const handleChange = (setTableState) =>
    dispatch({
      type: SET_SHIPPER_CONTRACTS_OVERVIEW_TABLE_STATE,
      payload: setTableState(tableState),
    });

  const handleDownloadContracts = async () => {
    const { data } = await getContracts(getGQLClient, tableState, true);

    Contract.downloadContracts(data.map((contract) => contract.id));
  };

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true);
  };

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false);
  };

  return (
    <Page title="Contracts" ComponentTitleRight={<Beta />}>
      <Alert
        severity="error"
        sx={{
          display: "flex",
          width: "100vw",
          fontSize: "1.5em",
          fontWeight: "bold",
        }}
      >
        There&#39;s a newer version of this page available{" "}
        <a href="https://admin2.leaflogistics.com/contracts">here</a>. Please
        note that there will be no further updates or fixes, use it at your own
        risk.
      </Alert>

      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(
              history,
              handleOpenImportDialog,
              handleDownloadContracts
            )}
            columns={getColumns(filterOptions, copy)}
            fn={() => getContracts(getGQLClient, tableState)}
            quickFilterComponents={[
              {
                label: "Type",
                name: "companyTypes",
                values: ["SHIPPER", "LSP"],
                inputType: "toggle-button",
              },
              {
                label: "Time",
                name: "timeStatuses",
                values: ["PAST", "ONGOING", "FUTURE"],
                inputType: "toggle-button",
              },
              {
                label: "Status",
                name: "contractStatuses",
                values: ["DRAFT", "CONTRACTED"],
                inputType: "toggle-button",
              },
            ]}
            gqlQuickFiltersMeta={shipperContractsQuickFilters}
            tableState={tableState}
            setTableState={handleChange}
            errorHandler={enqueueSnackbar}
          />
          <ImportContractDialog
            open={openImportDialog}
            handleCloseDialog={handleCloseImportDialog}
            title="IMPORT CONTRACTS"
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
});
