/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Box, Button, Typography, DialogContentText } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useStateMachine } from 'little-state-machine'
import useGQL from '@/hooks/useGQL'
import { useSnackbar } from 'notistack'
import { ROUTE_EVENTS } from '@/contracts/shared/constants'
import CustomDialog from '@/contracts/shared/CustomDialog'
import { getContractRoutes } from '@/contracts/details/domain/contractDetailsModel'
import { OverlayLoader } from '@/contracts//shared/OverlayLoader'
import { RoutesOverview } from '@leaf/components'
import styled from '@emotion/styled'
import { ROUTE_CREATE_EVENTS } from './constants'
import { SectionHeader } from '../partials/viewHelpers'
import { CreateRoute } from './CreateRoute'
import {
  searchLaneV2,
  detachRouteFromContract,
  attachRouteToContract,
} from '../domain/contractModel'
import {
  setDefaultLaneOptions,
  updateRouteCount,
  untrackNewEditRoutes,
  trackDeleteRoutes,
  setContractToDirty,
} from '../domain/stateMachine'

const RouteOverviewContainer = styled.div`
  height: 70vh;
`

export function SelectRoutes() {
  const location = useLocation()
  const params = useParams()
  const getGQLClient = useGQL()
  const { getState, actions } = useStateMachine({
    setDefaultLaneOptions,
    updateRouteCount,
    untrackNewEditRoutes,
    trackDeleteRoutes,
    setContractToDirty,
  })
  const [openAddRoute, setOpenAddRoute] = useState(false)
  const [openDialogToConfirmDelete, setOpenDialogToConfirmDelete] = useState(false)
  const [editingRoute, setEditingRoute] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [routeList, setRouteList] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const needToAttachRouteToDuplicateContract = useRef(
    location.search.toLowerCase().includes('duplicate')
  )
  const [needRoutesData, setNeedRoutesData] = useState(true)
  const firstRender = useRef(false)
  const routeId = useRef(null)
  const editModeFlow = useRef(params.id && !location.search.toLowerCase().includes('duplicate'))

  const { newContract: contract, ROUTE_COUNT, newEditRoutes } = getState()

  const manageRoutesActions = (action, route) => {
    if (action && route) {
      routeId.current = route.id
      if (action.toUpperCase() === ROUTE_EVENTS.DELETE) {
        setOpenDialogToConfirmDelete(true)
      }

      if (action.toUpperCase() === ROUTE_EVENTS.EDIT) {
        setEditingRoute(route)
      }

      if (action.toUpperCase() === ROUTE_EVENTS.SET_PRIMARY_ROUTE) {
        attachRouteToContract(contract.id, route.id, { isPrimary: true })
          .then(() => {
            enqueueSnackbar(`Successfuly updated primary route `, { variant: 'success' })
            setNeedRoutesData(true)
            actions.setContractToDirty(true)
          })
          .catch((error) => {
            enqueueSnackbar(`Setting primary route failded. ${error}`)
          })
      }
    }
    if (action.toUpperCase() === ROUTE_CREATE_EVENTS.ROUTES_CHANGES) {
      setNeedRoutesData(true)
    }
  }

  const deleteRoute = () => {
    detachRouteFromContract(contract.id, routeId.current, {})
      .then(() => {
        // id deleting route is in the new newEditRoutes
        // remove from it.
        if (newEditRoutes.includes(routeId.current)) {
          actions.untrackNewEditRoutes(routeId.current)
        } else {
          // track deleting route
          actions.trackDeleteRoutes(routeId.current)
        }
        routeId.current = null
        actions.updateRouteCount(ROUTE_COUNT - 1)
        enqueueSnackbar(`Successfuly detached Route(s) from contract!`, { variant: 'success' })
        setNeedRoutesData(true)
      })
      .catch((error) => {
        routeId.current = null
        enqueueSnackbar(`Detaching routes from contract failded. ${error}`)
      })
  }

  const toggleAddRoute = () => {
    setEditingRoute(null)
    setOpenAddRoute(!openAddRoute)
  }

  const handleCreateRouteAction = (actionTaken) => {
    toggleAddRoute()
    routeId.current = null
    setEditingRoute(null)
    manageRoutesActions(actionTaken, null)
  }

  const mayExecuteDelete = (event) => {
    setOpenDialogToConfirmDelete(!openDialogToConfirmDelete)
    const confirm = event.target.getAttribute('data-accept')
    if (confirm) {
      deleteRoute()
    }
  }

  useEffect(() => {
    if (getGQLClient && !firstRender.current) {
      const variables = contract.buyerId
        ? { where: { _and: [{ shipper: { id: { _eq: contract.buyerId } } }] } }
        : { where: {} }
      searchLaneV2(getGQLClient, variables).then((res) => {
        actions.setDefaultLaneOptions(res.lane)
      })
      firstRender.current = true
    }
  }, [getGQLClient, actions, contract.buyerId])

  useEffect(() => {
    if (routeId.current && editingRoute) {
      setOpenAddRoute(true)
    }
  }, [editingRoute, routeId])

  useEffect(() => {
    let contractRoutes
    const attachRoutesToDuplicateConract = async (duplicateRoutes) => {
      const results = duplicateRoutes.map((route) =>
        attachRouteToContract(contract.id, route.id, { isPrimary: route.isPrimary })
          .then((res) => res)
          .catch((error) => {
            enqueueSnackbar(`Failed to attach route to duplicate contract . ${error}`)
          })
      )
      return Promise.all(results)
    }
    const manageDuplicateRoutes = async () => {
      contractRoutes = await getContractRoutes(getGQLClient, params.id).catch((error) => {
        enqueueSnackbar(`Failed to fetch original routes for the duplicate contract . ${error}`)
      })
      return attachRoutesToDuplicateConract(contractRoutes.data[0].routes)
    }

    if (needToAttachRouteToDuplicateContract.current) {
      setIsLoading(true)
      //  Fetch all routes from original contract
      manageDuplicateRoutes().then(() => {
        enqueueSnackbar(`Successfuly attach routes to duplicate contract `, { variant: 'success' })
        setRouteList(contractRoutes.data[0])
        setIsLoading(false)
      })
      needToAttachRouteToDuplicateContract.current = false
    }
  }, [getGQLClient, params.id, enqueueSnackbar, contract.id])

  useEffect(() => {
    if (contract.id && needRoutesData) {
      setIsLoading(true)
      getContractRoutes(getGQLClient, contract.id).then(({ data }) => {
        setRouteList(data[0])
        setIsLoading(false)
      })
      setNeedRoutesData(false)
    }
  }, [getGQLClient, contract.id, needRoutesData])

  return (
    <Box sx={{ position: 'relative', minHeight: '40vh' }}>
      <SectionHeader>
        <Button onClick={toggleAddRoute} startIcon={<AddCircleIcon />}>
          Add route
        </Button>
      </SectionHeader>
      {isLoading && <OverlayLoader active={isLoading} />}

      <RouteOverviewContainer>
        <RoutesOverview routesData={routeList} editDeleteRouteAction={manageRoutesActions} />
      </RouteOverviewContainer>

      <CustomDialog
        maxWidth='xl'
        fullWidth
        open={openAddRoute}
        title='Select Route'
        handleClose={toggleAddRoute}
        defaultActions={false}
      >
        <CreateRoute
          callbackAction={handleCreateRouteAction}
          editingRoute={editingRoute}
          editModeFlow={editModeFlow.current}
        />
      </CustomDialog>

      <CustomDialog
        open={openDialogToConfirmDelete}
        title='Delete Route'
        handleClose={mayExecuteDelete}
      >
        <DialogContentText sx={{ p: '0 16px' }}>
          <Typography sx={{ mb: 2 }}>Are you sure you want to delete this route?</Typography>
          <Typography sx={{ mb: 2 }}>
            You will not be able to recover it and your data will be lost.
          </Typography>
        </DialogContentText>
      </CustomDialog>
    </Box>
  )
}
