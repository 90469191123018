import React, { useRef } from 'react'
import { IconButton, Grow, Input, InputAdornment } from '@mui/material'
import { Search, Cancel } from '@mui/icons-material'
import { Box } from '@mui/material'

function debounce(func, wait, immediate) {
  let timeout

  return (...args) => {
    const context = this

    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const classes = {
  main: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
  },
  searchIcon: {
    color: '#183b5a',
    marginRight: '8px',
  },
  searchText: {
    'flex': '0.6 0',
    '&.MuiInput-underline:before': {
      border: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.54);',
    },
    '&.MuiInput-underline:after': {
      borderBottom: '1px solid #4A90E2',
    },
  },
  searchTextBorder: {
    'borderBottom': '1px solid #757575',
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  clearIcon: {
    'color': 'rgba(0, 0, 0, 0.34);',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54);',
    },
  },
}

const CustomSearchFieldComponent = (props) => {
  const { searchText, onSearch, debounceWait, autoFocus } = props
  let searchField = useRef()

  const handleTextChangeWrapper = (debouncedSearch) => (event) => {
    debouncedSearch(event.target.value)
  }

  const handleTextClearWrapper = (debouncedSearch) => () => {
    debouncedSearch('')
    searchField.value = ''
  }

  const debouncedSearch = debounce((value) => {
    onSearch(value)
  }, debounceWait)

  return (
    <Grow appear in timeout={300}>
      <Box sx={classes.main}>
        <Search sx={classes.searchIcon} />
        <Input
          variant='standard'
          sx={{
            ...classes.searchText,
            ...(searchText ? classes.searchTextBorder : {}),
          }}
          autoFocus={autoFocus}
          defaultValue={searchText}
          onChange={handleTextChangeWrapper(debouncedSearch)}
          fullWidth
          inputRef={(el) => {
            searchField = el
          }}
          placeholder='Search'
          endAdornment={
            searchText ? (
              <InputAdornment position='end'>
                <IconButton
                  sx={classes.clearIcon}
                  onClick={handleTextClearWrapper(debouncedSearch)}
                  size='large'
                >
                  <Cancel />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        />
      </Box>
    </Grow>
  )
}

const CustomSearchField = CustomSearchFieldComponent

export { CustomSearchField }

export const debounceCustomSearchRender =
  (debounceWait = 200, autoFocus = false) =>
  (searchText, handleSearch, hideSearch, options) =>
    (
      <CustomSearchField
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
        options={options}
        debounceWait={debounceWait}
        autoFocus={autoFocus}
      />
    )
