import * as Sentry from '@sentry/react'

export default () => {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: window.location.hostname,
    })
  }
}
