/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { utility } from '@leaf/components'
import { getCurrentUserMetadata } from '@/authentication/token'
import { GQL_COMPANIES } from '@/graphql/filter-queries/GQL_COMPANIES'
import { getCompaniesGQL } from './GQL_COMPANIES'
import {
  GQL_ACCOUNT_TEAM_MEMBERS,
  getAccountTeamMembersByCompanyGQL,
} from './GQL_ACCOUNT_TEAM_MEMBERS'
import { getUsersByRoleGQL } from './GQL_USERS'

const mapCompanies = (companies) =>
  companies.map((companyItem) => ({
    ...companyItem,
    view: { id: companyItem.id },
    created: utility.date.formatStringDate(companyItem.created, utility.date.VIEW_FORMAT),
    accountTeamMembers: companyItem.accountTeamMembers.map((member) => member.user),
  }))

const getOrderBy = (sort) => {
  if (sort) {
    return [{ [sort.field ?? sort.name]: sort.order }]
  }
  return null
}

const onlyMyAccFilter = (client, currentUserAccountMember) => {
  if (Array.isArray(currentUserAccountMember) && currentUserAccountMember.length === 1 && client) {
    const userData = getCurrentUserMetadata(client?.options?.headers?.Authorization.split(' ')[1])
    return [Number(userData?.uid)]
  }
}

export const getCompanies = (getGQLClient, tableState) => {
  const variables = {
    sort: tableState.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      company_type: tableState.filters.companyType ? tableState.filters.companyType : undefined,
      account_team_members: tableState.filters.accountTeamMembers
        ? tableState.filters.accountTeamMembers
        : undefined,
      current_user_account_member: tableState.filters.currentUserAccountMember
        ? tableState?.filters?.currentUserAccountMember
        : undefined,
    },
  }
  const { where, search, sort, ...rest } = variables

  return getGQLClient().then((client) => {
    where.current_user_account_member = onlyMyAccFilter(
      client,
      tableState?.filters?.currentUserAccountMember
    )
    const GQL = getCompaniesGQL({
      where,
      search,
      ...rest,
    })
    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then((res) => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.company_aggregate.aggregate.count,
      data: mapCompanies(res.company),
    }))
  })
}

export const getAllCompanies = (getGQLClient) =>
  getGQLClient().then((client) => client.request(GQL_COMPANIES).then((res) => res.company))

export const getAccountTeamMembers = (getGQLClient) =>
  getGQLClient().then((client) =>
    client.request(GQL_ACCOUNT_TEAM_MEMBERS).then((res) =>
      res.account_team_member.map(({ user }) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }))
    )
  )

export const getUsers = (getGQLClient, role) =>
  getGQLClient().then((client) =>
    client.request(getUsersByRoleGQL(role)).then((res) => res.user.map((user) => user))
  )

export const getAccountTeamMembersByCompany = (getGQLClient, companyId) =>
  getGQLClient().then((client) =>
    client.request(getAccountTeamMembersByCompanyGQL(companyId)).then(({ accountTeamMember }) =>
      accountTeamMember.map(({ user }) => ({
        ...user,
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }))
    )
  )
