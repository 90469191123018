import { divider, disabled, deEmphasize } from './colors';

const createPalette = () => ({
  primary: {
    main: '#4a90e2',
    dark: '#0064b0',
    light: '#83c1ff',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#183b5a',
    dark: '#001530',
    light: '#476587',
    contrastText: '#ffffff',
  },
  actions: {
    border: '#e0e0e0',
  },
  info: {
    main: '#4a90e2',
    dark: '#0064b0',
    light: '#83c1ff',
    darkText: '#001530',
  },
  error: {
    main: '#f44336',
    dark: '#e31b0c',
    light: '#f88078',
  },
  warning: {
    main: '#ff9800',
    dark: '#c77700',
    light: '#ffb547',
  },
  success: {
    main: '#4caf50',
    dark: '#3b873e',
    light: '#7bc67e',
  },
  background: {
    main: '#ffffff',
    default: '#fafafa',
  },
  divider,
  disabled,
  deEmphasize,
  matchContract: '#7b61ff',
});

export default createPalette;
