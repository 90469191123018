import React from 'react'

import { Alert } from '@mui/material'
import { Page } from '@leaf/components'
import { HolisticsDashboard } from './HolisticsDashboard'

const DashboardView = () => {
  return (
    <Page title='Dashboard'>
      <Alert
        severity='error'
        sx={{
          display: 'flex',
          width: '100vw',
          fontSize: '1.5em',
          fontWeight: 'bold',
        }}
      >
        There&#39;s a newer version of this page available{' '}
        <a href='https://admin2.leaflogistics.com/dashboard'>here</a>. Please note that there will
        be no further updates or fixes, use it at your own risk.
      </Alert>

      <HolisticsDashboard />
    </Page>
  )
}

export default DashboardView
