import { gql } from 'graphql-request'

export const getCompanyFSCGQL = (id) => gql`
  query getCompanyFSC {
    fuelSurchargeSchedule: fuel_surcharge_schedule(where: { company_id: { _eq: "${id}" } }) {
      id
      name
      peg
      increment
      validFrom: valid_from
      bucketSize: bucket_size
      isFirstStepZero: is_first_step_zero
      created: created_at
      fuelPriceIndex: fuel_price_index {
        id
        name
      }
    }
  }
`
