import { getUsers } from '@/domain/user/GQL_USERS.js'
import { GQL_COMPANIES } from '@/graphql/filter-queries/GQL_COMPANIES'

const getOrderBy = (sort) => {
  if (sort) {
    return [{ [sort.field ?? sort.name]: `${sort.order}_nulls_last` }]
  }
  return null
}

export const getAll = (getGQLClient, tableState) => {
  const variables = {
    sort: tableState.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    where: {
      company_id: tableState.filters?.company,
      role: tableState.filters?.role,
      active: tableState.filters?.active,
    },
  }
  const { where, search, sort, ...rest } = variables

  return getGQLClient().then((client) => {
    const GQL = getUsers({
      where,
      search,
      ...rest,
    })
    return client
      .request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) })
      .then((response) => ({
        limit: rest.limit,
        offset: rest.offset,
        total: response.user_aggregate.aggregate.count,
        data: response.user,
      }))
  })
}

export const getCompanies = (getGQLClient) =>
  getGQLClient().then((client) =>
    client
      .request(GQL_COMPANIES)
      .then((response) => response.company.map((e) => ({ label: e.name, value: e.id })))
  )
