/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Form } from '@leaf/components'
import { useStateMachine } from 'little-state-machine'
import { isEqual } from 'lodash'

import { disableEnterKey } from '@/utility/disableEnterKey'
import { triggerSubmitEvent } from '@/utility/form'
import usePrevious from '@/hooks/usePrevious'

import { FormContent, FormItem, FormFooter, FormActions } from '../partials/viewHelpers'
import {
  updateContract,
  setContractToDirty,
  setPricingStepValid,
  defaultValues as resetValues,
} from '../domain/stateMachine'

const PRICING_MECHANISM_OPTIONS = ['FIXED', 'INDEX', 'OPEN_BOOK']
const ASSET_PRICING_FREQUENCY_OPTIONS = ['DAILY', 'WEEKLY']
const MIN_CHARGEABLE_MILES_FREQUENCY_OPTIONS = ['DAILY', 'WEEKLY']
const PRICE_INDEX_OPTIONS = ['DAT']

export function Pricing({ next, back, abortContract }) {
  const formRef = useRef(null)
  const { actions, getState } = useStateMachine({
    updateContract,
    setPricingStepValid,
    setContractToDirty,
  })
  const [formState, setFormState] = useState()

  const {
    newContract: defaultValues,
    CONTRACT_FORM_OPTIONS: { fuelSurchargeSchedule },
  } = getState()

  const buyerId = parseInt(defaultValues.buyerId, 10)
  const FSC_OPTIONS =
    defaultValues.buyerId !== ''
      ? fuelSurchargeSchedule.filter((fsc) => fsc.companyId === buyerId)
      : fuelSurchargeSchedule.filter(
          (fsc) =>
            fsc.companyId === null && fsc.equipmentTypeId === defaultValues.equipmentTypeId.id
        )

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'all',
    defaultValues,
  })
  const previousIsValid = usePrevious(isValid)
  const previousIsDirty = usePrevious(isDirty)

  const updateContractState = useCallback(
    (data) => {
      actions.updateContract(data)
    },
    [actions]
  )

  const handleFormSubmit = (formData, event) => {
    actions.updateContract(formData)
    if (event.nativeEvent.submitter) {
      next()
    }
  }

  const watchPricingMechanism = watch('pricingMechanism')
  const watchAssetPricing = watch('assetPricing')
  const watchMinChargeableMiles = watch('minChargeableMiles')
  const watchAllIn = watch('allin')
  const watchAllFields = watch()

  useEffect(() => {
    if (abortContract) {
      actions.updateAction(resetValues)
      reset(resetValues)
    }
  }, [abortContract, actions, reset])

  useEffect(() => {
    if (watchPricingMechanism === 'FIXED') {
      setValue('carrierUpcharge', '')
      setValue('fixedFeePerShipment', defaultValues.fixedFeePerShipment)
      setValue('linehaulRpm', defaultValues.linehaulRpm)
      setValue('minimumLinehaulCharge', defaultValues.minimumLinehaulCharge)
      setValue('pricingIndexPremium', '')
      setValue('pricingIndex', '')
      setValue('minTenderLeadTimeHrs', '')
    } else if (watchPricingMechanism === 'OPEN_BOOK') {
      setValue('carrierUpcharge', defaultValues.carrierUpcharge)
      setValue('fixedFeePerShipment', '')
      setValue('linehaulRpm', '')
      setValue('minimumLinehaulCharge', '')
      setValue('pricingIndexPremium', '')
      setValue('pricingIndex', '')
      setValue('minTenderLeadTimeHrs', '')
    } else {
      setValue('carrierUpcharge', '')
      setValue('fixedFeePerShipment', '')
      setValue('linehaulRpm', '')
      setValue('minimumLinehaulCharge', '')
      setValue('pricingIndexPremium', defaultValues.pricingIndexPremium)
      setValue('pricingIndex', defaultValues.pricingIndex)
      setValue('minTenderLeadTimeHrs', defaultValues.minTenderLeadTimeHrs)
      setValue('assetPricing', '')
      setValue('assetPricingFrequency', '')
      setValue('minChargeableMiles', '')
      setValue('minChargeableMilesFrequency', '')
    }
    trigger()
  }, [watchPricingMechanism])

  useEffect(() => {
    const formData = getValues()
    if (!isEqual(formData, formState)) {
      setFormState(formData)
      trigger()
    }
  }, [watchAllFields])

  useEffect(() => {
    if (watchAllIn) {
      setValue('fscId', '')
      trigger()
    }
  }, [watchAllIn])

  useEffect(() => {
    actions.updateContract(formState)
    trigger()
  }, [formState])

  useEffect(() => {
    if (!previousIsDirty && isDirty) {
      actions.setContractToDirty(isDirty)
      trigger()
    }
    if (isDirty && previousIsValid !== isValid) {
      actions.setPricingStepValid(isValid)
      actions.updateContract(getValues())
      trigger()
    }
  }, [isDirty, isValid, actions, getValues, getState, updateContractState, trigger])

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      onKeyDown={(e) => disableEnterKey(e)}
      ref={formRef}
    >
      <FormContent>
        <FormItem>
          <Typography variant='h5'>RPM Pricing</Typography>
          <Controller
            name='pricingMechanism'
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={PRICING_MECHANISM_OPTIONS}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={(option) => option}
                onChange={(_, data) => {
                  onChange(data)
                  if (defaultValues.id) {
                    triggerSubmitEvent(formRef)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Pricing Mechanism*'
                    error={!!errors.pricingMechanism}
                    helperText={errors.pricingMechanism ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
          <TextField
            name='carrierUpcharge'
            label='Carrier Upcharge'
            type='number'
            defaultValue={defaultValues.carrierUpcharge}
            {...register('carrierUpcharge', {
              required: false,
            })}
            onChange={() => {
              if (defaultValues.id) {
                triggerSubmitEvent(formRef)
              }
            }}
            error={watchPricingMechanism === 'OPEN_BOOK' ? errors.carrierUpcharge : false}
            helperText={errors.carrierUpcharge ? 'Required field' : null}
            inputRef={register}
            variant='outlined'
            fullWidth
            disabled={watchPricingMechanism !== 'OPEN_BOOK'}
            InputProps={{
              inputProps: { step: 0.01, min: -100, max: 100 },
              startAdornment: (
                <InputAdornment position='start' sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>%</b>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name='fixedFeePerShipment'
            label='Fixed Fee per Shipment'
            type='number'
            defaultValue={defaultValues.fixedFeePerShipment}
            {...register('fixedFeePerShipment', {
              required: false,
            })}
            onChange={() => {
              if (defaultValues.id) {
                triggerSubmitEvent(formRef)
              }
            }}
            error={watchPricingMechanism === 'FIXED' ? errors.fixedFeePerShipment : false}
            helperText={errors.fixedFeePerShipment ? 'Required field' : null}
            inputRef={register}
            variant='outlined'
            fullWidth
            disabled={watchPricingMechanism !== 'FIXED'}
            InputProps={{
              inputProps: { min: 0, step: 0.01 },
              startAdornment: (
                <InputAdornment position='start' sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>$</b>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name='linehaulRpm'
            label='Linehaul RPM'
            type='number'
            defaultValue={defaultValues.linehaulRpm}
            {...register('linehaulRpm', {
              required: watchPricingMechanism === 'FIXED',
            })}
            onChange={() => {
              if (defaultValues.id) {
                triggerSubmitEvent(formRef)
              }
            }}
            error={watchPricingMechanism === 'FIXED' ? errors.linehaulRpm : false}
            helperText={errors.linehaulRpm ? 'Required field' : null}
            inputRef={register}
            variant='outlined'
            fullWidth
            disabled={watchPricingMechanism !== 'FIXED'}
            InputProps={{
              inputProps: { min: 0, step: 0.01 },
              startAdornment: (
                <InputAdornment position='start' sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>$</b>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name='minimumLinehaulCharge'
            label='Minimum Linehaul Charge'
            type='number'
            defaultValue={defaultValues.minimumLinehaulCharge}
            {...register('minimumLinehaulCharge', {
              required: false,
            })}
            onChange={() => {
              if (defaultValues.id) {
                triggerSubmitEvent(formRef)
              }
            }}
            error={watchPricingMechanism === 'FIXED' ? errors.minimumLinehaulCharge : false}
            helperText={errors.minimumLinehaulCharge ? 'Required field' : null}
            inputRef={register}
            variant='outlined'
            fullWidth
            disabled={watchPricingMechanism !== 'FIXED'}
            InputProps={{
              inputProps: { min: 0, step: 0.01 },
              startAdornment: (
                <InputAdornment position='start' sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>$</b>
                </InputAdornment>
              ),
            }}
          />

          <Controller
            name='pricingIndex'
            control={control}
            rules={{ required: watchPricingMechanism === 'INDEX' }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={PRICE_INDEX_OPTIONS}
                disabled={watchPricingMechanism !== 'INDEX'}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={(option) => option}
                onChange={(_, data) => {
                  onChange(data)
                  if (defaultValues.id) {
                    triggerSubmitEvent(formRef)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Pricing Index'
                    error={watchPricingMechanism === 'INDEX' ? !!errors.pricingIndex : false}
                    helperText={errors.pricingIndex ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
          <TextField
            name='pricingIndexPremium'
            label='Pricing Index Premium'
            type='number'
            {...register('pricingIndexPremium', {
              required: watchPricingMechanism === 'INDEX',
            })}
            onChange={() => {
              if (defaultValues.id) {
                triggerSubmitEvent(formRef)
              }
            }}
            error={watchPricingMechanism === 'INDEX' ? errors.pricingIndexPremium : false}
            helperText={errors.pricingIndexPremium ? 'Required field' : null}
            inputRef={register}
            defaultValue={defaultValues.pricingIndexPremium}
            variant='outlined'
            fullWidth
            disabled={watchPricingMechanism !== 'INDEX'}
            InputProps={{
              inputProps: { step: 0.01, min: -100, max: 100 },
              startAdornment: (
                <InputAdornment position='start' sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>%</b>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name='pricingIndexPremiumOverrideSun'
            label='Index Premium Override for Sundays'
            type='number'
            defaultValue={defaultValues.pricingIndexPremiumOverrideSun}
            {...register('pricingIndexPremiumOverrideSun')}
            inputRef={register}
            onChange={() => {
              if (defaultValues.id) {
                triggerSubmitEvent(formRef)
              }
            }}
            variant='outlined'
            InputProps={{
              inputProps: { step: 0.01, min: -100, max: 100 },
              startAdornment: (
                <InputAdornment position='start' sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>%</b>
                </InputAdornment>
              ),
            }}
            fullWidth
            disabled={watchPricingMechanism !== 'INDEX'}
          />
          <TextField
            name='minTenderLeadTimeHrs'
            label='Minimum Tender Lead Time (Hours)'
            type='number'
            defaultValue={defaultValues.minTenderLeadTimeHrs}
            {...register('minTenderLeadTimeHrs')}
            inputRef={register}
            onChange={() => {
              if (defaultValues.id) {
                triggerSubmitEvent(formRef)
              }
            }}
            variant='outlined'
            InputProps={{
              inputProps: { step: 1, min: 0, max: 336 },
            }}
            fullWidth
            disabled={watchPricingMechanism !== 'INDEX'}
          />

          {watchPricingMechanism === 'INDEX' && (
            <FormControlLabel
              label='Index pricing is all-in (no additional fuel surcharge will be applied)'
              control={
                <Controller
                  name='allin'
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked)
                        if (defaultValues.id) {
                          triggerSubmitEvent(formRef)
                        }
                      }}
                    />
                  )}
                />
              }
            />
          )}

          <Controller
            name='fscId'
            control={control}
            rules={{ required: !watchAllIn }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={FSC_OPTIONS || []}
                isOptionEqualToValue={(option, selected) => option.id === selected.id}
                getOptionLabel={(option) => option.name || option}
                onChange={(_, data) => {
                  onChange(data)
                  if (defaultValues.id) {
                    triggerSubmitEvent(formRef)
                  }
                }}
                disabled={watchAllIn}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Fuel Surcharge Schedule'
                    error={!!errors.fscId}
                    helperText={errors.fscId ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
        </FormItem>

        <FormItem>
          {defaultValues.contractPartyFlag !== 'SHIPPER' && (
            <>
              <Typography variant='h5'>Asset Pricing</Typography>

              <TextField
                name='assetPricing'
                label='Rate Per Asset'
                type='number'
                defaultValue={defaultValues.assetPricing}
                inputRef={register}
                onChange={() => {
                  if (defaultValues.id) {
                    triggerSubmitEvent(formRef)
                  }
                }}
                variant='outlined'
                fullWidth
                disabled={watchPricingMechanism === 'INDEX'}
                InputProps={{
                  inputProps: { min: 0.1, step: 0.01 },
                  startAdornment: (
                    <InputAdornment
                      position='start'
                      sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}
                    >
                      <b>$</b>
                    </InputAdornment>
                  ),
                }}
              />
              <Controller
                name='assetPricingFrequency'
                control={control}
                rules={{ required: watchAssetPricing !== '' }}
                render={({ value, onChange }) => (
                  <Form.Generic.Autocomplete
                    value={value}
                    options={ASSET_PRICING_FREQUENCY_OPTIONS}
                    isOptionEqualToValue={(option, selected) => option === selected}
                    getOptionLabel={(option) => option}
                    onChange={(_, data) => {
                      onChange(data)
                      if (defaultValues.id) {
                        triggerSubmitEvent(formRef)
                      }
                    }}
                    disabled={watchPricingMechanism === 'INDEX'}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Asset Pricing Frequency'
                        error={watchAssetPricing !== '' ? !!errors.assetPricingFrequency : false}
                        helperText={errors.assetPricingFrequency ? 'Required field' : null}
                      />
                    )}
                  />
                )}
              />
              <TextField
                name='minChargeableMiles'
                label='Min Chargeable Miles'
                type='number'
                defaultValue={defaultValues.minChargeableMiles}
                inputRef={register}
                onChange={() => {
                  if (defaultValues.id) {
                    triggerSubmitEvent(formRef)
                  }
                }}
                disabled={watchPricingMechanism === 'INDEX'}
                variant='outlined'
                fullWidth
                InputProps={{
                  inputProps: { min: 0.1, step: 0.01 },
                  startAdornment: (
                    <InputAdornment
                      position='start'
                      sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}
                    >
                      <b>mi</b>
                    </InputAdornment>
                  ),
                }}
              />
              <Controller
                name='minChargeableMilesFrequency'
                control={control}
                rules={{ required: watchMinChargeableMiles !== '' }}
                render={({ value, onChange }) => (
                  <Form.Generic.Autocomplete
                    value={value}
                    options={MIN_CHARGEABLE_MILES_FREQUENCY_OPTIONS}
                    isOptionEqualToValue={(option, selected) => option === selected}
                    getOptionLabel={(option) => option}
                    onChange={(_, data) => {
                      onChange(data)
                      if (defaultValues.id) {
                        triggerSubmitEvent(formRef)
                      }
                    }}
                    disabled={watchPricingMechanism === 'INDEX'}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Min Chargeable Miles Frequency'
                        error={
                          watchMinChargeableMiles !== ''
                            ? !!errors.minChargeableMilesFrequency
                            : false
                        }
                        helperText={errors.minChargeableMilesFrequency ? 'Required field' : null}
                      />
                    )}
                  />
                )}
              />
            </>
          )}
        </FormItem>
      </FormContent>
      <FormFooter requiredText='* Fields are required'>
        <FormActions>
          <Button type='button' variant='outlined' size='large' onClick={back}>
            Previous
          </Button>
          {defaultValues.id !== '' && (
            <Button type='submit' variant='contained' size='large' disabled={!isValid}>
              continue
            </Button>
          )}
        </FormActions>
      </FormFooter>
    </form>
  )
}
