import axios from 'axios'

import {
  TRIP_TYPE_DISPLAY_NAMES,
  utility,
  LANE_COLORS,
  CONTRACT_TYPE,
  TRIP_TYPE,
} from '@leaf/components'

import {
  getContractRoutesGQL,
  getContractDetailsGQL,
  getContractDetailsLaneDescriptionGQL,
} from './GQL_CONTRACT_DETAILS'
import domicilesToText from '../domicilesToText'

const mapContractRoutes = (contracts) =>
  contracts.map((contract) => ({
    routes: contract?.contractRoutes.map((contractRoute) => {
      const legs = contractRoute?.route?.legs || []

      const firstLeg = legs?.[0]
      const lastLeg = legs?.[legs.length - 1]

      return {
        deadheadMiles: utility.format.miles(
          contractRoute?.route.avgDeadheadMiles
        ),
        totalMiles: utility.format.miles(contractRoute?.route.avgTotalMiles),
        tripType: TRIP_TYPE_DISPLAY_NAMES[contractRoute?.route.tripType],
        isPrimary: contractRoute?.isPrimary,
        id: contractRoute?.route.routeId,
        displayOriginName: firstLeg?.lane?.originName,
        displayDestinationName:
          contractRoute?.route.tripType === TRIP_TYPE.ROUND_TRIP
            ? firstLeg?.lane?.destinationName
            : lastLeg?.lane?.destinationName,
        legs: contractRoute.route.legs.map((leg) => ({
          origin: leg?.lane?.originName,
          pickupType: leg?.lane?.pickupType,
          destination: leg?.lane?.destinationName,
          deliveryType: leg?.lane?.deliveryType,
          miles: utility.format.miles(leg?.lane?.leafMiles),
          laneId: leg?.lane?.laneId,
          deadheadMiles: utility.format.miles(leg?.deadheadMiles) ?? '',
          originGeo: leg?.lane?.originGeo,
          originH3: leg?.lane?.originH3Cells,
          originPoint: leg?.lane?.originPoint,
          destinationGeo: leg?.lane?.destinationGeo,
          destinationH3: leg?.lane?.destinationH3Cells,
          destinationPoint: leg?.lane?.destinationPoint,
          pathGeo: leg?.lane?.path,
        })),
        editLegs: contractRoute.route.legs,
      }
    }),
  }))

export const mapDataToRouteSchedule = (legs) => {
  const retVal = []
  legs.forEach((leg, index) => {
    retVal.push({
      data: {
        origin: leg.origin,
        pickupType: leg.pickupType,
        destination: leg.destination,
        deliveryType: leg.deliveryType,
        miles: leg.miles,
        laneId: leg.leniId,
        type: 'PTP',
      },
      meta: {
        position: index * 2 + 1,
        color: LANE_COLORS[index],
      },
    })
    retVal.push({
      data: {
        deadheadMiles: leg.deadheadMiles,
      },
    })
  })

  return retVal
}

const mapVolumePattern = (volumePattern) => {
  let retVal = {}
  if (volumePattern) {
    const patternArray = volumePattern.split(',')
    patternArray.forEach((item) => {
      const dayArray = item.split('=')
      retVal = {
        ...retVal,
        [dayArray[0]]: dayArray[1],
      }
    })
  }

  return retVal
}

const mapContractDetails = (contracts) => {
  let retVal = {}

  contracts.forEach((contract) => {
    retVal = {
      contractType:
        contract.contract_type === CONTRACT_TYPE.DEDICATED && contract.is_fleet
          ? CONTRACT_TYPE.FLEET
          : contract.contract_type ?? '-',
      contractParty: contract.contract_party ?? '-',
      contractStatus: contract.contract_status ?? '-',
      equipmentType: contract?.equipment_type?.name ?? '-',
      accessorials: contract?.contract_accessorials.map(
        ({ accessorial }) => accessorial
      ),
      incumbentContract: '-',
      opsPlansIds: contract?.shipper_route_plans.map(
        ({ shipper_route_plan_id }) => shipper_route_plan_id
      ),
      domicile: domicilesToText(contract?.dedicated_domiciles) || '-',
      notes: contract.notes ?? '-',

      startDate:
        utility.date.backendStringToViewString(contract.start_date) ?? '-',
      endDate: utility.date.backendStringToViewString(contract.end_date) ?? '-',
      volumeType: contract.volume_type ?? '-',
      volumeFrequency: contract.volume_frequency ?? '-',
      minVolume: contract.min_volume ?? '-',
      maxVolume: contract.max_volume ?? '-',
      includeWeekends: contract.include_weekends ? 'Yes' : 'No',

      pricingMechanism: contract.pricing_mechanism ?? '-',
      linehaulRPM: contract.linehaul_rpm ?? '-',
      carrierUpcharge: utility.numbers.toPercent(contract?.carrier_upcharge),
      pricingIndex: contract.pricing_index ?? '-',
      pricingIndexPremium: utility.numbers.toPercent(
        contract?.pricing_index_premium
      ),
      pricingIndexPremiumOverrideSun: utility.numbers.toPercent(
        contract?.pricing_index_premium_override_sun
      ),
      fuelSurchargeSchedule: contract.fuel_surcharge_schedule?.name ?? '-',
      ratePerAsset: contract.asset_pricing ?? '-',
      assetPricingFrequency: contract.asset_pricing_frequency ?? '-',
      minChargeableMiles: contract.min_chargeable_miles ?? '-',
      minChargeableMilesFrequency:
        contract.min_chargeable_miles_frequency ?? '-',
      volumePattern: mapVolumePattern(contract.volume_pattern),
      seller: contract?.seller,
      buyer: contract?.buyer,
      isBackupAward: contract?.is_backup_award,
      dedicatedDomiciles: contract?.dedicatedDomiciles,
      minTenderLeadTimeHrs: contract?.min_tender_lead_time_hrs,
      fixedFeePerShipment: contract?.fixed_fee_per_shipment,
      minimumLinehaulCharge: contract?.minimum_linehaul_charge,
    }
  })

  return retVal
}

function extractContractDetailsLaneDescription(response) {
  // Why do we pass multiple contracts and call it contract???
  const contract = response.contract[0]
  const details = {
    contractStatus: contract.contractStatus,
    domiciles: contract.dedicated_domiciles,
    isBuyerContract: !!contract.buyerId,
    buyerId: contract.buyerId,
    sellerId: contract.sellerId,
  }
  // Why do we have multiple routes that we overwrote in the old code?
  const contractRoute = contract.contractRoutes[0]
  if (contractRoute) {
    const { legs } = contractRoute.route
    if (legs && legs.length !== 0) {
      const originLeg = legs[0]
      const destinationLeg = legs[legs.length - 1]
      details.route = {
        origin: originLeg?.lane?.originName ?? '',
        destination: destinationLeg?.lane?.destinationName ?? '',
      }
    }
  }
  return details
}

/**
 * Use by RoutesOverview
 * @param {*} getGQLClient
 * @param {*} id
 * @returns
 */
export const getContractRoutes = (getGQLClient, id) => {
  const GQL = getContractRoutesGQL(id)
  return getGQLClient().then((client) =>
    client.request(GQL).then((res) => ({
      data: mapContractRoutes(res.contract),
    }))
  )
}

export const getContractDetails = (getGQLClient, id) => {
  const GQL = getContractDetailsGQL(id)
  return getGQLClient().then((client) =>
    client.request(GQL).then((res) => ({
      data: mapContractDetails(res.contract),
    }))
  )
}

export const getContractDetailsLaneDescription = (getGQLClient, id) => {
  const GQL = getContractDetailsLaneDescriptionGQL(id)
  return getGQLClient().then((client) =>
    client.request(GQL).then(extractContractDetailsLaneDescription)
  )
}

export const deleteContract = (id) =>
  axios.delete(`contracting/contracts/${id}`).then((response) => response)
