/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { PolygonMap, Form } from '..'
import { v4 } from 'uuid'
import RouteCard from './RouteCard'
import { debounce } from 'lodash'

const ContainerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

const RouteSelectContainer = styled.div`
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2)}`};
  background-color: #fafafa;
`

const RouteCardContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  min-width: 450px;
`

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`

const { Select } = Form.Generic

const RoutesOverview = ({ routesData, copyRoute, editDeleteRouteAction }) => {
  const [routes, setRoutes] = useState([])
  const [mapData, setMapData] = useState([])
  const [primaryRoute, setPrimaryRoute] = useState(null)

  useEffect(() => {
    if (routesData?.routes) {
      setRoutes(
        routesData.routes.map((item) => ({
          ...item,
          expand: routesData?.routes.length === 1,
          hover: false,
        }))
      )
    }
  }, [routesData])

  useEffect(() => {
    if (routes?.find((singleRoute) => singleRoute.expand)) {
      routes
        ?.filter((singleRoute) => singleRoute.expand)
        .map((singleRoute) => {
          setMapData(
            singleRoute?.legs.map((leg) => ({
              originGeo: leg.originGeo,
              destinationGeo: leg.destinationGeo,
              pathGeo: leg.pathGeo,
            }))
          )
        })
    } else {
      let mapDataHelper = []
      routes.map((singleRoute, index) => {
        mapDataHelper = [
          ...mapDataHelper,
          ...singleRoute?.legs.map((leg) => ({
            pathGeo: leg.pathGeo,
            hover: singleRoute.hover,
            routeIndex: index,
          })),
        ]
      })
      mapDataHelper.sort((a, b) => a.hover - b.hover)
      setMapData(mapDataHelper)
    }

    setPrimaryRoute(routes.find((item) => item.isPrimary === true))
  }, [routes])

  const handleExpand = (event, routeId) => {
    event.stopPropagation()
    setRoutes((prevRoutes) =>
      prevRoutes.map((item) => ({
        ...item,
        expand: item.id === routeId ? !item.expand : false,
      }))
    )
  }

  const debounceHoverCard = useCallback(
    debounce((routeId) => {
      setRoutes((prevRoutes) =>
        prevRoutes.map((item) => ({
          ...item,
          hover: item.id === routeId,
        }))
      )
    }, 100),
    []
  )

  const handleHoverOn = (event, routeId) => {
    event.stopPropagation()
    debounceHoverCard(routeId)
  }

  const handleHoverOff = (event, routeId) => {
    event.stopPropagation()
    debounceHoverCard()
  }

  const handleChangePrimaryRoute = (event) => {
    const selectedRoute = routes.find((item) => item.id === event.target.value)
    setPrimaryRoute(selectedRoute)
    editDeleteRouteAction('setPrimaryRoute', selectedRoute)
  }

  return (
    <ContainerWrapper>
      <RouteCardContainer>
        <RouteSelectContainer>
          {editDeleteRouteAction && (
            <Select
              label='Display Route on summary page'
              name='primaryRoute'
              defaultValue={primaryRoute?.id}
              required
              fullWidth
              disabled={!editDeleteRouteAction}
              onChange={handleChangePrimaryRoute}
              options={routes.map((item) => ({
                id: item.id,
                label: `${item.displayOriginName} - ${item.displayDestinationName}`,
              }))}
            />
          )}
          {routes.map((route, index) => (
            <RouteCard
              key={v4()}
              route={route}
              handleExpand={handleExpand}
              routeIndex={index}
              handleHoverOn={handleHoverOn}
              handleHoverOff={handleHoverOff}
              copyRoute={copyRoute}
              canExpand={routes.length > 1}
              editDeleteRouteAction={editDeleteRouteAction}
            />
          ))}
        </RouteSelectContainer>
      </RouteCardContainer>
      <MapContainer>
        <PolygonMap data={mapData} />
      </MapContainer>
    </ContainerWrapper>
  )
}

export default RoutesOverview
