import React from 'react'
import { utility, DetailsOverview } from '@leaf/components'

const calculateDeadheadMiles = (move) => {
  let deadheadSum = 0
  if (move.deadheadL0L1) deadheadSum += move.deadheadL0L1
  if (move.deadheadL1L2) deadheadSum += move.deadheadL1L2
  if (move.deadheadFinal) deadheadSum += move.deadheadFinal

  return utility.format.miles(deadheadSum)
}

const calculateDeadheadMilesPercent = (move) => {
  let deadheadSum = 0
  if (move.deadheadL0L1) deadheadSum += move.deadheadL0L1
  if (move.deadheadL1L2) deadheadSum += move.deadheadL1L2
  if (move.deadheadFinal) deadheadSum += move.deadheadFinal

  const { totalMiles } = move

  return utility.numbers.toPercent((deadheadSum / totalMiles) * 100)
}

const AdaptNetworkMoveDetailsInfo = ({ move }) => (
  <DetailsOverview
    title="SUMMARY"
    data={[
      {
        type: 'row',
        data: {
          label: 'Network',
          value: move?.networkType,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Type',
          value: move?.moveType,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Balance Type',
          value: move?.balanceType,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Total miles (includes all deadhead)',
          value: move?.view.totalMiles,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Total loaded miles',
          value: move?.view.totalLoadedMiles,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Deadhead miles',
          value: calculateDeadheadMiles(move),
        },
      },
      {
        type: 'row',
        data: {
          label: 'Deadhead miles (%)',
          value: calculateDeadheadMilesPercent(move),
        },
      },
      {
        type: 'row',
        data: {
          label: 'Trip duration (days)',
          value: move?.days,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Possible annual trips',
          value: move?.view.minTrips,
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'row',
        data: {
          label: 'Cost Savings per Trip',
          value: utility.table.signed(move.margin),
        },
      },
      {
        type: 'row',
        data: {
          label: 'Annualized Cost Savings',
          value: utility.table.signed(move.benefit),
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'row',
        data: {
          label: 'Supply target day rate',
          value: move?.view.supplyTargetDayRateLsp,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Supply target linehaul',
          value: move?.view.supplyTargetLinehaulLsp,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Leaf daily linehaul',
          value: move?.view.leafDailyLinehaulLsp,
        },
      },
      {
        type: 'row',
        data: {
          label: 'Shipper trigger linehaul',
          value: move?.view.shipperTriggerLinehaulLsp,
        },
      },
    ]}
  />
)

export default AdaptNetworkMoveDetailsInfo
