import React from 'react'
import { withRouter } from 'react-router-dom'
import { Box, Grid, Alert } from '@mui/material'
import { useSnackbar } from 'notistack'

import {
  Page,
  BackButton,
  PolylineMap,
  RouteSchedule,
  RouteDataTable,
  Tabs,
  useLoading,
  PageContentLayout,
  PageContent,
  SpacedContainer,
} from '@leaf/components'

import AdaptNetworkMove from '@/domain/adapt/AdaptNetworkMove'
import Beta from '@/components/Beta'

import styled from '@emotion/styled'
import AdaptNetworkMoveDetailsInfo from './AdaptNetworkMoveDetailsInfo'

const MapWrapper = styled.div`
  height: auto;
  width: 100%;
  border-left: ${({ theme }) => `1px solid ${theme.palette.actions.border}`};
`

const AdaptNetworkMoveDetails = ({
  history,
  location,
  match: {
    params: { id },
  },
}) => {
  const [adaptNetworkMove, setAdaptNetworkMove] = React.useState(null)

  const { enqueueSnackbar } = useSnackbar()
  const [getAdaptNetworkMove, isAdaptNetworkMoveLoading] = useLoading(
    async () => AdaptNetworkMove.get(id).then(setAdaptNetworkMove).catch(enqueueSnackbar),
    true
  )

  React.useEffect(() => {
    getAdaptNetworkMove()
  }, [id])

  const onLaneCardClick = (data) => {
    if (data?.shipperId && data?.laneId && data?.hasExplorer)
      history.push(`/adapt/explorer/companies/${data?.shipperId}/lanes/${data?.laneId}`)
  }

  return (
    <Page
      title={`Adapt Network Move ${id}`}
      ComponentTitleLeft={<BackButton history={history} />}
      ComponentTitleRight={<Beta />}
      lazySpinner={isAdaptNetworkMoveLoading}
    >
      <Alert
        severity='error'
        sx={{
          display: 'flex',
          width: '100vw',
          fontSize: '1.5em',
          fontWeight: 'bold',
        }}
      >
        There&#39;s a newer version of this page available{' '}
        <a href='https://admin2.leaflogistics.com/adapt/network-moves'>here</a>. Please note that
        there will be no further updates or fixes, use it at your own risk.
      </Alert>

      <SpacedContainer container spacing={2}>
        <Grid item md={12} lg={3}>
          <PageContentLayout withoutSpacing fullHeight={false}>
            <AdaptNetworkMoveDetailsInfo move={adaptNetworkMove} />
          </PageContentLayout>
        </Grid>

        <Grid item md={12} lg={9}>
          <PageContentLayout withoutSpacing fullHeight={false}>
            <PageContent fullHeight style={{ display: 'flex' }}>
              <Tabs
                location={location}
                history={history}
                mainUrlPath={`/adapt/network-moves/${id}`}
                isStickyHeader
                tabs={[
                  {
                    label: 'Route',
                    content: (
                      <Box display='flex'>
                        <RouteSchedule
                          route={adaptNetworkMove?.toRouteFormat()}
                          offsetCount={0}
                          onCardClick={onLaneCardClick}
                        />
                        <MapWrapper>
                          <PolylineMap dataList={adaptNetworkMove?.toMapFormat()} outlinedLine />
                        </MapWrapper>
                      </Box>
                    ),
                  },
                  {
                    label: 'Lane Details',
                    content: (
                      <Box display='flex' position='relative' height={1}>
                        <RouteSchedule
                          route={adaptNetworkMove?.toRouteFormat()}
                          shouldFitContainerHeight={false}
                          onCardClick={onLaneCardClick}
                        />
                        <RouteDataTable
                          header={[
                            'Mean RPM',
                            'Annual loads',
                            'Weekly base',
                            'Low Volume Flag',
                            'Outlier Flag',
                          ]}
                          body={adaptNetworkMove?.toRouteDataTableFormat()}
                          isFullLaneRowSpan
                        />
                      </Box>
                    ),
                  },
                ]}
              />
            </PageContent>
          </PageContentLayout>
        </Grid>
      </SpacedContainer>
    </Page>
  )
}

export default withRouter(AdaptNetworkMoveDetails)
